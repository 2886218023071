<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          <Icon icon="scanIcon" :xlarge="true" color="red" class="v-icon" />
        </v-card-title>
        <v-card-text>
          <v-layout column wrap class="text-center">
            <v-flex xs12> {{ $t("get-closer") }} </v-flex>
            <v-flex xs12 v-if="$vuetify.breakpoint.smAndDown">
              <small> {{ $t("toFocus") }} </small>
            </v-flex>
          </v-layout>
          <template v-if="!success">
            <StreamBarcodeReader @decode="onDecode" />
          </template>
          <template v-else>
            <v-layout row wrap justify-center>
              <Icon icon="checkIcon" size="80" color="success" class="v-icon" />
            </v-layout>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="$emit('close')">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
  name: "QrModal",
  props: ["activator"],
  components: { StreamBarcodeReader },
  data() {
    return {
      dialog: false,
      success: false,
    };
  },

  created() {
    this.dialog = this.activator;
  },
  watch: {
    activator() {
      this.dialog = this.activator;
    },
  },
  methods: {
    onDecode(decoded) {
      let decodedArr = decoded.split("\r\n");
      let validMac = null;
      decodedArr.forEach((optMac) => {
        const valid = this.checkMac(optMac);
        if (valid) validMac = optMac;
      });
      this.success = true;
      setTimeout(() => {
        this.$emit("setMac", validMac);
      }, 1500);
      // console.log(`Decode text from QR code is ${x}`);
    },
    // onLoaded() {
    //   console.log(`Ready to start scanning barcodes`);
    // },
    checkMac(text) {
      const regex = new RegExp("^([0-9a-f](:)?){12}$", "gmi");
      return regex.exec(text) != null;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-icon {
  margin: 0 auto;
}
.v-dialog > .v-card > .v-card__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
