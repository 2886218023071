<template>
  <v-layout row wrap justify-center class="mx-auto main mb-3 px-2">
    <v-flex
      xs12
      d-flex
      row
      justify-space-between
      align-center
      class="text-xs-center pa-3 header"
      elevation-2
    >
      <div class="d-flex ms-2">
        <Icon class="ms-2" icon="dateRange" color="switchq lighten-1" />
        <h3 class="ms-2">{{ content.schedule }}</h3>
      </div>
      <v-btn color="switchq lighten-1" text @click="openDialog()">
        {{ content.new_schedule }}
        <Icon iconClass="ms-2" icon="Plus" color="switchq lighten-1" />
      </v-btn>
    </v-flex>
    <v-flex xs10>
      <v-layout column v-if="schedules && Object.keys(schedules).length">
        <v-flex
          grow
          v-for="(sched, index) in schedules"
          :key="index"
          class="schedule white mt-2"
          elevation-1
        >
          <v-layout row nowrap align-center class="black--text px-3 py-2">
            <v-layout column>
              <v-flex v-if="sched.days.length < 7" xs10 md8>
                <span v-for="(day, dKey) in sched.days" :key="dKey">
                  <small>
                    {{ days[day] }}
                  </small>
                  <span shrink v-if="dKey + 1 != sched.days.length">, </span>
                </span>
              </v-flex>
              <v-flex v-else>
                <span>{{ content.all_days }}</span>
              </v-flex>
              <v-divider />
              <v-flex class="mt-1">
                {{ sched.start_at }} - {{ sched.end_at }}
              </v-flex>
            </v-layout>
            <v-flex xs2 md4>
              <v-layout row wrap justify-end>
                <v-btn
                  text
                  icon
                  color="switchq lighten-1"
                  @click="openDialog(sched, index)"
                >
                  <Icon icon="edit" />
                </v-btn>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-else>
        <v-flex xs12 class="white">
          <div class="text-xs-center pa-2">
            {{ content.empty }}
          </div>
        </v-flex>
      </v-layout>
    </v-flex>

    <schedialog
      :locale="locale"
      :status="dialog"
      :data="dialogData"
      @modifyScheduleEvent="modify"
    ></schedialog>
  </v-layout>
</template>

<script>
import Schedialog from "./edit/scheduleDialog";
export default {
  props: ["locale", "action"],
  components: { Schedialog },
  data() {
    return {
      schedules: null,
      dialog: false,
      dialogData: null,
      modifiedSched: null,
      edit: false,
    };
  },
  created() {
    this.setLang();
    this.schedules = this.data;
    this.analyze();
  },
  computed: {
    data() {
      return this.$store.getters["devices/device"](this.$route.params.slug).data
        .schedule;
    },
  },
  watch: {
    data() {
      this.schedules = this.data;
      if (this.schedules) {
        this.analyze();
        if (this.edit) {
          this.edit = false;
          this.clear();
        }
      }
    },
    action() {
      if (this.action == 1) {
        this.openDialog();
      } else if (this.action == 2) {
        this.modifySchedule([
          {
            days: [1, 2, 3, 4, 5, 6, 7],
            start_at: "00:00",
            end_at: "00:00",
          },
        ]);
      }
    },
  },
  methods: {
    analyze() {
      for (let i = 0; i < this.schedules.length; i++) {
        if (!isNaN(this.schedules[i].start_at)) {
          this.schedules[i].start_at = this.getTimeByMins(
            this.schedules[i].start_at
          );
        }
        if (!isNaN(this.schedules[i].end_at)) {
          this.schedules[i].end_at = this.getTimeByMins(
            this.schedules[i].end_at
          );
        }
      }
    },
    getTimeByMins(mins) {
      return (
        `0${(mins / 60) ^ 0}`.slice(-2) + ":" + ("0" + (mins % 60)).slice(-2)
      );
    },

    modify(sched) {
      let schedule = this.schedules;
      if (sched.delete) {
        delete schedule[this.modifiedSched];
        schedule = schedule.filter(function (e) {
          return e;
        });
      } else if (this.modifiedSched != null) {
        schedule[this.modifiedSched] = sched;
      } else {
        schedule.push(sched);
      }
      // console.log(schedule);
      this.modifySchedule(schedule);
    },
    modifySchedule(schedule) {
      let data = {
        slug: this.$route.params.slug,
        modify: {
          parameter: "schedule",
          value: schedule,
        },
      };
      this.edit = this.$route.params.slug;
      this.$store.dispatch("devices/modifyDevice", data);
      // this.$emit('modifyItemEvent', data);
    },

    openDialog(sched = null, index = null) {
      this.dialog += 1;
      this.dialogData = sched;
      this.modifiedSched = index;
    },
    clear() {
      this.dialog = false;
    },

    setLang() {
      if (this.locale == "en") {
        this.days = [null, "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        this.daysOptions = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        this.content = {
          schedule: "Schedule",
          new_schedule: "New Schedule",
          all_days: "All Days",
          edit: "Edit Schedule",
          agree: "Agree",
          save: "Save",
          cancel: "Cancel",
          start_at: "Start at",
          end_at: "End at",
          days: "Days",
          delete: "Delete",
          ask_delete: "Are you sure you would like to delete?",
          yes: "Yes",
          no: "No",
          empty: "Empty Schedule",
        };
      } else if (this.locale == "he") {
        this.days = [
          null,
          "ראשון",
          "שני",
          "שלישי",
          "רביעי",
          "חמישי",
          "שישי",
          "שבת",
        ];
        this.daysOptions = [
          "ראשון",
          "שני",
          "שלישי",
          "רביעי",
          "חמישי",
          "שישי",
          "שבת",
        ];
        this.content = {
          schedule: "לוח זמנים",
          new_schedule: "תזמון חדש",
          all_days: "כל הימים",
          edit: "עריכת תזמון",
          agree: "מסכים",
          save: "שמירה",
          cancel: "ביטול",
          start_at: "מתחיל ב-",
          end_at: "נגמר ב-",
          days: "ימים",
          delete: "מחיקה",
          ask_delete: "אתה בטוח שברצונך למחוק?",
          yes: "כן",
          no: "לא",
          empty: "לוח זמנים ריק",
        };
      }
    },
  },
};
</script>
<style lang="css" scoped>
/* .main { */
/* border: 1px solid rgba(25, 118, 210, 0.3); */
/* } */

.header {
  /* border: 1px solid rgba(25, 118, 210, 0.3); */
  border-radius: 10px;
  /* border-bottom: 1px solid rgba(25, 118, 210, 0.3); */
  background-color: rgba(25, 118, 210, 0.1);
}
.schedule {
  border-radius: 10px;
}
</style>
