<template>
  <!-- <div class="searcher"> -->
  <v-text-field
    class="searcher"
    rounded
    dense
    hide-details
    background-color="white"
    :placeholder="$t('search')"
    v-model="deviceSearch"
    append-icon="search"
    clearable
    :autofocus="this.focused"
    @blur="tryBlur"
  ></v-text-field>
  <!-- :v-on="this.focused" -->
  <!-- <v-autocomplete
    v-model="device"
    flat
    :menu-props="{
      closeOnClick: true,
      closeOnContentClick: true,
      openOnClick: false,
      maxHeight: 200,
      offsetY: true,
    }"
    :items="allDevices"
    item-text="name"
    item-value="slug"
    :placeholder="$t('search')"
    hide-details
    background-color="white"
    dense
    rounded
    solo
  ></v-autocomplete> -->
  <!-- </div> -->
</template>
<script>
export default {
  name: "SearchInput",
  props: {
    focused: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //   device: null,
      allDevices: null,
    };
  },
  computed: {
    deviceSearch: {
      get() {
        return this.$store.getters["devices/deviceSearch"];
      },
      set(search) {
        this.$store.dispatch("devices/setSearchDevice", search);
      },
    },
    devices() {
      return this.$store.getters["devices/devices"];
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    tryBlur() {
      if (this.deviceSearch == null || this.deviceSearch == "") {
        this.close();
      }
    },
  },
  //   mounted() {
  //     this.allDevices = JSON.parse(JSON.stringify(Object.values(this.devices)));
  //     this.allDevices = this.allDevices.map((d) => {
  //       d.name = d.name + " - " + d.identifier;
  //       return d;
  //     });
  //   },
  //   watch: {
  //     device() {
  //       console.log("searching for ", this.device);
  //     },
  //   },
};
</script>
<style lang="scss">
.searcher {
  border: 1px lightgray solid;
  max-width: 350px;
  .v-input__icon {
    margin-left: -15px;
    margin-right: 10px;
    margin-top: 1px;
  }
}
</style>
