<template>
  <div :class="'logo-container ' + size">
    <img ref="logoImage" :src="src" class="transparent mx-auto" />
    <span v-if="!custom && showSlogan" id="logo-text" class="mx-2">
      <span id="logo-text-name">SwitchQ</span>
      <span id="logo-text-slogan">
        {{ $t("slogan") }}
      </span>
    </span>
  </div>
</template>
<script>
import auth from "@/helpers/auth";
export default {
  name: "Logo",
  props: {
    slogan: {
      default: true,
    },
    size: {
      default: "md",
    },
  },
  computed: {
    showSlogan() {
      const customApp = localStorage.getItem("customApp");
      if (!this.userValid) return this.slogan && !this.business.custom;
      return this.slogan && !this.business.custom && !customApp;
    },
    business() {
      return this.$store.getters["business/business"];
    },
    custom() {
      return this.$store.getters["general/custom"];
    },
    src() {
      const url = process.env.VUE_APP_ASSETS + "logo.png?token=";
      return url + auth.getToken();
    },
    userValid() {
      return this.$store.getters["user/valid"];
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 600px) {
  img {
    width: 100%;
    max-height: 120px;
    height: auto;
  }
}
@media screen and (max-width: 600px) {
  img {
    width: auto;
    height: auto;
    max-height: 50px;
  }
}

.logo-container {
  color: rgb(128, 130, 133);
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  direction: ltr;
  img {
    max-width: 300px;
    height: auto;
  }
  &.xs img {
    max-width: 150px;
  }
  &.sm img {
    max-width: 220px;
  }
  &.md img {
    width: auto;
  }
  #logo-text {
    display: flex;
    flex-direction: column;
    #logo-text-name {
      font-family: cabin;
      font-size: 3em;
      @media only screen and (max-width: 600px) {
        font-size: 2.5em;
      }
    }
    #logo-text-slogan {
      font-size: 1em;
      margin-top: -12px;
      text-align: center;
      @media only screen and (max-width: 600px) {
        font-size: 0.8em;
      }
    }
  }
}
</style>
