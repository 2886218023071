import Vue from "vue";
import VueRouter from "vue-router";
import Groups from "@/components/groups/Groups.vue";
import SubGroups from "@/components/groups/SubGroups.vue";
import EditGroups from "@/components/groups/EditGroups.vue";
import Main from "@/components/devices/Main.vue";
import Device from "@/components/devices/device.vue";
import Automations from "@/components/devices/automations.vue";
import Signin from "../views/user/Signin";
import NewDevice from "@/views/devices/NewDevice.vue";
import Logs from "@/components/logs/create.vue";
import Notifications from "@/components/User/notifications.vue";
import Guard from "@/helpers/guards";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "signin",
    component: Signin,
    meta: { requiresGuest: true },
  },
  {
    path: "/sign-up",
    name: "signup",
    component: () => import("@/views/user/Signup"),
    meta: { requiresGuest: true },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/components/User/profile"),
    meta: { requiresAuth: true },
  },
  {
    path: "/gateways",
    name: "GatewaysPage",
    component: () => import("@/components/devices/GatewaysPage"),
    meta: { requiresAuth: true },
  },
  {
    path: "/gateways/:identifier",
    name: "GatewaysPage",
    component: () => import("@/components/devices/gateway"),
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/Settings",
    name: "settings",
    component: () => import("@/components/Settings/settings"),
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/notifications",
    name: "notifications",
    component: Notifications,
    meta: { requiresAuth: true },
  },
  {
    path: "/",
    name: "home",
    component: Main,
    meta: { requiresAuth: true },
  },
  {
    path: "/devices",
    name: "devices",
    component: Main,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/devices/:slug",
        name: "device",
        component: Device,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/new-device/new",
    name: "new-device",
    component: NewDevice,
    meta: { requiresAuth: true },
  },
  {
    path: "/edit-view",
    name: "edit-view",
    component: () => import("@/components/devices/editview"),
    meta: { requiresAuth: true },
  },
  {
    path: "/groups",
    name: "groups",
    component: Groups,
    meta: { requiresAuth: true },
  },

  {
    path: "/groups/:gslug/sub-groups",
    name: "group-groups",
    component: SubGroups,
    meta: { requiresAuth: true },
    // children: [
    //   {
    //     path: "/groups/:gs/sub-groups/:sgs",
    //     name: "InsideSub",
    //     component: SubGroups,
    //     meta: { requiresAuth: true },
    //   },
    // ],
  },
  {
    path: "/groups/:gslug/sub-group",
    name: "InsideSub",
    component: SubGroups,
    meta: { requiresAuth: true },
  },
  {
    path: "/logs",
    name: "logs",
    component: Logs,
    meta: { requiresAuth: true },
  },
  {
    path: "/automations",
    name: "automations",
    component: Automations,
    meta: { requiresAuth: true },
  },
  {
    path: "/groups/edit",
    name: "edit-groups",
    component: EditGroups,
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/groups/edit/:gslug/sub-groups",
  //   name: "groupSubGroup",
  //   component: SubGroups,
  //   meta: { requiresAuth: true },
  // },

  {
    path: "/groups/:gslug/devices",
    name: "group-devices",
    component: Main,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/groups/:gslug/devices/:slug",
        name: "groupdevice",
        component: Device,
        meta: { requiresAuth: true },
      },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !Guard.validUser()) {
    return next("/login");
  } else if (to.meta.requiresGuest && Guard.validUser()) {
    return next("/");
  }

  if (to.path == "/") {
    let homepage = "/" + (localStorage.getItem("homePage") || "devices");
    return next(homepage);
  }
  next();
});

export default router;
