<style lang="scss" scoped>
.modeBtns {
  width: 100%;
  max-width: 300px;
  .modeBtn {
    height: 36px;
    width: 36px;
    // margin: 0 3px;
  }
  .big-btns {
    .modeBtn {
      height: 48px;
      width: 48px;
      margin: 0 3px;
    }
  }
}
</style>

<template>
  <v-flex d-flex nowrap row shrink class="modeBtns">
    <v-layout row nowrap justify-space-around :class="big ? 'big-btns' : ''">
      <v-flex shrink>
        <v-btn
          class="modeBtn btn-off"
          :loading="loading == 2 && device.status != 'Off'"
          :outlined="btnMode != 2"
          :icon="btnMode != 2"
          :fab="btnMode == 2"
          elevation="1"
          color="red"
          @click="changeMode(2)"
        >
          <Icon
            icon="flashOff"
            :large="big ? true : ''"
            :medium="!big ? true : ''"
            :color="btnMode === 2 ? 'white' : 'red'"
          />
        </v-btn>
      </v-flex>
      <v-flex shrink>
        <v-btn
          class="modeBtn btn-auto"
          :loading="loading == 0"
          :outlined="btnMode != 0"
          :icon="btnMode != 0"
          :fab="btnMode == 0"
          elevation="1"
          color="blue"
          @click="changeMode(0)"
        >
          <Icon
            icon="flashAuto"
            :large="big ? true : ''"
            :medium="!big ? true : ''"
            :color="btnMode === 0 ? 'white' : 'blue'"
          />
        </v-btn>
      </v-flex>
      <v-flex shrink>
        <v-btn
          class="modeBtn btn-on"
          :loading="loading == 1"
          :outlined="btnMode != 1"
          :icon="btnMode != 1"
          :fab="btnMode == 1"
          elevation="1"
          color="green"
          @click="changeMode(1)"
        >
          <Icon
            icon="flash"
            :large="big ? true : ''"
            :medium="!big ? true : ''"
            :color="btnMode === 1 ? 'white' : 'green'"
          />
        </v-btn>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
export default {
  props: ["slug", "big"],
  data() {
    return {
      loading: null,
    };
  },
  computed: {
    btnMode() {
      let device = this.device;
      if (device && device.data && !isNaN(device.data.mode)) {
        return device.data.mode;
      } else {
        return null;
      }
    },
    device() {
      return this.$store.getters["devices/devices"][this.slug];
    },
  },
  methods: {
    async changeMode(mode) {
      if (this.btnMode !== mode) {
        this.loading = mode;
        let data = {
          slug: this.slug,
          modify: {
            parameter: "mode",
            value: mode,
          },
        };
        let prevmode = { ...this.device.data.mode };
        await this.$store.dispatch("devices/modifyDevice", data);
        if (this.btnMode !== prevmode) {
          this.loading = null;
        }
      }
    },
  },
};
</script>
