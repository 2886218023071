<template>
  <v-layout
    column
    wrap
    class="groupTree"
    :style="{
      width: $vuetify.breakpoint.mdAndUp ? '1000px' : '',
      margin: $vuetify.breakpoint.mdAndUp ? '0 auto' : '',
    }"
  >
    <!-- class="draghandle" -->
    <draggable v-model="groups" handle=".draghandle">
      <groups-tree-item
        v-for="item in items"
        :key="item.slug"
        :item="item"
        :depth="0"
      />
    </draggable>
  </v-layout>
</template>
<script>
import GroupsTreeItem from "@/components/tools/groups-tree/GroupsTreeItem.vue";
import draggable from "vuedraggable";
import { mdiChevronDown, mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Lang from "@/helpers/lang";
export default {
  name: "GroupsTree",
  props: ["items"],
  components: {
    "groups-tree-item": GroupsTreeItem,
    draggable,
  },
  data() {
    return {
      keyboardArrowDown: mdiChevronDown,
      arrowRight: mdiChevronRight,
      arrowLeft: mdiChevronLeft,
      locale: Lang.get(),
      groupsData: [],
    };
  },
  mounted() {},
  computed: {
    groups: {
      get() {
        let groups = JSON.parse(JSON.stringify(this.items));
        groups = Object.values(groups).map((g) => {
          g.hasSubGroups =
            Object.values(groups).filter((grp) => grp.parent_slug == g.slug)
              .length > 0;
          return g;
        });
        return Object.values(groups).sort((a, b) => a.order - b.order);
      },
      set(groups) {
        this.groupsData = this.fixgroupsOrder(groups);
        // console.log("EDIT!");
        this.$store.dispatch("groups/editGroups", this.groupsData);
      },
    },
  },
  methods: {
    fixgroupsOrder(groups) {
      groups = Object.values(groups);
      for (let i = 0; i < groups.length; i++) {
        groups[i].order = i;
      }
      return groups;
    },
  },
};
</script>
<style lang="scss" scoped>
.groupTreeChildren {
  padding-inline-start: 16px;
}
</style>
