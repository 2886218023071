<template>
  <div class="selectors">
    <v-radio-group v-model="ifType" row>
      <v-radio :label="$t('byTime')" value="time" />
      <v-radio :label="$t('byDevice')" value="device" />
    </v-radio-group>
    <div v-if="ifType == 'device'">
      <v-select
        :items="devices"
        v-model="sensorVal"
        item-text="name"
        item-value="identifier"
        :label="$t('sensorSelect')"
      ></v-select>
      <v-select
        :items="sensorDevices"
        v-model="sensorDeviceVal"
        :label="$t('sensorDevSelect')"
      ></v-select>
      <v-select
        v-if="
          sensorVal == null ||
          ((device(sensorVal).type < 11 || device(sensorVal).type > 14) &&
            device(sensorVal).type != 21 &&
            device(sensorVal).type != 7)
        "
        :items="actions"
        v-model="ifAction"
        :label="$t('ifs')"
      ></v-select>

      <v-radio-group
        v-model="ifAction"
        row
        v-else-if="device(sensorVal).type == 7"
      >
        <v-radio
          class="radio"
          color="primary"
          :label="$t('lightOn')"
          value="1"
        ></v-radio>
        <v-radio
          class="radio"
          color="primary"
          :label="$t('lightOff')"
          value="0"
        ></v-radio>
      </v-radio-group>
      <v-radio-group
        v-model="ifAction"
        row
        v-else-if="device(sensorVal).type == 21"
      >
        <v-radio
          class="radio"
          color="primary"
          :label="$t('vibrate')"
          value="1"
        ></v-radio>
        <v-radio
          class="radio"
          color="primary"
          :label="$t('steady')"
          value="0"
        ></v-radio>
      </v-radio-group>
      <v-radio-group
        v-model="ifAction"
        row
        v-else-if="device(sensorVal).type >= 11 && device(sensorVal).type <= 14"
      >
        <v-radio
          class="radio"
          color="primary"
          :label="$t('open')"
          value="0"
        ></v-radio>
        <v-radio
          class="radio"
          color="primary"
          :label="$t('close')"
          value="1"
        ></v-radio>
      </v-radio-group>

      <v-radio-group
        v-model="radioVal"
        row
        v-if="
          sensorVal == null ||
          ((device(sensorVal).type <= 10 || device(sensorVal).type > 14) &&
            device(sensorVal).type != 21 &&
            device(sensorVal).type != 7)
        "
      >
        <v-radio :label="$t('staticTemp')" value="staticTemp"></v-radio>
        <v-radio :label="$t('toDeviceTemp')" value="toDeviceTemp"></v-radio>
      </v-radio-group>
      <v-text-field
        v-if="radioVal == 'staticTemp'"
        type="Number"
        :label="$t(sensorDeviceVal)"
        v-model="tempVal"
      ></v-text-field>
      <v-select
        v-if="radioVal == 'toDeviceTemp'"
        :items="deviceSelector"
        item-text="name"
        item-value="identifier"
        v-model="toSensorVal"
        :label="$t('sensorSelect')"
      ></v-select>
    </div>

    <div class="d-flex flex-column" v-if="ifType == 'time'">
      <h3 class="ms-2 text-decoration-underline">
        {{ $t("scheduale") }}
      </h3>
      <v-radio-group class="mt-2" v-model="schedVal" row>
        <v-radio :label="$t('24/7')" value="24/7"></v-radio>
        <v-radio :label="$t('dayTime')" value="dayTime"></v-radio>
      </v-radio-group>

      <v-layout row wrap>
        <v-btn-toggle
          :class="
            $vuetify.breakpoint.xs ? 'mb-2 justify-center' : 'mb-2 mx-auto'
          "
          v-model="toggle_days"
          v-if="schedVal == 'dayTime'"
          multiple
        >
          <v-btn value="sunday">
            {{ $t("weekDays.sunday") }}
          </v-btn>
          <v-btn value="monday">
            {{ $t("weekDays.monday") }}
          </v-btn>
          <v-btn value="tuesday">
            {{ $t("weekDays.tuesday") }}
          </v-btn>
          <v-btn value="wednesday">
            {{ $t("weekDays.wednesday") }}
          </v-btn>
          <v-btn value="thursday">
            {{ $t("weekDays.thursday") }}
          </v-btn>
          <v-btn value="friday">
            {{ $t("weekDays.friday") }}
          </v-btn>
          <v-btn value="saturday">
            {{ $t("weekDays.saturday") }}
          </v-btn>
        </v-btn-toggle>
      </v-layout>
      <v-layout v-if="schedVal == 'dayTime'" row wrap>
        <v-dialog
          ref="startDialog"
          v-model="startTime"
          :return-value.sync="startTimeVal"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startTimeVal"
              :label="$t('startTime')"
              prepend-icon="mdi-clock-time-four-outline"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            format="24hr"
            v-if="startTime"
            v-model="startTimeVal"
            full-width
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="startTime = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.startDialog.save(startTimeVal)"
            >
              {{ $t("confirm") }}
            </v-btn>
          </v-time-picker>
        </v-dialog>
        <v-dialog
          ref="endDialog"
          v-model="endTime"
          :return-value.sync="endTimeVal"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endTimeVal"
              :label="$t('endTime')"
              prepend-icon="mdi-clock-time-four-outline"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            format="24hr"
            v-if="endTime"
            v-model="endTimeVal"
            full-width
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="endTime = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.endDialog.save(endTimeVal)"
            >
              {{ $t("confirm") }}
            </v-btn>
          </v-time-picker>
        </v-dialog>
      </v-layout>
    </div>
    <v-layout row wrap justify-center class="my-3 mb-2">
      <v-btn color="primary" class="mx-8" @click="confirmIf">
        {{ $t("confirm") }}
      </v-btn>
      <v-btn @click="close" class="mx-8">
        {{ $t("cancel") }}
      </v-btn>
    </v-layout>
  </div>
</template>
<script>
export default {
  name: "automationsIfs",
  props: {
    config: {
      type: Boolean,
      default: false,
    },
    configData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      deviceSelector: [],
      sensorDeviceVal: null,
      sensorVal: null,
      toSensorVal: null,
      ifAction: null,
      ifType: null,
      radioVal: null,
      schedVal: null,
      endTimeVal: "23:59",
      startTimeVal: "00:00",
      startTime: null,
      endTime: null,
      toggle_days: [],

      actionVal: null,
      tempVal: null,
    };
  },
  computed: {
    automations() {
      let automations = this.$store.getters["automations/automations"];
      return automations;
    },

    devices() {
      let devices = this.$store.getters["devices/devices"];
      Object.values(devices).filter((d) => {
        return d.role == 0 && this.sensorDeviceVal in d.data.sensors
          ? this.deviceSelector.push({
              name: d.name,
              slug: d.slug,
              identifier: d.identifier,
              sensors: Object.keys(d.data.sensors),
              type: d.settings.sensor_type,
            })
          : "";
      });
      return Object.values(devices).map((d) => {
        return {
          name: d.name,
          slug: d.slug,
          identifier: d.identifier,
          sensors: Object.keys(d.data.sensors),
          type: d.settings.sensor_type,
        };
      });
    },
    // controllerDevices() {
    //   let devices = this.$store.getters["devices/devices"];
    //   return Object.values(devices).map((d) => {
    //     if (d.role == 1) {
    //       return {
    //         name: d.name,
    //         slug: d.slug,
    //         identifier: d.identifier,
    //         sensors: Object.keys(d.data.sensors),
    //         type: d.settings.sensor_type,
    //       };
    //     }
    //   });
    // },
    sensorDevices() {
      let sensors = [
        {
          value: "temperatures",
          text: this.$t("temperature"),
        },
        {
          value: "humidity",
          text: this.$t("humidity"),
        },
        {
          value: "range",
          text: this.$t("range"),
        },
        {
          value: "light",
          text: this.$t("light"),
        },
        {
          value: "isVibrating",
          text: this.$t("isVibrating"),
        },
        {
          value: "hall",
          text: this.$t("hall"),
        },
        {
          value: "water",
          text: this.$t("water"),
        },
        {
          value: "drycontact",
          text: this.$t("drycontact"),
        },
        {
          value: "pressure",
          text: this.$t("pressure"),
        },
        {
          value: "co2",
          text: this.$t("co2"),
        },
      ];
      if (this.sensorVal) {
        const deviceSensors = this.devices.filter(
          (d) => d.identifier == this.sensorVal
        )[0].sensors;
        return sensors.filter((s) => deviceSensors.includes(s.value));
      } else {
        return [];
      }
    },
    actions() {
      return [
        {
          value: ">",
          text: this.$t("bigger"),
        },
        {
          value: "<",
          text: this.$t("smaller"),
        },
        {
          value: "==",
          text: this.$t("equals"),
        },
        {
          value: "!=",
          text: this.$t("not-equals"),
        },
      ];
    },
  },
  mounted() {
    if (this.config) {
      // console.log("data: ", this.configData);
      if (this.configData.device) {
        this.ifType = "device";
        this.sensorVal = this.configData.device.if_sensor;
        this.sensorDeviceVal = this.configData.device.if_sensorDevice;
        this.ifAction = this.configData.device.if_action ?? null;
        this.radioVal = this.configData.device.state ?? null;
        if (
          (this.device(this.sensorVal).type <= 10 ||
            this.device(this.sensorVal).type > 14) &&
          this.device(this.sensorVal).type != 21 &&
          this.device(this.sensorVal).type != 7
        ) {
          this.configData.device.to_state.length <= 3
            ? (this.tempVal = this.configData.device.to_state)
            : (this.toSensorVal = this.configData.device.to_state);
        } else {
          this.tempVal = null;
          this.toSensorVal = null;
        }
      } else if (this.configData.scheduale) {
        this.ifType = "time";
        if (this.configData.scheduale.time) {
          this.schedVal = this.configData.scheduale.time;
        } else {
          this.schedVal = "dayTime";
          this.endTimeVal = this.configData.scheduale.endTime;
          this.startTimeVal = this.configData.scheduale.startTime;
          this.toggle_days = this.configData.scheduale.days;
        }
      }
    } else {
      this.ifType = "device";
      this.sensorDeviceVal = null;
      this.sensorVal = null;
      this.toSensorVal = null;
      this.ifAction = null;
      this.radioVal = null;
      this.actionVal = null;
      this.tempVal = null;
      this.schedVal = null;
      this.endTimeVal = "23:59";
      this.startTimeVal = "00:00";
      this.endTime = null;
      this.startTime = null;
      this.toggle_days = [];
    }
  },
  watch: {
    // sensorVal() {
    //   console.log(
    //     "sensorVal: ",
    //     this.device(this.sensorVal).type,
    //     this.device(this.sensorVal).type <= 10 &&
    //       this.device(this.sensorVal).type > 14 &&
    //       this.device(this.sensorVal).type != 21 &&
    //       this.device(this.sensorVal).type != 7
    //   );
    // },
  },
  methods: {
    device(identifier) {
      return this.devices.filter((d) => d.identifier == identifier)[0];
    },
    alertError(msg) {
      this.$store.dispatch("general/setAlert", {
        type: "oops",
        msg: msg,
      });
    },

    close() {
      this.sensorDeviceVal = null;
      this.sensorVal = null;
      this.toSensorVal = null;
      this.ifAction = null;
      this.radioVal = null;
      this.actionVal = null;
      this.tempVal = null;
      this.schedVal = null;
      this.endTimeVal = "23:59";
      this.startTimeVal = "00:00";
      this.endTime = null;
      this.startTime = null;
      this.toggle_days = [];
      this.$emit("closeForm");
    },
    async confirmIf() {
      let scheduale = {};
      let device = {};
      let data = {};

      if (this.ifType == "time") {
        if (
          (this.schedVal != "24/7" && this.toggle_days.length == 0) ||
          this.schedVal == null
        ) {
          this.alertError("mustFill");
        } else {
          if (this.schedVal == "24/7") {
            scheduale.time = "24/7";
          }
          if (this.schedVal == "dayTime") {
            scheduale.startTime = this.startTimeVal;
            scheduale.endTime = this.endTimeVal;
            scheduale.days = this.toggle_days;
          }
          data = {
            scheduale: Object.values(scheduale).length > 0 ? scheduale : null,
          };
          if (!this.config) {
            this.$emit("createNewIf", data);
          } else {
            this.$emit("updateNewIf", data);
          }
          this.close();
        }
      } else if (this.ifType == "device") {
        if (
          this.sensorVal &&
          (this.device(this.sensorVal).type <= 10 ||
            this.device(this.sensorVal).type > 14) &&
          this.device(this.sensorVal).type != 21 &&
          this.device(this.sensorVal).type != 7
        ) {
          if (
            (this.sensorVal &&
              this.sensorDeviceVal &&
              this.ifAction &&
              this.radioVal &&
              this.tempVal) ||
            (this.sensorVal &&
              this.sensorDeviceVal &&
              this.ifAction &&
              this.radioVal &&
              this.toSensorVal)
          ) {
            device = {
              if_sensor: this.sensorVal,
              if_sensorDevice: this.sensorDeviceVal,
              if_action: this.ifAction,
              state: this.radioVal,
              to_state: this.tempVal ? this.tempVal : this.toSensorVal,
            };
            data = {
              device: device,
            };
            if (!this.config) {
              this.$emit("createNewIf", data);
            } else {
              this.$emit("updateNewIf", data);
            }
            this.close();
          } else {
            this.alertError("mustFill");
          }
        } else {
          if (this.sensorVal && this.sensorDeviceVal && this.ifAction) {
            device = {
              if_sensor: this.sensorVal,
              if_sensorDevice: this.sensorDeviceVal,
              if_action: this.ifAction,
            };
            data = {
              device: device,
            };
            if (!this.config) {
              this.$emit("createNewIf", data);
            } else {
              this.$emit("updateNewIf", data);
            }
            this.close();
          } else {
            this.alertError("mustFill");
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pageHeader {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  margin: 0 auto;
}
.vertDivider {
  color: red;
}

.autoNameField {
  margin: 0 auto;
  padding-top: 50px;
  width: 85%;
}

.selectors {
  padding-top: 20px;
  margin: 0 auto;
  width: 85%;
}

.autoCard {
  width: 85%;
  text-align: start;
  .v-card__title {
    margin-inline-start: -14px;
    margin-inline-end: -14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
  }
  .delIcon {
    display: flex;
    .v-icon {
      cursor: pointer;
      color: red;
    }
  }
}
.v-input--selection-controls {
  margin-top: unset;
  padding-top: unset;
}
</style>
