import { render, staticRenderFns } from "./NotificationAlerts.vue?vue&type=template&id=3f167e0b&scoped=true&"
import script from "./NotificationAlerts.vue?vue&type=script&lang=js&"
export * from "./NotificationAlerts.vue?vue&type=script&lang=js&"
import style0 from "./NotificationAlerts.vue?vue&type=style&index=0&id=3f167e0b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f167e0b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VAlert,VLayout,VScaleTransition})
