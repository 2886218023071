import Lang from "@/helpers/lang";
import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
require("dayjs/locale/he");

dayjs.extend(utc);
dayjs.extend(timezone);

if (Lang.get() == "he") {
  dayjs.tz.setDefault("Asia/Jerusalem");
  dayjs.locale("he");
}

export default dayjs;
