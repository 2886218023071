<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <Icon icon="mdiClose" @click.native="$emit('close')" iconClass="pa-3" />
        <v-card-title class="text-h5">
          <Icon
            icon="alertOctagon"
            :xlarge="true"
            iconClass="mb-5"
            color="red"
          />
        </v-card-title>
        <v-card-text class="text-center">{{
          $t("empty-groups-first")
        }}</v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: "CantDeleteModal",
  props: ["activator"],
  data() {
    return {
      dialog: false,
    };
  },

  created() {
    this.dialog = this.activator;
  },
  watch: {
    activator() {
      this.dialog = this.activator;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-icon {
  margin: 0 auto;
}
.v-dialog > .v-card > .v-card__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
