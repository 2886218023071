<template>
  <img
    :src="require('@/assets/icons/' + iconName + '.svg')"
    :class="'s-icon ' + size"
  />
</template>
<script>
export default {
  name: "s-icon",
  props: {
    color: {
      type: String,
    },
    small: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconName() {
      return this.$slots.default[0].text;
    },
    size() {
      let size = "medium";
      ["small", "medium", "large", "xLarge"].forEach((s) => {
        if (this.$props[s]) size = s;
      });
      return size;
    },
  },
  //   mounted() {
  //     console.log("this.props", this.$props);
  //     console.log("size", this.size);
  //   },
};
</script>
<style lang="scss" scoped>
.s-icon {
  width: 100%;
  height: 100%;
  max-height: 16pt !important;
  max-width: 16pt;
  &.small {
    max-height: 11pt !important;
  }
  &.medium {
    max-height: 16pt !important;
  }
  &.large {
    max-height: 20pt !important;
  }
  &.xLarge {
    max-height: 24pt !important;
  }
}
</style>
