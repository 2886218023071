import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import "@/styles/app.scss";
import i18n from "./i18n";

Vue.config.productionTip = false;

const files = require.context("./components/global", true, /\.vue$/i);
files.keys().map((key) => {
  Vue.component(
    files(key).default.name ?? key.split("/").pop().split(".")[0],
    files(key).default
  );
});

const { host } = window.location;
const isCustomApp =
  !host.startsWith("localhost") &&
  !host.startsWith("127.0.0.1") &&
  !host.startsWith("app.switchq");

if (isCustomApp) {
  localStorage.setItem("customApp", true);
} else {
  localStorage.removeItem("customApp");
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
