import auth from "@/helpers/auth";
import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API;

if (auth.logged()) {
  axios.defaults.headers.Authorization = "Bearer " + auth.getToken();
}
const device_id = localStorage.getItem("device_identification");
if (device_id) {
  axios.defaults.headers["X-Device-Id"] = device_id;
}

// axios.defaults.headers.post["Content-Type"] = "application/json";
// axios.defaults.headers.post["Accept"] = "application/json";

export default axios;
