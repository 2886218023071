<template>
  <v-layout column wrap justify-center align-center class="SignInView pa-2">
    <div class="logo-container">
      <Logo />
    </div>
    <v-card
      :loading="loading"
      class="mx-auto my-4"
      max-width="374"
      min-width="300"
    >
      <template slot="progress">
        <v-progress-linear
          color="purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-card-title>{{ $t("signin") }}</v-card-title>

      <v-card-text class="pa-8">
        <v-form ref="form" @submit="submit">
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                v-model="form.email"
                type="email"
                :label="$t('email')"
                dir="ltr"
                :rules="[(v) => !!v || $t('validation.email')]"
                @keyup.enter="submit"
                autocomplete="username"
              >
                <Icon icon="emailFast" slot="prepend" color="switchq" />
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="form.password"
                :type="revealPassword ? 'text' : 'password'"
                :label="$t('password')"
                :rules="[(v) => !!v || $t('validation.password')]"
                autocomplete="current-password"
                @keyup.enter="submit"
              >
                <Icon
                  iconClass="pointer"
                  :icon="revealPassword ? 'eyeOff' : 'eye'"
                  @click.native="toggleRevealPassword"
                  slot="append"
                  color="switchq"
                />
                <Icon icon="lock" slot="prepend" color="switchq" />
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="font-weight-medium"
          color="switchq lighten-1"
          dark
          block
          @click="submit"
        >
          {{ $t("signin") }}
        </v-btn>
      </v-card-actions>
      <v-card-actions class="mt-2">
        <v-btn
          class="font-weight-bold"
          color="switchq lighten-1"
          text
          @click="$router.replace('/sign-up')"
        >
          {{ $t("signup") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="forgotDialog = true"
          class="font-weight-bold"
          color="red"
          text
        >
          {{ $t("forgotpw") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <locale />
    <ForgotModal v-if="forgotDialog" @closeDialog="forgotDialog = false" />
  </v-layout>
</template>
<script>
import lang from "@/helpers/lang";
import Logo from "@/components/app_parts/logo.vue";
import locale from "@/components/general/locale.vue";
import ForgotModal from "@/components/general/ForgotModal.vue";
export default {
  name: "SignIn",
  components: {
    locale,
    Logo,
    ForgotModal,
  },
  data: () => ({
    forgotDialog: false,
    loading: false,
    selection: 1,
    revealPassword: false,
    form: {
      email: "",
      password: "",
    },
  }),

  created() {
    this.locale = lang.get();
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        if (this.form.password == "12345678") {
          localStorage.setItem("first_login", true);
        }
        await this.$store.dispatch("user/signIn", this.form);
        this.$router.replace("/");
      }
    },
    toggleRevealPassword() {
      this.revealPassword = !this.revealPassword;
    },
  },
};
</script>

<style lang="scss" scoped>
.SignInView {
  min-height: 100vh;
  .logo-container {
    color: rgb(128, 130, 133);
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    direction: ltr;
  }
}
</style>
