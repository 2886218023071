<template>
  <v-layout
    row
    wrap
    class="groupTreeItem mt-0 py-2 elevation-2 rounded-lg mb-2"
    :style="{ backgroundColor: color }"
  >
    <v-flex xs12>
      <v-layout column wrap>
        <v-flex xs12 d-flex justify-space-between>
          <v-flex row @click="open = !open" class="pointer" align-center>
            <v-layout row wrap>
              <v-flex grow d-flex>
                <Icon icon="cursorMove" :class="handler" />
                <div :style="{ width: '30px' }">
                  <Icon
                    v-if="item.hasSubGroups"
                    :icon="open ? 'keyboardArrowDown' : sideArrow"
                  />
                </div>
                {{ item.name }}
              </v-flex>
              <v-flex shrink>
                <v-badge
                  v-if="item.children.length > 0"
                  bordered
                  overlap
                  :content="item.children.length"
                  color="switchq"
                >
                  <Icon icon="groupIcon" />
                </v-badge>
              </v-flex>
              <v-flex shrink> </v-flex>
              <v-badge
                v-if="item.devices.length > 0"
                bordered
                overlap
                :content="item.devices.length"
                color="switchq"
              >
                <Icon icon="sensors" color="blue" :large="true" />
              </v-badge>
            </v-layout>
          </v-flex>
          <v-flex shrink class="ms-2">
            <v-menu offset-y min-width="200px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <Icon icon="more" iconClass="mx-4" />
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(action, index) in actions" :key="index">
                  <v-list-item-title @click="action.value" class="pointer">
                    <Icon
                      :icon="action.icon"
                      v-if="!action.sIcon"
                      iconClass="me-2"
                    />
                    <Icon
                      :icon="action.icon"
                      v-if="action.sIcon"
                      iconClass="me-2"
                    />
                    {{ action.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
        </v-flex>
        <groups-tree-children
          v-show="open"
          v-if="item.hasSubGroups"
          :items="item.children"
          :key="item.slug"
          :name="item.slug"
          :depth="depth"
          class="mt-4 mb-2"
        />
      </v-layout>
    </v-flex>
    <DeleteModal
      :activator="!!deleteGroupSlug"
      @close="deleteGroupSlug = null"
      @delete="deleteGroup"
    />
    <CantDeleteModal :activator="!!cantDelete" @close="cantDelete = null" />
    <EditDeviceInGroupModal
      :activator="!!editDevices"
      :item="item"
      @close="editDevices = false"
    />
    <GroupName @close="editName = null" v-if="editName" :groupSlug="editName" />
    <create
      :dialog="createGroup"
      @close="(createGroup = false), (createSubGroup = false)"
      :parent="gslug && createSubGroup ? gslug : null"
    />
  </v-layout>
</template>
<script>
import GroupName from "@/components/groups/GroupsName.vue";
import create from "@/components/groups/create.vue";
import DeleteModal from "@/components/general/DeleteModal.vue";
import CantDeleteModal from "@/components/general/CantDeleteModal.vue";
import GroupsTreeChildren from "@/components/tools/groups-tree/GroupsTreeChildren.vue";
import EditDeviceInGroupModal from "@/components/tools/groups-tree/EditDeviceInGroupModal.vue";
import Lang from "@/helpers/lang";
export default {
  name: "GroupsTreeItem",
  props: {
    item: {},
    depth: {},
    handler: {
      default: "draghandle",
    },
  },
  components: {
    "groups-tree-children": GroupsTreeChildren,
    GroupName,
    create,
    DeleteModal,
    CantDeleteModal,
    EditDeviceInGroupModal,
  },
  data() {
    return {
      locale: Lang.get(),
      open: false,
      deleteGroupSlug: null,
      cantDelete: null,
      editName: null,
      content: {},
      gslug: null,
      createSubGroup: false,
      createGroup: false,
      editDevices: false,
      addDevices: {},
    };
  },
  computed: {
    sideArrow() {
      return this.locale == "he" ? "arrowLeft" : "arrowRight";
    },
    color() {
      const transparency = this.depth / 20;
      const blue = this.depth * 40 > 255 ? 255 : this.depth * 40;
      return `rgba(0,0,${blue},${transparency})`;
    },
    actions() {
      let actions = [
        {
          value: () => {
            this.editGroupName(this.item.slug);
          },
          title: this.content.edit,
          icon: "editNote",
        },
        {
          value: () => {
            this.deleteGroup();
          },
          title: this.content.delete,
          icon: "deleteIcon",
        },
      ];
      if (this.item.devices.length == 0 && this.item.children.length == 0) {
        actions.push({
          value: () => {
            this.editDevices = true;
          },
          title: this.content.editDevices,
          icon: "sensors",
          sIcon: true,
        });
        actions.push({
          value: () => {
            this.createGroup = true;
            this.item.devices.length == 0
              ? ((this.createSubGroup = true), (this.gslug = this.item.slug))
              : (this.createSubGroup = false);
          },
          title: this.content.subGroup,
          icon: "Plus",
        });
      } else if (this.item.devices.length == 0) {
        actions.push({
          value: () => {
            this.createGroup = true;
            this.item.devices.length == 0
              ? ((this.createSubGroup = true), (this.gslug = this.item.slug))
              : (this.createSubGroup = false);
          },
          title: this.content.subGroup,
          icon: "Plus",
        });
      } else {
        actions.push({
          value: () => {
            this.editDevices = true;
          },
          title: this.content.editDevices,
          icon: "sensors",
          sIcon: true,
        });
      }
      return actions;
    },
    devices() {
      return this.$store.getters["devices/devices"];
    },
  },
  mounted() {},
  created() {
    if (this.locale) {
      this.setLang();
    } else {
      this.locale = Lang.get();
      this.setLang();
    }
  },
  watch: {},
  methods: {
    editGroupName(slug) {
      this.editName = this.editName === slug ? null : slug;
    },
    deleteGroup() {
      let group = this.item;
      if (!group.hasSubGroups) {
        if (this.deleteGroupSlug) {
          this.$store.dispatch("groups/deleteGroup", group.slug);

          this.deleteGroupSlug = null;
        } else {
          this.deleteGroupSlug = group;
        }
      } else {
        this.cantDelete = true;
      }
    },
    setLang() {
      if (this.locale == "en") {
        this.content = {
          groups: "Edit Device Groups",
          create: "Create Group",
          delete: "Delete",
          edit: "Edit name",
          subGroup: "Add sub-group",
          editDevices: "add/remove devices",
        };
      } else if (this.locale == "he") {
        this.content = {
          Editgroups: "עריכת קבוצות מכשירים",
          create: "יצירת קבוצה חדשה",
          delete: "מחיקה",
          edit: "עריכת שם",
          subGroup: "הוספת תת-קבוצה",
          editDevices: "עריכת מכשירים",
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.groupTreeItem {
  padding-inline-start: 16px;
}

.searchBar {
  margin-bottom: -25px;
}

.draghandle {
  cursor: move;
}

.draghandlechild {
  cursor: move;
}
</style>
