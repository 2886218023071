// import dayjs from "@/plugins/dayjs";
import axios from "@/plugins/axios";

/**
 * Initial state
 */
export const state = {
  groups: {},
  loading: true,
  dailyLogs: {},
  hasDailyLogs: [],
};

/**
 * Mutations
 */
export const mutations = {
  ["SET_GROUPS"](state, data) {
    if (data.length) localStorage.setItem("has-groups", true);
    else localStorage.removeItem("has-groups");
    state.groups = {};
    data.forEach((group) => {
      state.groups[group.slug] = group;
    });
    state.loading = false;
  },
  // ["SET_GROUP"](state, data) {
  // console.log("SET_CATEGORY", data);
  // },
  ["EDIT_NAME"](state, data) {
    let groups = { ...state.groups };
    groups[data.slug].name = data.name;
    state.groups = groups;
  },
  ["DELETE_GROUP"](state, slug) {
    let groups = { ...state.groups };
    delete groups[slug];
    state.groups = groups;
  },
  ["ADD_DEVICE"](state, data) {
    // console.log("ADD DEVICE TO GROUP:", data);
    let groups = { ...state.groups };
    if (!groups[data.group].devices.includes(data.device)) {
      groups[data.group].devices.push(data.device);
      state.groups = groups;
    }
  },
  ["DELETE_DEVICE"](state, data) {
    // console.log("DELETE DEVICE FROM GROUP:", data);
    let groups = { ...state.groups };
    if (groups[data.group].devices.includes(data.device)) {
      const index = groups[data.group].devices.indexOf(data.device);
      groups[data.group].devices.splice(index, 1);
      state.groups = groups;
    }
  },
  ["LOGOUT"](state) {
    state.groups = {};
    state.loading = true;
  },
  ["SET_DAILY_LOGS"](state, data) {
    state.dailyLogs[data.slug] = data.logs;
    state.hasDailyLogs.push(data.slug);
  },
};

/**
 * Actions
 */
export const actions = {
  logOut({ commit }) {
    commit("LOGOUT");
  },
  getGroups({ commit }) {
    axios
      .get("/devices/groups")
      .then((res) => {
        commit("SET_GROUPS", res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  },
  async addDeviceToGroup({ commit }, data) {
    await axios
      .post(`/devices/groups/${data.group}/device`, data)
      .then((res) => {
        if (res.data) {
          commit("ADD_DEVICE", data);
          this.dispatch("general/setAlert", {
            type: "success",
            msg: "deviceAdd",
          });
        }
      })
      .catch((err) => {
        this.dispatch("general/setAlert", {
          type: "failed",
          msg: "oops",
        });
        console.error(err);
      });
  },
  async delDeviceFromGroup({ commit }, data) {
    await axios
      .post(`/devices/groups/${data.group}/device/delete`, data)
      .then((res) => {
        if (res.data) {
          commit("DELETE_DEVICE", data);
          this.dispatch("general/setAlert", {
            type: "success",
            msg: "deviceDel",
          });
        }
      })
      .catch((err) => {
        this.dispatch("general/setAlert", {
          type: "failed",
          msg: "oops",
        });
        console.error(err);
      });
  },
  async editGroups({ commit }, groups) {
    await axios
      .post("/devices/groups", groups)
      .then((res) => {
        if (res.data) {
          commit("SET_GROUPS", res.data.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },
  async editGroupName({ commit }, editName) {
    await axios
      .post(`/devices/groups/${editName.slug}/rename`, editName)
      .then((res) => {
        if (res.data) {
          commit("EDIT_NAME", editName);
          this.dispatch("general/setAlert", {
            type: "success",
            msg: "changeGroupName",
          });
        }
      })
      .catch((err) => {
        this.dispatch("general/setAlert", {
          type: "failed",
          msg: "oops",
        });
        console.error(err);
      });
  },
  async deleteGroup({ commit }, slug) {
    await axios
      .post(`/devices/groups/${slug}/delete`)
      .then(() => {
        commit("DELETE_GROUP", slug);
        this.dispatch("general/setAlert", {
          type: "success",
          msg: "groupDel",
        });
      })
      .catch((err) => {
        this.dispatch("general/setAlert", {
          type: "failed",
          msg: "oops",
        });
        console.error(err);
      });
  },
  async createGroup({ commit }, group) {
    await axios
      .post("/devices/groups/create", group)
      .then((res) => {
        if (res.data) {
          commit("SET_GROUPS", res.data.success);
          this.dispatch("general/setAlert", {
            type: "success",
            msg: "groupAdd",
          });
        }
      })
      .catch((err) => {
        this.dispatch("general/setAlert", {
          type: "failed",
          msg: "oops",
        });
        console.error(err);
      });
  },
  async getGroupLogs({ commit }, group) {
    // console.log("group is", group);
    // if (group === 123) console.log(commit);
    await axios
      .get(`/devices/groups/${group.slug}/logs`)
      .then((res) => {
        // console.log("res", res);
        for (const slug in res.data.data) {
          // console.log("slug", slug);
          commit("SET_DAILY_LOGS", { logs: res.data.data[slug], slug: slug });
          // if (slug.length ) {

          // }
        }
        // if (res.data) {
        //   commit("SET_GROUPS", res.data.success);
        //   this.dispatch("general/setAlert", {
        //     type: "success",
        //     msg: "groupAdd",
        //   });
        // }
      })
      .catch((err) => {
        console.error(err);
        // this.dispatch("general/setAlert", {
        //   type: "failed",
        //   msg: "oops",
        // });
      });
  },
};

/**
 * Getters
 */
export const getters = {
  hasGroups: (state) => Object.keys(state.groups).length > 0,
  // hasSubGroups: (state) => (slug) =>
  //   state.groups[slug].subGroups
  //     ? state.groups[slug].subGroups.length > 0
  //     : false,
  groups: (state) => state.groups,
  group: (state) => (slug) => state.groups[slug],
  sub_groups: (state) => (slug) =>
    Object.values(state.groups).filter((g) => g.parent_slug == slug),
  loading: (state) => state.loading,
  deviceLogs: (state) => (slug) => state.dailyLogs[slug],
  hasDailyLogs: (state) => (slug) => state.hasDailyLogs.includes(slug),
  getGroupsByDevice: (state) => (deviceSlug) => {
    // console.log("state.groups", state.groups);
    let groups = Object.values(state.groups).filter((g) =>
      g.devices.includes(deviceSlug)
    );
    let parents = groups.map((g) => g.name);
    // console.log("groups", groups);
    for (const group of groups) {
      // console.log("group", group);
      if (group.parent_slug) {
        // const groupParents =
        getGroupParents(group, Object.values(state.groups), parents);
        // console.log("groupParents", groupParents);
      }
      // parents.push(groups[group].slug);
    }
    return parents;
  },
};

function getGroupParents(group, groups, parents = []) {
  if (group.parent_slug) {
    const parent = groups.find((g) => g.slug == group.parent_slug);
    // console.log("parent", parent);
    parents.push(parent.name);
    getGroupParents(parent, groups, parents);
  }
  return parents;
}
