<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <div class="searchBarAll">
        <Icon
          icon="mdiClose"
          @click.native="$emit('close')"
          iconClass="pa-3 pointer"
        />
        <v-layout row class="searchBar">
          <v-flex xs12 class="mt-4">
            <h4 class="mt-2 header">{{ $t("EditDeviceInGroup") }}</h4>
          </v-flex>
          <v-flex xs8 md4 class="mt-4">
            <v-autocomplete
              v-model="addDevices[item.slug]"
              :items="
                Object.values(devices).filter((d) => {
                  return !item.devices.includes(d.slug);
                })
              "
              dense
              small-chips
              outlined
              :item-text="(d) => d.name + ' - ' + d.identifier"
              item-value="slug"
              :label="$t('device')"
              multiple
              clearable
              :menu-props="devicesMenuProps"
            >
            </v-autocomplete>
            <v-layout v-if="addDevices[item.slug]" row wrap align-center>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="item.devices"
          row
          nowrap
          id="groupdevices"
          class="ma-4 scrollbar"
          style="text-align: center; margin-bottom: 10px"
        >
          <v-flex
            v-for="(device, index) in item.devices"
            :key="index"
            xs12
            class="py-2"
            align-center
          >
            <v-layout v-if="device && devices[device]" justify-space-between>
              <div>
                {{ devices[device].name }}
                {{ devices[device].identifier }}
              </div>
              <Icon
                icon="deleteIcon"
                @click.native="deleteDeviceSlug = devices[device].slug"
                color="red"
                iconClass="mx-3 mb-1"
              />
            </v-layout>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
      </div>
      <v-card-actions>
        <v-btn
          color="switchq"
          text
          outlined
          :width="$vuetify.breakpoint.mdAndDown ? '' : '350px'"
          :block="$vuetify.breakpoint.mdAndDown"
          @click="add(item.slug, addDevices[item.slug])"
        >
          {{ $t("add") }}
          <Icon icon="Plus" />
        </v-btn>
      </v-card-actions>
    </v-card>
    <DeleteModal
      :activator="!!deleteDeviceSlug"
      @close="deleteDeviceSlug = null"
      @delete="
        removeDevice({
          group: item.slug,
          device: deleteDeviceSlug,
        })
      "
    />
  </v-dialog>
</template>
<script>
import DeleteModal from "@/components/general/DeleteModal.vue";
export default {
  name: "EditDeviceModal",
  props: ["activator", "item"],
  components: { DeleteModal },
  data() {
    return {
      dialog: false,
      addDevices: {},
      deleteDeviceSlug: null,
      devicesMenuProps: {
        closeOnClick: false,
        closeOnContentClick: false,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 150,
        offsetY: true,
        offsetOverflow: true,
        transition: false,
      },
    };
  },
  computed: {
    devices() {
      return this.$store.getters["devices/alldevices"];
    },
  },
  created() {
    this.dialog = this.activator;
  },
  watch: {
    activator() {
      this.dialog = this.activator;
    },
  },
  methods: {
    add(group, devices) {
      devices.forEach((device) => {
        this.$store.dispatch("groups/addDeviceToGroup", {
          group: group,
          device: device,
        });
      });
      this.addDevices = {};
    },
    removeDevice(data) {
      // console.log("im Here");
      if (this.deleteDeviceSlug) {
        this.$store.dispatch("groups/delDeviceFromGroup", {
          group: data.group,
          device: data.device,
        });

        this.deleteDeviceSlug = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.searchBarAll {
  .searchBar {
    justify-content: center;
  }
}

.header {
  text-align: center;
}
#groupdevices {
  max-height: 300px;
  overflow-y: auto;
}
</style>
