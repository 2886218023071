<template>
  <v-layout row wrap class="languages text-xs-center my-3" justify-center>
    <div
      :class="lang !== 'en' ? 'changeable' : 'blocked'"
      @click="changeLang('en')"
    >
      <span>
        <img src="@/assets/images/flags/us.png" alt="US Flag" />
      </span>
      English
    </div>
    <v-divider vertical></v-divider>
    <div
      :class="lang !== 'he' ? 'changeable' : 'blocked'"
      @click="changeLang('he')"
    >
      <span>
        <img src="@/assets/images/flags/il.png" alt="Israel Flag" />
      </span>
      עברית
    </div>
  </v-layout>
</template>

<script>
import Lang from "@/helpers/lang";
export default {
  name: "Languages",
  data() {
    return {
      lang: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/valid"];
    },
  },
  methods: {
    async changeLang(lang) {
      if (this.lang != lang) {
        this.$i18n.locale = lang;
        Lang.set(lang);
        if (this.user) {
          await this.$store.dispatch("user/setLocale", lang);
        }
        window.location.reload();
      }
    },
  },
  created() {
    this.lang = Lang.get();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.languages {
  max-height: 30px;
  div {
    min-width: 50px;
    margin: 3px 5px;
    display: flex;
    span {
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 3px;
      img {
        max-height: 20px;
        max-width: 20px;
      }
    }
  }
  .changeable:hover {
    color: #008acc;
    cursor: pointer;
  }
  .blocked:hover {
    cursor: not-allowed;
  }
}
hr {
  margin: 0 5px;
}
</style>
