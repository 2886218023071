<template>
  <pull-to
    ref="pullToContainer"
    :top-config="topConfig"
    :is-bottom-bounce="false"
    :is-top-bounce="allowPullToRefresh"
    :top-load-method="loadMethod"
    :top-block-height="60"
    @top-state-change="stateChange"
  >
    <template slot="top-block">
      <v-layout row nwrap justify-center>
        <v-progress-circular
          :size="24"
          :width="2"
          color="switchq"
          value="70"
          :indeterminate="loadingIcon == 'trigger' ? true : false"
        ></v-progress-circular>
      </v-layout>
    </template>
    <v-container class="pa-0 pt-0" style="overflow-y: auto">
      <v-layout
        class="mt-3 inGroupHeader"
        v-if="inGroup"
        row
        wrap
        justify-center
      >
        <h3>{{ group ? group.name : "" }}</h3>
      </v-layout>
      <v-layout
        v-if="Object.keys(items).length"
        row
        wrap
        justify-center
        id="devicesMain"
      >
        <v-flex
          px-2
          xs12
          md6
          v-if="!selectedItem || (selectedItem && $vuetify.breakpoint.mdAndUp)"
          :id="selectedItem ? 'devices' : 'devices-xs'"
          :class="dir === 'rtl' ? 'd-r' : 'd-l'"
        >
          <v-layout column wrap>
            <v-expand-transition>
              <v-flex
                v-show="
                  ($vuetify.breakpoint.smAndUp || showViewEdit) &&
                  Object.keys(items).length &&
                  !inGroup
                "
                xs12
                class="mb-2"
              >
                <v-layout
                  row
                  wrap
                  justify-space-between
                  align-content-center
                  align-center
                >
                  <small class="px-3">
                    {{ content.countdevices }}: {{ Object.keys(items).length
                    }}<span v-if="countalldevices > Object.keys(items).length"
                      >/{{ countalldevices }}</span
                    >
                  </small>
                  <v-btn
                    v-if="!inGroup"
                    text
                    small
                    class="btn-xs"
                    color="info"
                    @click="$router.push('/edit-view')"
                    >{{ content.editview }}</v-btn
                  >
                </v-layout>
              </v-flex>
            </v-expand-transition>

            <v-flex
              grow
              v-for="(item, index) in items"
              :key="item ? item.slug : index"
              class="mb-3 white device-wrapper"
            >
              <DeviceBar
                @click="openItem(item.slug)"
                :item="item"
                :index="index"
                :inGroup="inGroup"
                :group="group"
              />
            </v-flex>
          </v-layout>
          <v-flex
            xs10
            row
            mt-5
            justify-start
            v-if="inGroup && $vuetify.breakpoint.mdAndDown"
          >
          </v-flex>
        </v-flex>

        <v-flex
          ref="devicePage"
          v-if="selectedItem && items[selectedItem]"
          xs12
          md6
          :class="dir === 'rtl' ? 'divide-r' : 'divide-l'"
          :id="$vuetify.breakpoint.mdAndUp ? 'device' : ''"
        >
          <router-view
            :dir="dir"
            :key="selectedItem"
            :locale="locale"
          ></router-view>
        </v-flex>
      </v-layout>
      <v-layout v-else-if="!searchDevices" row wrap justify-center>
        <v-flex xs11 md6 lg4 text-xs-center>
          <h1 v-if="!countalldevices && !inGroup" class="display">
            {{ content.empty.title }}
          </h1>
          <h1 v-else-if="inGroup" class="display">
            {{ content.empty.empty }}
          </h1>
          <h1 v-else class="display">
            {{ content.empty.sectitle }}
          </h1>
          <v-layout row wrap justify-center>
            <v-flex xs8>
              <v-btn
                v-if="countalldevices && !inGroup"
                class="btn-lg mb-4 notdevicesbtn"
                color="switchq"
                block
                @click="$router.push('/edit-view')"
                >{{ content.empty.clickto }}{{ content.editview }}</v-btn
              >
            </v-flex>
            <v-flex xs8>
              <v-btn
                v-if="!countalldevices"
                class="btn-lg mt-3 lighten-1 notdevicesbtn"
                color="switchq"
                dark
                block
                @click="$router.push('/new-device/new')"
                >{{ content.empty.btn }}
                <Icon iconClass="ms-3" icon="Plus" />
              </v-btn>
            </v-flex>
            <v-flex xs9>
              <v-btn
                v-if="inGroup"
                class="btn-lg mt-3 lighten-1 notdevicesbtn"
                color="switchq"
                dark
                block
                @click="$router.push('/groups/edit')"
                >{{ content.empty.clickto }}{{ content.editGroup }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </pull-to>
</template>

<script>
import DeviceBar from "@/components/devices/DeviceBar.vue";
import PullTo from "vue-pull-to";
import Lang from "@/helpers/lang";
export default {
  components: {
    DeviceBar,
    PullTo,
  },
  data() {
    return {
      locale: Lang.get(),
      content: {},
      selectedItem: null,
      scroll: 0,
      pulled: 0,
      scrollInterval: null,
      scrollY: 0,
      showViewEdit: true,
      freeToRefresh: false,
      loadingIcon: null,
      displayBackBtn: false,
      topConfig: {
        pullText: this.$t("pullTo"),
        triggerText: this.$t("releaseTo"),
        loadingText: this.$t("refresh"),
        failText: this.$t("refreshFail"),
        triggerDistance: 70,
      },
    };
  },
  computed: {
    dir() {
      return this.$vuetify.rtl ? "rtl" : "ltr";
    },
    searchDevices() {
      let search = this.$store.getters["devices/deviceSearch"];
      if (search && search != "") {
        search = search.trim().toLowerCase();
      }
      return search;
      // return this.$store.getters["devices/deviceSearch"].trim().toLowerCase();
    },
    inGroup() {
      let inGroup = localStorage.getItem("inGroup");
      if (inGroup == "true") {
        return true;
      }
      return false;
    },
    group() {
      return this.inGroup
        ? this.$store.getters["groups/group"](this.$route.params.gslug)
        : null;
    },
    groups() {
      return this.$store.getters["groups/groups"];
    },
    items() {
      let devices = {};
      if (this.inGroup) {
        if (this.group) {
          this.group.devices.forEach((dslug) => {
            devices[dslug] = this.$store.getters["devices/device"](dslug);
          });
        }
        return devices;
      } else {
        // devices = this.$store.getters["devices/devices"] || {};
        const search = this.searchDevices;
        devices = this.$store.getters["devices/devices"] || {};
        if (search && search.length > 0) {
          devices = Object.values(devices).filter((d) => {
            let groups = this.$store.getters["groups/getGroupsByDevice"](
              d.slug
            );
            return (
              d.identifier.toLowerCase().includes(search) ||
              d.name.toLowerCase().includes(search) ||
              groups.join(",").toLowerCase().includes(search)
            );
          });
          let result = {};
          for (let i = 0; i < devices.length; i++) {
            result[devices[i].slug] = devices[i];
          }
          devices = result;
        }
      }
      return devices;
    },
    countalldevices() {
      return this.$store.getters["devices/devicesCount"];
    },
    loadMethod() {
      return (this.showViewEdit || this.$route.params.slug) &&
        this.scrollY === 0
        ? this.refresh
        : null;
    },
    allowPullToRefresh() {
      return this.$vuetify.breakpoint.smAndDown && this.scrollY === 0;
    },
  },
  created() {
    if (this.$route.params.slug) {
      if (!this.items[this.$route.params.slug]) {
        this.$router.push("/");
      } else {
        this.selectedItem = this.$route.params.slug;
      }
    }
    this.setLang();
  },
  mounted() {
    this.$vuetify.goTo(0, { duration: 0 });
    if (this.$vuetify.breakpoint.smAndDown) {
      this.scrollInterval = setInterval(() => {
        this.scrollY = window.scrollY;
      }, 100);
    }
  },
  destroyed() {
    clearInterval(this.scrollInterval);
  },
  watch: {
    $route() {
      if (
        this.$route.params.slug == null ||
        this.$route.params.slug == undefined
      ) {
        this.selectedItem = null;
        if (this.$vuetify.breakpoint.smAndDown)
          this.$vuetify.goTo(0, { duration: 0 });
      } else {
        this.openItem(this.$route.params.slug);
        this.$vuetify.goTo(0, { duration: 0 });
      }
    },
    items() {
      this.items;
    },
  },
  methods: {
    refresh() {
      if (this.showViewEdit) {
        window.location.href = "";
      }
    },
    stateChange(state) {
      if (this.showViewEdit) {
        if (state === "pull") {
          this.loadingIcon = "pull";
        } else if (state === "trigger") {
          this.loadingIcon = "trigger";
        }
      }
    },
    openItem(slug) {
      this.selectedItem = slug;
      let path = this.inGroup ? "/groups/" + this.group.slug : "";
      path += "/devices/" + slug;
      this.$store.dispatch("devices/setSearchDevice", "");
      if (this.$route.path !== path) {
        this.$router.push({ path: path });
      }
    },
    setLang() {
      if (this.locale == "en") {
        this.content = {
          countdevices: "Number of devices",
          editview: "edit view",
          addDevices: "add switches",
          editGroup: "edit group",

          empty: {
            group: "The group don't have registered switches yet!",
            title: "You don't have any switches registered yet!",
            subtitle: "Click here to register a new switch",
            btn: "Register A New Switch",
            empty: "The group is empty!",
            sectitle: "All of our devices are hidden",
            clickto: "Click here to ",
          },
        };
      } else if (this.locale == "he") {
        this.content = {
          countdevices: "מספר מכשירים",
          editview: "עריכת תצוגה",
          addDevices: "הוספת מכשירים",
          editGroup: "עריכת קבוצה",

          empty: {
            group: "עדיין לא נוספו מכשירים לקבוצה!",
            title: "עדיין לא רשומים אצלך מכשירים!",
            subtitle: "לחץ כאן כדי לרשום מכשיר חדש",
            btn: "הוספת מכשיר חדש",
            empty: "קבוצה זו עדיין ריקה",
            sectitle: "כל המכשירים הרשומים אצלך מוסתרים.",
            clickto: "לחץ כאן ל",
          },
        };
      }
    },
  },
};
</script>

<style lang="css" scoped>
.btn-xs {
  height: 16px;
}

.inGroupHeader {
  margin-bottom: -12px;
}

.backBtn {
  background-position: top;
  margin-bottom: 10px;
  padding: 0px 30px 30px 0px;
  position: fixed;
  bottom: 0px;
}

.display {
  text-align: center;
}

.device-wrapper {
  border-radius: 5px;
}
.switchBar {
  min-height: 50px;
  /* border-radius: 5px; */
}

.itemTitle {
  font-size: 11pt;
}

.pt-0 {
  margin-top: -5px;
}

.dryContact {
  max-height: 20px;
  /* max-width: 30px; */
  width: auto;
}

.maxWidthWithBtns {
  max-width: calc(100vw - 165px);
}
.itemBtns .minWidth160 {
  min-width: 120px;
}

.layout.itemBtns.row.wrap.justify-end.align-center.shrink {
  font-size: 13pt;
}

@media screen and (max-width: 600px) {
  #devices-xs {
    margin-top: 15px;
    height: fit-content;
  }
  .itemTitle,
  .limitWidth {
    width: 100%;
    /* max-width: calc(100vw - 186px); */
  }
  #devicesMain {
    /* min-height: calc(96vh - (48px + 56px)); */
    height: fit-content;
  }
}
@media screen and (min-width: 600px) {
  #devices {
    padding: 0 15px;
  }
  /* #devices,
  #device {
    overflow-y: auto;
    max-height: calc(100vh - 195px);
  } */
  #devices::-webkit-scrollbar,
  #device::-webkit-scrollbar {
    scrollbar-width: 0px;
    width: 1em;
    display: none;
  }
}
@media screen and (min-width: 960px) {
  .d-r,
  .divide-l {
    padding-left: 15px;
  }
  .d-l,
  .divide-r {
    padding-right: 15px;
  }
  .divide-l {
    border-left: 2px solid rgba(255, 255, 255, 0.6);
  }
  .divide-r {
    border-right: 2px solid rgba(255, 255, 255, 0.6);
  }
}

.notdevicesbtn {
  font-size: 14pt;
}
</style>
