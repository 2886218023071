import Lang from "@/helpers/lang";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

const { host } = window.location;

const isCustom =
  !host.startsWith("localhost") &&
  !host.startsWith("127.0.0.1") &&
  !host.startsWith("switchq") &&
  !host.startsWith("app.switchq");

let mainColor = "#7a00cc";
if (isCustom) {
  if (host.startsWith("iot.ip-com.co.il")) {
    mainColor = "#282fef";
  }
}

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        switchq: mainColor,
        switchqSub: "#dde1e6",
      },
      dark: {
        switchq: mainColor,
        switchqSub: "#dde1e6",
      },
    },
  },
  iconfont: "mdiSvg",
  rtl: Lang.get() === "he" ? true : false,
});
