<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="300"
      nudge-bottom="45"
      z-index="105"
      offset-x
      content-class="rounded-lg"
    >
      <!-- content-class="nots-menu" -->
      <template v-slot:activator="{ on }">
        <v-layout
          v-on="on"
          class="ma-0 pa-0 topbar-btn"
          align-center
          justify-center
          row
          fill-height
        >
          <v-badge
            :value="unread.length"
            color="blue-grey darken-3"
            left
            overlap
          >
            <template v-if="unread.length > 0" v-slot:badge>
              {{ unread.length }}
            </template>
            <Icon
              icon="notificationsBell"
              iconClass="pointer"
              size="22pt"
              :color="unread.length ? 'switchq ' : 'grey darken-1'"
            />
          </v-badge>
        </v-layout>
      </template>

      <v-card class="notifications-card">
        <!-- <router-link to="/profile/notifications">
            </router-link> -->
        <h3
          class="text-center py-2 switchqSub--text pointer switchq lighten-1"
          @click="openNotifications"
        >
          <v-layout row wrap justify-space-between>
            <v-flex xs2>
              <Icon icon="notificationsBell" color="white" />
            </v-flex>
            <v-flex xs8>
              {{ content.notifications }}
            </v-flex>
            <v-flex xs2 />
          </v-layout>
        </h3>
        <v-layout row wrap v-if="loading">
          <v-flex xs12 class="list-item text-xs-center pa-0">
            <v-progress-linear
              :indeterminate="true"
              class="my-0"
            ></v-progress-linear>
          </v-flex>
        </v-layout>
        <v-layout
          column
          class="notifications-list"
          ref="notifications"
          v-if="!loading"
        >
          <v-flex
            xs12
            v-for="(not, index) in notifications"
            :key="index"
            class="notification-row pt-2 px-2"
          >
            <v-layout
              column
              wrap
              @click="openDevicePage(not.data.device)"
              class="pointer"
            >
              <v-flex xs12 class="text-subtitle-2">
                <strong>
                  {{ not.data.message.body }}
                </strong>
              </v-flex>
              <v-flex xs12>
                <v-layout row wrap justify-end>
                  <small dir="rtl" class="grey--text text--darken-1">
                    {{ new Date(not.created_at).toLocaleTimeString() }}
                    {{ new Date(not.created_at).toLocaleDateString() }}
                  </small>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            xs12
            class="list-item text-center pa-3"
            v-if="notifications && notifications.length == 0"
          >
            <strong class="text-xs-center">{{ content.nonew }}</strong>
          </v-flex>
        </v-layout>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import lang from "@/helpers/lang";
export default {
  name: "Notifications",
  data() {
    return {
      locale: lang.get(),
      notifications: [],
      loading: true,
      menu: false,
    };
  },
  computed: {
    unread() {
      return this.$store.getters["notifications/unread"];
    },
  },
  created() {
    this.setLang();
  },
  mounted() {
    this.fetchUnread();
  },
  watch: {
    unread() {
      this.syncNotifications();
      this.loading = false;
    },
    menu() {
      this.notifications = this.unread;
      if (this.menu) {
        this.read();
      }
    },
  },
  methods: {
    syncNotifications() {
      const notifications = [...this.unread, ...this.notifications].filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );
      if (this.notifications.length < notifications.length) {
        this.read();
      }
      this.notifications = notifications;
    },
    async fetchUnread() {
      if (this.unread.length == 0) {
        await this.$store.dispatch("notifications/fetchUnread");
      }
      this.loading = false;
    },
    openNotifications() {
      this.menu = false;
      this.$router.push("/profile/notifications");
    },
    read() {
      if (this.notifications.length > 0) {
        setTimeout(() => {
          if (this.menu) {
            this.$store.dispatch("notifications/readAll");
          }
        }, 3500);
      }
    },
    openDevicePage(slug) {
      this.$router.push("/devices/" + slug);
      this.menu = false;
    },
    setLang() {
      if (this.locale == "en") {
        this.content = {
          notifications: "Notifications",
          scrolldown: "scroll down",
          nonew: "no new notifications",
          type: {
            NotConnected: "Lost power or internet connection",
            IsConnected: "The power or internet connection is back",
            NoSensor: "The sensor has disconnected",
            ForbiddenArea: "Is in a forbidden area",
            MetTreshold: "Reached the threshold you set",
            FixedMetTreshold: "The indicators returned to normal",
            FloodSuspected: "The buoy identifies flooding",
            FloodFixed: "The buoy is back to normal",
            DoorOpened: "The sensor detected a door opening",
            DoorClosed: "The sensor detected a door closing",
            SmokeAlarm: "Warning of smoke!",
            MotionDetected: "Detects motion!",
            LowBattery: "The device has low battery",
          },
        };
      } else if (this.locale == "he") {
        this.content = {
          notifications: "התראות",
          scrolldown: "גלול למטה",
          nonew: "אין התראות חדשות",
          type: {
            NotConnected: "איבד חיבור לחשמל או לאינטרנט",
            IsConnected: "חזר החיבור לחשמל או לאינטרנט",
            NoSensor: "החיישן לא מחובר",
            ForbiddenArea: "נמצא באזור אסור",
            MetTreshold: "הגיע לסף שהגדרת",
            FixedMetTreshold: "המדדים חזרו למצב תקין",
            FloodSuspected: "המצוף מזהה הצפה",
            FloodFixed: "המצוף חזר למצב תקין",
            DoorOpened: "החיישן מזהה פתיחה של דלת",
            DoorClosed: "החיישן מזהה סגירה של דלת",
            SmokeAlarm: "אזהרת עשן!",
            MotionDetected: "מזהה תנועה!",
            LowBattery: "למכשיר יש סוללה חלשה",
          },
        };
      }
    },
  },
};
</script>
<style scoped lang="scss">
.notifications-card {
  overflow-y: none;
  h3 {
    text-decoration: none;
  }
  .notifications-list {
    overflow-y: auto;
    height: 100%;
    max-height: 400px;
    max-width: 350px;
    .notification-row {
      border-bottom: 1px solid #cecece;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
    }
  }
}
</style>
