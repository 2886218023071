<template>
  <div>
    <v-layout row wrap justify-center class="mt-3 px-2">
      <v-flex
        class="editRules py-3 px-3"
        row
        xs12
        d-flex
        align-center
        justify-space-between
        elevation-2
      >
        <div class="d-flex">
          <h3 class="ms-2">
            <Icon
              icon="bellCogOutline"
              color="switchq lighten-1"
              iconClass="ms-2"
            />
            {{ content.header }}
          </h3>
        </div>
        <v-btn
          v-if="
            'sensors' in device.data &&
            Object.keys(device.data.sensors).length &&
            device.settings.sensor_type > 0
          "
          color="switchq lighten-1 "
          text
          :disabled="
            (rules.length != 0 && rules[0].sensor == 'water') ||
            (rules.length != 0 && rules[0].sensor == 'hall') ||
            (rules.length != 0 && rules[0].sensor == 'isVibrating') ||
            disabledRules
          "
          @click="addRule"
        >
          <span v-show="$vuetify.breakpoint.smAndUp">
            {{ content.add }}
          </span>
          <Icon
            icon="Plus"
            color="switchq lighten-1"
            :iconClass="
              $vuetify.breakpoint.smAndUp ? 'ms-2' : 'addRuleBtn  ms-2'
            "
          />
        </v-btn>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="dialog"
      width="500"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @click:outside="close"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ content.select }}
        </v-card-title>

        <v-card-text>
          <v-form @submit.prevent="submit" ref="form">
            <v-select
              :item-disabled="config || editRuleSensor ? '' : 'disabled'"
              v-model="form.sensor"
              :items="sensors"
              :label="$t('selectSens')"
              :rules="[(v) => !!v || $t('mustSens')]"
              required
            ></v-select>
            <v-select
              v-show="
                requiresThreshold &&
                this.device.data.sensor_type != 7 &&
                this.device.data.sensor_type != 21
              "
              :item-disabled="config || editRuleSensor ? '' : 'disabled'"
              v-model="form.action"
              :disabled="!form.sensor"
              :items="availableActions"
              :label="$t('selectThres')"
              :rules="!requiresThreshold ? [(v) => !!v || $t('mustAct')] : []"
              required
            ></v-select>
            <v-text-field
              v-show="
                requiresThreshold &&
                this.device.data.sensor_type != 7 &&
                this.device.data.sensor_type != 21
              "
              dir="ltr"
              :disabled="!form.action"
              v-model="form.value"
              :label="$t('threshold')"
              type="number"
              :rules="
                !requiresThreshold ? [(v) => !isNaN(v) || $t('mustThres')] : []
              "
              required
            ></v-text-field>
            <v-layout v-if="device.data.sensor_type == 11" row>
              <v-flex d-flex align-center align-content-center>
                <h4>{{ content.correctState }}:</h4>
              </v-flex>
              <v-flex>
                <v-radio-group v-model="form.value" row>
                  <v-radio
                    class="radio"
                    color="primary"
                    :label="content.open"
                    :value="0"
                  ></v-radio>
                  <v-radio
                    class="radio"
                    color="primary"
                    :label="content.close"
                    :value="1"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-layout
              v-if="
                device.data.sensor_type == 12 ||
                device.data.sensor_type == 13 ||
                device.data.sensor_type == 14
              "
              row
            >
              <v-flex d-flex align-center align-content-center>
                <h4>{{ content.correctState }}:</h4>
              </v-flex>
              <v-flex>
                <v-radio-group v-model="form.value" row>
                  <v-radio
                    class="radio"
                    color="primary"
                    :label="content.open"
                    :value="0"
                  ></v-radio>
                  <v-radio
                    class="radio"
                    color="primary"
                    :label="content.close"
                    :value="1"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-layout
              v-if="
                /* device.data.sensor_type == 6 || */ device.data.sensor_type ==
                7
              "
              row
            >
              <v-flex d-flex align-center align-content-center>
                <h4>{{ content.correctState }}:</h4>
              </v-flex>
              <v-flex>
                <v-radio-group v-model="form.value" row>
                  <v-radio
                    class="radio"
                    color="primary"
                    :label="content.lightOff"
                    :value="0"
                  ></v-radio>
                  <v-radio
                    class="radio"
                    color="primary"
                    :label="content.lightOn"
                    :value="1"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-layout
              v-if="
                /* device.data.sensor_type == 6 || */ device.settings
                  .sensor_type == 21
              "
              row
            >
              <v-flex d-flex align-center align-content-center>
                <h4>{{ content.correctState }}:</h4>
              </v-flex>
              <v-flex>
                <v-radio-group v-model="form.value" row>
                  <v-radio
                    class="radio"
                    color="primary"
                    :label="content.vibrateOff"
                    :value="0"
                  ></v-radio>
                  <v-radio
                    class="radio"
                    color="primary"
                    :label="content.vibrateOn"
                    :value="1"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <span class="advanced" @click="isAdvanced = !isAdvanced">
              <span>
                {{ $t("advancedOpts") }}
              </span>
              <Icon v-if="!isAdvanced" icon="chevronDown" />
              <Icon v-if="isAdvanced" icon="chevronUp" />
            </span>
            <div class="advancedOpts" v-show="isAdvanced">
              <div class="form">
                <span>
                  {{ $t("notifications") }}
                </span>
                <div class="formSwitchs">
                  <div>
                    <div :class="setLang() == 'en' ? 'switchLabel' : ''">
                      {{ $t("push") }}
                    </div>
                    <v-switch
                      :disabled="disabledNotifications.push ? true : false"
                      v-model="form.notifications.push"
                      :input-value="form.notifications.push"
                      inset
                      :false-value="0"
                      :true-value="1"
                      default
                      color="switchq lighten-2"
                    ></v-switch>
                  </div>
                  <div>
                    <div :class="setLang() == 'en' ? 'switchLabel' : ''">
                      {{ $t("emailNot") }}
                    </div>
                    <v-switch
                      :disabled="disabledNotifications.email ? true : false"
                      v-model="form.notifications.email"
                      :input-value="form.notifications.email"
                      inset
                      :false-value="0"
                      :true-value="1"
                      color="switchq lighten-2"
                    ></v-switch>
                  </div>
                  <div>
                    <div :class="setLang() == 'en' ? 'switchLabel' : ''">
                      {{ $t("smsNot") }}
                    </div>
                    <v-switch
                      :disabled="disabledNotifications.sms ? true : false"
                      :input-value="form.notifications.sms"
                      v-model="form.notifications.sms"
                      inset
                      :false-value="0"
                      :true-value="1"
                      color="switchq lighten-2"
                    ></v-switch>
                  </div>
                </div>

                <span>{{ $t("timeToErr") }} </span>
                <div class="repeats" dir="rtl">
                  <v-btn
                    @click="plusMinRepeat(form.repeats)"
                    @contextmenu.prevent="plusMinRepeat(form.repeats)"
                    @mousedown="changeRepeat(true)"
                    @touchstart="changeRepeat(true)"
                    @mouseleave="changeRepeat(true, false)"
                    @mouseup="changeRepeat(true, false)"
                    @touchend="changeRepeat(true, false)"
                    @touchcancel="changeRepeat(true, false)"
                  >
                    <Icon icon="Plus" />
                  </v-btn>
                  <v-layout column wrap align-center>
                    <v-flex
                      shrink
                      @click="editRepeats"
                      v-if="!editRepeatsTime"
                      class="pointer"
                      >{{ formRepeats }}</v-flex
                    >
                    <v-flex shrink v-else>
                      <time-fields
                        :value="formRepeats"
                        @save="saveRepFromDialog"
                        @close="editRepeatsTime = false"
                      />
                    </v-flex>
                    <v-flex shrink class="mt-n2">
                      <small>{{ $t("minutes") }}</small>
                    </v-flex>
                  </v-layout>
                  <v-btn
                    @click="minusMinRepeat(form.repeats)"
                    @contextmenu.prevent="minusMinRepeat(form.repeats)"
                    @mousedown="changeRepeat(false)"
                    @touchstart="changeRepeat(false)"
                    @mouseleave="changeRepeat(false, false)"
                    @mouseup="changeRepeat(false, false)"
                    @touchend="changeRepeat(false, false)"
                    @touchcancel="changeRepeat(false, false)"
                  >
                    <Icon icon="Minus" />
                  </v-btn>
                </div>
                <span>{{ $t("timeToNormal") }}</span>
                <div class="counters">
                  <v-btn
                    @click="plusMinCounter(form.counter_repeats)"
                    @contextmenu.prevent="plusMinCounter(form.counter_repeats)"
                    @mousedown="changeCounter(true)"
                    @touchstart="changeCounter(true)"
                    @mouseleave="changeCounter(true, false)"
                    @mouseup="changeCounter(true, false)"
                    @touchend="changeCounter(true, false)"
                    @touchcancel="changeCounter(true, false)"
                  >
                    <Icon icon="Plus" />
                  </v-btn>
                  <v-layout column wrap align-center>
                    <v-flex
                      v-if="!editCounterRepeatsTime"
                      shrink
                      @click="editCounterRepeats"
                      class="pointer"
                      >{{ formCounterRepeats }}</v-flex
                    >
                    <v-flex shrink v-else>
                      <time-fields
                        :value="formCounterRepeats"
                        @save="saveCounterRepFromDialog"
                        @close="editCounterRepeatsTime = false"
                      />
                    </v-flex>
                    <v-flex
                      v-show="!editCounterRepeatsTime"
                      shrink
                      class="mt-n2"
                    >
                      <small>{{ $t("minutes") }}</small>
                    </v-flex>
                  </v-layout>
                  <v-btn
                    @click="minusMinCounter(form.counter_repeats)"
                    @contextmenu.prevent="minusMinCounter(form.counter_repeats)"
                    @mousedown="changeCounter(false)"
                    @touchstart="changeCounter(false)"
                    @mouseleave="changeCounter(false, false)"
                    @mouseup="changeCounter(false, false)"
                    @touchend="changeCounter(false, false)"
                    @touchcancel="changeCounter(false, false)"
                  >
                    <Icon icon="Minus" />
                  </v-btn>
                </div>
              </div>
            </div>
          </v-form>
        </v-card-text>

        <v-divider light v-if="!$vuetify.breakpoint.smAndDown"></v-divider>

        <v-card-actions class="cardActions">
          <v-spacer></v-spacer>
          <v-flex row xs12 justify-space-between>
            <v-btn
              color="primary"
              text
              @click="!config ? submit() : submitEdit()"
            >
              {{ $t("confirm") }}
            </v-btn>
            <v-btn color="primary" text @click="close()">
              {{ $t("cancel") }}
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout row wrap justify-center>
      <v-flex xs10>
        <v-layout column v-if="Object.values(device.rules).length">
          <v-flex
            grow
            v-for="(rule, index) in rules"
            :key="index"
            class="rule mt-2'"
            elevation-1
          >
            <v-layout
              row
              nowrap
              align-center
              :class="
                rule.value == exceeds && exceeds
                  ? 'ruleExceeds black--text px-3 py-2'
                  : 'black--text px-3 py-2'
              "
            >
              <v-flex xs10 md8>
                <v-layout column>
                  <v-flex>
                    <span class="me-4 text-subtitle-2">
                      {{ $t("sensor") }}:
                    </span>
                    <span class="text-xs-subtitle-1 text-md-h6">
                      {{ $t(rule.sensor) }}
                    </span>
                  </v-flex>
                  <v-divider light v-if="rule.sensor != 'water'"></v-divider>
                  <v-flex v-if="rule.sensor != 'water'">
                    <v-layout row wrap align-baseline>
                      <v-flex shrink>
                        <!-- device.data.sensor_type != 6 && -->
                        <span class="me-4 text-subtitle-2">
                          {{
                            rule.sensor != "hall" &&
                            device.data.sensor_type != 7 &&
                            rule.sensor != "isVibrating" &&
                            rule.sensor != "drycontact" &&
                            rule.sensor != "detection"
                              ? $t(rule.action_text)
                              : content.correctState
                          }}:
                        </span>
                      </v-flex>
                      <v-flex row v-if="rule.sensor != 'water'">
                        <!-- device.data.sensor_type != 6 && -->
                        <span
                          v-if="
                            editRuleSensor == rule.id &&
                            !config &&
                            rule.sensor != 'hall' &&
                            device.data.sensor_type != 7 &&
                            rule.sensor != 'isVibrating' &&
                            rule.sensor != 'drycontact'
                          "
                        >
                          <v-text-field
                            v-model="form.value"
                            class="input-group--focused"
                            single-line
                            type="number"
                            autofocus
                            dense
                            hide-details
                            @keypress.enter="submitEdit"
                          ></v-text-field>
                        </span>
                        <span v-else>
                          <!-- device.data.sensor_type == 6 || -->
                          <span
                            v-if="device.data.sensor_type == 7"
                            @click="rule.sensor != 'hall' ? editRule(rule) : ''"
                            class="text-xs-subtitle-1 text-md-h6"
                            dir="ltr"
                          >
                            {{
                              rule.value == 1 || rule.value == true
                                ? content.lightOn
                                : content.lightOff
                            }}{{ sensorsTitles[rule.sensor] }}
                          </span>
                          <span
                            v-else-if="device.settings.sensor_type == 21"
                            @click="editRule(rule)"
                            class="text-xs-subtitle-1 text-md-h6"
                            dir="ltr"
                          >
                            {{
                              rule.value == 1 || rule.value == true
                                ? content.vibrateOn
                                : content.vibrateOff
                            }}{{ sensorsTitles[rule.sensor] }}
                          </span>
                          <span
                            v-else
                            @click="
                              rule.sensor != 'hall' &&
                              rule.sensor != 'drycontact' &&
                              rule.sensor != 'water' &&
                              rule.sensor != 'detection'
                                ? editRule(rule)
                                : ''
                            "
                            class="text-xs-subtitle-1 text-md-h6"
                            dir="ltr"
                          >
                            {{
                              rule.sensor != "hall" &&
                              rule.sensor != "drycontact"
                                ? rule.value
                                : rule.value == 0
                                ? content.open
                                : content.close
                            }}{{ sensorsTitles[rule.sensor] }}
                          </span>
                        </span>
                        <v-spacer></v-spacer>
                        <!-- device.data.sensor_type != 6 && -->
                        <v-btn
                          v-if="
                            editRuleSensor != rule.id &&
                            rule.sensor != 'hall' &&
                            device.data.sensor_type != 7 &&
                            rule.sensor != 'isVibrating' &&
                            rule.sensor != 'drycontact'
                          "
                          @click="editRule(rule)"
                          text
                          icon
                          color="switchq lighten-1"
                        >
                          <Icon icon="edit" />
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs2 md4>
                <v-layout row wrap justify-end>
                  <v-btn
                    v-if="editRuleSensor != rule.id"
                    @click="configRule(rule)"
                    text
                    icon
                    color="grey darken-2"
                  >
                    <Icon icon="settingsIcon" />
                  </v-btn>
                  <v-btn
                    v-if="editRuleSensor == rule.id && !config"
                    @click="submitEdit()"
                    text
                    icon
                    color="green"
                  >
                    <Icon icon="checkIcon" />
                  </v-btn>
                  <v-btn
                    v-if="editRuleSensor != rule.id && !config"
                    @click="deleteRule(rule)"
                    text
                    icon
                    color="red"
                  >
                    <Icon icon="deleteIcon" />
                  </v-btn>
                  <v-btn
                    v-if="editRuleSensor == rule.id && !config"
                    @click="close()"
                    text
                    icon
                    color="grey darken-1"
                  >
                    <Icon icon="mdiClose" />
                  </v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-flex xs10>
        <v-layout column mt-0>
          <template>
            <v-row justify="center">
              <v-expansion-panels
                class="rule disconnectRule"
                accordion
                v-model="openAccordion"
                light
              >
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    <v-layout column wrap align-center>
                      <div class="disconnectLabel">
                        {{ $t("to-disconnect") }}
                      </div>
                      <div>{{ disconnectTime }}</div>
                    </v-layout>
                    <template v-slot:actions>
                      <Icon icon="edit" color="switchq lighten-1" />
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    ref="disconnectAccordion"
                    dir="rtl"
                  >
                    <v-flex grow class="rule" elevation-1 mb-6>
                      <v-layout row nowrap align-center black--text px-3 py-2>
                        <v-btn
                          @click="plusMinDisconnect(timeToDisconnect)"
                          @contextmenu.prevent="
                            plusMinDisconnect(timeToDisconnect)
                          "
                          @mousedown="changeDisconnect(true)"
                          @touchstart="changeDisconnect(true)"
                          @mouseleave="changeDisconnect(true, false)"
                          @mouseup="changeDisconnect(true, false)"
                          @touchend="changeDisconnect(true, false)"
                          @touchcancel="changeDisconnect(true, false)"
                        >
                          <Icon icon="Plus" /> </v-btn
                        ><v-layout column wrap align-center>
                          <v-flex
                            shrink
                            @dblclick="editDisconnect"
                            v-if="!editDisconnectTime"
                            class="pointer"
                          >
                            {{ disconnectTime }}
                          </v-flex>
                          <v-flex shrink v-else>
                            <v-text-field
                              :autofocus="true"
                              :label="$t('minutes')"
                              class="disconnectTime"
                              type="number"
                              v-model="timeToDisconnect"
                              @keypress.enter="disconnectChange"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-btn
                          @click="minusMinDisconnect(timeToDisconnect)"
                          @contextmenu.prevent="
                            minusMinDisconnect(timeToDisconnect)
                          "
                          @mousedown="changeDisconnect(false)"
                          @touchstart="changeDisconnect(false)"
                          @mouseleave="changeDisconnect(false, false)"
                          @mouseup="changeDisconnect(false, false)"
                          @touchend="changeDisconnect(false, false)"
                          @touchcancel="changeDisconnect(false, false)"
                        >
                          <Icon icon="Minus" />
                        </v-btn>
                      </v-layout>
                    </v-flex>
                    <v-btn color="primary" @click="disconnectChange" block>{{
                      $t("confirm")
                    }}</v-btn>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </template>
        </v-layout>
      </v-flex>
    </v-layout>
    <Modal
      :activator="!!deleteRuleSensor"
      @close="deleteRuleSensor = null"
      @delete="deleteRule()"
    />
  </div>
</template>
<script>
import Lang from "@/helpers/lang";
import axios from "@/plugins/axios";
import Modal from "@/components/general/DeleteModal.vue";
import TimeFields from "@/components/general/TimeFields.vue";
export default {
  name: "notificationRules",
  components: {
    Modal,
    TimeFields,
  },
  data() {
    return {
      touching: null,
      config: false,
      isAdvanced: false,
      dialog: false,
      deleteRuleSensor: null,
      editRuleSensor: null,
      editRepeatsTime: false,
      editCounterRepeatsTime: false,
      editDisconnectTime: false,
      timeToDisconnect: null,
      openAccordion: [],
      form: {
        sensor: null,
        action: null,
        value: null,
        repeats: 0,
        counter_repeats: 0,
        notifications: {
          push: 1,
          email: 1,
          sms: 1,
          text: "",
          counter_text: "",
        },
      },
      customMsg: {
        forText: null,
        forCounterText: null,
      },
      sensorsTitles: {
        range: "cm",
        temperature: "°C",
        humidity: "%",
        co2: "ppm",
        pressure: "hPa",
      },
    };
  },
  computed: {
    disabledRules() {
      let device = JSON.parse(JSON.stringify(this.device));
      let disabled = false;
      if (
        Object.keys(device.data.sensors).includes("temperature") &&
        !Object.keys(device.data.sensors).includes("humidity") &&
        this.rules.length == 2
      ) {
        disabled = true;
      } else if (
        !Object.keys(device.data.sensors).includes("temperature") &&
        Object.keys(device.data.sensors).includes("humidity") &&
        this.rules.length == 2
      ) {
        disabled = true;
      } else if (
        Object.keys(device.data.sensors).includes("temperature") &&
        Object.keys(device.data.sensors).includes("humidity") &&
        !Object.keys(device.data.sensors).includes("co2") &&
        !Object.keys(device.data.sensors).includes("pressure") &&
        this.rules.length == 4
      ) {
        disabled = true;
      } else if (
        Object.keys(device.data.sensors).includes("temperatures") &&
        Object.keys(device.data.sensors).includes("humidity") &&
        Object.keys(device.data.sensors).includes("co2") &&
        Object.keys(device.data.sensors).includes("pressure") &&
        this.rules.length == 8
      ) {
        disabled = true;
      } else {
        disabled = false;
      }
      return disabled;
    },
    disabledNotifications() {
      let disabled = {};
      let userNotes = this.$store.getters["user/notifications"];
      if (userNotes.email == 0) {
        disabled.email = true;
      }
      if (userNotes.sms == 0) {
        disabled.sms = true;
      }
      let pushNotes = this.settings.push;
      if (pushNotes == 0) {
        disabled.push = true;
      }
      return disabled;
    },
    settings() {
      return this.$store.getters["devices/device"](this.$route.params.slug)
        .settings;
    },
    notifications() {
      let ruleNotes = this.$store.getters["devices/device"](
        this.$route.params.slug
      ).rules;
      let notes;
      for (let i = 0; i < ruleNotes.length; i++) {
        if ("notifications" in ruleNotes[i]) {
          notes = { ...ruleNotes[i].notifications };
        } else {
          notes = {};
        }
      }
      return notes;
    },
    cycle() {
      if (this.settings && this.settings.cycle) {
        let roundCycle = this.$store.getters["devices/device"](
          this.$route.params.slug
        ).settings.cycle;
        return parseFloat(((Math.ceil(roundCycle / 10) * 10) / 60).toFixed(2));
      } else {
        return null;
      }
    },
    device() {
      let device = this.$store.getters["devices/device"](
        this.$route.params.slug
      );
      return device;
    },
    exceeds() {
      return this.device.exceeds ? this.device.exceedsVal : false;
    },
    sensors() {
      const sensorsNames = {
        temperature: this.$t("temperature"),
        hall: this.$t("hall"),
        water: this.$t("water"),
        drycontact: this.$t("drycontact"),
        humidity: this.$t("humidity"),
        light: this.$t("light"),
        co2: this.$t("co2"),
        pressure: this.$t("pressure"),
        range: this.$t("range"),
        isVibrating: this.$t("isVibrating"),
      };
      if (
        this.device &&
        this.device.data &&
        "sensors" in this.device.data &&
        Object.keys(this.device.data.sensors).length
      ) {
        // delete this.device.data.sensors.temperatures;
        return Object.keys(this.device.data.sensors)
          .map((s) => {
            if (s === "temperatures") s = "temperature";
            return {
              value: s,
              text: sensorsNames[s],
              disabled:
                this.rules.filter((r) => {
                  const sensor =
                    r.sensor == "temperature" ? "temperature" : r.sensor;
                  return sensor == s;
                }).length == this.actions.length,
            };
          })
          .filter((s) => s.text);
      } else {
        return {};
      }
    },
    actions() {
      return [
        {
          value: ">",
          text: this.$t("top_threshold"),
        },
        {
          value: "<",
          text: this.$t("bottom_threshold"),
        },
      ];
    },
    availableActions() {
      const sensorExistingRules = this.rules
        .filter((r) => r.sensor === this.form.sensor)
        .map((r) => r.action);
      let actions = [...this.actions].map((action) => {
        action.disabled = sensorExistingRules.includes(action.value);
        return action;
      });
      return actions;
    },

    rules() {
      return { ...this.device }.rules.map((r) => {
        r.sensor = r.sensor == "temperatures" ? "temperature" : r.sensor;
        r.action_text = this.actions.find((a) => a.value == r.action).text;
        return r;
      });
    },
    formCounterRepeats() {
      return this.numToTime(this.form.counter_repeats);
    },
    formRepeats() {
      return this.numToTime(this.form.repeats);
    },
    ttl() {
      return this.device.offline_notification.ttl;
    },
    disconnectTime() {
      const ttl = this.timeToDisconnect ?? this.ttl;
      const minutes = String(ttl % 60).padStart(2, 0);
      const hours = String((ttl - minutes) / 60).padStart(2, 0);
      return [hours, minutes, "00"].join(":");
    },
    notificationsDefaultTime() {
      let wantedTime = 20;
      const cycle = this.cycle || 0.1;
      let defaultTime = cycle;
      while (defaultTime < wantedTime) {
        defaultTime = defaultTime + cycle;
      }
      defaultTime = parseFloat(defaultTime.toFixed(2));
      return defaultTime;
    },
    requiresThreshold() {
      const sensors = [
        "temperature",
        "humidity",
        "light",
        "co2",
        "pressure",
        "range",
      ];
      return sensors.includes(this.form.sensor);
    },
  },
  watch: {
    ttl(ttl) {
      this.timeToDisconnect = ttl;
    },
    openAccordion() {
      if (this.openAccordion == []) {
        this.editDisconnectTime = false;
      }
    },
    "form.value"(value) {
      if (this.form.sensor == "hall" || this.form.sensor == "drycontact") {
        if (value == 1 || value == "1.00") {
          this.form.action = "<";
        } else {
          this.form.action = ">";
        }
      }
      if (this.form.sensor == "isVibrating") {
        // console.log("value is", value);
        if (value == 1) this.form.action = "<";
        else this.form.action = ">";
      }
      if (
        // this.device.data.sensor_type == 6 ||
        this.device.data.sensor_type == 7
      ) {
        // console.log("value is", value);
        if (value == 1) this.form.action = "<";
        else this.form.action = ">";
      }
    },
    "form.sensor"(sensor) {
      if (sensor == "water") {
        this.form.action = ">";
        this.form.value = 0;
      } else if (
        // this.device.data.sensor_type != 6 &&
        this.device.data.sensor_type != 7 &&
        sensor != "water" &&
        sensor != "hall" &&
        this.config == false &&
        !this.editRuleSensor
      ) {
        this.form.action = null;
        this.form.value = null;
      }
    },
    device: {
      handler() {
        if (this.device.settings.sensor_type == 21) {
          this.form.sensor = "isVibrating";
        }
      },
      deep: true,
    },
  },
  created() {
    this.setLang();
    this.device;
  },
  mounted() {
    this.timeToDisconnect = this.ttl;
  },
  methods: {
    saveRepFromDialog(value) {
      this.form.repeats = this.timeToNum(value);
    },
    saveCounterRepFromDialog(value) {
      this.form.counter_repeats = this.timeToNum(value);
    },
    timeToNum(time) {
      time = time.split(":");
      let seconds = Math.ceil((100 / 60) * time[1]);
      seconds = String(seconds).padStart(2, "0");
      return parseFloat(time[0] + "." + seconds);
    },
    numToTime(number) {
      let time = parseFloat(number);
      if (time == 0) return "00:00";
      time = time.toFixed(2).split(".");
      time[0] = time[0].padStart(2, "0");
      time[1] = parseInt(time[1]);
      time[1] = String(parseInt((time[1] / 100) * 60)).padStart(2, "0");
      return time.join(":");
    },
    editDisconnect() {
      this.editDisconnectTime = true;
    },
    editRepeats() {
      this.editRepeatsTime = true;
    },
    editCounterRepeats() {
      this.editCounterRepeatsTime = true;
    },
    openPanel() {
      this.openAccordion = [0];
    },
    changeRepeat(add = true, run = true) {
      let counter = 0;
      if (run) {
        clearInterval(this.touching);
        this.touching = false;
        if (add) {
          this.touching = setInterval(() => {
            counter++;
            this.plusMinRepeat(
              this.form.repeats,
              this.getMultiplyByCounter(counter)
            );
          }, 150);
        } else {
          this.touching = setInterval(() => {
            counter++;
            this.minusMinRepeat(
              this.form.repeats,
              this.getMultiplyByCounter(counter)
            );
          }, 150);
        }
      } else {
        clearInterval(this.touching);
        this.touching = false;
      }
    },
    changeDisconnect(add = true, run = true) {
      let counter = 0;
      if (run) {
        clearInterval(this.touching);
        this.touching = false;
        if (add) {
          this.touching = setInterval(() => {
            counter++;
            this.plusMinDisconnect(
              this.timeToDisconnect,
              this.getMultiplyByCounter(counter)
            );
          }, 150);
        } else {
          this.touching = setInterval(() => {
            counter++;
            this.minusMinDisconnect(
              this.timeToDisconnect,
              this.getMultiplyByCounter(counter)
            );
          }, 150);
        }
      } else {
        clearInterval(this.touching);
        this.touching = false;
      }
    },
    changeCounter(add = true, run = true) {
      let counter = 0;
      if (run) {
        clearInterval(this.touching);
        this.touching = false;
        if (add) {
          this.touching = setInterval(() => {
            counter++;
            this.plusMinCounter(
              this.form.counter_repeats,
              this.getMultiplyByCounter(counter)
            );
          }, 150);
        } else {
          this.touching = setInterval(() => {
            counter++;
            this.minusMinCounter(
              this.form.counter_repeats,
              this.getMultiplyByCounter(counter)
            );
          }, 150);
        }
      } else {
        clearInterval(this.touching);
        this.touching = false;
      }
    },
    plusMinRepeat(time, multiply = 1) {
      time = parseFloat(time);
      if (time < 1000) {
        time += this.cycle * multiply;
        this.form.repeats = time.toFixed(2);
      }
    },
    plusMinCounter(time, multiply = 1) {
      time = parseFloat(time);
      if (time < 1000) {
        time += this.cycle * multiply;
        this.form.counter_repeats = time.toFixed(2);
      }
    },
    plusMinDisconnect(time, multiply = 1) {
      time = parseFloat(time);
      if (time < 1000) {
        time += 1 * multiply;
        this.timeToDisconnect = parseInt(time);
      }
    },
    minusMinDisconnect(time, multiply = 1) {
      time = parseFloat(time);
      if (time > 0) {
        time -= 1 * multiply;
        if (time >= 2) {
          // time = 2;
          this.timeToDisconnect = parseInt(time);
        }
      }
    },
    minusMinRepeat(time, multiply = 1) {
      time = parseFloat(time);
      if (time > 0) {
        time -= this.cycle * multiply;
        if (time < 0) {
          time = 0;
        }
        this.form.repeats = time.toFixed(2);
      }
    },
    minusMinCounter(time, multiply = 1) {
      time = parseFloat(time);
      if (time > 0) {
        time -= this.cycle * multiply;
        if (time < 0) {
          time = 0;
        }
        this.form.counter_repeats = time.toFixed(2);
      }
    },
    getMultiplyByCounter(counter) {
      switch (true) {
        case counter > 60:
          return 10;
        case counter > 40:
          return 5;
        case counter > 30:
          return 4;
        case counter > 20:
          return 3;
        case counter > 10:
          return 2;
        default:
          return 1;
      }
    },
    setLang() {
      let lang = Lang.get();
      if (lang == "en") {
        this.content = {
          select: "Select settings",
          correctState: "Normal status",
          add: "Create Rule",
          edit: "Edit",
          header: "Edit notification rules",
          threshold: "Threshold",
          top: "Top Threshold",
          bottom: "Bottom Threshold",
          open: "Opened",
          close: "Closed",
          lightOn: "Light On",
          lightOff: "Light Off",
          vibrateOn: "Vibrating",
          vibrateOff: "Not Vibrating",
          decimal: "The threshold must be a whole number",
          cantChange: "Can't change those settings for this device",
        };
      } else if (lang == "he") {
        this.content = {
          select: "בחירת הגדרות",
          correctState: "מצב תקין",
          add: "יצירת הגדרה",
          edit: "עריכה",
          header: "הגדרות התראה",
          threshold: "סף",
          top: "סף עליון",
          bottom: "סף תחתון",
          open: "פתוח",
          close: "סגור",
          lightOn: "אור דולק",
          lightOff: "אור כבוי",
          vibrateOn: "רעידות",
          vibrateOff: "ללא רעידות",
          decimal: "הסף חייב להיות מספר שלם",
          cantChange: "לחיישן זה לא ניתן לשנות הגדרות אלו",
        };
      }
      return lang;
    },
    addRule() {
      this.close();
      this.dialog = true;
      let repeatTime = this.notificationsDefaultTime;
      if ([10, 11].includes(parseInt(this.device.data.sensor_type))) {
        repeatTime = 0;
      }
      this.form.repeats = repeatTime;
      this.form.counter_repeats = repeatTime;
    },
    deleteRule(rule) {
      if (this.deleteRuleSensor) {
        axios
          .delete(
            `/devices/${this.$route.params.slug}/notifications/rules/${this.deleteRuleSensor}/delete`
          )
          .then((res) => {
            this.$store.dispatch("devices/setDevice", res.data.data);
            this.$store.dispatch("general/setAlert", {
              type: "success",
              msg: "deleteRule",
            });
            this.dialog = false;
          })
          .catch((err) => {
            console.log("ERROR: ", err);
            this.$store.dispatch("general/setAlert", {
              type: "failed",
              msg: "oops",
            });
          });

        this.deleteRuleSensor = null;
      } else {
        this.deleteRuleSensor = rule.id;
      }
    },
    submitEdit() {
      axios
        .post(
          `/devices/${this.$route.params.slug}/notifications/rules/${this.editRuleSensor}/edit`,
          this.form
        )
        .then((res) => {
          const device = res.data.data;
          this.$store.dispatch("devices/setDevice", device);
          this.$store.dispatch("general/setAlert", {
            type: "success",
            msg: "editRule",
          });
          this.device;
          this.close();
        })
        .catch((err) => {
          console.log("ERROR: ", err);
          this.$store.dispatch("general/setAlert", {
            type: "failed",
            msg: "oops",
          });
        });
    },
    editRule(rule) {
      this.editRuleSensor = rule.id;
      this.form.sensor = rule.sensor;
      this.form.action = rule.action;
      this.form.value = rule.value;
      this.form.repeats = rule.repeats;
      this.form.counter_repeats = rule.counter_repeats;
      this.form.notifications.email = rule.notifications.email;
      this.form.notifications.sms = rule.notifications.sms;
      this.form.notifications.push = rule.notifications.push;
    },
    configRule(rule) {
      this.config = true;
      this.dialog = true;
      this.editRuleSensor = rule.id;
      this.form.sensor = rule.sensor;
      if (
        rule.sensor == "hall" ||
        rule.sensor == "isVibrating" ||
        rule.sensor == "drycontact"
      ) {
        this.form.value = parseInt(rule.value);
      } else {
        this.form.value = rule.value;
      }
      this.form.action = rule.action;
      this.form.repeats = rule.repeats;
      this.form.counter_repeats = rule.counter_repeats;
      this.form.notifications.email = rule.notifications.email;
      this.form.notifications.sms = rule.notifications.sms;
      this.form.notifications.push = rule.notifications.push;
    },
    close() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      this.dialog = false;
      this.editRuleSensor = null;
      this.editRepeatsTime = false;
      this.editCounterRepeatsTime = false;
      this.isAdvanced = false;
      this.config = false;
      this.form.action = null;
      this.form.value = null;
      this.form.sensor = null;
      this.form.notifications.email = 1;
      this.form.notifications.sms = 1;
      this.form.notifications.push = 1;
      this.form.repeats = 0;
      this.form.counter_repeats = 0;
      this.customMsg.forText = false;
      this.customMsg.forCounterText = false;
      this.device;
    },
    submit() {
      if (this.$refs.form.validate()) {
        axios
          .post(
            `/devices/${this.$route.params.slug}/notifications/rules/new`,
            this.form
          )
          .then((res) => {
            const device = { ...this.device };
            for (const param in res.data.data) {
              device[param] = res.data.data[param];
            }
            this.$store.dispatch("devices/setDevice", device);
            this.$store.dispatch("general/setAlert", {
              type: "success",
              msg: "addRule",
            });
            this.device;
            this.close();
          })
          .catch(() => {
            this.$store.dispatch("general/setAlert", {
              type: "failed",
              msg: "oops",
            });
          });
      }
    },
    disconnectChange() {
      axios
        .post(`/devices/${this.$route.params.slug}/notifications/disconnect`, {
          ttl: this.timeToDisconnect,
        })
        .then((res) => {
          const device = res.data.data;
          this.$store.dispatch("devices/setDevice", device);
          this.$store.dispatch("general/setAlert", {
            type: "success",
            msg: "addRule",
          });
          this.openAccordion = [];
        })
        .catch(() => {
          this.$store.dispatch("general/setAlert", {
            type: "failed",
            msg: "min2",
          });
        });
      return this.timeToDisconnect;
    },
    // },
  },
};
</script>
<style lang="scss" scoped>
.disconnectTime,
.counterRepeatTime,
.repeatTime {
  width: 100px;
}

.editRules {
  border-radius: 10px;
  background-color: rgba(25, 118, 210, 0.1);
  .editBtn {
    width: max-content;
    border-radius: unset;
    .mx-3,
    .v-icon {
      color: #235db4;
    }
  }
}

.addRuleBtn {
  border: 1px solid;
  border-radius: 5px;
}

.rule {
  border-radius: 10px;
  background: rgb(255, 255, 255);
  margin-top: 5px;
}

.advanced {
  margin-inline-start: 35px;
  font-size: 10pt;
  color: #235db4;
  cursor: pointer;
  span {
    text-decoration: underline;
  }
}
.disconnectRule {
  margin-top: -6px;
}

.disconnectLabel {
  font-size: 11pt;
  text-align: center;
  padding: 5px;
  text-decoration: underline;
}

.advancedOpts {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    color: darkslategrey;
    // .disableForDevice {
    //   margin: 0 auto;
    //   text-align: center;
    // }

    span {
      text-decoration: underline;
      font-size: 12pt;
    }
    .shrink {
      text-align: start;
    }
    .formSwitchs {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 30px;
      align-items: center;
      div {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        .switchLabel {
          padding-inline-end: 10px;
        }
      }
    }
    .repeats,
    .counters {
      margin: 10px 0px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: baseline;
      div {
        font-size: 14pt;
      }
      small {
        text-decoration: none;
        font-size: 10pt;
      }
    }
    h1 {
      margin-top: 20px;
      font-size: 10pt;
      color: red;
      font-weight: 600;
      text-align: center;
    }
  }
}
@media screen and (max-width: 950px) {
  .cardActions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    position: relative;
    bottom: 0;
  }
}
</style>
