class Lang {
  set(lang) {
    if (lang != this.get()) localStorage.setItem("lang", lang);
    document.cookie = "locale=" + lang + "; path=/";
  }

  get() {
    if (!localStorage.getItem("lang")) {
      return "he";
    } else {
      return localStorage.getItem("lang");
    }
  }
}

// export default Lang = new Lang();
module.exports = new Lang();
