import axios from "axios";
import dayjs from "dayjs";
/**
 * Initial state
 */
export const state = {
  actions: {},
};

/**
 * Mutations
 */
export const mutations = {
  ["SET_ACTIONS"](state, data) {
    data.actions = data.actions.map(function (action) {
      action.created_at = new dayjs(action.created_at);
      return action;
    });
    // Vue.set(state.actions, data.device, Object.values(data.actions));
  },
  ["SET_ACTIONS_FAILURE"](state) {
    state.actions = {};
  },
};

/**
 * Actions
 */
export const actions = {
  async fetchActions({ commit }, slug) {
    await axios
      .get(`/api/switch/${slug}/actions`)
      .then(async (res) => {
        if (res.data && res.data.success) {
          await commit("SET_ACTIONS", {
            device: slug,
            actions: res.data.success,
          });
        }
      })
      .catch((err) => {
        commit("SET_ACTIONS_FAILURE");
        console.error(err);
      });
  },
};

/**
 * Getters
 */
export const getters = {
  device: (state) => (slug) => {
    return state.actions && state.actions[slug] ? state.actions[slug] : [];
  },
};
