<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          <Icon icon="alertOctagon" :xlarge="true" color="red" class="v-icon" />
        </v-card-title>
        <v-card-text>{{ $t("varify-logout") }}</v-card-text>
        <v-card-actions>
          <v-btn color="switchq darken-1" text @click="logout">
            {{ $t("out") }}
          </v-btn>
          <v-btn color="red darken-1" text @click="$emit('close')">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: "LogOutModal",
  props: ["activator"],
  data() {
    return {
      dialog: false,
    };
  },

  created() {
    this.dialog = this.activator;
  },
  watch: {
    activator() {
      this.dialog = this.activator;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("user/signOut");
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss" scoped>
.v-icon {
  margin: 0 auto;
}
.v-dialog > .v-card > .v-card__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
