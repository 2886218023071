import dayjs from "@/plugins/dayjs";
import createUUID from "@/helpers/uuid";

function sleep(seconds = 3) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), seconds * 1000);
  });
}
/**
 * Initial state
 */
export const state = {
  time: dayjs(),
  timeInterval: false,
  sideMenu: false,
  alerts: {},
  device_id: localStorage.getItem("device_identification"),
  isCustomApp: true,
};

/**
 * Mutations
 */
export const mutations = {
  ["START_TIME"](state) {
    if (!state.timeInterval) {
      state.timeInterval = true;
      state.time = dayjs();
      setInterval(() => {
        state.time = dayjs();
      }, 1000);
    }
  },
  ["SET_SIDEMENU"](state, status) {
    state.sideMenu = status;
  },
  ["TOGGLE_SIDEMENU"](state) {
    state.sideMenu = !state.sideMenu;
  },
  ["UNSET_ALERT"](state, uuid) {
    let alerts = { ...state.alerts };
    delete alerts[uuid];
    state.alerts = alerts;
  },
  ["SET_ALERT"](state, alert) {
    let alerts = { ...state.alerts };
    const uuid = createUUID();
    alerts[uuid] = alert;
    state.alerts = alerts;
    sleep(alerts.ttl).then(() => {
      this.commit("general/UNSET_ALERT", uuid);
    });
  },
  ["SET_DEVICE_ID"](state, id) {
    state.device_id = id;
  },
  ["SET_CUSTOM_APP"](state, data) {
    state.isCustomApp = data;
  },
  ["LOGOUT"](state) {
    state.time = dayjs();
    state.timeInterval = false;
    state.sideMenu = false;
    state.alerts = {};
    state.device_id = localStorage.getItem("device_identification");
    state.isCustomApp = true;
  },
};

/**
 * Actions
 */
export const actions = {
  logOut({ commit }) {
    // this.reset();
    commit("LOGOUT");
  },
  async startTimeInterval({ commit }) {
    commit("START_TIME");
  },
  async setSideMenu({ commit }, status) {
    commit("SET_SIDEMENU", status);
  },
  async toggleSideMenu({ commit }) {
    commit("TOGGLE_SIDEMENU");
  },
  async setAlert({ commit }, alert) {
    commit("SET_ALERT", alert);
  },
  unsetAlert({ commit }, uuid) {
    commit("UNSET_ALERT", uuid);
  },
  setDeviceId({ commit }, id) {
    commit("SET_DEVICE_ID", id);
  },
  setCustomApp({ commit }, data) {
    commit("SET_CUSTOM_APP", data);
  },
};

/**
 * Getters
 */
export const getters = {
  time: (state) => state.time,
  sideMenu: (state) => state.sideMenu,
  alerts: (state) => Object.values(state.alerts),
  custom: (state) => state.isCustomApp,
};
