import axios from "@/plugins/axios";

export default {
  data() {
    return {
      publicVapidKey:
        "BM0y8CuzwW3kHMzRmTMDz_t2b5y7VWEV9MDk7i0eeEWioo2pJd9SclJsB6Bgi8sB2YFpGvTz-Lqf785cYIfFV6o",
      checkedSubscription: false,
      isSubscribed: null,
      register: null,
      subscription: null,
    };
  },
  mounted() {
    this.registerSw();
  },

  methods: {
    async registerSw() {
      this.register = await navigator.serviceWorker.register("/push-sw.js", {
        scope: "/push-notifications",
      });
      await this.getSubscription();
    },
    async getSubscription() {
      await this.register.pushManager
        .getSubscription()
        .then(async (subscription) => {
          this.subscription = subscription;
          await this.getServerSubscription()
            .then((res) => {
              // console.log("getServerSubscription: ", res);
              const endpoint = res.data.data.endpoint;
              if (endpoint == subscription.endpoint) {
                this.isSubscribed = true;
              }
            })
            .catch(() => {
              //
            });
        })
        .catch(() => {
          // console.log('err', err);
        })
        .finally(() => {
          this.checkedSubscription = true;
        });
      // console.log("this.subscription", this.subscription);
      return this.isSubscribed;
    },
    getServerSubscription() {
      return axios.get(`/user/notifications/push`);
      // .then((res) => {
      //   console.log("res is: ", res);
      // })
      // .catch(() => {
      //   // console.log("ERROR: ", err);
      // });
    },
    async allowPush() {
      if (localStorage.getItem("deniedPush")) {
        localStorage.removeItem("deniedPush");
      }
      await this.subscribe();
      // console.log("Got to push subscribe");
    },
    denyPush() {
      localStorage.setItem("deniedPush", "true");
    },
    async subscribe() {
      const subscription = await this.register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: this.publicVapidKey,
      });
      await this.sendSubscription(subscription);
    },

    async sendSubscription(subscription) {
      await axios
        .post(`/user/notifications/push/subscribe`, subscription)
        .then(() => {
          // console.log("res is: ", res);
        })
        .catch(() => {
          // console.log("ERROR: ", err);
        });
    },
    async unsubscribe() {
      try {
        this.subscription
          .unsubscribe()
          .then(() => {
            // console.log("unsubscribe: ", unsubed);
          })
          .catch(() => {
            // console.log("For some reason you could not unsubscribe", e);
          })
          .finally(async () => {
            this.unsubscribeInServer();
          });
      } catch (err) {
        console.error(err);
        this.unsubscribeInServer();
      }
    },
    async unsubscribeInServer() {
      await axios
        .post(
          `/user/notifications/push/unsubscribe`,
          this.subscription.toJSON()
        )
        .then(() => {
          // console.log("res is: ", res);
        })
        .catch(() => {
          // console.log("ERROR: ", err);
        });
    },
  },
};
