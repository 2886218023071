<style lang="scss">
.v-btn--active {
  color: inherit;
  span {
    color: inherit;
  }
}
</style>

<template>
  <v-layout column wrap>
    <v-layout row wrap justify-center class="my-4">
      <v-flex xs12 class="text-xs-center">
        <p class="text-center blue-grey--text text--lighten-1 mb-1">
          {{ content.graph }}
        </p>
      </v-flex>
      <v-flex xs12>
        <v-layout row wrap justify-center>
          <v-btn-toggle
            v-model="time"
            class="elevation-3"
            id="chart-quick-btns"
            dense
            light
            color="switchq"
          >
            <v-btn text value="hour">
              <span>{{ content.h }}</span>
            </v-btn>
            <v-btn text value="6hours">
              <span>6{{ content.hs }}</span>
            </v-btn>
            <v-btn text value="day">
              <span>24{{ content.hs }}</span>
            </v-btn>
            <v-btn text value="week">
              <span>7 {{ content.days }}</span>
            </v-btn>
            <v-btn v-show="$vuetify.breakpoint.mdAndUp" text value="month">
              <span>30 {{ content.days }}</span>
            </v-btn>
          </v-btn-toggle>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout
      v-if="time"
      ref="deviceChart"
      column
      wrap
      justify-center
      align-center
      class="mt-2 mb-4"
      id="itemGraph"
    >
      <div class="chart" v-if="!loading && !noLogsData">
        <section v-if="!loading">
          <line-chart
            v-if="
              logs[time].length &&
              device.settings.sensor_type &&
              (device.settings.sensor_type <= 6 ||
                (device.settings.sensor_type == 9 &&
                  device.settings.sensor_type != 20))
            "
            :logs="logs[time]"
            :sensors="sensors"
            :temperaturescount="amountOfTemperatures"
            class="linechart"
            :key="THEKEY"
          />
          <stackedBarChart
            v-if="
              logs[time].length &&
              device.settings.sensor_type &&
              device.settings.sensor_type >= 7 &&
              device.settings.sensor_type != 9 &&
              device.settings.sensor_type != 20
            "
            :logs="logs[time]"
            :sensors="sensors"
          />
        </section>
      </div>
      <div v-if="noLogsData" class="noLogs">
        <h3 class="warning--text">{{ content.noLogs }}</h3>
      </div>
      <v-flex v-if="loading" class="progress pa-5">
        <v-progress-circular
          :size="100"
          :width="6"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import LineChart from "@/components/tools/linechart_Js.vue";
import stackedBarChart from "@/components/tools/stackedBarChart.vue";
import Lang from "@/helpers/lang";
import axios from "@/plugins/axios";

export default {
  name: "itemGraph",
  components: {
    LineChart,
    stackedBarChart,
    // TimelineChart,
  },
  props: ["slug"],
  data() {
    return {
      loading: false,
      noLogsData: false,
      time: null,
      THEKEY: null,
      mainTemp: null,
      logs: {},
      sensors: [],
      // styles: {
      //   height: 300,
      // },
    };
  },
  created() {
    this.locale = Lang.get();
    this.mainTemp = this.mainTempChange;
    this.setLang();
  },
  computed: {
    amountOfTemperatures() {
      const device = this.$store.getters["devices/device"](this.slug);
      if ("temperatures" in device.data.sensors) {
        return device.data.sensors.temperatures.length;
      }
      return 0;
    },

    mainTempChange() {
      const device = this.$store.getters["devices/device"](this.slug);
      return device.settings.single_temp;
    },
    device() {
      return this.$store.getters["devices/device"](this.$route.params.slug);
    },
  },
  watch: {
    time(time) {
      if (time) {
        this.THEKEY = time;
        this.startGraph();
        // console.log("logs", this.logs);
      }
    },
    mainTempChange() {
      if (this.mainTemp != this.mainTempChange && this.time) {
        this.THEKEY = this.mainTempChange;
        this.mainTemp = this.mainTempChange;
      } else if (this.mainTemp != this.mainTempChange && !this.time) {
        this.THEKEY = this.time;
        this.mainTemp = this.mainTempChange;
      }
    },
  },
  methods: {
    async startGraph() {
      await this.getData();
    },
    async getData() {
      this.loading = true;
      this.noLogsData = false;
      if (this.logs[this.time]) {
        this.loading = false;
      } else {
        await axios
          .post(`/devices/${this.slug}/logs`, {
            time: this.time,
          })
          .then(async (res) => {
            if (res.data.success && res.data.data.data.length > 1) {
              let data = { ...res.data.data };
              if (
                data.data[0].type &&
                data.data[0].type <= 6 &&
                data.data[0].type != 20
              ) {
                await this.setLineData(data);
              } else {
                await this.setBarData(data);
                // console.log("data", data);
              }
            } else if (
              (res.data.success && !res.data.data.data.length) ||
              res.data.data.data.length == 1
            ) {
              this.loading = false;
              this.noLogsData = true;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    async setLineData(logs) {
      // console.log("Logs", logs);
      const sensors = ["temperatures", "humidity", "light", "co2", "pressure"];
      let hasSensors = [];
      if (logs.data.length > 1) {
        this.logs[this.time] = logs.data.map((log) => {
          const l = { time: +new Date(log.created_at) };
          for (const param in log) {
            if (sensors.includes(param)) {
              // if (param === "temperatures") {
              //   if (!hasSensors.includes("temperatures1"))
              //     hasSensors.push("temperatures1");
              //   if (log[param][1]) {
              //     if (!hasSensors.includes("temperatures2"))
              //       hasSensors.push("temperatures2");
              //   }
              // } else {
              if (!hasSensors.includes(param)) hasSensors.push(param);
              // }
              l[param] = log[param];
            }
          }
          return l;
        });
        this.loading = false;
      }
      this.sensors = hasSensors;
      // console.log("hasSensors", hasSensors);
    },
    async setBarData(logs) {
      // console.log("Logs", logs);
      const sensors = [
        "drycontact",
        "water",
        "hall",
        "light",
        "visiblelight",
        "isVibrating",
      ];
      let hasSensors = [];
      if (logs.data.length > 1) {
        this.logs[this.time] = logs.data.map((log) => {
          const l = { time: +new Date(log.created_at) };
          for (const param in log) {
            if (sensors.includes(param)) {
              // if (param === "temperatures") {
              //   if (!hasSensors.includes("temperatures1"))
              //     hasSensors.push("temperatures1");
              //   if (log[param][1]) {
              //     if (!hasSensors.includes("temperatures2"))
              //       hasSensors.push("temperatures2");
              //   }
              // } else {
              if (!hasSensors.includes(param)) hasSensors.push(param);
              // }
              l[param] = log[param];
            }
          }
          return l;
        });
        this.loading = false;
      }
      this.sensors = hasSensors;
      // console.log("logs", this.logs);
    },
    setLang() {
      if (this.locale == "en") {
        this.content = {
          graph: "Graph - select time",
          h: "One Hour",
          hs: "Hours",
          days: "Days",
          year: "Year",
          time: "Time",
          status: "status",
          temp: "temperature",
          sectemp: "second temperature",
          thirdtemp: "third temperature",
          humidity: "humidity",
          light: "light",
          pressure: "pressure",
          co2: "CO2",
          click: "Click and drag in the plot area to zoom in",
          pinch: "Pinch the chart to zoom in",
          noLogs: "No logs at the requested time",
        };
      } else if (this.locale == "he") {
        this.content = {
          graph: "גרף - בחירת זמנים",
          h: "שעה אחת",
          hs: " שעות",
          days: "ימים",
          year: "שנה",
          time: "זמן",
          status: "סטטוס",
          temp: "טמפרטורה",
          sectemp: "טמפרטורה שנייה",
          thirdtemp: "טמפרטורה שלישית",
          humidity: "לחות",
          light: "אור",
          pressure: "לחץ אטמוספרי",
          co2: "CO2",
          click: "לחץ וגרור את שטח הגרף כדי להתקרב",
          pinch: "צבוט את התרשים כדי להתקרב",
          noLogs: "אין לוגים שמורים בזמן המבוקש",
        };
      }
    },
  },
};
</script>
