class Auth {
  z = [
    "skw4qw4n3fd3rs7o",
    "kbfstslfq1fsvgx9",
    "8jpm0d9xy68g4g06",
    "7mwa5xlrkb05dm2t",
    "nu5saymh60yk66so",
    "fhm511rf9bxka06n",
    "1r7mopg08f1gj9xj",
    "hppw5gk90078z3j0",
    "zopezwul3mrrjfwr",
    "o2utx6nrcdg3rv2f",
    "e9m4y27brzyqhrup",
    "0l2y59uk08dt4u1a",
    "ybik9e8x3gvygppb",
    "574y9adjalpcp5qe",
    "cavorbmsazzzg3by",
    "gyenxqbvqv9c13e7",
    "4iy2g0ijtkb1ynhb",
    "k9ztcpg8ygrauj89",
    "0rbx4f2125ajxuyc",
    "mq0fhdxia4re7csb",
    "f8jjzpn0ez9oe13o",
    "7wh0y4p84xv176dt",
  ];

  s = [
    "submitted",
    "addons",
    "last_configurations",
    "time_of_lost",
    "last_form",
    "ota",
    "socket_log",
    "fixed_lost",
    "ark",
    "gentle_source",
    "controllers_api",
    "fireworks",
    "dt_alerts",
    "api_func",
    "call_of_el",
    "data_log_ps",
    "ampm",
    "sensor_breaks",
    "functions_key",
    "waited_for",
    "green_token",
    "orbit",
    "false_peacocks",
  ];

  save(t) {
    // console.log("SAVE TOKEN", t);
    this.do();
    // const keys = this.gk();
    // let hex = uth(t);
    // console.log("hex", hex);
    // const values = this.gst(uth(t));
    // const data = this.mkv(keys, values);
    // const data = this.mkv(this.gk(), this.gst(uth(t)));
    // console.log("data", data);
    this.stls(this.mkv(this.gk(), this.gst(uth(t))));
    // const newToken = this.getToken();
    // console.log("TOKEN", newToken);
  }

  do() {
    // const r = ["lang"];
    let td = [];
    for (let i = 0; i < localStorage.length; i++) {
      let k = localStorage.key(i);
      let ko = k.split("_");
      ko = ko[ko.length - 1];
      if (this.z.includes(ko)) {
        td.push(k);
      }
    }
    for (const k of td) {
      localStorage.removeItem(k);
    }
  }

  getToken() {
    let d = {};
    for (let i = 0; i < localStorage.length; i++) {
      const k = localStorage.key(i);
      if (k.includes("_")) {
        const v = localStorage.getItem(k);
        let ok = k.split("_");
        ok = ok[ok.length - 1];
        const o = this.z.indexOf(ok);
        if (o >= 0) {
          d[k] = [o, v];
        }
      }
    }
    if (Object.keys(d).length < 8) return false;
    let st = [];
    const dv = Object.values(d);
    for (let i = 0; i < dv.length; i++) {
      st[i] = "";
    }
    dv.forEach((dv) => {
      st[dv[0]] = dv[1];
    });
    return htu(st.join(""));
  }

  // get splitted token
  gst(t, l = 8) {
    let c = 0;
    let a = [];
    const vl = Math.ceil(t.length / l);
    // console.log("value length", vl);
    for (let i = 0; i < t.length; i++) {
      if (a[c] && a[c].length === vl) c++;
      if (!a[c]) a[c] = "";
      a[c] += t[i].toString();
    }
    return a;
  }

  mkv(k, v) {
    let m = {};
    for (let i = 0; i < v.length; i++) {
      m[k[i]] = v[i];
    }
    return m;
  }

  stls(d) {
    shA(Object.entries(d)).forEach((a) => {
      localStorage.setItem(a[0], a[1]);
    });
  }

  gk(l = 8) {
    let k = [];
    let os = shA(this.s);
    for (let i = 0; i < l; i++) {
      k[i] = os[i] + "_" + this.z[i];
    }
    return k;
  }

  logged() {
    return this.getToken() ? true : false;
  }

  logout() {
    // console.log("im here");
    this.do();
  }
}

module.exports = new Auth();

function uth(s) {
  return Array.from(s)
    .map((c) =>
      c.charCodeAt(0) < 128
        ? c.charCodeAt(0).toString(16)
        : encodeURIComponent(c).replace(/%/g, "").toLowerCase()
    )
    .join("");
}
function htu(hex) {
  return decodeURIComponent("%" + hex.match(/.{1,2}/g).join("%"));
}

const shA = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const t = a[i];
    a[i] = a[j];
    a[j] = t;
  }
  return a;
};
