import axios from "@/plugins/axios";

/**
 * Initial state
 */
export const state = {
  business: {
    name: "",
    number: null,
    admin: "",
    logo: "",
    title: "",
    custom: false,
  },
};

/**
 * Mutations
 */
export const mutations = {
  ["SET_BUSINESS"](state, business) {
    business.custom = !!business.custom;
    state.business = business;
  },
  ["LOGOUT"](state) {
    state.business.name = "";
    state.business.number = null;
    state.business.admin = "";
    state.business.logo = "";
    state.business.title = "";
    state.business.custom = false;
  },
};

/**
 * Actions
 */
export const actions = {
  logOut({ commit }) {
    // this.reset();
    commit("LOGOUT");
  },
  async get({ commit }) {
    axios
      .get("business")
      .then((res) => {
        // console.log("business/get", res.data);
        if (res.data.data) {
          commit("SET_BUSINESS", res.data.data);
        }
      })
      .catch((err) => {
        console.log("business/get", err);
      });
  },
};

/**
 * Getters
 */
export const getters = {
  business: (state) => state.business,
};
