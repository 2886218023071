<template>
  <v-layout row nowrap align-center justify-center class="px-2">
    <v-flex xs9 class="text-xs-right">
      <v-layout row wrap align-center class="text-xs-right">
        <v-flex grow>
          <v-select
            :append-outer-icon="icons[devmode].icon"
            class="text-xs-right"
            :items="available"
            :placeholder="content.none"
            v-model="edit"
            @change="modify()"
            :menu-props="{ maxHeight: '500px' }"
          >
            <template v-slot:label>
              <h2 class="text-nowrap">{{ content.title }} :</h2>
            </template>
            <template v-slot:append-outer>
              <v-flex
                shrink
                v-if="
                  !isNaN(devmode) && (!role == 0 || (role == 0 && devmode != 0))
                "
              >
                <v-btn
                  text
                  fab
                  small
                  :color="icons[devmode] ? icons[devmode].color : ''"
                  :loading="loading"
                >
                  <Icon :icon="icons[devmode].icon" />
                </v-btn>
              </v-flex>
            </template>
          </v-select>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ["locale", "devmodes"],
  data() {
    return {
      edit: null,
      loading: null,
      icons: [
        {
          icon: "timerIcon",
          color: "blue darken-4",
        },
        {
          icon: "wb_sunny",
          color: "yellow darken-2",
        },
        {
          icon: "mdiSnowflakeVariant",
          color: "blue",
        },
        {
          icon: "mdiOpacity",
          color: "cyan lighten-3",
        },
        {
          icon: "mdiWaterOff",
          color: "cyan lighten-3",
        },
        {
          icon: "wb_incandescent",
          color: "yellow darken-1",
        },
        {
          icon: "wb_incandescent",
          color: "grey lighten-1",
        },
        {
          icon: "mdiArrowSplitHorizontal",
          color: "purple lighten-1",
        },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {
          icon: "mdiArrowSplitHorizontal",
          color: "teal darken-1",
        },
      ],
    };
  },
  created() {
    this.setLang();
    this.setEdit();
  },
  mounted() {
    if (this.devmode) this.edit = this.devmode;
  },
  computed: {
    device() {
      return this.$store.getters["devices/device"](this.$route.params.slug);
    },
    devmode() {
      const devmode = this.device.data.devmode;
      // console.log("devmode", devmode);
      if (this.role == 0 && this.devmodes == 0) {
        return null;
      } else {
        return devmode;
      }
    },
    role() {
      return this.device.role;
    },
    available() {
      const SENSOR = this.device.settings.sensor_type;
      const TopBottom = !!(this.device.role == 0 && this.device.data.battery);
      let available = [];
      if (this.role) available.push(this.content.devmodes[0]);
      if (SENSOR >= 1 && SENSOR <= 6) {
        available.push(this.content.devmodes[1]);
        available.push(this.content.devmodes[2]);
        if (TopBottom) available.push(this.content.devmodes[7]);
      }
      if (SENSOR >= 2 && SENSOR <= 6) {
        available.push(this.content.devmodes[3]);
        available.push(this.content.devmodes[4]);
      }
      if (SENSOR >= 6 && SENSOR <= 7) {
        available.push(this.content.devmodes[5]);
        available.push(this.content.devmodes[6]);
      }
      if ("co2" in this.device.data.sensors) {
        // console.log(this.content.devmodes[7]);
        available.push(this.content.devmodes[8]);
      }
      // console.log("available devmodes", available);
      return available;
    },
    // available: function() {
    // 	const TYPE = this.$store.getters['devices/device'](this.$route.params.slug).type;
    // 	let available = this.content.devmodes.slice((this.role == 0 ? 1 : 0), this.devmodes);
    // 	if (TYPE && TYPE > 1) {
    // 		available.push(this.content.devmodes[this.content.devmodes.length - 1])
    // 	}
    // 	return available;
    // }
  },
  watch: {
    devmode(devmode) {
      this.edit = devmode;
    },
  },
  methods: {
    setEdit() {
      this.edit = this.content.devmodes[this.devmode];
    },
    async modify() {
      this.loading = true;
      let data = {
        slug: this.$route.params.slug,
        modify: {
          parameter: "devmode",
          value: this.edit,
        },
      };
      await this.$store.dispatch("devices/modifyDevice", data);
      this.edit = this.devmode;
      this.loading = null;
    },

    setLang() {
      if (this.locale == "en") {
        this.content = {
          title: "Operation Mode",
          none: "please choose a mode",
          devmodes: [
            {
              text: "Timer",
              value: 0,
            },
            {
              text: "Heating",
              value: 1,
            },
            {
              text: "Cooling",
              value: 2,
            },
            {
              text: "Increasing humidity",
              value: 3,
            },
            {
              text: "Decreasing humidity",
              value: 4,
            },
            {
              text: "Turning light on",
              value: 5,
            },
            {
              text: "Turning light off",
              value: 6,
            },
            {
              text: "Temperature - Upper and lower threshold",
              value: 7,
            },
            {
              text: "CO2 - Upper and lower threshold",
              value: 15,
            },
          ],
        };
      } else if (this.locale == "he") {
        this.content = {
          title: "מצב פעולה",
          none: "אנא בחר מצב פעולה",
          devmodes: [
            {
              text: "טיימר",
              value: 0,
            },
            {
              text: "חימום",
              value: 1,
            },
            {
              text: "קירור",
              value: 2,
            },
            {
              text: "הגברת לחות",
              value: 3,
            },
            {
              text: "הורדת לחות",
              value: 4,
            },
            {
              text: "הגברת אור",
              value: 5,
            },
            {
              text: "הורדת אור",
              value: 6,
            },
            {
              text: "טמפרטורה - סף עליון ותחתון",
              value: 7,
            },
            {
              text: "CO2 - סף עליון ותחתון",
              value: 15,
            },
          ],
        };
      }
    },
  },
};
</script>
