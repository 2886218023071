<template>
  <v-layout row wrap>
    <v-flex v-if="item.connection && 'battery' in item.data" shrink>
      <battery
        :data="item.data.battery"
        :key="item.data.battery.value"
        :size="'xs'"
      />
    </v-flex>
    <v-flex
      shrink
      v-if="
        item.settings.sensor_type >= 8 &&
        item.settings.sensor_type <= 20 &&
        item.connection
      "
    >
      <v-layout row wrap class="limitWidth">
        <v-flex v-if="item.settings.sensor_type === 20" shrink d-flex column>
          <small dir="ltr" class="mx-0 black--text">
            <span class="mx-1"> {{ item.data.sensors.range }}cm </span>
            <Icon color="blue" size="18" :icon="sensors.range.symbol" />
          </small>
        </v-flex>
        <v-flex
          v-if="item.settings.sensor_type === 11"
          shrink
          d-flex
          column
          class="mx-0"
        >
          <small dir="ltr" v-if="item.data.sensors.hall">
            <span class="mx-1">{{ content.magnet_true }}</span>
            <Icon color="blue" size="18" icon="magnetIcon" />
          </small>
          <small v-else dir="ltr">
            <span class="mx-1" :dir="locale == 'he' ? 'rtl' : 'ltr'">{{
              content.magnet_false
            }}</span>
            <Icon color="red" size="18" icon="magnetOn" />
          </small>
        </v-flex>
        <v-flex
          v-if="item.settings.sensor_type === 12"
          shrink
          d-flex
          column
          class="mx-0"
        >
          <small dir="ltr" v-if="item.data.sensors.hall">
            <span class="mx-1">{{ content.door_true }}</span>
            <Icon color="blue" size="18" icon="doorClosed" />
          </small>
          <small v-else dir="ltr">
            <span class="mx-1" :dir="locale == 'he' ? 'rtl' : 'ltr'">{{
              content.door_false
            }}</span>
            <Icon color="red" size="18" icon="doorOpen" />
          </small>
        </v-flex>

        <v-flex
          v-if="item.settings.sensor_type === 13"
          shrink
          d-flex
          column
          class="mx-0"
        >
          <small dir="ltr" v-if="item.data.sensors.hall">
            <span class="mx-1" :dir="locale == 'he' ? 'rtl' : 'ltr'">{{
              content.window_true
            }}</span>
            <Icon color="blue" size="18" icon="windowClosedVariant" />
          </small>
          <small dir="ltr" v-else>
            <span class="mx-1" :dir="locale == 'he' ? 'rtl' : 'ltr'">{{
              content.window_false
            }}</span>
            <Icon color="red" size="18" icon="windowOpenVariant" />
          </small>
        </v-flex>
        <v-flex
          v-if="item.settings.sensor_type === 14"
          shrink
          d-flex
          column
          class="mx-0"
        >
          <small dir="ltr" v-if="!item.data.sensors.drycontact">
            <span class="mx-1" :dir="locale == 'he' ? 'rtl' : 'ltr'">{{
              content.sensor_open
            }}</span>
            <Icon color="blue" size="18" icon="drycontact" />
          </small>
          <small dir="ltr" v-else>
            <span class="mx-1" :dir="locale == 'he' ? 'rtl' : 'ltr'">{{
              content.sensor_close
            }}</span>
            <Icon color="red" size="18" icon="drycontactflood" />
          </small>
        </v-flex>
        <v-flex
          v-if="item.settings.sensor_type >= 8"
          shrink
          d-flex
          column
          class="mx-0"
        >
          <Icon
            v-if="item.settings.sensor_type === 10"
            color="blue"
            size="18"
            :icon="item.exceeds ? 'homeFlood' : 'waterIcon'"
          />
          <Icon
            v-if="item.settings.sensor_type === 9"
            size="18"
            :icon="item.data.sensors.smoke ? 'fireIcon' : 'smokeDetector'"
            :color="item.data.sensors.smoke ? 'red' : 'blue'"
          />
          <template v-if="item.settings.sensor_type === 8">
            <Icon
              v-if="item.status == 'Off'"
              color="red"
              size="18"
              icon="walkIcon"
            />
            <Icon v-else color="blue" size="18" icon="eyeOutline" />
          </template>
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex shrink v-if="item.connection">
      <v-layout row wrap class="limitWidth">
        <v-flex
          align-start
          v-if="
            item.role === 1 && item.current_schedule && item.data.mode === 0
          "
        >
          <small dir="ltr">
            <span class="mx-1"
              >{{ item.current_schedule.start_at }}
              -
              {{ item.current_schedule.end_at }}</span
            >
            <Icon color="blue darken-4" size="18" icon="timerIcon" />
          </small>
        </v-flex>

        <v-flex v-if="item.settings.sensor_type >= 1" align-end>
          <v-layout row wrap shrink justify-start>
            <v-flex
              v-if="
                !!item.data.sensors.temperatures &&
                !isNaN(item.data.sensors.temperatures[0]) &&
                item.settings.sensor_type <= 6
              "
              shrink
              d-flex
              column
            >
              <small dir="ltr" style="white-space: nowrap">
                <span class="mx-1 black--text"
                  >{{ item.data.sensors.temperatures[0] }}℃</span
                >
                <Icon
                  :color="sensors.temperature.color"
                  size="18"
                  :icon="sensors.temperature.symbol"
                />
              </small>
            </v-flex>
            <v-flex
              v-if="
                !!item.data.sensors.temperatures &&
                !isNaN(item.data.sensors.temperatures[1]) &&
                item.settings.sensor_type <= 6
              "
              shrink
              d-flex
              column
            >
              <small dir="ltr" style="white-space: nowrap">
                <span class="mx-1 black--text"
                  >{{ item.data.sensors.temperatures[1] }}℃</span
                >
                <Icon
                  :color="sensors.temperature.color"
                  size="18"
                  :icon="sensors.temperature.symbol"
                />
              </small>
            </v-flex>
            <v-flex
              v-if="
                !isNaN(item.data.sensors.humidity) &&
                item.settings.sensor_type >= 2 &&
                item.settings.sensor_type <= 6 &&
                item.data.sensors.humidity != 255
              "
              d-flex
              column
              shrink
            >
              <small dir="ltr" style="white-space: nowrap">
                <span class="mx-1 black--text"
                  >{{ item.data.sensors.humidity }}%</span
                >
                <Icon
                  :color="sensors.temperature.color"
                  size="18"
                  :icon="sensors.humidity.symbol"
                />
              </small>
            </v-flex>
            <v-flex
              v-if="
                !isNaN(item.data.sensors.pressure) &&
                item.settings.sensor_type === 5
              "
              d-flex
              column
              shrink
            >
              <small dir="ltr" style="white-space: nowrap">
                <span class="mx-1 black--text"
                  >{{ item.data.sensors.pressure }}<small>hPa</small></span
                >
                <Icon color="teal" size="18" icon="gauge" />
              </small>
            </v-flex>
            <v-flex
              v-if="
                item.settings.sensor_type === 6 ||
                item.settings.sensor_type === 7
              "
              shrink
              d-flex
              column
            >
              <small dir="ltr" style="white-space: nowrap">
                <span
                  class="mx-1 black--text"
                  v-if="item.settings.sensor_type === 7"
                  >{{
                    item.data.sensors.light || item.data.sensors.light != 0
                      ? "האור פעיל"
                      : "האור כבוי"
                  }}
                </span>
                <span class="mx-1 black--text" v-else
                  >{{ item.data.sensors.light }} lx
                </span>
                <Icon
                  :color="sensors.light.color"
                  size="18"
                  :icon="sensors.light.symbol"
                />
              </small>
            </v-flex>
            <v-flex v-if="'co2' in item.data.sensors" shrink>
              <small dir="ltr" style="white-space: nowrap">
                <span class="mx-1 black--text">
                  {{ item.data.sensors.co2 }}ppm
                  <em class="co2-text-icon light-green--text text--darken-3">
                    <span>CO²</span>
                  </em>
                </span>
              </small>
            </v-flex>
            <v-flex
              v-if="
                !!item.data.sensors.electricity_usage &&
                item.settings.sensor_type >= 16 &&
                item.settings.sensor_type <= 18
              "
              shrink
            >
              <small
                v-if="!isNaN(item.data.sensors.electricity_usage[0])"
                dir="ltr"
                style="white-space: nowrap"
              >
                <span class="mx-1 black--text"
                  >{{ item.data.sensors.electricity_usage[0]
                  }}<small>Wh</small></span
                >
                <Icon color="light-green" size="18" icon="flashOutline" />
              </small>
              <small
                v-if="!isNaN(item.data.sensors.electricity_usage[1])"
                dir="ltr"
                style="white-space: nowrap"
              >
                <span class="mx-1 black--text"
                  >{{ item.data.sensors.electricity_usage[1]
                  }}<small>Wh</small></span
                >
                <Icon color="light-green" size="18" icon="flashOutline" />
              </small>
              <small
                v-if="!isNaN(item.data.sensors.electricity_usage[2])"
                dir="ltr"
                style="white-space: nowrap"
              >
                <span class="mx-1 black--text"
                  >{{ item.data.sensors.electricity_usage[2]
                  }}<small>Wh</small></span
                >
                <Icon color="light-green" size="18" icon="flashOutline" />
              </small>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
import Battery from "@/components/tools/battery.vue";
import Lang from "@/helpers/lang";
export default {
  name: "DeviceBarData",
  props: ["item"],
  components: {
    Battery,
  },
  data() {
    return {
      locale: Lang.get(),
      content: {},
      sensors: {
        temperature: {
          symbol: "mdiSnowflakeVariant",
          color: "blue",
        },
        humidity: {
          symbol: "mdiOpacity",
          color: "cyan lighten-3",
        },
        light: {
          symbol: "mdiBrightness5",
          color: "yellow darken-2",
        },
        range: {
          symbol: "mdiArrowExpandVertical",
          color: "blue",
        },
      },
    };
  },
  computed: {
    dir() {
      return this.$vuetify.rtl ? "rtl" : "ltr";
    },
  },
  created() {
    this.setLang();
  },
  methods: {
    setLang() {
      if (this.locale == "en") {
        this.content = {
          countdevices: "Number of devices",
          editview: "edit view",
          addDevices: "add switches",
          unpaid: "Not Authorized",
          not_connected: "Not Connected",
          no_sensor: "Sensor is not connected",
          forbidden_area: "Forbidden area!",
          exceeds: "Exceeds the threshold",
          tamper: "Tamper warning!",
          flooding: "Warning of flooding!",
          motion: "Detected motion!",
          smoke: "Warning of smoke!",
          sensor_close: "Warning of flooding!",
          sensor_open: "Normal state",
          magnet_true: "Magnet detected",
          magnet_false: "Magnet is NOT detected",
          door_true: "The door is closed",
          door_false: "The door is open",
          window_true: "The window is closed",
          window_false: "The window is open",
          empty: {
            group: "The group don't have registered switches yet!",
            title: "You don't have any switches registered yet!",
            subtitle: "Click here to register a new switch",
            btn: "Register A New Switch",
            sectitle: "All of our devices are hidden",
            clickto: "Click here to ",
          },
        };
      } else if (this.locale == "he") {
        this.content = {
          countdevices: "מספר מכשירים",
          editview: "עריכת תצוגה",
          addDevices: "הוספת מכשירים",
          unpaid: "לא מורשה",
          not_connected: "לא מחובר",
          no_sensor: "חיישן אינו מחובר",
          forbidden_area: "באזור אסור!",
          exceeds: "חורג מהסף",
          tamper: "אזהרת התעסקות!",
          flooding: "אזהרת הצפה!",
          motion: "זיהה תנועה!",
          smoke: "אזהרת עשן!",
          sensor_close: "אזהרת הצפה!",
          sensor_open: "מצב תקין",
          magnet_true: "מזהה מגנט",
          magnet_false: "לא מזהה מגנט",
          door_true: "הדלת סגורה",
          door_false: "הדלת פתוחה",
          window_true: "החלון סגור",
          window_false: "החלון פתוח",
          empty: {
            group: "עדיין לא נוספו מכשירים לקבוצה!",
            title: "עדיין לא רשומים אצלך מכשירים!",
            subtitle: "לחץ כאן כדי לרשום מכשיר חדש",
            btn: "הוספת מכשיר חדש",
            sectitle: "כל המכשירים הרשומים אצלך מוסתרים.",
            clickto: "לחץ כאן ל",
          },
        };
      }
    },
  },
};
</script>
<style lang="css" scoped>
.dryContact {
  max-height: 20px;
  /* max-width: 30px; */
  width: auto;
}
</style>
