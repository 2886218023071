<style scoped>
.scrolllll {
  max-height: 350px;
  overflow-y: auto;
}
</style>

<template>
  <v-container grid-list-xs,sm,md,lg,xl>
    <v-layout row wrap justify-center>
      <h1 class="text-xs-center">{{ content.title }}</h1>

      <v-flex v-if="form" xs12>
        <v-layout row wrap justify-space-between>
          <v-flex xs12 sm6 lg4 class="px-1 mt-2">
            <h2 class="text-xs-center mb-2">
              {{ content.select_switches }}
            </h2>

            <div class="text-xs-left">
              <v-btn text small color="primary" @click="toggleAllSwitches()">{{
                selectedSwitches.length ==
                Object.values(this.switches).filter((s) => s.active_status)
                  .length
                  ? content.checkall
                  : content.uncheckall
              }}</v-btn>
            </div>
            <v-card class="px-3 pt-2 scrolllll scrollbar">
              <v-checkbox
                v-for="(sw, index) in switches"
                :key="index"
                v-model="selectedSwitches"
                :label="sw.name"
                :value="sw.slug"
                class="text-xs-left mt-1 mb-1"
              ></v-checkbox>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 lg4 class="px-1 mt-2">
            <h2 class="text-xs-center mb-2">
              {{ content.select_dates }}
            </h2>

            <v-flex xs12 d-flex row wrap justify-space-between>
              <v-btn text small color="primary" @click="timeFormat('today')">{{
                content.today
              }}</v-btn>
              <v-btn text small color="primary" @click="timeFormat('week')"
                >7 {{ content.days }}</v-btn
              >
              <v-btn text small color="primary" @click="timeFormat('30days')"
                >30 {{ content.days }}</v-btn
              >
            </v-flex>
            <v-card class="px-3 pt-2">
              <h4>{{ content.startat }}:</h4>

              <v-dialog
                ref="sdate"
                v-model="startAtMenu"
                :return-value.sync="startAtDate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="startAtDate"
                    :label="content.date"
                    :prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="edpicker"
                  v-model="startAtDate"
                  scrollable
                  :max="new Date().toISOString().substr(0, 10)"
                  min="2019-06-01"
                  :locale="locale == 'he' ? 'he-il' : 'en-us'"
                >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.sdate.save(startAtDate)"
                    >{{ content.ok }}</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn text color="grey" @click="startAtMenu = false">{{
                    content.cancel
                  }}</v-btn>
                </v-date-picker>
              </v-dialog>
              <v-dialog
                ref="startTime"
                v-model="startTimeDialog"
                :return-value.sync="startAtTime"
                persistent
                max-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="startAtTime"
                    :label="content.time"
                    :prepend-icon="access_time"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="startTimeDialog"
                  v-model="startAtTime"
                  full-width
                  format="24hr"
                >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.startTime.save(startAtTime)"
                    >{{ content.ok }}</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn text color="grey" @click="startTimeDialog = false">{{
                    content.cancel
                  }}</v-btn>
                </v-time-picker>
              </v-dialog>

              <v-divider></v-divider>

              <h4 class="mt-2">{{ content.endat }}:</h4>

              <v-dialog
                ref="edate"
                v-model="endAtMenu"
                :return-value.sync="endAtDate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="endAtDate"
                    :label="content.date"
                    :prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="endDatePicker"
                  v-model="endAtDate"
                  scrollable
                  :max="new Date().toISOString().substr(0, 10)"
                  :min="endTimeMinimum"
                  :locale="locale == 'he' ? 'he-il' : 'en-us'"
                >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.edate.save(endAtDate)"
                    >{{ content.ok }}</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn text color="grey" @click="endAtMenu = false">{{
                    content.cancel
                  }}</v-btn>
                </v-date-picker>
              </v-dialog>

              <v-dialog
                ref="endTime"
                v-model="endTimeDialog"
                :return-value.sync="endAtTime"
                persistent
                max-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="endAtTime"
                    :label="content.time"
                    :prepend-icon="access_time"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="endTimeDialog"
                  v-model="endAtTime"
                  full-width
                  format="24hr"
                >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.endTime.save(endAtTime)"
                    >{{ content.ok }}</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn text color="grey" @click="endTimeDialog = false">{{
                    content.cancel
                  }}</v-btn>
                </v-time-picker>
              </v-dialog>
            </v-card>
          </v-flex>
        </v-layout>

        <v-layout row wrap justify-center class="mt-4">
          <v-flex xs12 sm10 md8 lg6>
            <v-alert
              :value="error ? true : false"
              transition="scale-transition"
              dense
              outlined
              type="error"
              ><span class="px-3">{{ error }}</span></v-alert
            >
            <v-btn color="switchq switchqSub--text" @click="create()" block>{{
              content.create_file
            }}</v-btn>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex v-else xs12>
        <v-layout row wrap justify-center>
          <v-flex xs10 sm8 md6 class="mt-4">
            <h3 class="text-xs-center">{{ generateStatus }}</h3>
            <template>
              <v-progress-linear
                value="100"
                :color="generateColor"
                :indeterminate="generateProgress"
              ></v-progress-linear>
            </template>
          </v-flex>

          <v-flex
            v-if="readyDownload"
            xs12
            class="mt-2 text-center"
            justify-center
          >
            <v-btn color="success" @click="downloadFile">{{
              content.download
            }}</v-btn>
          </v-flex>

          <v-flex
            v-if="readyDownload"
            xs12
            class="mt-5 text-center"
            justify-center
          >
            <v-btn
              color="switchq"
              dark
              class="mt-2 text-center"
              @click="createNew()"
              >{{ content.create_another_file }}</v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Lang from "@/helpers/lang";
import axios from "@/plugins/axios";
import dayjs from "dayjs";
import xlsx from "json-as-xlsx";
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
import { mdiCalendar, mdiClockOutline } from "@mdi/js";
export default {
  data() {
    return {
      locale: Lang.get(),
      form: true,
      event: mdiCalendar,
      access_time: mdiClockOutline,
      selectedSwitches: [],
      startAtMenu: false,
      startAtDate: null,
      endAtMenu: false,
      endAtDate: null,
      startAt: {},
      startAtTime: "00:00",
      startTimeDialog: false,
      endAt: {},
      endAtTime: "23:59",
      endTimeMinimum: "2019-06-01",
      endTimeDialog: false,
      error: null,
      generateStatus: "GENERATING FILE",
      generateProgress: true,
      generateColor: "primary",
      fileName: null,
      fileDownloadName: null,
      readyDownload: false,
      logsData: {},
      xlsxData: [],
    };
  },
  created() {
    this.setLang();
    // console.log("mounted!!");
    // this.toggleAllSwitches();
  },
  computed: {
    switches() {
      return this.$store.getters["devices/devices"];
    },
    dailyLogs() {
      return this.$store.getters["devices/deviceLogs"](
        this.selectedSwitches[0]
      );
    },
    email() {
      return this.$store.getters["user/user"].email;
    },
  },
  watch: {
    startAtMenu(val) {
      val && setTimeout(() => (this.$refs.edpicker.activePicker = "YEAR"));
    },
    endAtMenu(val) {
      val && setTimeout(() => (this.$refs.endDatePicker.activePicker = "YEAR"));
    },
    startAtDate: function (val) {
      this.endTimeMinimum = val;
      if (val > this.endAtDate) {
        this.endAtDate = null;
      }
    },
  },
  methods: {
    getLogs() {
      // console.log("selected: ", this.selectedSwitches[0]);
      if (this.selectedSwitches.length) {
        console.log(this.dailyLogs);
      }
    },

    create() {
      if (this.selectedSwitches.length < 1) {
        this.error = this.errorText[0];
      } else {
        if (
          !this.startAtDate ||
          (this.startAtDate && this.startAtDate.length < 1)
        ) {
          this.error = this.errorText[2];
        } else {
          if (
            !this.startAtTime ||
            (this.startAtTime && this.startAtTime.length < 1)
          ) {
            this.error = this.errorText[3];
          } else {
            if (
              !this.endAtDate ||
              (this.endAtDate && this.endAtDate.length < 1)
            ) {
              this.error = this.errorText[4];
            } else {
              if (
                !this.endAtTime ||
                (this.endAtTime && this.endAtTime.length < 1)
              ) {
                this.error = this.errorText[5];
              } else {
                this.error = null;
                this.send();
              }
            }
          }
        }
      }
    },
    async send() {
      this.generateStatus = this.content.generation[0];
      this.form = false;
      const data = {
        startAt: dayjs(
          this.startAtDate + " " + this.startAtTime,
          "YYYY-MM-DD HH:mm"
        ),
        endAt: dayjs(this.endAtDate + " " + this.endAtTime, "YYYY-MM-DD HH:mm"),
      };
      // console.log("data", data);
      for (let i = 0; i < this.selectedSwitches.length; i++) {
        const device = this.selectedSwitches[i];
        await axios
          .post(`/devices/${device}/logs`, data)
          .then((res) => {
            // console.log("data from axios", res.data);
            this.logsData[device] = res.data.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.generateFile();
    },
    toggleAllSwitches() {
      if (
        this.selectedSwitches.length !==
        Object.values(this.switches).filter((s) => s.active_status).length
      ) {
        this.selectedSwitches = [];
      } else {
        this.selectedSwitches = [];
        for (let s in this.switches) {
          // if (this.switches[s].active_status) {
          this.selectedSwitches.push(this.switches[s].slug);
          // }
        }
      }
    },
    timeFormat(time) {
      switch (time) {
        case "today":
          this.startAtDate = new Date().toISOString().substr(0, 10);
          this.endAtDate = new Date().toISOString().substr(0, 10);
          this.startAtTime = "00:00";
          this.endAtTime = "23:59";
          this.startAt = {
            time: this.startAtTime,
            date: this.startAtDate,
          };
          this.endAt = {
            time: this.endAtTime,
            date: this.endAtDate,
          };
          break;
        case "week":
          this.startAtDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
            .toISOString()
            .substr(0, 10);
          this.endAtDate = new Date().toISOString().substr(0, 10);
          this.startAtTime = "00:00";
          this.endAtTime = "23:59";
          this.startAt = {
            time: this.startAtTime,
            // date: this.startAtDate
          };
          this.endAt = {
            time: this.endAtTime,
            // date: this.endAtDate
          };
          break;
        case "30days":
          this.startAtDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
            .toISOString()
            .substr(0, 10);
          this.endAtDate = new Date().toISOString().substr(0, 10);
          this.startAtTime = "00:00";
          this.endAtTime = "23:59";
          this.startAt = {
            time: this.startAtTime,
            // date: this.startAtDate
          };
          this.endAt = {
            time: this.endAtTime,
            date: this.endAtDate,
          };
          break;
      }
    },
    checkTempValue(v, i) {
      // console.log("v", v);
      return (
        "temperatures" in v &&
        Array.isArray(v.temperatures) &&
        v.temperatures &&
        v.temperatures[i]
      );
    },

    cleanWorksheetName(name, identifier) {
      // Remove leading and trailing whitespace
      name = name.trim();

      // Replace any forbidden characters with underscores
      // name = name.replace(/[\/\\\?\*\:\[\]]/g, "_");
      name = name.replace(/[:\\/\\?*[\]]/g, "_");

      // Remove any apostrophes at the beginning or end
      name = name.replace(/^'|'$/g, "");

      // Check if the name is too long
      if (name.length > 31) name = name.slice(0, 31);

      // Check if the name is "History"
      if (name.length === 0 || name.toLowerCase() === "history") {
        name = identifier;
      }

      return name;
    },

    generateFile() {
      this.generateStatus = this.content.generation[1];
      let data = [];
      let baseColumns = [
        {
          label: this.$t("date"),
          value: (v) => dayjs(v.created_at).format("YYYY-MM-DD"),
        },
        {
          label: this.$t("time"),
          value: (v) => dayjs(v.created_at).format("HH:mm"),
        },
      ];
      for (const device in this.logsData) {
        const sheet = this.cleanWorksheetName(
          this.switches[device].name,
          this.switches[device].identifier
        );
        const sensors = this.getLogsSensors(this.logsData[device]);
        let columns = [...baseColumns];
        // console.log("sensors", sensors);
        if (sensors.includes("temperature1")) {
          let label = this.$t("temperature");
          if (sensors.includes("temperature2")) label += " 1";
          // ? this.$t("temperature") +
          // : this.$t("temperature");
          columns.push({
            label: label,
            value: (v) => (this.checkTempValue(v, 0) ? v.temperatures[0] : ""),
          });
        }
        if (sensors.includes("temperature2")) {
          columns.push({
            label: this.$t("temperature") + " 2",
            value: (v) => (this.checkTempValue(v, 1) ? v.temperatures[1] : ""),
          });
        }
        if (sensors.includes("temperature3")) {
          columns.push({
            label: this.$t("temperature") + " 3",
            value: (v) => (this.checkTempValue(v, 2) ? v.temperatures[2] : ""),
          });
        }
        if (sensors.includes("humidity")) {
          columns.push({ label: this.$t("humidity"), value: "humidity" });
        }
        if (sensors.includes("co2")) {
          columns.push({ label: this.$t("co2"), value: "co2" });
        }
        if (sensors.includes("pressure")) {
          columns.push({ label: this.$t("pressure"), value: "pressure" });
        }
        if (sensors.includes("light")) {
          columns.push({ label: this.$t("light"), value: "light" });
        }
        let content = this.logsData[device];
        data.push({ sheet, columns, content });
      }
      this.xlsxData = data;
      this.generateStatus = this.content.generation[2];
      this.generateProgress = false;
      this.generateColor = "success";
      this.readyDownload = true;
    },
    getLogsSensors(logs) {
      let sensors = [];
      logs.forEach((log) => {
        if ("temperatures" in log) {
          if (!sensors.includes("temperature1")) {
            sensors.push("temperature1");
          }
          if (
            log.temperatures.length > 1 &&
            !sensors.includes("temperature2")
          ) {
            sensors.push("temperature2");
          }
          if (
            log.temperatures.length > 2 &&
            !sensors.includes("temperature3")
          ) {
            sensors.push("temperature3");
          }
        }
        if ("humidity" in log) {
          if (!sensors.includes("humidity")) {
            sensors.push("humidity");
          }
        }
        if ("light" in log) {
          if (!sensors.includes("light")) {
            sensors.push("light");
          }
        }
        if ("pressure" in log) {
          if (!sensors.includes("pressure")) {
            sensors.push("pressure");
          }
        }
        if ("co2" in log) {
          if (!sensors.includes("co2")) {
            sensors.push("co2");
          }
        }
      });
      return sensors;
    },
    downloadFile() {
      const settings = {
        fileName: this.email + "_" + dayjs().format("DD-MM-YY_HH-mm"),
        extraLength: 3,
      };
      xlsx(this.xlsxData, settings);
    },
    createNew() {
      this.selectedSwitches = [];
      this.selectedParameters = this.parameters;
      this.startAtDate = null;
      this.endAtDate = null;
      this.startAtTime = "00:00";
      this.endAtTime = "23:59";
      this.endTimeMinimum = "2019-06-01";
      this.form = true;
      this.generateProgress = true;
      this.generateColor = "primary";
      this.readyDownload = false;
    },
    setLang() {
      if (Lang.get() == "en") {
        this.parameters = [
          "status",
          "temperature",
          "humidity",
          "light",
          "pressure",
          "CO2",
        ];
        this.selectedParameters = [
          "status",
          "temperature",
          "humidity",
          "light",
          "pressure",
          "CO2",
        ];
        this.content = {
          title: "Download Logs File",
          select_switches: "Select Switches",
          select_parameters: "Select Parameters",
          select_dates: "Select Dates",

          today: "Today",
          days: "Days",
          startat: "Start At",
          endat: "End At",
          date: "Date",
          time: "Time",

          ok: "OK",
          cancel: "Cancel",

          checkall: "Check All",
          uncheckall: "Uncheck All",

          create_file: "Create File",
          generation: [
            "GENERATING FILE",
            "PREPARING FOR DOWNLOAD",
            "READY TO DOWNLOAD!",
          ],
          download: "Download",
          create_another_file: "Create Another File",
        };
        this.errorText = [
          "Must select atleast one switch",
          "Must select atleast one parameter",
          "Must select a start date",
          "Must select a start time",
          "Must select an end date",
          "Must select an end time",
        ];
      } else if (Lang.get() == "he") {
        this.parameters = [
          "סטטוס",
          "טמפרטורה",
          "לחות",
          "אור",
          "לחץ אטמוספרי",
          "CO2",
        ];
        this.selectedParameters = [
          "סטטוס",
          "טמפרטורה",
          "לחות",
          "אור",
          "לחץ אטמוספרי",
          "CO2",
        ];
        this.content = {
          title: "הורדת קובץ לוגים",
          select_switches: "בחירת מכשירים",
          select_parameters: "בחירת פרמטרים",
          select_dates: "בחירת זמנים",

          today: "היום",
          days: "ימים",
          startat: "מתחיל ב",
          endat: "נגמר ב",
          date: "תאריך",
          time: "שעה",

          ok: "אישור",
          cancel: "ביטול",

          checkall: "סמן הכל",
          uncheckall: "בטל סימון של הכל",

          create_file: "יצירת קובץ",
          generation: ["מייצר קובץ", "מכין להורדה", "מוכן להורדה!"],
          download: "הורדה",
          create_another_file: "צור קובץ אחר",
        };
        this.errorText = [
          "חובה לבחור לפחות מכשיר אחד",
          "חובה לבחור לפחות פרמטר אחד",
          "חובה לבחור תאריך התחלה",
          "חובה לבחור שעת התחלה",
          "חובה לבחור תאריך סיום",
          "חובה לבחור שעת סיום",
        ];
      }
    },
  },
};
</script>
