<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <!-- <span class="headline">User Profile</span> -->
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <v-text-field
                    counter
                    maxlength="20"
                    v-model="newName"
                    :label="content.name"
                    required
                    :rules="rules.name"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editName">{{
            content.save
          }}</v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="$emit('close'), (dialog = false)"
            >{{ content.cancel }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Lang from "@/helpers/lang";
export default {
  props: {
    groupSlug: {
      default: null,
    },
  },
  data() {
    return {
      dialog: true,
      oldName: "",
      newName: "",
      rules: {},
    };
  },
  computed: {
    group: {
      get() {
        return {
          ...this.$store.getters["groups/group"](this.groupSlug),
        };
      },
      set(group) {
        // console.log("Set group", group);
        this.$store
          .dispatch("groups/editGroupName", {
            name: group.name,
            slug: group.slug,
          })
          .then(() => {
            this.dialog = false;
          });
      },
    },
  },

  created() {
    this.locale = Lang.get();
    this.setLang();
    this.setRules();
  },
  mounted() {},
  methods: {
    editName() {
      const newCat = { ...this.group };
      this.oldName = newCat.name;
      newCat.name = this.newName;
      this.group = newCat;
    },
    setRules() {
      this.rules.name = [
        (v) => v.length >= 2 || this.content.rules.name_short,
        (v) => v.length <= 40 || this.content.rules.name_long,
      ];
    },
    setLang() {
      if (this.locale == "en") {
        this.content = {
          create: "Edit group's Name",
          save: "Save",
          cancel: "Cancel",
          name: "group Name",
          none: "None",
          rules: {
            name_short: "The name must be at least 2 characters long",
            name_long: "The name must be shorter than 20 characters",
            parent: "The parent group is invalid",
          },
        };
      } else if (this.locale == "he") {
        this.content = {
          create: "עריכת שם קבוצה",
          save: "שמירה",
          cancel: "ביטול",
          name: "שם קבוצה",
          none: "ללא",
          rules: {
            name_short: "השם חייב להכיל לפחות 2 תווים",
            name_long: "השם חייב להיות קצר מ-20 תווים",
          },
        };
      }
    },
  },
};
</script>

<style></style>
