<style lang="scss" scoped></style>

<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-layout row wrap justify-center>
        <v-flex xs12 class="mt-2" v-if="notifications.length">
          <template v-if="device">
            <div class="table">
              <v-data-table
                :options.sync="pagination"
                :loading="loading"
                :headers="headers"
                :items="notifications"
                :server-items-length="total"
                :items-per-page="perPage"
                :no-data-text="''"
                :no-results-text="content.noresults"
                :footer-props="{
                  'items-per-page-text': content.footer.rowsperpage,
                  'items-per-page-all-text': content.all,
                }"
                locale="he"
                class="elevation-4"
                disable-sort
              >
                <template v-slot:headers="props">
                  <tr>
                    <th
                      v-for="header in props.headers"
                      :key="header.text"
                      :class="textdir"
                    >
                      {{ header.text }}
                    </th>
                  </tr></template
                >
                <template v-slot:items="nots">
                  <td class="px-1">{{ nots.item.created_at }}</td>
                  <td class="tableData px-1">
                    {{ nots.item.msg_title }}
                  </td>
                </template>
                <template v-slot:[`footer.page-text`]="items">
                  {{ items.pageStart }} - {{ items.pageStop }}
                  {{ content.footer.of }} {{ items.itemsLength }}
                </template>
              </v-data-table>
            </div>
          </template>
        </v-flex>
        <v-flex xs12 class="mt-2" v-if="!notifications.length && !loading">
          <span class="text-center">
            <h3>
              {{ content.noNots }}
            </h3>
          </span>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import Lang from "@/helpers/lang";
import dayjs from "@/plugins/dayjs";
export default {
  data() {
    return {
      locale: null,
      loading: true,
      content: {},
      textdir: null,
    };
  },
  created() {
    this.locale = Lang.get();
    this.setLang();
    this.$store.dispatch("deviceNotifications/fetchNotifications", {
      page: null,
      slug: this.$route.params.slug,
    });
    this.notifications;
    this.textdir = this.locale == "he" ? "text-right" : "text-left";
    this.setHeaders();
  },
  watch: {
    notifications() {
      // setTimeout(() => {
      this.loading = false;
      // }, 1500);
    },
  },
  mounted() {
    // console.log("mounted!!");
  },
  computed: {
    time(val) {
      return val;
    },
    notifications() {
      const notifications = JSON.parse(
        JSON.stringify(this.$store.getters["deviceNotifications/notifications"])
      );
      return notifications.filter((not) => {
        not.created_at = dayjs(not.created_at).format("DD/MM/YY - HH:mm");
        return not;
      });
    },
    device() {
      return this.$store.getters["devices/device"](this.$route.params.slug);
    },
    total() {
      return this.$store.getters["deviceNotifications/total"];
    },
    perPage() {
      return this.$store.getters["deviceNotifications/perPage"];
    },
    pagination: {
      get: function () {
        return this.$store.getters["deviceNotifications/pagination"];
      },
      set: function (value) {
        this.loading = true;
        this.$store.dispatch("deviceNotifications/fetchNotifications", {
          page: value,
          slug: this.$route.params.slug,
        });
      },
    },
  },
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.content.headers.time,
          value: "created_at",
          sortable: false,
        },
        { text: this.content.headers.not, value: "msg_title", sortable: false },
      ];
    },
    setLang() {
      if (this.locale == "en") {
        this.content = {
          title: "Notifications History",
          noNots: "No notifications yet",
          footer: {
            of: "of",
            rowsperpage: "Rows per page",
          },
          all: "ALL",
          noresults: "No Notifications Found",
          notowned: "Not owned anymore",
          nodevice: "No such device",
          sortBy: "Sort by",
          headers: {
            time: "Notified At",
            short_time: "Time",
            name: "Device Name",
            device: "Device",
            identifier: "Device Identifier",
            not: "Notification",
            read: "Read At",
          },
          type: {
            NotConnected: "Lost power or internet connection",
            IsConnected: "The power or internet connection is back",
            NoSensor: "The sensor has disconnected",
            ForbiddenArea: "Is in a forbidden area",
            MetTreshold: "Reached the threshold you set",
            FixedMetTreshold: "The indicators returned to normal",
            FloodSuspected: "The buoy identifies flooding",
            FloodFixed: "The buoy is back to normal",
            DoorOpened: "The sensor detected a door opening",
            DoorClosed: "The sensor detected a door closing",
            SmokeAlarm: "Warning of smoke!",
            MotionDetected: "Detects motion!",
            LowBattery: "The device has low battery",
          },
        };
      } else if (this.locale == "he") {
        this.content = {
          title: "היסטוריית התראות",
          noNots: "אין התראות למכשיר זה",
          footer: {
            of: "מתוך",
            rowsperpage: "שורות בעמוד",
          },
          all: "הכל",
          noresults: "לא נמצאו התראות",
          notowned: "כבר לא בבעלות",
          nodevice: "המכשיר אינו קיים ",
          sortBy: "סינון לפי",
          headers: {
            time: "זמן ההתראה",
            short_time: "זמן",
            name: "שם מכשיר",
            device: "מכשיר",
            identifier: "מזהה מכשיר",
            not: "התראה",
            read: "זמן קריאה",
          },
          type: {
            NotConnected: "איבד חיבור לחשמל או לאינטרנט",
            IsConnected: "חזר החיבור לחשמל או לאינטרנט",
            NoSensor: "החיישן לא מחובר",
            ForbiddenArea: "נמצא באזור אסור",
            MetTreshold: "הגיע לסף שהגדרת",
            FixedMetTreshold: "המדדים חזרו למצב תקין",
            FloodSuspected: "המצוף מזהה הצפה",
            FloodFixed: "המצוף חזר למצב תקין",
            DoorOpened: "החיישן מזהה פתיחה של דלת",
            DoorClosed: "החיישן מזהה סגירה של דלת",
            SmokeAlarm: "אזהרת עשן!",
            MotionDetected: "מזהה תנועה!",
            LowBattery: "למכשיר יש סוללה חלשה",
          },
        };
      }
    },
  },
};
</script>
