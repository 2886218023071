<template>
  <span direction="ltr" dir="ltr" v-click-outside="close">
    <v-layout row nowrap align-baseline>
      <v-flex xs5>
        <v-text-field
          name="minutes"
          type="tel"
          v-model="time.minutes"
          class="timeInput text-center"
        />
      </v-flex>
      <v-flex xs1>:</v-flex>
      <v-flex xs5>
        <v-text-field
          name="seconds"
          type="tel"
          v-model="time.seconds"
          class="timeInput text-center"
        />
      </v-flex>
    </v-layout>
  </span>
</template>
<script>
export default {
  name: "TimeFields",
  props: {
    value: {
      default: "00:00",
    },
    min: {
      default: 0,
    },
    max: {
      default: 999,
    },
  },
  data() {
    return {
      time: {
        minutes: 0,
        seconds: 0,
      },
      dialog: true,
    };
  },
  mounted() {
    this.valueToTime();
  },
  computed: {
    minutes() {
      return parseInt(this.value.split(":")[0]);
    },
    seconds() {
      return parseInt(this.value.split(":")[1]);
    },
    valid() {
      return true;
    },
  },
  watch: {
    value() {
      this.valueToTime();
    },
    "time.seconds"(seconds, old) {
      let value = parseInt(seconds);
      if (value > 59) value = old;
      if (!value) value = 0;
      value = String(value).padStart(2, "0");
      this.$nextTick(() => (this.time.seconds = value));
      this.save();
    },
    "time.minutes"(minutes, old) {
      let value = parseInt(minutes);
      if (value > this.max) value = old;
      if (value < this.min) value = this.min;
      if (!value) value = 0;
      value = String(value).padStart(2, "0");
      this.$nextTick(() => (this.time.minutes = value));
      this.save();
    },
  },
  methods: {
    valueToTime() {
      this.time.minutes = String(this.minutes).padStart(2, "0");
      this.time.seconds = String(this.seconds).padStart(2, "0");
    },
    save() {
      const time = [
        String(this.time.minutes).padStart(2, "0"),
        String(this.time.seconds).padStart(2, "0"),
      ].join(":");
      this.$emit("save", time);
    },
    cancel() {
      this.$emit("close");
      //
    },
    close() {
      if (this.valid) {
        this.save();
      } else {
        this.cancel();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.timeInput {
  max-width: 60px;
}
</style>
