<style lang="css" scoped></style>

<template>
  <v-layout align-center justify-center class="px-2" v-if="devmode">
    <v-flex xs9 class="text-xs-right">
      <v-form ref="thresholdForm" @submit.prevent="updateThreshold()">
        <v-layout
          v-if="![7, 15].includes(devmode)"
          row
          wrap
          class="text-xs-right"
        >
          <v-flex grow>
            <v-text-field
              :type="inputType"
              step="1"
              v-model="edit"
              dir="ltr"
              :class="locale === 'he' ? 'text-xs-right' : ''"
              ref="thresholdInput"
              :rules="!decimal ? this.rules.decimal : []"
              @focus="focusedOnThreshold()"
              @blur="quitEditThreshold()"
            >
              <template v-slot:label>
                <h2 class="text-nowrap">{{ content.threshold }} :</h2>
              </template>
            </v-text-field>
          </v-flex>
          <v-flex shrink class="mt-0 text-xs-right">
            <v-btn
              small
              :color="btn.color"
              :loading="loading"
              icon
              fab
              @click="startEditThreshold()"
              class="white--text"
            >
              <Icon :icon="btn.icon" size="20" />
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout v-else row wrap class="text-xs-right">
          <v-flex grow>
            <v-layout column wrap>
              <v-flex grow>
                <v-layout row wrap>
                  <v-flex xs3 d-flex align-center>
                    <h3 class="ma-0 pa-0">{{ content.top }} :</h3>
                  </v-flex>
                </v-layout>
              </v-flex></v-layout
            >
          </v-flex>
          <v-flex shrink class="mt-0 text-xs-right">
            <v-layout column wrap justify-center fill-height>
              <v-btn
                small
                :color="btn.color"
                :loading="loading"
                icon
                fab
                @click="startEditThreshold()"
                class="white--text"
              >
                <Icon :icon="btn.icon" size="20" />
              </v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ["locale"],
  data() {
    return {
      slug: this.$route.params.slug,
      edit: null,
      editTop: null,
      editBottom: null,
      loading: null,
      decimal: false,
      icons: {
        temp: "℃",
        humidity: "%",
        light: "lx",
      },
      icon: "",
      btn: {
        color: "blue",
        icon: "edit",
      },
      rules: {
        decimal: [(v) => Math.floor(v) == v || this.content.decimal],
      },
    };
  },
  created() {
    this.setLang();
    if (this.devmode) {
      this.updateThresholdIcon();
    }
  },
  mounted() {
    this.decimal = this.type > 1 ? true : false;
    this.edit = this.threshold;
  },
  computed: {
    type: function () {
      return this.$store.getters["devices/device"](this.$route.params.slug)
        .type;
    },
    devmode: function () {
      return this.$store.getters["devices/device"](this.$route.params.slug).data
        .devmode;
    },
    threshold: function () {
      if (this.loading) {
        return this.edit;
      }
      let device = this.$store.getters["devices/device"](
        this.$route.params.slug
      );
      return device.data.threshold ? device.data.threshold : null;
    },
    thresholds: function () {
      if (this.loading) {
        return {
          top:
            this.editTop ??
            this.$store.getters["devices/device"](this.$route.params.slug).data
              .top_threshold,
          bottom:
            this.editBottom ??
            this.$store.getters["devices/device"](this.$route.params.slug).data
              .bottom_threshold,
        };
      }
      return {
        top: this.$store.getters["devices/device"](this.$route.params.slug).data
          .top_threshold,
        bottom: this.$store.getters["devices/device"](this.$route.params.slug)
          .data.bottom_threshold,
      };
    },
    inputType() {
      let type = "tel";
      type = "numeric";
      return type;
    },
  },
  watch: {
    edit(val, old) {
      this.watchFix("edit", val, old);
    },
    editTop(val, old) {
      this.watchFix("editTop", val, old);
    },
    editBottom(val, old) {
      this.watchFix("editBottom", val, old);
    },
    devmode() {
      this.updateThresholdIcon();
    },
    type() {
      this.decimal = this.type > 1 ? true : false;
    },
  },
  methods: {
    watchFix(model, val, old) {
      if (val && !isNaN(val)) {
        if (!this.decimal) {
          this.$nextTick(() => {
            this[model] = parseInt(val);
          });
        } else {
          this[model] = parseFloat(val);
        }
      } else if (val && isNaN(val)) {
        this.$nextTick(() => {
          if (!isNaN(old)) {
            this[model] = old;
          } else {
            this.$refs.thresholdForm.resetValidation();
          }
        });
      }
    },
    updateThresholdIcon() {
      switch (this.devmode) {
        case 1:
        case 2:
        case 7:
          this.icon = this.icons.temp;
          break;
        case 3:
        case 4:
          this.icon = this.icons.humidity;
          break;
        case 5:
        case 6:
          this.icon = this.icons.light;
          break;
        default:
          this.icon = "";
          break;
      }
    },
    startEditThreshold() {
      if (
        this.btn.color === "green" &&
        (this.edit || this.editTop || this.editBottom)
      ) {
        this.updateThreshold();
      } else if ([7, 15].includes(this.devmode)) {
        if (this.editTop && !this.editBottom) {
          this.$refs.thresholdInputBottom.focus();
        } else {
          this.$refs.thresholdInputTop.focus();
        }
      } else {
        this.$refs.thresholdInput.focus();
      }
    },
    focusedOnThreshold() {
      this.btn = {
        color: "green",
        icon: "checkIcon",
      };
    },
    quitEditThreshold() {
      if ([7, 15].includes(this.devmode)) {
        if (isNaN(this.editTop) || isNaN(this.editBottom)) {
          this.editTop = null;
          this.editBottom = null;
          this.$refs.thresholdForm.reset();
        }
      } else {
        if (isNaN(this.edit)) {
          this.edit = this.threshold;
          this.$refs.thresholdForm.reset();
        }
      }
      this.$refs.thresholdForm.resetValidation();
      if (this.devmode) {
        if (this.$refs.thresholdInput) {
          this.$refs.thresholdInput.blur();
        }
        if (this.$refs.thresholdInputTop) {
          this.$refs.thresholdInputTop.blur();
        }
        if (this.$refs.thresholdInputBottom) {
          this.$refs.thresholdInputBottom.blur();
        }
        if (!this.edit && !this.editTop && !this.editBottom) {
          this.btn = {
            color: "blue",
            icon: "edit",
          };
        }
      }
    },
    async updateThreshold() {
      let thres;
      if ([7, 15].includes(this.devmode)) {
        thres = {
          top: this.editTop,
          bottom: this.editBottom,
        };
      } else {
        thres = this.edit;
      }
      if (
        (!isNaN(thres) ||
          (typeof thres === "object" &&
            (!isNaN(thres.top) || !isNaN(thres.bottom)))) &&
        this.$refs.thresholdForm.validate()
      ) {
        if (![7, 15].includes(this.devmode) && this.threshold == this.edit) {
          this.edit = this.threshold;
          this.quitEditThreshold();
        } else if (
          [7, 15].includes(this.devmode) &&
          thres.top == this.thresholds.top &&
          thres.bottom == this.thresholds.bottom
        ) {
          this.editTop = null;
          this.editBottom = null;
          this.quitEditThreshold();
        } else {
          this.loading = true;
          let data = {
            slug: this.slug,
            modify: {
              parameter: [7, 15].includes(this.devmode)
                ? "thresholds"
                : "threshold",
              value: thres,
            },
          };
          await this.$store.dispatch("devices/modifyDevice", data);
          if (![7, 15].includes(this.devmode) && this.threshold == this.edit) {
            this.edit = this.threshold;
          } else if ([7, 15].includes(this.devmode)) {
            if (thres.top == this.thresholds.top) this.editTop = null;
            if (thres.bottom == this.thresholds.bottom) this.editBottom = null;
          }
          this.loading = null;
          this.quitEditThreshold();
        }
      }
    },

    setLang() {
      if (this.locale == "en") {
        this.content = {
          threshold: "Threshold",
          top: "Top Threshold",
          bottom: "Bottom Threshold",
          decimal: "The threshold must be a whole number",
        };
      } else if (this.locale == "he") {
        this.content = {
          threshold: "סף",
          top: "סף עליון",
          bottom: "סף תחתון",
          decimal: "הסף חייב להיות מספר שלם",
        };
      }
    },
  },
};
</script>
