<style scoped lang="scss">
.headline {
  display: contents;
}

.groupSubs {
  text-decoration: underline;
  color: rgb(37, 37, 241);
}

.groups-layout {
  padding: 8px 16px;
  .groups {
    .Group {
      margin-bottom: 16px;
    }
  }
}
.draghandle {
  cursor: move;
}

.v-card__actions {
  padding: unset;
}

.delDevice {
  width: 5px;
}
</style>

<template>
  <v-layout column wrap class="groups-layout">
    <v-flex xs12>
      <v-layout row wrap justify-center>
        <h2 class="display-1 text-center">{{ content.Editgroups }}</h2>
      </v-layout>
    </v-flex>
    <v-flex xs12 class="mt-4 mb-6" @click.prevent="createGroup = !createGroup">
      <v-layout row wrap justify-center>
        <v-btn
          color="switchq lighten-1 switchqSub--text"
          d-flex
          align-center
          @click.self="createGroup = !createGroup"
          :block="$vuetify.breakpoint.xs"
        >
          <span class="mx-2">
            {{ content.create }}
          </span>
          <Icon icon="Plus" color="switchqSub" />
        </v-btn>
      </v-layout>
    </v-flex>

    <div v-if="!loading" class="groups">
      <groups-tree :items="treeItems"> </groups-tree>
    </div>
    <div v-else>
      <v-layout row wrap justify-center class="pa-5">
        <v-progress-circular
          :size="120"
          color="switchq"
          indeterminate
        ></v-progress-circular>
      </v-layout>
    </div>
    <create
      :dialog="createGroup"
      @close="(createGroup = false), (createSubGroup = false)"
      :parent="gslug && createSubGroup ? gslug : null"
    />
  </v-layout>
</template>

<script>
import create from "./create.vue";
import Lang from "@/helpers/lang";
import GroupsTree from "@/components/tools/groups-tree/GroupsTree.vue";
export default {
  components: {
    create,
    GroupsTree,
  },
  data() {
    return {
      content: {},
      timeout: null,
      showGroupFully: null,
      groupsData: [],
      createGroup: false,
      createGroupCount: 0,
      createSubGroup: false,
      hasSubGroups: false,
      editName: null,
      gslug: null,
      deleteGroupSlug: null,
    };
  },
  computed: {
    dir() {
      return this.$vuetify.rtl ? "rtl" : "ltr";
    },
    loading() {
      return this.$store.getters["groups/loading"];
    },
    devices() {
      return this.$store.getters["devices/devices"];
    },
    groups: {
      get() {
        let groups = JSON.parse(
          JSON.stringify(this.$store.getters["groups/groups"])
        );
        groups = Object.values(groups).map((g) => {
          g.hasSubGroups =
            Object.values(groups).filter((grp) => grp.parent_slug == g.slug)
              .length > 0;
          return g;
        });
        return Object.values(groups).sort((a, b) => a.order - b.order);
      },
      set(groups) {
        this.groupsData = this.fixgroupsOrder(groups);
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          // console.log("EDIT!");
          this.$store.dispatch("groups/editGroups", this.groupsData);
        }, 3000);
      },
    },
    treeItems() {
      const groups = [...this.groups];
      let hirGroups = groups.filter((g) => !g.parent_slug);
      return (hirGroups = hirGroups.map((group) => {
        return this.recursivlyTreeGroups(group, groups);
      }));
    },
  },
  watch: {
    groups(groups) {
      this.groupsData = groups;
      for (let i = 0; i < groups.length; i++) {
        if (groups[i].devices.length == 0) {
          this.showGroupFully = i;
          break;
        }
      }
    },
    createGroup() {
      this.createGroupCount++;
    },
  },
  created() {
    this.locale = Lang.get();
    this.setLang();
  },
  mounted() {
    this.groupsData = this.groups;
    window.scrollTo(0, 0);
  },
  methods: {
    recursivlyTreeGroups(group, groups) {
      const childrenGroups = groups.filter((g) => g.parent_slug == group.slug);
      if (childrenGroups) {
        group.children = childrenGroups;
        group.children.map((g) => {
          return this.recursivlyTreeGroups(g, groups);
        });
      }
      return group;
    },
    fixgroupsOrder(groups) {
      groups = Object.values(groups);
      for (let i = 0; i < groups.length; i++) {
        groups[i].order = i;
      }
      return groups;
    },
    setLang() {
      if (this.locale == "en") {
        this.content = {
          groups: "Edit Device Groups",
          create: "Create Group",
          delete: "Delete",
          edit: "Edit name",
          subGroup: "Add sub-group",
        };
      } else if (this.locale == "he") {
        this.content = {
          Editgroups: "עריכת קבוצות מכשירים",
          create: "יצירת קבוצה חדשה",
          delete: "מחיקה",
          edit: "עריכת שם",
          subGroup: "הוספת תת-קבוצה",
        };
      }
    },
  },
};
</script>
