<template>
  <div class="chartcontainer w-100 elevation-4">
    <LineChartGenerator
      dir="rtl"
      ref="line"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :width="chartWidth"
      :height="300"
    />
  </div>
</template>
<script>
import dayjs from "@/plugins/dayjs";
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import lang from "@/helpers/lang";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-luxon";
// import annotationPlugin from "chartjs-plugin-annotation";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  TimeScale,
  PointElement,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  PointElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  TimeScale,
  zoomPlugin
  // annotationPlugin
);

export default {
  components: { LineChartGenerator },
  props: ["logs", "sensors", "temperaturescount"],
  name: "LineChart",
  data() {
    return {
      LOCALE: null,

      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {},
      colors: {
        temperatures0: {
          color: "#247BA0",
          borderColor: "#247BA0",
          backgroundColor: "#247BA07f",
        },
        temperatures1: {
          color: "#45b6fe",
          borderColor: "#45b6fe",
          backgroundColor: "#45b6fe7f",
        },
        temperatures2: {
          color: "#8fd3fe",
          borderColor: "#8fd3fe",
          backgroundColor: "#8fd3fe7f",
        },
        humidity: {
          color: "#c934ac",
          borderColor: "#c934ac",
          backgroundColor: "#c934ac7f",
        },
        light: {
          color: "#fc9600",
          borderColor: "#fc9600",
          backgroundColor: "#fc96007f",
        },
        pressure: {
          color: "#009688",
          borderColor: "#009688",
          backgroundColor: "#0096887f",
        },
        co2: {
          color: "#",
          borderColor: "#558B2F",
          backgroundColor: "#558B2F7f",
        },
      },
    };
  },
  computed: {
    chartWidth() {
      if (this.width) return this.width;
      if (this.$vuetify.breakpoint.isMobile) {
        return "100%";
      } else {
        const contWidth = document.getElementById("mainWrap").offsetWidth;
        return contWidth > 660 ? 660 : contWidth - 16;
      }
    },
    // device() {
    //   return this.$store.getters["devices/device"](this.$route.params.slug);
    // },
  },
  mounted() {
    this.LOCALE = lang.get();
    this.sensors;
    this.setDataNew();
    this.initiate();
  },
  methods: {
    async initiate() {
      this.$emit("ready");
    },
    setDataNew() {
      let lastLog = this.logs[this.logs.length - 1].time + 900000;
      let chartOptions = {
        locale: this.LOCALE,

        plugins: {
          // legend: {
          //   title: {
          //     display: true,
          //     text: this.$t("threshold") + " ---",
          //   },
          // },
          zoom: {
            pan: {
              enabled: true,
              mode: "x",
              // overScaleMode: "x",
            },
            limits: {
              x: {
                max: lastLog,
                min: this.logs[0].time,
              },
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "x",
              rangeMin: {
                x: null,
              },
              // rangeMax: {
              //   x: null,
              // },
            },
          },
          // annotation: {
          //   annotations: {},
          // },
          tooltip: {
            rtl: true,
          },
        },
        animations: {
          radius: {
            duration: 400,
            easing: "linear",
            loop: (context) => context.active,
          },
        },
        maintainAspectRatio: false,
        pointRadius: 0,
        pointHoverRadius: 5,
        responsive: true,
        hoverMode: "index",
        title: {
          display: false,
          text: "",
        },
        interaction: {
          mode: "index",
          intersect: false,
          axis: "x",
        },
        stacked: false,
        scales: {
          x: {
            type: "time",
            distribution: "series",
            offset: false,
            ticks: {
              major: {
                enabled: true,
                fontStyle: "bold",
              },
              source: "data",
              autoSkip: true,
              autoSkipPadding: 75,
              maxRotation: 0,
              sampleSize: 100,
            },
            time: {
              displayFormats: {
                millisecond: "HH:mm",
                second: "HH:mm",
                minute: "HH:mm",
                hour: "DD HH:mm",
                day: "DD HH:mm",
                week: "DD/MM",
                month: "DD/MM",
                quarter: "DD/MM",
                year: "DD/MM",
              },
            },
          },
        },
      };
      this.chartOptions = chartOptions;
      this.setYScales();
      // this.setAnnotation();
      this.setChartData();
    },
    setYScales() {
      let isLeft = true;
      if (this.sensors.includes("temperatures")) {
        this.chartOptions.scales.temperatures = {
          type: "linear",
          display: true,
          position: isLeft ? "left" : "right",
          gridLines: {
            drawBorder: false,
          },
          grid: {
            drawOnChartArea: true,
          },
          ticks: {
            color: "#247BA0",
            callback: function (label) {
              if (String(label).includes(".")) label = label.toFixed(2);
              let reversedLabel = parseFloat(label).toString();
              if (label < 0) {
                reversedLabel = reversedLabel.split("");
                let minus = reversedLabel.shift();
                reversedLabel.push(minus);
                reversedLabel = reversedLabel.join("");
              }
              return `℃ ${reversedLabel}`;
            },
          },
        };

        isLeft = !isLeft;
      }
      if (this.sensors.includes("humidity")) {
        this.chartOptions.scales.humidity = {
          type: "linear",
          display: true,
          position: isLeft ? "left" : "right",
          min: 0,
          max: 100,
          gridLines: {
            drawBorder: false,
          },
          grid: {
            drawOnChartArea: false,
          },
          ticks: {
            color: "#c934ac",
            callback: function (label) {
              return label + "%";
            },
          },
        };
        isLeft = !isLeft;
      }
      if (this.sensors.includes("light")) {
        this.chartOptions.scales.light = {
          type: "linear",
          display: true,
          position: isLeft ? "left" : "right",
          ticks: {
            color: "#fc9600",
            callback: function (label) {
              return parseFloat(label / 1000).toFixed(1) + "K";
            },
          },
        };
        isLeft = !isLeft;
      }
      if (this.sensors.includes("pressure")) {
        this.chartOptions.scales.pressure = {
          type: "linear",
          display: true,
          position: isLeft ? "left" : "right",
          ticks: {
            color: "#009688",
            callback: function (label) {
              return parseFloat(label / 1000).toFixed(1) + "hPa";
            },
          },
        };
        isLeft = !isLeft;
      }
      if (this.sensors.includes("co2")) {
        this.chartOptions.scales.co2 = {
          type: "linear",
          display: true,
          position: isLeft ? "left" : "right",
          ticks: {
            color: "#558B2F",
            callback: function (label) {
              return parseFloat(label / 1000).toFixed(1) + "K ppm";
            },
          },
        };
        isLeft = !isLeft;
      }
    },
    // setAnnotation() {
    //   let device = this.device;
    //   let ruleValMinTemp;
    //   let ruleValMaxTemp;
    //   let ruleValMinHum;
    //   let ruleValMaxHum;
    //   let ruleValMinPres;
    //   let ruleValMaxPres;
    //   let ruleValMinCo2;
    //   let ruleValMaxCo2;
    //   for (let i = 0; i < Object.values(device.rules).length; i++) {
    //     if (
    //       Object.values(device.rules)[i].action == "<" &&
    //       Object.values(device.rules)[i].sensor == "temperature"
    //     ) {
    //       ruleValMinTemp = Object.values(device.rules)[i].value;
    //     }
    //     if (
    //       Object.values(device.rules)[i].action == ">" &&
    //       Object.values(device.rules)[i].sensor == "temperature"
    //     ) {
    //       ruleValMaxTemp = Object.values(device.rules)[i].value;
    //     }
    //     if (
    //       Object.values(device.rules)[i].action == "<" &&
    //       Object.values(device.rules)[i].sensor == "humidity"
    //     ) {
    //       ruleValMinHum = Object.values(device.rules)[i].value;
    //     }
    //     if (
    //       Object.values(device.rules)[i].action == ">" &&
    //       Object.values(device.rules)[i].sensor == "humidity"
    //     ) {
    //       ruleValMaxHum = Object.values(device.rules)[i].value;
    //     }
    //     if (
    //       Object.values(device.rules)[i].action == "<" &&
    //       Object.values(device.rules)[i].sensor == "pressure"
    //     ) {
    //       ruleValMinPres = Object.values(device.rules)[i].value;
    //     }
    //     if (
    //       Object.values(device.rules)[i].action == ">" &&
    //       Object.values(device.rules)[i].sensor == "pressure"
    //     ) {
    //       ruleValMaxPres = Object.values(device.rules)[i].value;
    //     }
    //     if (
    //       Object.values(device.rules)[i].action == "<" &&
    //       Object.values(device.rules)[i].sensor == "co2"
    //     ) {
    //       ruleValMinCo2 = Object.values(device.rules)[i].value;
    //     }
    //     if (
    //       Object.values(device.rules)[i].action == ">" &&
    //       Object.values(device.rules)[i].sensor == "co2"
    //     ) {
    //       ruleValMaxCo2 = Object.values(device.rules)[i].value;
    //     }
    //   }
    //   console.log("min max", ruleValMinTemp, ruleValMaxTemp);
    //   if (this.sensors.includes("temperatures") && ruleValMaxTemp) {
    //     this.chartOptions.plugins.annotation.annotations["temperaturesMax"] = {
    //       type: "line",
    //       yMin: ruleValMaxTemp,
    //       yMax: ruleValMaxTemp,
    //       borderDash: [6, 6],
    //       borderDashOffset: 0,
    //       borderColor: "#247BA07f",
    //       borderWidth: 2,
    //     };
    //   }
    //   if (this.sensors.includes("temperatures") && ruleValMinTemp) {
    //     this.chartOptions.plugins.annotation.annotations["temperaturesMin"] = {
    //       type: "line",
    //       yMin: ruleValMinTemp,
    //       yMax: ruleValMinTemp,
    //       borderDash: [6, 6],
    //       borderDashOffset: 0,
    //       borderColor: "#247BA07f",
    //       borderWidth: 2,
    //     };
    //   }
    //   if (this.sensors.includes("humidity") && ruleValMaxHum) {
    //     this.chartOptions.plugins.annotation.annotations["humidityMax"] = {
    //       type: "line",
    //       yMin: ruleValMaxHum,
    //       yMax: ruleValMaxHum,
    //       borderDash: [6, 6],
    //       borderDashOffset: 0,
    //       borderColor: "#c934ac7f",
    //       borderWidth: 2,
    //     };
    //   }
    //   if (this.sensors.includes("humidity") && ruleValMinHum) {
    //     this.chartOptions.plugins.annotation.annotations["humidityMin"] = {
    //       type: "line",
    //       yMin: ruleValMinHum,
    //       yMax: ruleValMinHum,
    //       borderDash: [6, 6],
    //       borderDashOffset: 0,
    //       borderColor: "#c934ac7f",
    //       borderWidth: 2,
    //     };
    //   }
    //   if (this.sensors.includes("pressure") && ruleValMaxHum) {
    //     this.chartOptions.plugins.annotation.annotations["pressureMax"] = {
    //       type: "line",
    //       yMin: ruleValMaxPres,
    //       yMax: ruleValMaxPres,
    //       borderDash: [6, 6],
    //       borderDashOffset: 0,
    //       borderColor: "#0096887f",
    //       borderWidth: 2,
    //     };
    //   }
    //   if (this.sensors.includes("pressure") && ruleValMinHum) {
    //     this.chartOptions.plugins.annotation.annotations["pressureMin"] = {
    //       type: "line",
    //       yMin: ruleValMinPres,
    //       yMax: ruleValMinPres,
    //       borderDash: [6, 6],
    //       borderDashOffset: 0,
    //       borderColor: "#0096887f",
    //       borderWidth: 2,
    //     };
    //   }
    //   if (this.sensors.includes("co2") && ruleValMaxHum) {
    //     this.chartOptions.plugins.annotation.annotations["co2Max"] = {
    //       type: "line",
    //       yMin: ruleValMaxCo2,
    //       yMax: ruleValMaxCo2,
    //       borderDash: [6, 6],
    //       borderDashOffset: 0,
    //       borderColor: "#558B2F7f",
    //       borderWidth: 2,
    //     };
    //   }
    //   if (this.sensors.includes("co2") && ruleValMinHum) {
    //     this.chartOptions.plugins.annotation.annotations["co2Min"] = {
    //       type: "line",
    //       yMin: ruleValMinCo2,
    //       yMax: ruleValMinCo2,
    //       borderDash: [6, 6],
    //       borderDashOffset: 0,
    //       borderColor: "#558B2F7f",
    //       borderWidth: 2,
    //     };
    //   }
    // },
    setChartData() {
      // let labels = [];
      const sensors = {};
      this.sensors.forEach((sensor) => {
        sensors[sensor] = [];
        if (sensor === "temperatures") {
          for (let i = 0; i < this.temperaturescount; i++) {
            sensors[sensor][i] = [];
          }
        }
      });
      for (let i = 0; i < this.logs.length; i++) {
        const log = { ...this.logs[i] };
        this.chartData.labels.push(dayjs(log.time.valueOf()).toDate());
        const data = { ...log };
        delete data.time;
        for (const sensor in data) {
          if (sensor === "temperatures") {
            for (let ti = 0; ti < data[sensor].length; ti++) {
              if (!sensors["temperatures"][ti]) {
                sensors["temperatures"][ti] = [];
              }
              sensors["temperatures"][ti].push(data[sensor][ti]);
            }
          } else {
            sensors[sensor].push(data[sensor]);
          }
        }
        this.sensors.forEach((s) => {
          if (!(s in log)) {
            if (s !== "temperatures") {
              sensors[s].push(null);
            } else {
              for (let ti = 0; ti < this.temperaturescount; ti++) {
                sensors["temperatures"][ti].push(null);
              }
            }
          }
        });
      }
      this.sensors.forEach((sensor) => {
        if (sensor === "temperatures" && this.temperaturescount >= 2) {
          for (let i = 0; i < sensors[sensor].length; i++) {
            this.chartData.datasets.push({
              label: this.$t(sensor + i),
              borderColor: this.colors[sensor + i].borderColor,
              backgroundColor: this.colors[sensor + i].backgroundColor,
              data: Object.values(sensors[sensor][i]),
              yAxisID: sensor,
              tension: 0.1,
            });
          }
        } else if (sensor === "temperatures") {
          this.chartData.datasets.push({
            label: this.$t(sensor),
            borderColor: this.colors[sensor + 0].borderColor,
            backgroundColor: this.colors[sensor + 0].backgroundColor,
            data: Object.values(sensors[sensor][0]),
            yAxisID: sensor,
            tension: 0.1,
          });
        } else {
          this.chartData.datasets.push({
            label: this.$t(sensor),
            borderColor: this.colors[sensor].borderColor,
            backgroundColor: this.colors[sensor].backgroundColor,
            data: sensors[sensor],
            yAxisID: sensor,
            tension: 0.1,
          });
        }
      });
    },
  }, // End of Methods
};
</script>
<style lang="scss" scoped>
.chartcontainer {
  background-color: #fff;
  border-radius: 5px;
  padding: 8px;
}
</style>
