<style lang="css" scoped></style>

<template>
  <v-layout row justify-center v-if="status">
    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-card-title primary-title class="orange lighten-3 py-2">
          <v-layout row nowrap align-center align-content-center>
            <v-flex grow>
              <h2>
                {{ content.title }}
              </h2>
            </v-flex>
            <v-flex shrink>
              <v-btn flat icon color="secondary" @click="dialog = false">
                <Icon icon="mdiClose" />
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-card-text>
          {{ content.body1 }}<br />
          {{ content.body2 }}
        </v-card-text>
        <v-card-actions>
          <v-layout row column align-start>
            <v-btn color="blue darken-4" flat @click="action(1)">{{
              content.btn_new
            }}</v-btn>
            <v-btn color="blue darken-1" flat @click="action(2)">{{
              content.btn_default
            }}</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Lang from "@/helpers/lang";
export default {
  props: ["status", "slug"],
  data() {
    return {
      dialog: false,
    };
  },
  created() {
    this.setLang();
    this.dialog = this.status;
  },
  watch: {
    status() {
      this.dialog = this.status;
    },
    slug() {
      this.dialog = this.status;
    },
  },
  methods: {
    action(answer) {
      this.$emit("reqScheduleEvent", answer);
      this.dialog = false;
    },

    setLang() {
      let locale = Lang.get();
      if (locale == "en") {
        this.content = {
          title: "A schedule is required",
          body1: "Unspecified schedule on the device.",
          body2:
            'In "automatic" mode without a schedule the device will remain off!',
          btn_new: "Add a new schedule",
          btn_default: "Add a default schedule",
        };
      } else if (locale == "he") {
        this.content = {
          title: "נדרש להזין לוח זמנים",
          body1: "לוח זמנים לא מוגדר במכשיר.",
          body2: 'במצב "אוטומט" ללא לוח זמנים המכשיר יישאר כבוי!',
          btn_new: "הוסף לוח זמנים חדש",
          btn_default: "הוסף לוח זמנים ברירת מחדל",
        };
      }
    },
  },
};
</script>
