import socket from "@/plugins/socket";

import Axios from "@/plugins/axios";

export default {
  data() {
    return {
      socket: {
        state: false,
        expire_at: null,
        token: null,
        reconnectTimeout: null,
      },
      connectionCheckInterval: null,
      connectionCheckIntervalState: false,
      lastDeviceUpdate: 0,
      tokenFetchedAt: null,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters["user/valid"];
    },
  },
  watch: {
    loggedIn(valid) {
      if (valid && !this.socket.state) {
        this.connectSocket();
      } else {
        this.disconnectSocket();
      }
    },
  },
  mounted() {
    this.socketListeners();
    this.setConnectionCheckInterval();
  },
  methods: {
    async connectSocket() {
      await this.getSocketToken();
      socket.auth = { token: this.socket.token };
      if (this.socket.token) {
        socket.connect();
        this.createConnectivityIssues();
        this.setReconnectSocket();
      }
    },
    disconnectSocket() {
      socket.disconnect();
      this.socket.state = false;
    },
    setReconnectSocket() {
      let timeout = +new Date(this.socket.expire_at) - +new Date();
      timeout -= 300000; //5 minutes
      this.socket.reconnectTimeout = setTimeout(() => {
        this.disconnectSocket();
        this.connectSocket();
      }, timeout);
    },
    async getSocketToken() {
      const timeToWait = 1000 * 60 * 2; // 2 minutes
      const skipIf = +new Date() < this.tokenFetchedAt + timeToWait; // now is smaller than end skip time
      if (!skipIf || this.loggedIn) {
        await Axios.get("ws/get-token")
          .then((res) => {
            this.socket.expire_at = res.data.data.expire_at;
            this.socket.token = res.data.data.token;
          })
          .catch((err) => {
            console.log("error is: ", err);
          })
          .finally(() => {
            this.tokenFetchedAt = +new Date();
          });
      }
    },
    socketListeners() {
      socket.on("device", (data) => {
        // console.log("data socket", data);
        this.lastDeviceUpdate = +new Date();
        // let device;
        if (data.device) {
          const device = this.$store.getters["devices/device"](
            data.device.slug
          );
          for (const param in data.device) {
            device[param] = data.device[param];
          }
          this.$store.dispatch("devices/setDevice", device);
        }
      });
      socket.on("notification", (notification) => {
        this.$store.dispatch("notifications/newUnread", notification);
      });
    },
    createConnectivityIssues() {
      if (this.loggedIn) {
        this.$nextTick(function () {
          // window.addEventListener("blur", () => {
          // console.log("leftpage");
          // });
          window.addEventListener("focus", () => {
            const timeLost =
              this.lastDeviceUpdate > 0 &&
              +new Date() - this.lastDeviceUpdate > 180000;
            if (!socket.connected || timeLost) {
              this.$store.dispatch("devices/fetchDevices");
              this.disconnectSocket();
              this.connectSocket();
            }
          });
        });
      }
    },
    setConnectionCheckInterval() {
      if (!this.connectionCheckIntervalState) {
        this.connectionCheckIntervalState = true;
        clearInterval(this.connectionCheckInterval);
        this.connectionCheckInterval = setInterval(() => {
          if (!socket.connected) this.connectSocket();
        }, 10000);
      }
    },
  },
};
