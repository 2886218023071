<style lang="scss" scoped>
.text-xsmall {
  font-size: 60%;
}
</style>

<template>
  <v-layout column wrap>
    <Icon
      :icon="mdiBattery"
      :size="fontSize"
      :color="color"
      iconClass="emboss"
    />
    <small
      v-if="fontSize > 30"
      class="text-xs-center text-xsmall mx-auto dark--text"
      >{{ data.string }}</small
    >
  </v-layout>
</template>

<script>
import Lang from "@/helpers/lang";
export default {
  name: "battery",
  props: ["data", "size"],
  data() {
    return {
      mdiBattery: "mdiBattery",

      locale: null,
      color: "",
    };
  },
  created() {
    this.locale = Lang.get();
    this.getContent();
    this.setIcon();
  },
  computed: {
    typeNum: function () {
      switch (this.data.symbol) {
        case "percent":
          return 1;
        case "status":
          return 2;
        default:
          return 0;
      }
    },
    fontSize: function () {
      let data;
      if (this.size) {
        if (this.size == "xl") {
          data = 50;
        } else if (this.size == "sm") {
          data = 30;
        } else if (this.size == "xs") {
          data = 20;
        }
      } else {
        data = 40;
      }
      return data;
    },
  },
  methods: {
    setIcon() {
      if (this.data.type == "status") {
        if (this.data.value == 1) {
          this.mdiBattery = "mdiBattery";
          this.color = "green";
        } else if (this.data.value == 0) {
          this.mdiBattery = "mdiBatteryAlert";
          this.color = "deep-orange ";
        }
        return;
      }
      const voltage = parseFloat(this.data.value);
      const type = this.typeNum;
      const levels = [
        [2.7, 20],
        [2.8, 40],
        [2.9, 60],
        [3, 70],
        [3.1, 80],
        [3.2, 90],
        [3.3, 100],
      ];
      switch (true) {
        case voltage < levels[0][type]:
          this.mdiBattery = "mdiBatteryAlert";
          this.color = "deep-orange ";
          break;
        case voltage < levels[1][type]:
          this.mdiBattery = "mdiBattery20";
          this.color = "yellow darken-3";
          break;
        case voltage < levels[2][type]:
          this.mdiBattery = "mdiBattery50";
          this.color = "yellow darken-1";
          break;
        case voltage < levels[3][type]:
          this.mdiBattery = "mdiBattery60";
          this.color = "light-green lighten-1";
          break;
        case voltage < levels[4][type]:
          this.mdiBattery = "mdiBattery70";
          this.color = "green lighten-2";
          break;
        case voltage < levels[5][type]:
          this.mdiBattery = "mdiBattery80";
          this.color = "green lighten-1";
          break;
        case voltage < levels[6][type]:
          this.mdiBattery = "mdiBattery90";
          this.color = "green darken-1";
          break;
        default:
          this.mdiBattery = "mdiBattery";
          this.color = "green";
      }
    },
    getContent() {
      if (this.locale == "en") {
        this.content = {
          ok: "well",
        };
      } else if (this.locale == "he") {
        this.content = {
          ok: "תקין",
        };
      }
    },
  },
};
</script>
