import axios from "@/plugins/axios";
export default {
  created() {
    if (!localStorage.getItem("device_identification")) {
      this.setDeviceId();
    }
  },
  methods: {
    setDeviceId() {
      const device = [
        this.getDeviceName(),
        this.detectBrowser(),
        this.$vuetify.breakpoint.name,
        this.$vuetify.breakpoint.height,
        this.$vuetify.breakpoint.width,
      ].join("|");
      const device_id = this.utf8ToHex(device).toUpperCase();
      this.$store.dispatch("general/setDeviceId", device_id);
      axios.defaults.headers["X-Device-Id"] = device_id;
      localStorage.setItem("device_identification", device_id);
    },
    getDeviceName() {
      let deviceName = "";

      let isMobile = {
        Android: function () {
          return navigator.userAgent.match(/Android/i);
        },
        Datalogic: function () {
          return navigator.userAgent.match(/DL-AXIS/i);
        },
        Bluebird: function () {
          return navigator.userAgent.match(/EF500/i);
        },
        Honeywell: function () {
          return navigator.userAgent.match(/CT50/i);
        },
        Zebra: function () {
          return navigator.userAgent.match(/TC70|TC55/i);
        },
        BlackBerry: function () {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Windows: function () {
          return navigator.userAgent.match(/IEMobile/i);
        },
        any: function () {
          return (
            isMobile.Datalogic() ||
            isMobile.Bluebird() ||
            isMobile.Honeywell() ||
            isMobile.Zebra() ||
            isMobile.BlackBerry() ||
            isMobile.Android() ||
            isMobile.iOS() ||
            isMobile.Windows()
          );
        },
      };

      if (isMobile.Datalogic()) deviceName = "Datalogic";
      else if (isMobile.Bluebird()) deviceName = "Bluebird";
      else if (isMobile.Honeywell()) deviceName = "Honeywell";
      else if (isMobile.Zebra()) deviceName = "Zebra";
      else if (isMobile.BlackBerry()) deviceName = "BlackBerry";
      else if (isMobile.iOS()) deviceName = "iOS";
      else if (deviceName == "" && isMobile.Android()) deviceName = "Android";
      else if (deviceName == "" && isMobile.Windows()) deviceName = "Windows";

      if (deviceName != "") {
        console.log("Devices information deviceName = " + deviceName);
        console.log("Devices information any = " + isMobile.any());
        console.log("navigator.userAgent = " + navigator.userAgent);
      }

      return deviceName || "unknown";
    },
    detectBrowser() {
      if (
        (navigator.userAgent.indexOf("Opera") ||
          navigator.userAgent.indexOf("OPR")) != -1
      ) {
        return "Opera";
      } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        return "Chrome";
      } else if (navigator.userAgent.indexOf("Safari") != -1) {
        return "Safari";
      } else if (navigator.userAgent.indexOf("Firefox") != -1) {
        return "Firefox";
      } else if (
        navigator.userAgent.indexOf("MSIE") != -1 ||
        !!document.documentMode == true
      ) {
        return "IE"; //crap
      } else {
        return "Unknown";
      }
    },
    utf8ToHex(str) {
      return Array.from(str)
        .map((c) =>
          c.charCodeAt(0) < 128
            ? c.charCodeAt(0).toString(16)
            : encodeURIComponent(c).replace(/%/g, "").toLowerCase()
        )
        .join("");
    },
    hexToUtf8(hex) {
      return decodeURIComponent("%" + hex.match(/.{1,2}/g).join("%"));
    },
  },
};
