<template>
  <div class="selectors">
    <v-radio-group v-model="thenType" row>
      <v-radio :label="$t('sendMsg')" value="msg" />
      <v-radio :label="$t('deviceAction')" value="device" />
    </v-radio-group>
    <div v-if="thenType == 'device'">
      <v-select
        :items="controllerDevices"
        item-text="name"
        item-value="identifier"
        v-model="controllerVal"
        :label="$t('device')"
      ></v-select>
      <v-radio-group v-model="actionVal" row>
        <v-flex row justify-space-around>
          <v-radio
            :label="$t('on')"
            on-icon="mdi-flash"
            off-icon="mdi-flash"
            active-class="elevation-8 pa-2 rounded-pill"
            value="on"
          ></v-radio>
          <v-radio
            :label="$t('off')"
            on-icon="mdi-flash-off"
            off-icon="mdi-flash-off"
            active-class="elevation-8 pa-2 rounded-pill"
            value="off"
          ></v-radio>
          <v-radio
            :label="$t('auto')"
            on-icon="mdi-flash-auto"
            active-class="elevation-8 pa-2 rounded-pill"
            off-icon="mdi-flash-auto"
            value="auto"
          ></v-radio>
        </v-flex>
      </v-radio-group>
    </div>
    <div v-if="thenType == 'msg'">
      <v-layout class="mt-2" row wrap>
        <v-radio-group
          multiple
          :class="
            $vuetify.breakpoint.xs ? 'mt-2 justify-center' : 'mt-2 mx-auto'
          "
          v-model="thenMsgNot"
          row
        >
          <v-radio :label="$t('emailNot')" value="email" />

          <v-radio :label="$t('sms')" value="sms" />
          <v-radio :label="$t('whatsapp')" value="whatsapp" />
        </v-radio-group>
      </v-layout>
      <v-layout class="mt-2" row wrap>
        <v-textarea :label="$t('msg')" v-model="thenTxtMsg" auto-grow filled />
      </v-layout>
    </div>

    <v-layout row wrap justify-center class="my-3 mb-2">
      <v-btn color="primary" class="mx-8" @click="confirmThen">
        {{ $t("confirm") }}
      </v-btn>
      <v-btn @click="close" class="mx-8">
        {{ $t("cancel") }}
      </v-btn>
    </v-layout>
  </div>
</template>
<script>
export default {
  name: "automationsThens",
  props: {
    config: {
      type: Boolean,
      default: false,
    },
    configData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      controllerVal: null,
      thenTxtMsg: null,
      thenMsgNot: [],
      thenType: "device",
      actionVal: null,
    };
  },
  computed: {
    automations() {
      let automations = this.$store.getters["automations/automations"];
      return automations;
    },

    controllerDevices() {
      let devices = this.$store.getters["devices/devices"];
      return Object.values(devices).map((d) => {
        if (d.role == 1) {
          return {
            name: d.name,
            slug: d.slug,
            identifier: d.identifier,
            sensors: Object.keys(d.data.sensors),
            type: d.settings.sensor_type,
          };
        }
      });
    },
    actions() {
      return [
        {
          value: ">",
          text: this.$t("bigger"),
        },
        {
          value: "<",
          text: this.$t("smaller"),
        },
        {
          value: "==",
          text: this.$t("equals"),
        },
        {
          value: "!=",
          text: this.$t("not-equals"),
        },
      ];
    },
  },
  mounted() {
    if (this.config) {
      // console.log("data", this.configData);
      if (this.configData.then_notify) {
        this.thenType = "msg";
        this.thenTxtMsg = this.configData.then_notify.msg;
        this.thenMsgNot = this.configData.then_notify.not;
      } else if (this.configData.then_controller) {
        this.thenType = "device";
        this.actionVal = this.configData.then_action;
        this.controllerVal = this.configData.then_controller;
      }
    } else {
      this.controllerVal = null;
      this.thenType = "device";
      this.thenTxtMsg = null;
      this.thenMsgNot = [];
      this.actionVal = null;
    }
  },
  watch: {},
  methods: {
    alertError(msg) {
      this.$store.dispatch("general/setAlert", {
        type: "oops",
        msg: msg,
      });
    },
    close() {
      this.controllerVal = null;
      this.thenType = "device";
      this.thenTxtMsg = null;
      this.thenMsgNot = [];
      this.actionVal = null;
      this.$emit("closeForm");
    },
    async confirmThen() {
      let thenNotify = {};
      let data = {};

      if (this.thenType == "msg") {
        if (this.thenTxtMsg && this.thenMsgNot) {
          thenNotify.msg = this.thenTxtMsg;
          thenNotify.not = this.thenMsgNot;
          data = {
            then_notify: thenNotify,
          };
          if (!this.config) {
            this.$emit("createNewThen", data);
          } else {
            this.$emit("updateNewThen", data);
          }
          this.close();
        } else {
          this.alertError("mustFill");
        }
      } else if (this.thenType == "device") {
        if (this.controllerVal && this.actionVal) {
          data = {
            then_action: this.actionVal,
            then_controller: this.controllerVal,
          };
          if (!this.config) {
            this.$emit("createNewThen", data);
          } else {
            this.$emit("updateNewThen", data);
          }
          this.close();
        } else {
          this.alertError("mustFill");
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pageHeader {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  margin: 0 auto;
}
.vertDivider {
  color: red;
}

.autoNameField {
  margin: 0 auto;
  padding-top: 50px;
  width: 85%;
}

.selectors {
  padding-top: 20px;
  margin: 0 auto;
  width: 85%;
}

.autoCard {
  width: 85%;
  text-align: start;
  .v-card__title {
    margin-inline-start: -14px;
    margin-inline-end: -14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
  }
  .delIcon {
    display: flex;
    .v-icon {
      cursor: pointer;
      color: red;
    }
  }
}
.v-input--selection-controls {
  margin-top: unset;
  padding-top: unset;
}
</style>
