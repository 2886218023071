<template>
  <v-layout row wrap class="groupTreeChildren">
    <v-flex xs12>
      <v-layout column wrap>
        <draggable v-model="groups" handle=".draghandlechild">
          <!-- class="draghandlechild" -->
          <groups-tree-item
            :handler="'draghandlechild'"
            v-for="(item, index) in items"
            :key="index"
            :item="item"
            :depth="depth + 1"
          />
        </draggable>
      </v-layout> </v-flex
  ></v-layout>
</template>
<script>
import { mdiChevronDown, mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import draggable from "vuedraggable";
import Lang from "@/helpers/lang";
export default {
  name: "GroupsTreeChildren",
  props: ["items", "depth"],
  components: {
    "groups-tree-item": () =>
      import("@/components/tools/groups-tree/GroupsTreeItem.vue"),
    draggable,
  },
  data() {
    return {
      keyboardArrowDown: mdiChevronDown,
      arrowRight: mdiChevronRight,
      arrowLeft: mdiChevronLeft,
      locale: Lang.get(),
      groupsData: [],
    };
  },
  mounted() {},
  computed: {
    groups: {
      get() {
        let groups = JSON.parse(JSON.stringify(this.items));
        groups = Object.values(groups).map((g) => {
          g.hasSubGroups =
            Object.values(groups).filter((grp) => grp.parent_slug == g.slug)
              .length > 0;
          return g;
        });
        return Object.values(groups).sort((a, b) => a.order - b.order);
      },
      set(groups) {
        this.groupsData = this.fixgroupsOrder(groups);
        // console.log("EDIT!");
        this.$store.dispatch("groups/editGroups", this.groupsData);
      },
    },
  },
  methods: {
    fixgroupsOrder(groups) {
      groups = Object.values(groups);
      for (let i = 0; i < groups.length; i++) {
        groups[i].order = i;
      }
      return groups;
    },
  },
};
</script>
<style lang="scss" scoped>
.groupTreeChildren {
  padding-inline-start: 16px;
}
</style>
