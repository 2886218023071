<style lang="scss" scoped>
.otpInput {
  direction: ltr;
}

.nextBtn,
.resendTimer {
  width: fit-content;
  margin: 0 auto;
}
</style>

<template>
  <v-layout row justify-center>
    <v-dialog
      v-model="dialogState"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="400"
      height="100"
    >
      <v-card>
        <v-card-title class="headline">
          <v-layout row wrap justify-space-between align-center>
            <v-flex shrink>
              {{ content.chooseWay }}
            </v-flex>
            <v-flex shrink>
              <v-btn text icon color="black" @click="close">
                <Icon icon="mdiClose" />
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <v-layout
            class="mt-7"
            v-if="!loading && stage == 0"
            row
            wrap
            justify-center
          >
            <v-flex xs12>
              <h2>{{ content.resetWay }}</h2>
              <v-radio-group v-model="resetType" row>
                <v-radio :label="content.phone" value="phone"></v-radio>
                <v-radio
                  disabled
                  :label="content.email"
                  value="email"
                ></v-radio>
              </v-radio-group>
              <v-layout column wrap justify-center class="pa-8">
                <v-form ref="verifyCodeForm" class="my-2">
                  <v-text-field
                    ref="verifyCodeFormPhone"
                    v-if="resetType == 'phone'"
                    name="phone_number"
                    v-model="form.phone"
                    :rules="rules.phone_code"
                    :placeholder="$t('phone')"
                    class="phone-input"
                    autocomplete="off"
                    clearable
                    :disabled="loadings.phone"
                    autofocus
                  ></v-text-field>

                  <v-text-field
                    ref="verifyCodeFormEmail"
                    v-if="resetType == 'email'"
                    name="email_address"
                    v-model="form.email"
                    :rules="rules.email"
                    :placeholder="$t('email')"
                    class="email-input"
                    autocomplete="off"
                    clearable
                  ></v-text-field>
                </v-form>
                <v-layout
                  column
                  wrap
                  justify-center
                  align-content-center
                  class="py-3"
                >
                  <v-flex grow align-center align-content-center>
                    <v-btn
                      color="switchq"
                      :disabled="!formValid || retry != 0"
                      outlined
                      @click="sendOtp"
                      :loading="loadings.phone"
                      >{{ $t("confirm") }}</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-flex
            v-if="error && stage == 0"
            xs12
            class="text-center mb-3 title warning--text"
          >
            {{ error }}
          </v-flex>
          <v-layout
            class="mt-7"
            v-if="!loading && stage == 1"
            row
            wrap
            justify-center
          >
            <v-flex xs12>
              <h2 class="my-3">{{ content.code }}</h2>
              <v-otp-input
                ref="verifyCodeFormOtp"
                class="otpInput"
                v-model="form.otp"
                length="6"
                type="number"
                :disabled="loadings.otp"
                autofocus
              />
              <v-layout
                v-if="!loading"
                column
                wrap
                justify-center
                align-content-center
                class="py-3"
              >
                <v-flex
                  v-if="error"
                  xs12
                  class="text-center mb-3 title warning--text"
                >
                  {{ error }}
                </v-flex>
                <v-flex
                  v-if="incorrect"
                  xs12
                  class="text-center mb-3 subheading red--text text--darken-2"
                >
                  {{ incorrect }}
                </v-flex>
                <v-flex align-center align-content-center>
                  <div class="nextBtn my-2">
                    <v-btn
                      color="blue darken-4"
                      outlined
                      :disabled="!form.otp || form.otp.length != 6"
                      @click="verifyCode"
                      :loading="loadings.otp"
                      >{{ content.confirm }}</v-btn
                    >
                  </div>
                </v-flex>
                <v-flex grow align-center align-content-center>
                  <v-btn
                    color="blue darken-4"
                    outlined
                    :disabled="resendSMS > 0"
                    @click="sendNewCode"
                    >{{ content.phone_dialog.resend }}</v-btn
                  >
                </v-flex>

                <v-flex
                  grow
                  align-center
                  v-show="resendSMS > 0"
                  class="text-xs-center grey--text pt-1"
                >
                  <div class="resendTimer">
                    {{ content.phone_dialog.possible }} {{ resendSMS }}
                    {{ content.phone_dialog.seconds }}.
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout
            class="mt-7"
            v-if="!loading && stage == 2"
            row
            wrap
            justify-center
          >
            <v-flex xs12>
              <h2 class="my-3">{{ content.passwordReset }}</h2>
              <v-text-field
                :type="revealPasswords.pwd ? 'text' : 'password'"
                ref="verifyCodeFormPassword"
                name="password"
                v-model="form.password"
                :rules="rules.password"
                :label="$t('password')"
                class="password-input"
                autocomplete="off"
              >
                <Icon
                  :icon="revealPasswords.pwd ? 'eyeOff' : 'eye'"
                  @click.native="revealPasswords.pwd = !revealPasswords.pwd"
                  color="switchq"
                  slot="append"
                />
              </v-text-field>

              <v-text-field
                :type="revealPasswords.pwdc ? 'text' : 'password'"
                ref="verifyCodeFormPasswordConfirmation"
                name="password_confirmation"
                v-model="form.password_confirmation"
                :rules="rules.cpassword"
                :label="$t('cpassword')"
                class="password-confirmation-input"
                autocomplete="off"
              >
                <Icon
                  :icon="revealPasswords.pwdc ? 'eyeOff' : 'eye'"
                  @click.native="revealPasswords.pwdc = !revealPasswords.pwdc"
                  color="switchq"
                  slot="append"
                />
              </v-text-field>
              <v-flex align-center align-content-center>
                <div class="nextBtn my-2">
                  <v-btn
                    color="blue darken-4"
                    outlined
                    :disabled="
                      !form.password ||
                      !form.password_confirmation ||
                      form.password != form.password_confirmation
                    "
                    @click="confirm"
                    :loading="loadings.password"
                    >{{ content.confirm }}</v-btn
                  >
                </div>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Lang from "@/helpers/lang";
import axios from "@/plugins/axios";

export default {
  name: "Userdialog",
  data: () => ({
    resetType: "phone",
    loading: false,
    resendSMS: 0,
    resendSMSInterval: null,
    error: null,
    incorrect: null,
    dialogState: true,
    OTP: false,
    stage: 0,
    retry: 0,
    form: {
      phone: null,
      email: null,
      otp: null,
      temp: null,
      password: null,
      password_confirmation: null,
    },
    revealPasswords: {
      pwd: false,
      pwdc: false,
    },
    loadings: {
      phone: false,
      otp: false,
      password: false,
    },
  }),
  created() {
    // console.log("create forgot pwd dialog");
    this.locale = Lang.get();
    this.setLang();
    this.setRules();
  },
  destroyed() {
    // console.log("destroyed forgot pwd dialog");
    //   this.close();
  },
  computed: {
    formValid() {
      if (
        this.resetType == "phone" &&
        this.form.phone &&
        this.$refs.verifyCodeFormPhone
      ) {
        return this.$refs.verifyCodeFormPhone.validate();
      }
      if (
        this.resetType == "email" &&
        this.form.email &&
        this.$refs.verifyCodeFormEmail
      ) {
        return this.$refs.verifyCodeFormEmail.validate();
      }
      return false;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.dialogState = true;
      } else {
        this.dialogState = false;
        this.cancelInterval();
      }
    },
    verification_code(code) {
      if (code && code.length === 6) {
        this.incorrect = null;
        this.verifyCode();
      }
    },
    "form.phone"() {
      if (this.$refs.verifyCodeFormPhone.validate()) {
        this.sendOtp();
      }
    },
    "form.otp"() {
      if (this.form.otp.length === 6) {
        this.verifyCode();
      }
    },
  },
  methods: {
    confirm() {
      let path = "/user/forgot-password/set-password";
      this.loadings.password = true;
      axios
        .post(path, this.form)
        .then(() => {
          this.$store.dispatch("general/setAlert", {
            type: "success",
            msg: "update-pass",
          });
          this.close();
          this.loadings.password = false;
        })
        .catch((err) => {
          this.$store.dispatch("general/setAlert", {
            type: "failed",
            msg: "oops",
          });
          console.log(err);
        });
    },
    close() {
      this.$emit("closeDialog");
      this.form.phone = null;
      this.form.email = null;
      this.form.otp = null;
      this.resetType = "phone";
      this.temp = null;
      this.form.password = null;
      this.form.password_confirmation = null;
      this.stage = 0;
      this.loadings.phone = null;
      this.loadings.otp = null;
      this.loadings.password = null;
      this.retry = 0;
    },
    startInterval() {
      this.resendSMS = 60;
      this.resendSMSInterval = setInterval(() => {
        this.resendSMS--;
        if (this.resendSMS === 0) {
          clearInterval(this.resendSMSInterval);
          this.resendSMSInterval = null;
        }
      }, 1000);
    },
    cancelInterval() {
      this.verification_code = null;
      clearInterval(this.resendSMSInterval);
      this.resendSMSInterval = null;
      setTimeout(() => {
        this.loading = false;
        this.error = null;
      }, 500);
    },
    verifyCode() {
      if (this.$refs.verifyCodeFormOtp.validate()) {
        this.loadings.otp = true;
        this.error = null;
        this.incorrect = null;
        const PATH = "/user/forgot-password/check";
        axios
          .post(PATH, this.form)
          .then(() => {
            this.stage = 2;
          })
          .catch((err) => {
            this.form.otp = null;
            if (err.response.status == 429) {
              this.error = this.content.errors.invalid;
            }
            if (err.response.data.message.code) {
              if (err.response.data.message.code == 19.3) {
                this.error = this.content.errors.passedtime;
              } else if (err.response.data.message.code == 19.2) {
                this.error = this.content.errors.verified;
              } else if (err.response.data.message.code == 19.4) {
                this.incorrect = this.content.errors.incorrect;
              } else {
                this.error = this.content.errors.invalid;
              }
            }
            this.verification_code = "";
            this.loading = false;
          })
          .finally(() => {
            this.loadings.otp = false;
          });
      }
    },
    sendNewCode() {
      let path = "/user/forgot-password/resend";
      axios
        .post(path, this.form)
        .then(() => {
          clearInterval(this.resendSMSInterval);
          this.resendSMSInterval = null;
          this.startInterval();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendOtp() {
      let path = "/user/forgot-password";
      this.loadings.phone = true;
      // console.log("this.form", this.resetType, this.form);
      axios
        .post(path, this.form)
        .then((res) => {
          if (res.data.success) {
            this.stage = 1;
            this.form.temp = res.data.data.temporary;
            this.startInterval();
          }
          // console.log(res.data);
        })
        .catch((err) => {
          // console.log(err);
          if (err.response.status == 429) {
            // this.error = this.content.errors.tooMany;
            // console.log("err.response.headers", err.response.headers);
            this.setTooManyError(err.response.headers);
          }
        })
        .finally(() => {
          this.loadings.phone = false;
        });
    },
    setTooManyError(headers) {
      let RETRY = parseInt(headers["retry-after"].replace(": ", ""));
      // console.log("RETRY", RETRY);
      let error = this.content.errors.tooMany + " ";
      error += this.content.phone_dialog.possible;
      error += " %RETRYAFTER% ";
      error += this.content.phone_dialog.seconds + ".";
      error = error.replace("%RETRYAFTER%", RETRY);
      this.error = error;
      this.retry = RETRY;
      const retryInterval = setInterval(() => {
        RETRY--;
        this.retry = RETRY;
        this.error = this.error.replace(RETRY + 1, RETRY);
        if (RETRY == 0) {
          clearInterval(retryInterval);
          this.error = null;
        }
      }, 1000);
    },
    setRules() {
      this.rules = {
        phone_code: [
          (v) => !!v || this.content.rules.phonecode[0],
          (v) => (v && v.length === 10) || this.content.rules.phonecode[1],
        ],
        email: [
          (v) => !!v || this.content.rules.email[0],
          (v) => /.+@.+\..+/.test(v) || this.content.rules.email[1],
        ],
        password: [
          (v) => !!v || this.content.rules.password[0],
          (v) =>
            (v && v.length >= 6 && v.length <= 18) ||
            this.$t("validation.password_between"),
        ],
        cpassword: [
          (v) => !!v || this.content.rules.cpassword[0],
          (v) => v === this.form.password || this.content.rules.cpassword[0],
        ],
      };
    },

    setLang() {
      if (this.locale == "en") {
        this.content = {
          passwordReset: "Lets recreate the password now!",
          code: "Please enter the code that been sent to you",
          resetWay: "How would you like to get the code?",
          email: "Email address",
          phone: "Mobile Phone",
          chooseWay: "Choose the method of restoring",
          phone_dialog: {
            title: "Validating Phone Number",
            body1:
              "A verification message has been sent to the number you entered",
            body2: "Please fill the received code:",
            code: "verification code",
            checking: "validating verification code",
            notreceived: "Did not receive the code?",
            resend: "Send a new code",
            possible: "Will be possible in",
            seconds: "seconds",
          },
          rules: {
            phonecode: [
              "You must enter your phone",
              "Phone must be 10 digits long",
              "The code is invalid",
            ],
            email: [
              "You must enter your email",
              "Please enter valid email address",
              "The code is invalid",
            ],
            password: ["You must enter a password"],
            cpassword: [
              "The passwords dosen't match",
              "You must enter a password",
              "The code is invalid",
            ],
          },
          errors: {
            passedtime: "Too much time has passed, please try again.",
            invalid: "The request is invalid.",
            incorrect: "The code is incorrect.",
            tooMany: "Too many attemptes.",
          },

          edit: "Edit",
          save: "Save",
          cancel: "Cancel",
          confirm: "Confirm",
        };
      } else if (this.locale == "he") {
        this.content = {
          passwordReset: "סיסמה חדשה",
          code: "נא להזין את הקוד אשר נשלח אליכם",
          resetWay: "כיצד ישלח הקוד?",
          email: "כתובת אימייל",
          phone: "טלפון נייד",
          chooseWay: "בחר את תהליך האיפוס",
          phone_dialog: {
            title: "אימות מספר טלפון",
            body1: "הודעת אימות נשלחה למספר שהזנת ",
            body2: "ההודעה מכילה קוד קצר וחד פעמי.",
            body3: "אנא הזן פה את הקוד שקיבלת:",
            code: "קוד אימות",
            checking: "קוד האימות בבדיקה",
            notreceived: "לא קיבלת את הקוד?",
            resend: "שליחת הודעה חדשה",
            possible: "יתאפשר בעוד",
            seconds: "שניות",
          },
          errors: {
            passedtime: "עבר יותר מידי זמן, אנא נסה שנית.",
            // verified: "מספר הטלפון הזה כבר אושר.",
            invalid: "הבקשה אינה תקינה.",
            incorrect: "הקוד אינו נכון.",
            tooMany: "יותר מידי נסיונות.",
          },

          rules: {
            phonecode: [
              "חובה להזין מספר טלפון",
              "המספר חייב להיות באורך של 10 ספרות",
              "הקוד לא תקין",
            ],
            email: [
              "חובה להזין את כתובת האימייל",
              "כתובת האמייל אינה תקינה",
              "The code is invalid",
            ],
            password: ["חובה להזין סיסמה"],
            cpassword: ["הסיסמאות אין תואמות"],
          },

          edit: "עריכה",
          save: "שמירה",
          cancel: "ביטול",
          confirm: "אישור",
        };
      }
    },
  },
};
</script>
