<template>
  <div class="mainContainer">
    <v-container grid-list-xs class="mb-5">
      <v-flex xs12 sm10 md8 lg6 class="mx-auto">
        <v-card
          color="transparent"
          flat
          class="px-2 text-xs-center"
          v-if="content"
        >
          <v-card-title primary-title class="py-0">
            <h1 class="display-1 mx-auto">
              {{ content.title }}
            </h1>
          </v-card-title>
          <v-layout row wrap>
            <v-btn
              v-show="!scanQR"
              color="switchq lighten-1 text--accent-1"
              :loading="loading"
              @click="scanQR = true"
              dark
              class="my-3 qrBTN"
              >{{ !scanQR ? content.scan : content.cancel }}
              <Icon icon="scanIcon" v-if="!scanQR" iconClass="ms-2" />
            </v-btn>
          </v-layout>
          <v-expand-transition>
            <v-alert
              :value="error"
              color="error"
              :icon="warning"
              outlined
              class="mb-3"
            >
              {{ error }}
            </v-alert>
          </v-expand-transition>
          <v-form @submit.prevent="submit()" ref="form">
            <input type="password" id="fake" />
            <v-text-field
              v-model="form.name"
              :label="content.name"
              :rules="[rules.name]"
            ></v-text-field>
            <v-text-field
              type="text"
              v-model="form.identifier"
              @keyup="uppercase"
              :label="content.uid"
              :rules="[rules.switch]"
              mask="NNNNNNNNNNNNNNNN"
            ></v-text-field>
            <span class="red--text" v-if="errors && errors.switch">{{
              content.validation.uid
            }}</span>
            <v-text-field
              :type="showp ? 'text' : 'password'"
              v-model="form.password"
              :label="content.password"
              :rules="[rules.password]"
              :append-icon="showp ? eye : eyeOff"
              @click:append="showp = !showp"
            ></v-text-field>
            <v-layout row wrap>
              <v-flex d-flex shrink align-center align-content-center>
                <span class="title">{{ content.role }}:</span>
              </v-flex>
              <v-flex shrink>
                <v-radio-group v-model="form.role" row>
                  <v-radio
                    color="switchq lighten-1"
                    :label="content.controller"
                    value="1"
                  ></v-radio>
                  <v-radio
                    color="switchq lighten-1"
                    :label="content.sensors"
                    value="0"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <div class="qrBox" v-if="scanQR">
              <qr-modal
                :activator="scanQR"
                @close="scanQR = false"
                @setMac="setMac"
              />
            </div>
            <v-btn
              color="switchq lighten-1 text--accent-1"
              :loading="loading"
              block
              type="submit"
              dark
              class="mt-3"
              >{{ content.submit }}</v-btn
            >
          </v-form>
        </v-card>
      </v-flex>
    </v-container>
  </div>
</template>
<script>
import axios from "@/plugins/axios";
import Lang from "@/helpers/lang";
import { mdiAlert, mdiEyeOff, mdiEye } from "@mdi/js";
import QrModal from "@/components/general/QrModal.vue";
export default {
  components: {
    QrModal,
  },
  data() {
    return {
      warning: mdiAlert,
      eyeOff: mdiEyeOff,
      eye: mdiEye,
      form: {
        name: "",
        identifier: "",
        password: "",
        role: "0",
      },
      rules: {
        name: (v) => !v || v.length <= 25 || this.content.validation.name,
        switch: (v) => !!v || this.content.validation.uid,
        password: (v) =>
          (v && v.length > 7) || this.content.validation.password,
      },
      scanQR: false,
      showp: false,
      locale: null,
      content: {},
      errors: {},
      error: null,
      loading: false,
    };
  },
  created() {
    this.locale = Lang.get();
    this.setLang();
  },
  // watch: {
  //   form() {
  //     this.form.identifier = this.form.identifier.toUpperCase();
  //   },
  // },
  methods: {
    uppercase() {
      this.form.identifier = this.form.identifier.toUpperCase();
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        axios
          .post(`/devices/new`, this.form)
          .then((res) => {
            this.$store.dispatch("devices/setDevice", res.data.device);
            this.loading = false;
            this.$store.dispatch("devices/fetchDevices");
            this.$store.dispatch("general/setAlert", {
              type: "success",
              msg: "device-added",
            });
            this.$router.push("/devices");
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.data.message === "exists") {
              this.error = this.content.exists;
            } else {
              this.error = this.content.error;
            }
            this.loading = false;
          });
      }
    },
    setMac(mac) {
      this.form.identifier = mac;
      this.scanQR = false;
    },
    setLang() {
      if (this.locale == "en") {
        this.content = {
          scan: "Scan device's QR",
          title: "Add A New Unit",
          name: "Unit's Name (optional)",
          uid: "Unit's ID",
          password: "password",
          submit: "Create",
          role: "Unit's Role",
          controller: "Controller",
          sensors: "Sensors",
          validation: {
            name: "The name can't be longer than 25 characters",
            uid: "Unit's ID is required",
            password: "Password must be at least 8 characters",
          },
          error: "Unit's ID or Password are incorrect",
          exists: "You have already registered this unit",
          cancel: "Close scanner",
        };
      } else if (this.locale == "he") {
        this.content = {
          scan: "סריקת מכשיר",
          title: "הוסף מכשיר חדש",
          name: "שם מכשיר (אפשרי)",
          uid: "מספר המכשיר",
          password: "סיסמה",
          submit: "שלח",
          role: "תפקיד המכשיר",
          controller: "בקר",
          sensors: "חיישנים",
          validation: {
            name: "שם המכשיר לא יכול להיות ארוך 25 תווים",
            uid: "חובה להזין את מספר המכשיר",
            password: "הסיסמא חייבת להיות לפחות 8 תווים",
          },
          error: "מספר המכשיר או הסיסמה אינם נכונים",
          exists: "המכשיר הזה כבר רשום אצלך",
          cancel: "סגירת סורק",
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#fake {
  position: absolute;
  top: -15000px;
}

.qrBTN {
  margin: 0 auto;
}
// .qrBox {
//   border: 1px solid red;
// }
</style>
