import axios from "axios";
/**
 * Initial state
 */
export const state = {
  notifications: [],
  pagination: {
    page: 1,
    rowsPerPage: 5,
  },
  total: null,
};

/**
 * Mutations
 */
export const mutations = {
  ["SET_NOTIFICATIONS_PAGE"](state, payload) {
    let notifications = payload.data;
    let note;
    let data = [];
    for (const not in notifications) {
      const rule = notifications[not].data.rule;
      note = {
        created_at: notifications[not].created_at,
        device_identifier: notifications[not].data.device,
        read_at: notifications[not].read_at,
        type: notifications[not].type,
        rule_action: rule ? rule.action : null,
        rule_sensor: rule ? rule.sensor : null,
        rule_value: rule ? rule.value : null,
        msg_title: notifications[not].data.message.title,
        msg_body: notifications[not].data.message.body,
      };
      data[not] = note;
    }
    state.notifications = data;
    state.pagination.page = parseInt(payload.current_page);
    state.pagination.rowsPerPage = parseInt(payload.per_page);
    state.pagination.lastPage = parseInt(payload.last_page);
    state.pagination.totalItems = parseInt(payload.total);
    state.total = payload.total;
  },
};

/**
 * Actions
 */
export const actions = {
  fetchNotifications({ commit, state }, page) {
    // let val = page.page;
    let getPage = state.pagination.page;
    let perPage = state.pagination.rowsPerPage;
    if (page.page != null) {
      getPage = page.page.page;
      perPage =
        page.page.itemsPerPage == -1 ? state.total : page.page.itemsPerPage;
    } /* else {
      getPage = page.page.page || state.pagination.page;
      perPage =
        page.page.itemsPerPage == -1
          ? state.total
          : page.page.itemsPerPage || state.pagination.rowsPerPage;
    } */
    axios
      .get(
        "/devices/" +
          page.slug +
          "/notifications?page=" +
          getPage +
          "&perpage=" +
          perPage
      )
      .then((res) => {
        state.pagination = page;
        commit("SET_NOTIFICATIONS_PAGE", res.data.data);
        state.pagination.page = 1;
        state.pagination.rowsPerPage = 5;
      })
      .catch((err) => {
        console.error(err);
      });
  },
};

/**
 * Getters
 */
export const getters = {
  notifications: (state) =>
    state.notifications.length > 0 ? state.notifications : [],
  total: (state) => state.total,
  pagination: (state) => state.pagination,
  perPage: (state) => (state.pagination ? state.pagination.rowsPerPage : 10),
};
