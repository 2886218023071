var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('h2',{staticClass:"text-center my-2"},[_vm._v(_vm._s(_vm.content.title))])]),_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"mt-2",attrs:{"xs12":"","lg8":"","xl8":""}},[(_vm.devices)?[_c('div',{staticClass:"table"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"options":_vm.pagination,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.notifications,"server-items-length":_vm.total,"items-per-page":_vm.perPage,"no-data-text":'',"no-results-text":_vm.content.noresults,"footer-props":{
                'items-per-page-text': _vm.content.footer.rowsperpage,
                'items-per-page-all-text': _vm.content.all,
              },"locale":"he","disable-sort":""},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [(_vm.$vuetify.breakpoint.smAndUp)?_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,class:_vm.textdir},[_vm._v(" "+_vm._s(header.text)+" ")])}),0):_c('tr',_vm._l((_vm.XSheaders),function(header){return _c('th',{key:header.text,class:_vm.textdir},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)]}},(_vm.$vuetify.breakpoint.xsOnly)?{key:"items",fn:function(nots){return [_c('td',{staticClass:"px-1"},[_vm._v(_vm._s(nots.item.created_at))]),(nots.item.device_name)?_c('td',{staticClass:"tableData px-1"},[_vm._v(" "+_vm._s(nots.item.device_name)),_c('br'),_c('small',[_vm._v(_vm._s(nots.item.device_identifier))])]):_c('td',{staticClass:"px-1 orange--text text--darken-4"},[_vm._v(" "+_vm._s(_vm.content.notowned)+" ")]),_c('td',{staticClass:"tableData px-1"},[_vm._v(_vm._s(nots.item.msg_title))])]}}:{key:"items",fn:function(nots){return [_c('td',{staticClass:"tableData"},[_vm._v(" "+_vm._s(nots.item.created_at)+" ")]),(nots.item.device_name)?_c('td',{staticClass:"tableData"},[_vm._v(" "+_vm._s(nots.item.device_name)+" ")]):_c('td',{staticClass:"orange--text text--darken-4"},[_vm._v(" "+_vm._s(_vm.content.notowned)+" ")]),_c('td',{staticClass:"tableData"},[_vm._v(" "+_vm._s(_vm.content.type[nots.item.type])+" ")])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('td',{staticClass:"pointer",style:({
                    width: _vm.$vuetify.breakpoint.xsOnly ? '100vw' : '',
                  }),on:{"click":function($event){return _vm.onRowClick(item.device_slug)}}},[_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.content.headers.goto)+" ")])])]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" "+_vm._s(_vm.content.footer.of)+" "+_vm._s(items.itemsLength)+" ")]}}],null,true)})],1)]:_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }