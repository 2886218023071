<template>
  <div class="HomeButtons">
    <v-btn-toggle mandatory dense borderless v-model="homePage">
      <v-btn
        :active-class="
          $vuetify.theme.dark ? 'switchq switchqSub--text' : 'switchq--text'
        "
        small
        value="devices"
        outlined
        elevation="0"
        :color="!$vuetify.theme.dark ? 'switchq' : 'switchqSub lighten-3'"
      >
        {{ $t("links.all-devices") }}</v-btn
      >
      <v-btn
        :active-class="
          $vuetify.theme.dark ? 'switchq switchqSub--text' : 'switchq--text'
        "
        small
        value="groups"
        outlined
        elevation="0"
        :color="!$vuetify.theme.dark ? 'switchq' : 'switchqSub lighten-3'"
        >{{ $t("links.groups") }}</v-btn
      >
    </v-btn-toggle>
  </div>
</template>
<script>
export default {
  name: "HomeButtons",
  data() {
    return {
      //
    };
  },
  computed: {
    hasGroups() {
      return this.$store.getters["groups/hasGroups"];
    },
    homePage: {
      get() {
        return localStorage.getItem("homePage") || "devices";
      },
      set(home) {
        if (home) {
          if (home == "groups" && !this.hasGroups) {
            this.$router.push("/groups/edit");
          } else {
            const path = "/" + home;
            this.$router.push(path);
            localStorage.setItem("homePage", home);
          }
          localStorage.setItem("homePage", home);
          // console.log("home from storage", localStorage.getItem("homePage"));
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
// .v-btn-toggle--group {
//   border: unset;
// }
.HomeButtons {
  .v-btn {
    border-radius: 4px;
    padding: 2px 6px;
    // border-right: 1px solid darkgray;
    // &:last-of-type {
    // border-right: 0;
    // border-radius: 4px;
    // border-top-left-radius: 0;
    // border-bottom-right-radius: 0;
    // }
    // &:first-of-type {
    // border-left: 0;
    // border-top-right-radius: 0;
    // border-bottom-right-radius: 0;
    // }
    // &
  }
}
</style>
