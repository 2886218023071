import axios from "axios";
/**
 * Initial state
 */
export const state = {
  snackbar: null,
  alerts: [],
  unread: [],
  notifications: [],
  pagination: {
    page: 1,
    rowsPerPage: 5,
  },
  total: null,
  // notificationsCount: null,
};

/**
 * Mutations
 */
export const mutations = {
  ["SET_UNREAD"](state, notifications) {
    state.unread = notifications;
  },
  ["UNSET_UNREAD"](state) {
    state.unread = [];
  },
  ["UNSET_ALERT"](state, id) {
    state.alerts = state.alerts.filter((a) => a.id != id);
  },
  ["SET_NEW_UNREAD"](state, notification) {
    state.unread.push(notification);
    state.alerts.push(notification);
    setTimeout(() => {
      state.alerts = state.alerts.filter((a) => a.id !== notification.id);
    }, 5000);
  },
  ["SET_NOTIFICATION"](state, notifications) {
    const LANG = navigator.language || navigator.userLanguage;
    const OPTIONS = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };

    let data = [];
    for (let not in notifications) {
      if (!notifications[not].read_at) {
        data[not] = notifications[not];
        data[not].created_at = new Date(
          notifications[not].created_at * 1000
        ).toLocaleDateString(LANG, OPTIONS);
      }
    }
    state.unread = notifications;
    state.snackbar = notifications[0];
  },
  ["SET_NOTIFICATIONS_PAGE"](state, payload) {
    let notifications = payload.data;
    let note;
    let data = [];
    for (const not in notifications) {
      // console.log("not is:", notifications[not]);
      const rule = notifications[not].data.rule;
      note = {
        created_at: notifications[not].created_at,
        device_name: notifications[not].data.device.name,
        device_identifier: notifications[not].data.device,
        read_at: notifications[not].read_at,
        type: notifications[not].type,
        rule_action: rule ? rule.action : null,
        rule_sensor: rule ? rule.sensor : null,
        rule_value: rule ? rule.value : null,
        msg_title: notifications[not].data.message.title,
        msg_body: notifications[not].data.message.body,
      };
      data[not] = note;
    }
    state.notifications = data;
    state.pagination.page = parseInt(payload.current_page);
    state.pagination.rowsPerPage = parseInt(payload.per_page);
    state.pagination.lastPage = parseInt(payload.last_page);
    state.pagination.totalItems = parseInt(payload.total);
    state.total = payload.total;
  },
};

/**
 * Actions
 */
export const actions = {
  async fetchUnread({ commit }) {
    await axios
      .get("/user/notifications/unread")
      .then((res) => {
        if (res.data) {
          commit("SET_UNREAD", res.data.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },
  newUnread({ commit }, notification) {
    commit("SET_NEW_UNREAD", notification);
  },
  deleteAlert({ commit }, id) {
    commit("UNSET_ALERT", id);
  },
  readAll({ commit }) {
    axios
      .get("/user/notifications/read-all")
      .then(() => {
        // console.log('res', res);
        commit("UNSET_UNREAD");
      })
      .catch((err) => {
        console.error(err);
      });
  },
  setNew({ commit }, notifications) {
    commit("SET_NOTIFICATION", notifications);
  },
  fetchNotifications({ commit, state }, page) {
    let getPage = page.page || state.pagination.page;
    let perPage =
      page.itemsPerPage == -1
        ? state.total
        : page.itemsPerPage || state.pagination.rowsPerPage;
    axios
      .get("/user/notifications?page=" + getPage + "&perpage=" + perPage)
      .then((res) => {
        state.pagination = page;
        commit("SET_NOTIFICATIONS_PAGE", res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  },
};

/**
 * Getters
 */
export const getters = {
  unread: (state) =>
    state.unread.sort(
      (a, b) => +new Date(b.created_at) - +new Date(a.created_at)
    ),
  alerts: (state) =>
    state.alerts.sort(
      (a, b) => +new Date(b.created_at) - +new Date(a.created_at)
    ),
  unreadCount: (state) => {
    return state.unread ? state.unread.length : null;
  },
  new: (state) => state.snackbar,
  notifications: (state) =>
    state.notifications.length > 0 ? state.notifications : [],
  total: (state) => state.total,
  pagination: (state) => state.pagination,
  perPage: (state) => (state.pagination ? state.pagination.rowsPerPage : 10),
};
