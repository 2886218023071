<template>
  <pull-to
    ref="pullToContainer"
    :top-config="topConfig"
    :is-bottom-bounce="false"
    :is-top-bounce="allowPullToRefresh"
    :top-load-method="loadMethod"
    :top-block-height="60"
    @top-state-change="stateChange"
  >
    <template slot="top-block">
      <v-layout v-if="refresh" row wrap justify-center>
        <v-progress-circular
          :width="2"
          :size="24"
          color="switchq"
          value="70"
          :indeterminate="loadingIcon == 'trigger' ? true : false"
        ></v-progress-circular>
      </v-layout>
      <v-layout v-else> </v-layout>
    </template>
    <v-container grid-list-xs fluid xs12>
      <v-layout
        v-if="devices != null && Object.keys(groups).length"
        row
        nowrap
        class="groups-layout"
      >
        <v-flex xs12 md4 v-for="group in groups" :key="group.slug" class="pa-3">
          <v-layout row wrap justify-space-between align-baseline>
            <v-flex shrink>
              <div class="px-2 pointer">
                {{ group.name }}
              </div>
            </v-flex>
            <v-flex shrink>
              <router-link
                :to="
                  group.hasSubGroups
                    ? 'groups/' + group.slug + '/sub-groups'
                    : 'groups/' + group.slug + '/devices'
                "
              >
                {{ $t("enter-group") }}
              </router-link>
            </v-flex>
          </v-layout>
          <v-card elevation="3" outlined class="groupCard">
            <v-card-text class="pa-0">
              <v-layout
                :row="$vuetify.breakpoint.xsAndUp"
                :column="$vuetify.breakpoint.smAndDown"
                class="cardCont"
              >
                <v-flex
                  xs3
                  pa-3
                  class="sensors pointer"
                  v-if="
                    (group.hasLogs && Object.keys(group.sensors).length > 0) ||
                    (!group.hasLogs && Object.keys(group.sensors).length === 0)
                  "
                >
                  <v-layout
                    v-if="
                      group.hasLogs && Object.keys(group.sensors).length > 0
                    "
                    class="groupAvgs"
                    row
                    wrap
                    align-center
                  >
                    <v-flex
                      v-if="group.sensors.temperatures"
                      class="group-sensor-avg cardTemp"
                    >
                      <h3>{{ $t("temp-avg") }}</h3>
                      <div class="sensData sensTemp mt-1">
                        <span dir="ltr">
                          {{ group.sensors.temperatures }} °C
                        </span>
                      </div>
                    </v-flex>
                    <v-flex
                      v-if="group.sensors.humidity"
                      class="group-sensor-avg cardHum"
                    >
                      <h3>{{ $t("hum-avg") }}</h3>
                      <div class="sensData sensHum mt-1">
                        {{ group.sensors.humidity }}%
                      </div>
                    </v-flex>
                    <v-flex
                      v-if="group.sensors.pressure"
                      class="group-sensor-avg cardPres"
                    >
                      <h3>{{ $t("pres-avg") }}</h3>
                      <div class="sensData sensPres mt-1">
                        {{ group.sensors.pressure }}<small>hPa</small>
                      </div>
                    </v-flex>
                    <v-flex
                      v-if="group.sensors.co2"
                      class="group-sensor-avg cardCo2"
                    >
                      <h3>{{ $t("co2-avg") }}</h3>
                      <div class="sensData sensCo2 mt-1">
                        {{ group.sensors.co2 }}<small>ppm</small>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-divider
                  v-if="
                    group.devices.length &&
                    Object.keys(group.sensors).length > 0
                  "
                  light
                  :vertical="$vuetify.breakpoint.mdAndUp"
                ></v-divider>
                <v-flex
                  v-if="group.devices.length"
                  xs12
                  :xs9="
                    (!group.hasLogs &&
                      Object.keys(group.sensors).length === 0) ||
                    (group.hasLogs && Object.keys(group.sensors).length > 0)
                  "
                  d-flex
                  :flex-grow-1="
                    !group.hasLogs && Object.keys(group.sensors).length === 0
                  "
                  justify-center
                  :align-center="
                    (!group.hasLogs && group.devices.length === 0) ||
                    (group.hasLogs && Object.keys(group.sensors).length > 0)
                  "
                >
                  <GroupChart
                    v-if="
                      group.hasLogs && Object.keys(group.sensors).length > 0
                    "
                    :group-slug="group.slug"
                  />
                  <v-progress-circular
                    v-else-if="
                      !group.hasLogs && Object.keys(group.sensors).length === 0
                    "
                    :size="120"
                    :width="4"
                    color="switchq"
                    indeterminate
                    class="loader"
                  >
                    {{ $t("loading") }}...
                  </v-progress-circular>
                  <v-layout
                    v-if="
                      group.hasLogs && Object.keys(group.sensors).length === 0
                    "
                    class="pointer noLogsCont"
                    @click="$router.push('/groups/' + group.slug + '/devices/')"
                    row
                    wrap
                  >
                    <v-flex xs12 class="text-center">
                      <div
                        :style="{
                          textDecoration: 'underline',
                          margin: '35px 0 20px 0',
                        }"
                      >
                        <h3>
                          {{ $t("devices") }}
                        </h3>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <div
                        v-for="(device, index) in group.displayDevices"
                        :key="index"
                        class="ma-2"
                      >
                        <DeviceBar
                          :item="device"
                          :index="index"
                          :inGroup="true"
                          :group="group"
                        />
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-layout
                  py-16
                  mb-16
                  v-if="group.devices.length == 0 && !group.hasSubGroups"
                  row
                  wrap
                  justify-center
                >
                  <v-flex xs8 justify-center>
                    <div class="noSensorCont">
                      <h3 class="noSensors">
                        {{ $t("empty-group") }}
                      </h3>
                      <v-btn
                        @click="$router.push('/groups/edit')"
                        color="switchq switchqSub--text"
                      >
                        {{ $t("edit-group") }}
                        <Icon icon="Plus" />
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout
                  row
                  wrap
                  pa-4
                  v-if="group.hasSubGroups"
                  class="subGroupsContainer"
                >
                  <v-flex xs12 class="text-center">
                    <div :style="{ textDecoration: 'underline' }">
                      <h3>
                        {{ $t("links.groups") }}
                      </h3>
                    </div>
                  </v-flex>
                  <v-flex
                    xs6
                    md4
                    lg3
                    v-for="(sub, index) in group.subs"
                    :key="index"
                    pa-2
                    class="subGroupContainer"
                    d-flex
                    column
                  >
                    <v-card height="100">
                      <v-card-title primary-title>
                        {{ sub.name }}
                      </v-card-title>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </pull-to>
</template>
<script>
import GroupChart from "./GroupChart.vue";
import PullTo from "vue-pull-to";
import DeviceBar from "@/components/devices/DeviceBar.vue";
export default {
  components: {
    GroupChart,
    PullTo,
    DeviceBar,
  },
  name: "Groups",
  data() {
    return {
      interval: null,
      pulled: 0,
      scrollInterval: null,
      scrollY: 0,
      loadingIcon: "",
      scroll: 0,
      topConfig: {
        pullText: this.$t("pullTo"),
        triggerText: this.$t("releaseTo"),
        loadingText: this.$t("refresh"),
        failText: this.$t("refreshFail"),
        triggerDistance: 70,
      },
      hasSubGroups: false,
    };
  },
  computed: {
    loadMethod() {
      return this.scrollY === 0 ? this.refresh : null;
    },
    allowPullToRefresh() {
      return this.$vuetify.breakpoint.smAndDown && this.scrollY === 0;
    },
    devices() {
      return this.$store.getters["devices/devices"];
    },
    dir() {
      return this.$vuetify.rtl ? "rtl" : "ltr";
    },
    loadingGroups() {
      return this.$store.getters["groups/loading"];
    },
    hasGroups() {
      return this.$store.getters["groups/hasGroups"];
    },
    groups() {
      const sensorTypes = ["temperatures", "humidity", "pressure", "co2"];
      let groups = Object.values(this.$store.getters["groups/groups"]);
      groups = JSON.parse(JSON.stringify(groups));
      groups = groups
        .filter((g) => !g.parent_slug)
        .map((g) => {
          const devices = Object.values(this.devices).filter((d) =>
            g.devices.includes(d.slug)
          );
          g.displayDevices = Object.values(this.devices).filter((d) =>
            g.devices.includes(d.slug)
          );
          g.hasLogs = true;
          g.subs = groups.filter((group) => group.parent_slug == g.slug);
          g.hasSubGroups = g.subs.length > 0;
          g.devices.forEach((device) => {
            if (!this.$store.getters["groups/hasDailyLogs"](device)) {
              g.hasLogs = false;
            }
          });
          const count = {};
          g.sensors = {};
          devices.forEach((device) => {
            const sensors = device.data.sensors;
            for (const sensor in sensors) {
              if (sensorTypes.includes(sensor)) {
                count[sensor] = (count[sensor] ?? 0) + 1;
                const value = Array.isArray(sensors[sensor])
                  ? sensors[sensor][0]
                  : sensors[sensor];
                const prev = g.sensors[sensor] ?? 0;
                g.sensors[sensor] = prev + value;
              }
            }
          });
          for (const sensor in g.sensors) {
            g.sensors[sensor] = parseFloat(
              (g.sensors[sensor] / count[sensor]).toFixed(2)
            );
          }
          return g;
        });

      return groups;
    },
    slugs() {
      return this.groups.map((g) => g.devices).flat();
    },
    fetchedLogs() {
      return this.$store.getters["devices/fetchedLogs"];
    },
  },
  watch: {
    // groups() {
    //   if (Object.keys(this.groups).length) {
    //     // this.getDevicesLogs();
    //     // clearInterval(this.interval);
    //     // this.getGroupsLogs();
    //     // this.interval = null;
    //     this.interval = setInterval(() => {
    //       this.getGroupsLogs();
    //     }, 1000 * 60 * 5);
    //   }
    // },
    loadingGroups() {
      if (!this.hasGroups) {
        this.$router.push("/devices");
        localStorage.setItem("homePage", "devices");
      }
    },
  },

  beforeUnmount() {
    clearInterval(this.interval);
    this.interval = null;
  },
  beforeRouteLeave(to, from, next) {
    if (to != from) {
      clearInterval(this.interval);
      this.interval = null;
      next();
    }
  },

  created() {
    clearInterval(this.interval);
    this.interval = null;
  },

  mounted() {
    clearInterval(this.interval);
    this.interval = null;
    if (Object.keys(this.groups).length) {
      this.getGroupsLogs();
      // this.interval = null;
      this.interval = setInterval(() => {
        this.getGroupsLogs();
        //   console.log("getGroupsLogs interval");
        // }, 1000);
      }, 1000 * 60 * 5);
    } else {
      setTimeout(() => {
        this.getGroupsLogs();
        this.interval = setInterval(() => {
          // console.log("getGroupsLogs interval");
          this.getGroupsLogs();
          // }, 1000);
        }, 1000 * 60 * 5);
      }, 5000);
    }
    // });
    // this.getDevicesLogs();

    this.$vuetify.goTo(0, { duration: 0 });
    if (this.$vuetify.breakpoint.smAndDown) {
      this.scrollInterval = setInterval(() => {
        this.scrollY = window.scrollY;
      }, 100);
    }
  },
  destroyed() {
    for (const interval in this.intervals) {
      clearInterval(this.intervals[interval]);
    }
    this.intervals = {};
    // console.log("destyroyed");
    clearInterval(this.scrollInterval);
    clearInterval(this.interval);
  },
  methods: {
    openItem(slug) {
      let path = "/devices/" + slug;
      if (this.$route.path !== path) {
        this.$router.push({ path: path });
      }
    },
    refresh() {
      window.location.reload();
    },
    stateChange(state) {
      if (state === "pull") {
        this.loadingIcon = "pull";
      } else if (state === "trigger") {
        this.loadingIcon = "trigger";
      }
    },
    randBetween(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    // getDevicesLogs() {
    //   if (!this.fetchedLogs && this.slugs.length) {
    //     this.$store.dispatch("devices/startFetchingLogs");
    //     this.slugs.forEach((device) => {
    //       this.$store.dispatch("devices/deviceDailyLogs", device);
    //       this.intervals[device] = setInterval(() => {
    //         this.$store.dispatch("devices/deviceDailyLogs", device);
    //       }, 1000 * 60 * 5);
    //     });
    //   }
    // },
    getGroupsLogs() {
      this.groups.forEach((g) => {
        // console.log("getGroupsLogs", g);
        this.$store.dispatch("groups/getGroupLogs", g);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.px-2 {
  font-size: 16pt;
}

.loader {
  margin: auto 0;
}

.subGroupsContainer {
  max-height: 350px;
  overflow-y: auto;
  justify-content: center;
  .text-center {
    margin-bottom: auto;
  }
  .subGroupContainer {
    text-align: center;
    .v-card {
      width: 100%;
      background-color: #eef0ee;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      .v-card__title {
        justify-content: center;
        font-size: 12pt;
        font-weight: bold;
      }
    }
  }
}

.toGroupsEdit {
  div {
    width: fit-content;
    // margin: 0 auto;
  }
}

h3 {
  // color: black;
  font-size: 13pt;
  font-weight: 500;
}
.noSensorCont {
  display: flex;
  flex-direction: column;
  .noSensors {
    color: black;
    font-size: 13pt;
    font-weight: 700;
    margin: 10px auto 30px auto;
  }
}

.noLogsCont {
  justify-content: center;
  overflow-y: auto;
}

.deviceBar {
  border-radius: 10px;
  // width: 100%;
  padding: 8px 16px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    font-size: 14pt;
  }
  .rangData {
    display: flex;
    flex-direction: row;
    .value {
      font-size: 11pt;
    }
  }
}

.v-card {
  .cardCont {
    min-height: 350px;
  }
  .sensors {
    min-width: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    // .group-sensor-avg {
    //   display: flex;
    //   align-items: center;
    // }
    .sensData {
      font-size: 18pt;
    }
    .sensTemp {
      color: #ed6006;
    }
    .sensHum {
      color: #5f802f;
    }
    .sensPres {
      color: #009688;
    }
    .sensCo2 {
      color: #827717;
    }
  }
}
</style>
