<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5 switchq--text">
          {{ $t("pushNot") }}</v-card-title
        >
        <v-card-text>
          <div class="font-weight-bold">
            {{ $t("pushMsg") }}
          </div>
          <small>
            {{ $t("pushMsg2") }}
          </small>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="switchq lighten-1 switchqSub--text"
            text
            @click="subscribe(), (dialog = false)"
            class="font-weight-bold"
            >{{ $t("confirm") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            text
            @click="denyPush(), (dialog = false)"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import PushMixin from "@/mixins/pushNotificationMixin";
export default {
  props: ["show"],
  mixins: [PushMixin],
  data() {
    return {
      dialog: false,
      publicVapidKey:
        "BM0y8CuzwW3kHMzRmTMDz_t2b5y7VWEV9MDk7i0eeEWioo2pJd9SclJsB6Bgi8sB2YFpGvTz-Lqf785cYIfFV6o",
    };
  },
  mounted() {
    setTimeout(() => {
      if (Notification.permission === "default") {
        if (localStorage.getItem("deniedPush") != "true") {
          if (this.checkPushValid()) {
            this.dialog = true;
          }
        }
      }
    }, 2000);
  },
  methods: {
    checkPushValid() {
      if ("serviceWorker" in navigator) {
        this.dialog = true;
      }
    },
    //   denyPush() {
    //     localStorage.setItem("deniedPush", "true");
    //     this.dialog = false;
    //   },
    //   async subscribe() {
    //     this.dialog = false;
    //     const register = await navigator.serviceWorker.register("/push-sw.js", {
    //       scope: "/push-notifications",
    //     });
    //     const subscription = await register.pushManager.subscribe({
    //       userVisibleOnly: true,
    //       applicationServerKey: this.publicVapidKey,
    //     });
    //     this.sendSubscription(subscription);
    //   },

    //   async sendSubscription(subscription) {
    //     await axios
    //       .post(`/user/notifications/push-subscribe`, subscription)
    //       .then((res) => {
    //         console.log("res is: ", res);
    //       })
    //       .catch((err) => {
    //         console.log("ERROR: ", err);
    //       });
    //   },
    //   checkPushValid() {
    //     if ("serviceWorker" in navigator) {
    //       this.dialog = true;
    //       // this.subscribe().catch((err) => {
    //       //   console.log(err);
    //       // });
    //     }
    //   },
  },
};
</script>
