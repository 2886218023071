<template>
  <div class="navbar" id="topbar">
    <div class="navbar-top">
      <div class="navbar-top-side">
        <Icon
          iconClass="pointer"
          icon="menuIcon"
          :xlarge="true"
          color="grey darken-2"
          @click.native="sidebar()"
        />
      </div>
      <div class="navbar-top-logo pointer" @click="changeRoute('/')">
        <Logo
          :slogan="$vuetify.breakpoint.smAndUp"
          :size="$vuetify.breakpoint.name"
        />
      </div>
      <div v-if="$vuetify.breakpoint.xs" class="navbar-top-side">
        <notifications
          v-if="logged"
          shrink
          class="pa-0 ma-0 topbar-btn"
          :devices="devices"
        ></notifications>
      </div>
    </div>
    <div
      v-if="showBottom && swipDisplay"
      :class="['navbar-bottom', $vuetify.breakpoint.xsOnly ? 'small-bar' : '']"
    >
      <template v-if="$vuetify.breakpoint.smAndUp">
        <v-layout
          row
          wrap
          align-baseline
          class="grey--text text--darken-3 px-2 shrink"
        >
          <h2>{{ business.custom ? business.title : business.name }}</h2>
          <h3 class="ms-4 username">{{ username }}</h3>
        </v-layout>
        <span v-if="isHome">
          <home-buttons />
        </span>
        <span v-else class="backBtn d-flex">
          <v-btn color="info" text height="auto" @click="changeRoute('/')">
            <Icon icon="mdiHome" iconClass="ms-1" :small="true" />
          </v-btn>
          <v-divider vertical></v-divider>
          <v-btn color="info" text height="auto" @click="$router.go(-1)">
            {{ $t("back") }}
            <Icon icon="returnIcon" iconClass="ms-1" :small="true" />
          </v-btn>
        </span>
        <v-flex xs4 v-if="isHome && devicesPage == 'devices'">
          <span>
            <search-input />
          </span>
        </v-flex>
        <div class="navbar-bottom-time-data grey--text text--darken-3">
          <div id="navbar-bottom-time">
            <span>
              {{ time.format("D MMMM YYYY") }}
            </span>
            <span id="time-divider">|</span>
            <span class="time-cont">
              {{ time.format("HH:mm") }}
            </span>
          </div>
          <notifications
            v-if="logged"
            shrink
            class="pa-0 ma-0 topbar-btn"
            :devices="devices"
          ></notifications>
        </div>
      </template>
      <template v-if="$vuetify.breakpoint.xsOnly">
        <v-layout row wrap justify-space-between align-center>
          <div
            class="d-flex"
            xs6
            v-if="isHome && devicesPage == 'devices' && !search"
          >
            <!-- <span> -->
            <!-- <Icon icon="search" iconClass="ms-1" :small="true" /> -->
            <v-icon @click="search = true">search</v-icon>
            <!-- </span> -->
            <!-- <span v-if="search">
              <search-input />
            </span> -->
          </div>
          <v-spacer v-if="isHome && !search"></v-spacer>
          <v-flex>
            <!-- <span v-if="isHome && devicesPage == 'devices'">
              <search-input />
            </span> -->
            <span v-if="isHome && !search">
              <home-buttons />
            </span>
            <span v-else-if="isHome && search" class="d-flex">
              <search-input @close="search = false" :focused="search" />
              <v-btn color="info" text height="auto" @click="search = false">
                <Icon icon="mdiClose" iconClass="ms-2" />
              </v-btn>
            </span>
            <div v-else class="backBtn d-flex w-100">
              <v-btn color="info" text height="auto" @click="changeRoute('/')">
                <Icon icon="mdiHome" iconClass="ms-1" :small="true" />
              </v-btn>
              <!-- <v-divider vertical></v-divider> -->
              <v-spacer></v-spacer>
              <v-btn color="info" text height="auto" @click="$router.go(-1)">
                {{ $t("back") }}
                <Icon icon="returnIcon" iconClass="ms-1" :small="true" />
              </v-btn>
            </div>
          </v-flex>
          <!-- <v-spacer
            v-if="isHome && devicesPage == 'devices' && !search"
          ></v-spacer>
          <div
            class="d-flex"
            xs6
            v-if="isHome && devicesPage == 'devices' && !search"
          > -->
          <!-- <span> -->
          <!-- <Icon icon="search" iconClass="ms-1" :small="true" /> -->
          <!-- <v-icon @click="search = true">search</v-icon> -->
          <!-- </span> -->
          <!-- <span v-if="search">
              <search-input />
            </span> -->
          <!-- </div> -->
        </v-layout>
      </template>
    </div>
  </div>
</template>

<script>
import Notifications from "./notifications";
import Logo from "@/components/app_parts/logo.vue";
import HomeButtons from "./HomeButtons.vue";
import SearchInput from "./SearchInput.vue";
export default {
  name: "TopBar",
  components: { Notifications, Logo, HomeButtons, SearchInput },
  props: ["devices", "swipDisplay"],
  data() {
    return {
      clipped: true,
      openedMenu: null,
      title: "SwitchQ",
      logged: true,
      locale: null,
      devicesPage: localStorage.getItem("homePage"),
      search: false,
    };
  },
  computed: {
    backRoute() {
      let back = this.$route.path.split("/");
      back.pop();
      back = back.join("/");
      // console.log("back", back);
      return back;
    },
    showBottom() {
      return true;
    },
    isHome() {
      return ["/devices", "/groups"].includes(this.$route.path);
    },
    path() {
      return this.$route.path;
    },
    time() {
      const time = this.$store.getters["general/time"];
      return time;
    },
    username() {
      return this.$store.getters["user/name"];
    },
    business() {
      return this.$store.getters["business/business"];
    },
  },
  created() {
    this.$store.dispatch("general/startTimeInterval");
  },
  watch: {
    path: {
      handler() {
        this.devicesPage = localStorage.getItem("homePage");
      },
      deep: true,
    },
    // search: {
    //   handler() {
    //     console.log(this.search);
    //   },
    //   deep: true,
    // },
  },
  mounted() {},
  methods: {
    changeRoute(route) {
      if (this.$route.path !== "/") {
        this.$router.push(route).catch(() => {
          //
        });
      }
    },
    openMenu() {
      document.getElementById("menuBtn").click();
    },
    sidebar() {
      this.$store.dispatch("general/toggleSideMenu");
    },
  },
};
</script>

<style scoped lang="scss">
.username {
  font-size: 18pt;
  font-weight: 500;
}

.backBtn {
  margin: 0 auto;
}

@media screen and (min-width: 600px) {
  .navbar-top {
    height: 140px;
  }
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  .navbar-top {
    background-color: #fff;
    border-bottom: 2px solid rgb(224, 227, 231);
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    .navbar-top-side {
      width: 50px;
      display: flex;
      justify-content: center;
    }
  }
  .navbar-bottom {
    height: 55px;
    &.small-bar {
      height: 40px;
    }
    background-color: rgb(242, 246, 249);
    border-bottom: 2px solid rgb(224, 227, 231);
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    .navbar-bottom-time-data {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      #navbar-bottom-time {
        display: flex;
        font-size: 18pt;
        margin: 0px 16px;
        span {
          margin: 0 6px;
        }
        #time-divider {
          color: #cecece;
        }
        .time-cont {
          width: 70px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .navbar-top {
    height: 60px;
  }
}
</style>
