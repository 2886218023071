// import dayjs from "@/plugins/dayjs";
// import createUUID from "@/helpers/uuid";
import axios from "axios";
const URL = "http://127.0.0.1:3001/";

/**
 * Initial state
 */
export const state = {
  automations: [],
};

/**
 * Mutations
 */
export const mutations = {
  ["SET_AUTOMATIONS"](state, automations) {
    for (let i = 0; i < Object.values(automations).length; i++) {
      let automation = Object.values(automations)[i];
      let ifs = [];
      let thens = [];
      let ends = [];

      Object.keys(automation.ifs).forEach((ifType) => {
        if (ifType == "device_ifs") {
          console.log("ifType: ", "inside devices");
          for (let i = 0; i < automation.ifs[ifType].length; i++) {
            const deviceIf = automation.ifs[ifType][i].data;
            let ifObj = {
              if_sensor: deviceIf.device_id,
              if_sensorDevice: deviceIf.parameter,
              state: deviceIf.compare_type,
              to_state:
                deviceIf.compare_type == "toDeviceTemp"
                  ? deviceIf.to_device_id
                  : deviceIf.compare_type == "staticTemp"
                  ? deviceIf.static_parameter_value
                  : null,
              if_action: deviceIf.operator,
            };
            ifs.push({ device: ifObj });
          }
        } else if (ifType == "time_ifs") {
          for (let i = 0; i < automation.ifs[ifType].length; i++) {
            const timeIf = automation.ifs[ifType][i].data;
            // console.log("timeIf", timeIf);
            if (timeIf.time == 24) {
              let ifObj = {
                time: "24/7",
              };
              ifs.push({ scheduale: ifObj });
              // console.log("ifs: ", ifs);
            } else {
              let ifObj = {
                startTime: timeIf.start,
                endTime: timeIf.end,
                days: timeIf.days,
              };
              ifs.push({ scheduale: ifObj });
              // console.log("ifs: ", ifs);
            }
          }
        }
      });

      for (let i = 0; i < automation.thens.length; i++) {
        let thenValue = automation.thens[i].data;
        if (thenValue.type == "msg") {
          let nots = [];
          thenValue.parameter.split(",").forEach((not) => {
            nots.push(not);
          });
          let thenObj = {
            msg: thenValue.value,
            not: nots,
          };
          thens.push({ then_notify: thenObj });
        } else if (thenValue.type == "device") {
          let thenObj = {
            then_action: thenValue.value,
            then_controller: thenValue.device_id,
          };
          thens.push(thenObj);
        }
      }

      for (let i = 0; i < automation.ends.length; i++) {
        let endValue = automation.ends[i].data;
        if (endValue.type == "msg") {
          let nots = [];
          endValue.parameter.split(",").forEach((not) => {
            nots.push(not);
          });
          let endObj = {
            msg: endValue.value,
            not: nots,
          };
          ends.push({ end_notify: endObj });
        } else if (endValue.type == "device") {
          let endObj = {
            end_action: endValue.value,
            end_controller: endValue.device_id,
          };
          ends.push(endObj);
        }
      }

      state.automations[automation.uuid] = {
        name: automation.automation_name,
        // device: automation.if.device,
        uuid: automation.uuid,
        status: automation.status,
        automation_ifs: ifs,
        automation_thens: thens,
        automation_ends: ends,
      };
    }
    console.log("state.automations", state.automations);
  },
  ["NEW_AUTOMATION"](state, data) {
    console.log("data: ", data);
    // const uuid = createUUID();

    const automation = {
      name: data.auto.autoName,
      // device: data.auto.sensor,
      uuid: data.uuid,
      automation_ifs: data.auto.ifs /* {
        device: data.auto.sensor,
        sensor: data.auto.sensorDevice,
        state: data.auto.state,
        to_device: data.auto.state == "toDeviceTemp" ? data.auto.to_state : "",
        action: data.auto.if_action,
        required_param: data.auto.to_state,
      } */,
      automation_thens: data.auto.thens /* {
        sensor: data.auto.then_controller,
        action: data.auto.then_action,
      } */,
      automation_ends: data.auto.ends /* {
        sensor: data.auto.end_controller,
        action: data.auto.end_action,
      } */,
    };
    // console.log("uuid: ", uuid);
    state.automations[data.uuid] = automation;
    localStorage.setItem("automations", JSON.stringify([data.auto]));
  },
  ["DELETE_AUTOMATION"](state, data) {
    let automations = state.automations;
    if (state.automations[data.uuid]) {
      delete automations[data.uuid];
      this.dispatch("general/setAlert", {
        type: "success",
        msg: "auto-delete",
      });
      return automations;
    } else {
      this.dispatch("general/setAlert", {
        type: "failed",
        msg: "oops",
      });
    }
    state.automations = automations;
    localStorage.removeItem("automations");
  },
};

/**
 * Actions
 */
export const actions = {
  async getAutomations({ commit }) {
    await axios
      .get(URL + "auto/")
      .then((res) => {
        commit("SET_AUTOMATIONS", res.data);
      })
      .catch((err) => {
        console.log("ERROR IS: ", err);
      });
  },
  async setAutomation({ commit }, auto) {
    // console.log("auto: ", auto);

    await axios
      .post(URL + "auto/create-auto", auto)
      .then((res) => {
        // commit("NEW_AUTOMATION", { auto: auto, uuid: 0 });
        commit("NEW_AUTOMATION", { auto: auto, uuid: res.data });
        this.dispatch("general/setAlert", {
          type: "success",
          msg: "auto-create",
        });
      })
      .catch((err) => {
        console.log("ERROR IS: ", err);
        this.dispatch("general/setAlert", {
          type: "failed",
          msg: "oops",
        });
      });
  },
  async deleteAutomation({ commit }, uuid) {
    commit("DELETE_AUTOMATION", { uuid: uuid });
    // console.log(uuid);
    // await axios
    //   .post(`/auto/${uuid}/delete`, uuid)
    //   .then((res) => {
    //     // console.log(res.data);
    //     commit("DELETE_AUTOMATION", { uuid: uuid, automations: res.data });
    //     commit("SET_AUTOMATIONS", res.data);
    //     this.dispatch("general/setAlert", {
    //       type: "success",
    //       msg: "auto-create",
    //     });
    //   })
    //   .catch((err) => {
    //     console.log("ERROR IS: ", err);
    //     this.dispatch("general/setAlert", {
    //       type: "failed",
    //       msg: "oops",
    //     });
    //   });
  },
  async toggleAutomation({ commit }, auto) {
    // console.log("the auto is: ", auto);
    await axios
      .post(`/auto/${auto.uuid}/toggle`, auto)
      .then((res) => {
        // console.log("res is", res);
        commit("SET_AUTOMATIONS", res.data);
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  },
};

/**
 * Getters
 */
export const getters = {
  automations: (state) => state.automations,
};
