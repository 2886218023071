<template>
  <div class="chartcontainer w-100">
    <!-- type="line" -->
    <apexchart
      v-if="ready"
      height="300"
      width="100%"
      :options="chartOptions"
      :series="Object.values(series)"
    />
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import Lang from "@/helpers/lang";
// import dayjs from "@/plugins/dayjs";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: ["group-slug"],
  name: "LogsChart",
  data: function () {
    return {
      locale: Lang.get(),
      ready: false,
      chartOptions: {
        chart: {
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
          type: "line",
          stacked: false,
          zoom: {
            enabled: true,
            type: "x",
            autoScaleYaxis: false,
          },
          toolbar: {
            // autoSelected: "zoom",
            // offsetX: "30",
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        xaxis: {
          tickPlacement: "on",
          type: "datetime",
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: "yyyy",
              month: "MM/yy",
              day: "dd/MM/yy",
              hour: "HH:mm",
            },
          },
        },
        stroke: {
          width: 3,
          curve: "smooth",
        },
        tooltip: {
          onDatasetHover: {
            highlightDataSeries: true,
          },
          x: {
            show: true,
            format: "dd/MM/yy HH:mm",
          },
        },
        grid: {
          padding: {
            top: 10,
            right: -15,
            bottom: 0,
            left: 0,
          },
        },
        legend: {
          itemMargin: {
            vertical: 10,
          },
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          offsetY: 14,
          markers: {
            offsetX: 2,
            onClick: (chart, seriesIndex) => {
              console.log(seriesIndex);
            },
          },
          onItemClick: {
            toggleDataSeries: false,
          },
        },
      },
      series: {},

      yaxis: {},
    };
  },
  computed: {
    group() {
      return this.$store.getters["groups/group"](this.groupSlug);
    },
    hasAllLogs() {
      let has = 0;
      this.group.devices.forEach((slug) => {
        const deviceHasLogs = this.$store.getters["groups/hasDailyLogs"](slug);
        if (deviceHasLogs) has++;
      });
      return has == this.group.devices.length;
    },
    logs() {
      const logs = {};
      if (this.group && this.group.devices) {
        const devices = this.group.devices;
        for (let i = 0; i < devices.length; i++) {
          logs[devices[i]] = this.$store.getters["groups/deviceLogs"](
            devices[i]
          );
        }
      }
      return logs;
    },
    roundedTimeLogs() {
      const rlogs = {};
      Object.entries(this.logs).forEach((device) => {
        const logs = Object.values(device[1].data);
        let newDeviceLogs = {};
        logs.forEach((log) => {
          const fiveMins = 1000 * 60 * 5;
          const date = new Date(log.created_at);
          const rounded = new Date(
            Math.round(date.getTime() / fiveMins) * fiveMins
          );
          const newLog = {};
          if ("temperatures" in log) newLog.temperature = log.temperatures[0];
          if ("humidity" in log) newLog.humidity = log.humidity;
          if ("light" in log) newLog.light = log.light;
          if ("pressure" in log) newLog.pressure = log.pressure;
          if ("co2" in log) newLog.co2 = log.co2;
          newDeviceLogs[+rounded] = newLog;
          // console.log("time: ", +rounded);
        });
        rlogs[device[0]] = newDeviceLogs;
      });
      return rlogs;
    },
    averageLogs() {
      const logs = this.roundedTimeLogs;
      const combined = {};
      Object.values(logs).forEach((devicelogs) => {
        Object.entries(devicelogs).forEach((log) => {
          if (!(log[0] in combined)) combined[log[0]] = {};
          for (let i = 0; i < Object.keys(log[1]).length; i++) {
            const paramName = Object.keys(log[1])[i];
            const paramValue = Object.values(log[1])[i];
            if (!(paramName in combined[log[0]]))
              combined[log[0]][paramName] = [];
            combined[log[0]][paramName].push(paramValue);
          }
        });
      });
      const average = [];
      for (let i = 0; i < Object.keys(combined).length; i++) {
        const time = Object.keys(combined)[i];
        // console.log("time2: ", time, +new Date(parseInt(time)));
        const timeAverage = {
          time: parseInt(time),
        };
        const timeData = Object.values(combined)[i];
        for (let tdi = 0; tdi < Object.keys(timeData).length; tdi++) {
          const data = Object.values(timeData)[tdi].filter((d) => d);
          // console.log("data time: ", tdi, timeData);
          timeAverage[Object.keys(timeData)[tdi]] = parseFloat(
            (
              data.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
              data.length
            ).toFixed(2)
          );
        }
        this.logsSensors.forEach((s) => {
          if (!(s in timeAverage)) {
            timeAverage[s] = undefined;
          }
        });
        average.push(timeAverage);
      }
      return average.sort((a, b) => a.time - b.time);
    },
    logsSensors() {
      const logs = Object.values(this.logs)
        .map((d) => d.data)
        .flat();
      let sensors = [];
      const filterList = ["created_at", "type", "status"];
      for (const log in logs) {
        const logSensors = Object.keys(logs[log]);
        logSensors.forEach((s) => {
          if (s === "temperatures") s = "temperature";
          if (!filterList.includes(s) && !sensors.includes(s)) {
            sensors.push(s);
          }
        });
      }
      return sensors;
    },
  },

  mounted() {
    this.setYAxisData();
    setTimeout(() => {
      if (this.hasAllLogs) {
        this.setData();
      }
    }, 500);
  },
  watch: {
    hasAllLogs(has) {
      if (has) {
        this.setData();
      }
    },
  },
  methods: {
    setData() {
      if (this.averageLogs) {
        if (this.hasSensor("temperature")) {
          this.series.temperature = {
            name: this.$t("temperature"),
            data: [],
          };
        }
        if (this.hasSensor("humidity")) {
          this.series.humidity = {
            name: this.$t("humidity"),
            data: [],
          };
        }
        let lowestTemp = 99999;
        let lowestHumidity = 100;
        for (let i = 0; i < this.averageLogs.length; i++) {
          if (this.hasSensor("temperature")) {
            // console.log(
            //   "time: ",
            //   +new Date(this.averageLogs[i].time.valueOf()),
            //   this.averageLogs[i].time.valueOf()
            // );
            this.series.temperature.data.push([
              this.averageLogs[i].time.valueOf(),
              this.averageLogs[i].temperature,
            ]);
            if (this.averageLogs[i].temperature < lowestTemp) {
              lowestTemp = this.averageLogs[i].temperature;
            }
          }
          if (this.hasSensor("humidity")) {
            this.series.humidity.data.push([
              this.averageLogs[i].time.valueOf(),
              this.averageLogs[i].humidity,
            ]);

            if (this.averageLogs[i].humidity < lowestHumidity) {
              lowestHumidity = this.averageLogs[i].humidity;
            }
          }
        }
        this.yaxis.temperatures.min = lowestTemp - 1;
        this.yaxis.humidity.min =
          lowestHumidity > 1 ? lowestHumidity - 1 : lowestHumidity;
        this.chartOptions.yaxis = [];
        if (this.hasSensor("temperature")) {
          this.chartOptions.yaxis.push(this.yaxis.temperatures);
        }
        if (this.hasSensor("humidity")) {
          this.chartOptions.yaxis.push(this.yaxis.humidity);
        }
        this.ready = true;
      }
    },
    setYAxisData() {
      this.yaxis = {
        temperatures: {
          seriesName: this.$t("temperature"),
          axisBorder: {
            show: false,
            color: "#008FFB",
          },
          labels: {
            formatter: (v) => parseFloat(v).toFixed(2) + "°C",
            align: "center",
            // offsetX: this.locale == "he" ? 26 : -20,
            offsetX: -15,
            style: {
              colors: "#008FFB",
            },
          },
        },
        humidity: {
          seriesName: this.$t("humidity"),
          opposite: this.yaxis.temperatures ? false : true,
          axisBorder: {
            show: false,
            color: "#00B150",
          },
          labels: {
            formatter: (v) => (!isNaN(v) ? v.toFixed(2) + "%" : ""),
            align: "center",
            // offsetX: this.locale == "he" ? 8 : -30,
            offsetX: -30,
            style: {
              colors: "#00B150",
            },
          },
        },
      };
    },
    hasSensor(sensor) {
      return this.logsSensors.includes(sensor);
    },
  },
};
</script>
<style lang="scss" scoped>
.chartcontainer {
  // margin: 0 16px;
  background-color: #fff;
  border-radius: 5px;
  padding: 8px;
  box-sizing: content-box;
}
</style>
