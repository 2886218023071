<style lang="css" scoped></style>

<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="400"
  >
    <v-card>
      <v-layout align-center justify-space-between row fill-height>
        <v-card-title class="headline text-xs-left" shrink>{{
          content.edit
        }}</v-card-title>
        <v-btn icon text @click="clearAll()">
          <Icon icon="mdiClose" />
        </v-btn>
      </v-layout>

      <v-layout row wrap v-if="!loading">
        <!-- Hours -->
        <v-layout row wrap justify-space-between class="px-3">
          <!-- Start At -->
          <v-flex xs5>
            <v-dialog
              ref="dialogStart"
              v-model="startAt"
              :return-value.sync="schedule.start_at"
              persistent
              width="400px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="schedule.start_at"
                  :label="content.start_at"
                  :prepend-icon="access_time"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="schedule.start_at"
                v-model="schedule.start_at"
                :max="schedule.end_at === '00:00' ? '23:59' : schedule.end_at"
                format="24hr"
              >
                <v-btn
                  text
                  dark
                  color="switchq"
                  @click="$refs.dialogStart.save(schedule.start_at)"
                  >{{ content.save }}</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn text @click="startAt = false">{{
                  content.cancel
                }}</v-btn>
              </v-time-picker>
            </v-dialog>
          </v-flex>

          <!-- End At -->
          <v-flex xs5>
            <v-dialog
              ref="dialogEnd"
              v-model="endAt"
              :return-value.sync="schedule.end_at"
              persistent
              width="400px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="schedule.end_at"
                  :label="content.end_at"
                  :prepend-icon="access_time"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="schedule.end_at"
                v-model="schedule.end_at"
                :allowed-hours="allowedEndAtHours"
                :allowed-minutes="allowedEndAtMinutes"
                format="24hr"
              >
                <v-btn
                  text
                  dark
                  color="switchq"
                  @click="$refs.dialogEnd.save(schedule.end_at)"
                  >{{ content.save }}</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn text @click="endAt = false">{{ content.cancel }}</v-btn>
              </v-time-picker>
            </v-dialog>
          </v-flex>

          <v-flex v-if="fullday" xs12 class="pb-3 text-xs-center orange--text">
            <small>{{ content.fullday }}</small>
          </v-flex>
        </v-layout>

        <!-- Days -->
        <v-layout row wrap justify-center class="px-3">
          <v-flex
            class="text-xs-center mx-2"
            shrink
            v-for="(day, index) in daysOptions"
            :key="index"
          >
            <v-checkbox
              class="ma-0 pa-0"
              color="blue-grey darken-3"
              v-model="selectedDays"
              :label="day"
              :value="index + 1"
            ></v-checkbox>
          </v-flex>
        </v-layout>

        <!-- Action Buttons -->
        <v-layout row wrap justify-space-between class="px-3">
          <v-flex xs12>
            <v-layout v-if="deleteValidation" row justify-end>
              <span>
                {{ content.ask_delete }}
              </span>
            </v-layout>
          </v-flex>

          <v-flex shrink>
            <v-btn color="switchq switchqSub--text" @click="save()">{{
              content.save
            }}</v-btn>
          </v-flex>

          <v-flex shrink>
            <v-btn v-if="edit" color="red" dark @click="deleteSched()">{{
              content.delete
            }}</v-btn>
            <v-btn v-else text @click="clearAll()">{{ content.cancel }}</v-btn>
            <v-btn v-if="deleteValidation" @click="deleteValidation = false">{{
              content.no
            }}</v-btn>
          </v-flex>
        </v-layout>

        <!-- End of Dialog Content -->
      </v-layout>

      <!-- Loading -->
      <v-layout v-else row wrap justify-center class="pa-5">
        <v-progress-circular
          :size="70"
          color="switchq"
          indeterminate
        ></v-progress-circular>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClockOutline } from "@mdi/js";

export default {
  props: ["locale", "status", "data"],
  data() {
    return {
      access_time: mdiClockOutline,
      dialog: false,
      schedule: {
        days: null,
        start_at: "00:00",
        end_at: "00:00",
      },
      selectedDays: [1, 2, 3, 4, 5, 6, 7],
      edit: false,
      startAt: false,
      endAt: false,
      deleteValidation: false,
      loading: false,
    };
  },
  created() {
    this.setLang();
    // EventBus.$on("updateItemsBus", (data) => {
    //   if (data.modified == this.$route.params.slug && this.loading) {
    //     this.clearAll();
    //   }
    // });
  },
  computed: {
    fullday: function () {
      if (
        this.schedule.start_at == "00:00" &&
        this.schedule.end_at == "00:00"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    status() {
      this.clearAll();
      if (this.status) {
        this.dialog = true;
        if (this.data) {
          this.dialog = true;
          // this.schedule = this.data;
          this.schedule = { ...this.data };
          this.selectedDays = this.schedule.days;
          this.edit = true;
        }
      }
    },
    selectedDays() {
      this.schedule.days = this.selectedDays;
    },
    "schedule.end_at": function (v) {
      if (v.split(":")[0] == 0) {
        this.schedule.end_at = "00:00";
      }
    },
  },
  methods: {
    save() {
      this.send();
    },
    deleteSched() {
      if (this.deleteValidation) {
        this.schedule.delete = true;
        this.send();
      } else {
        this.deleteValidation = true;
      }
    },
    clearAll() {
      this.dialog = false;
      this.schedule = {
        days: null,
        start_at: "00:00",
        end_at: "00:00",
      };
      this.selectedDays = [1, 2, 3, 4, 5, 6, 7];
      this.edit = false;
      this.startAt = false;
      this.endAt = false;
      this.deleteValidation = false;
      this.loading = null;
    },
    send() {
      this.loading = true;
      this.$emit("modifyScheduleEvent", this.schedule);
    },
    allowedEndAtHours(v) {
      const startAtH = this.schedule.start_at.split(":")[0];
      return v >= startAtH || v == 0;
    },
    allowedEndAtMinutes(v) {
      const startAtH = this.schedule.start_at.split(":")[0];
      const startAtM = this.schedule.start_at.split(":")[1];
      const endAtH = this.schedule.end_at.split(":")[0];
      if (startAtH == endAtH) {
        return v > startAtM;
      } else {
        return v >= 0 && v <= 59;
      }
    },

    setLang() {
      if (this.locale == "en") {
        this.days = [null, "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        this.daysOptions = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        this.content = {
          schedule: "Schedule",
          new_schedule: "New Schedule",
          all_days: "All Days",
          edit: "Edit Schedule",
          agree: "Agree",
          save: "Save",
          cancel: "Cancel",
          start_at: "Start at",
          end_at: "End at",
          days: "Days",
          delete: "Delete",
          ask_delete: "Are you sure you would like to delete?",
          yes: "Yes",
          no: "No",
          fullday: "Attention! 00:00-00:00 will cause 24 hours of work.",
        };
      } else if (this.locale == "he") {
        this.days = [
          null,
          "ראשון",
          "שני",
          "שלישי",
          "רביעי",
          "חמישי",
          "שישי",
          "שבת",
        ];
        this.daysOptions = [
          "ראשון",
          "שני",
          "שלישי",
          "רביעי",
          "חמישי",
          "שישי",
          "שבת",
        ];
        this.content = {
          schedule: "לוח זמנים",
          new_schedule: "תזמון חדש",
          all_days: "כל הימים",
          edit: "עריכת תזמון",
          agree: "מסכים",
          save: "שמירה",
          cancel: "ביטול",
          start_at: "מתחיל ב-",
          end_at: "נגמר ב-",
          days: "ימים",
          delete: "מחיקה",
          ask_delete: "אתה בטוח שברצונך למחוק?",
          yes: "כן",
          no: "לא",
          fullday: "שים לב! 00:00-00:00 יגרום לעבודה של 24 שעות.",
        };
      }
    },
  },
};
</script>
