<template>
  <v-app id="mainWrap">
    <topbar v-if="login" :swipDisplay="displayBackBtn" />
    <sidemenu v-if="login" />
    <v-main id="scroll-target" v-scroll.self="onScroll">
      <router-view v-if="showPages" />
      <loading v-else />
    </v-main>
    <Toaster />
    <NotificationAlerts />
    <ChangePassModal :activator="changePass" @close="closeModal" />
    <PushNotificationsDialog v-if="login" />
  </v-app>
</template>

<script>
import ChangePassModal from "@/components/general/ChangePassModal.vue";
import Sidemenu from "@/components/app_parts/sidemenu.vue";
import Topbar from "@/components/app_parts/topbar.vue";
import lang from "./helpers/lang";
import socketMixin from "@/mixins/socketMixin";
import clientDeviceMixin from "@/mixins/clientDeviceMixin";
import auth from "@/helpers/auth";
import Toaster from "@/components/app_parts/Toaster.vue";
import NotificationAlerts from "@/components/app_parts/NotificationAlerts.vue";
import PushNotificationsDialog from "@/components/tools/PushNotificationsDialog.vue";
import loading from "./components/tools/loading.vue";
import axios from "@/plugins/axios";
export default {
  name: "App",
  components: {
    Topbar,
    Sidemenu,
    Toaster,
    NotificationAlerts,
    PushNotificationsDialog,
    loading,
    ChangePassModal,
  },
  mixins: [socketMixin, clientDeviceMixin],
  data() {
    return {
      initiated: false,
      intervals: {},
      pulled: 0,
      scrollInterval: null,
      scrollY: 0,
      lastScrollTop: 0,
      loadingIcon: "mdi-reload",
      displayBackBtn: true,
      changePass: false,
      scroll: 0,
      topConfig: {
        pullText: this.$t("pullTo"),
        triggerText: this.$t("releaseTo"),
        loadingText: this.$t("refresh"),
        failText: this.$t("refreshFail"),
        triggerDistance: 95,
      },
    };
  },
  computed: {
    showPages() {
      const token = this.token;
      const checked = this.checked;
      const loadingDevices = this.loadingDevices;
      return (token && checked && !loadingDevices) || !token;
    },
    login() {
      return this.$store.getters["user/valid"];
    },
    token() {
      return this.$store.getters["user/token"];
    },
    checked() {
      return this.$store.getters["user/checked"];
    },
    loadingDevices() {
      return this.$store.getters["devices/loading"];
    },
  },
  watch: {
    login(v) {
      if (v) {
        this.setHasTopBarClass();
        this.initiate();
        this.inGroup();
      } else {
        this.removeHasTopBarClass();
      }
    },
    scrollY() {
      if (this.scrollY < 40) {
        this.displayBackBtn = true;
      }
      this.onScroll();
    },
    $route() {
      if (this.inGroup()) {
        localStorage.setItem("inGroup", true);
      } else {
        localStorage.setItem("inGroup", false);
      }
    },
  },
  async created() {
    this.fixLocale();
    if (this.token) {
      await this.$store.dispatch("user/get");
      if (!this.login) {
        this.logOut();
      } else {
        this.initiate();
      }
    } else {
      localStorage.removeItem("inGroup");
      localStorage.removeItem("mydata");
      localStorage.removeItem("mydataview");
    }
    this.isCustomApp();
  },
  mounted() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.scrollInterval = setInterval(() => {
        this.scrollY = window.scrollY;
      }, 100);
    }
    let darkmode = localStorage.getItem("dark");
    darkmode && darkmode == "1"
      ? (this.$vuetify.theme.dark = true)
      : (this.$vuetify.theme.dark = false);
    this.fixLocale();
    if (this.login) {
      this.setHasTopBarClass();
    } else {
      this.removeHasTopBarClass();
    }
  },
  destroyed() {
    for (const interval in this.intervals) {
      clearInterval(this.intervals[interval]);
    }
    this.intervals = {};
    clearInterval(this.scrollInterval);
  },
  methods: {
    closeModal() {
      localStorage.removeItem("first_login");
      this.changePass = false;
    },
    inGroup() {
      let inGroup = !!this.$route.params.gslug;
      if (inGroup) {
        localStorage.setItem("inGroup", true);
      }
      localStorage.setItem("inGroup", false);
      return inGroup;
    },
    onScroll() {
      if (this.$vuetify.breakpoint.smAndDown) {
        let st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > this.lastScrollTop) {
          this.displayBackBtn = false;
          this.lastScrollTop = st <= 0 ? 0 : st;
        } else if (
          window.innerHeight + window.scrollY >=
          document.body.offsetHeight
        ) {
          this.displayBackBtn = false;
          this.lastScrollTop = st <= 0 ? 0 : st;
        } else {
          this.displayBackBtn = true;
          this.lastScrollTop = st <= 0 ? 0 : st;
        }
        if (
          window.innerHeight + window.scrollY <= document.body.offsetHeight ==
            false &&
          window.scrollY < 30
        ) {
          this.displayBackBtn = true;
          this.lastScrollTop = st <= 0 ? 0 : st;
        }
      }
    },
    isCustomApp() {
      axios
        .get("/app")
        .then((res) => {
          document.title = res.data.data.title;
          if (res.data.data.custom) {
            localStorage.setItem("customApp", true);
          }
          this.$store.dispatch("general/setCustomApp", res.data.data.custom);
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    stateChange(state) {
      if (state === "pull") {
        this.loadingIcon = "mdi-reload mdi-24px";
      } else {
        this.loadingIcon = "mdi-reload mdi-spin mdi-24px";
      }
    },
    initiate() {
      if (!this.initiated) {
        this.$store.dispatch("devices/fetchDevices");
        this.$store.dispatch("groups/getGroups");
        this.$store.dispatch("business/get");
        this.$store.dispatch("devices/getGatewaysNames");
        // this.$store.dispatch("automations/getAutomations");
        let changePass = localStorage.getItem("first_login");
        if (changePass && changePass == true) {
          this.changePass = true;
        } else if (changePass) {
          localStorage.removeItem("first_login");
        }
        setInterval(() => {
          this.$store.dispatch("devices/reanalyzeDevices");
        }, 10000);
        this.initiated = true;
      }
    },
    fixLocale() {
      this.$i18n.locale = lang.get();
    },
    setHasTopBarClass() {
      document.getElementById("body").classList.add("has-topbar");
    },
    removeHasTopBarClass() {
      document.getElementById("body").classList.remove("has-topbar");
    },
    logOut() {
      localStorage.removeItem("mydata");
      localStorage.removeItem("mydataview");
      localStorage.removeItem("customApp");
      auth.logout();
      this.$router.push("/login");
    },
  },
};
</script>
