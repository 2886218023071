import Auth from "./auth";

class Guard {
  user(to, from, next) {
    Auth.logged() ? next() : next("/login");
  }

  guest(to, from, next) {
    !Auth.logged() ? next() : next("/");
  }

  validUser() {
    return Auth.logged();
  }
}
export default new Guard();
