<template>
  <div class="alerts">
    <div v-for="(alert, i) in alerts" :class="'toaster ' + alert.type" :key="i">
      <v-snackbar
        v-model="snackbar"
        class="snack"
        :timeout="timeout"
        :color="
          alert.type == 'success'
            ? 'switchq lighten-1 switchqSub--text'
            : 'red darken-3'
        "
      >
        <v-layout row wrap justify-center>
          {{ $t(alert.msg) }}
        </v-layout>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
export default {
  name: "Toaster",
  data() {
    return {
      snackbar: true,
      timeout: 3000,
    };
  },
  computed: {
    alerts() {
      return this.$store.getters["general/alerts"];
    },
    // data() {
    //   return console.log(this.props);
    // },
  },
};
</script>
<style scoped lang="scss">
// .alerts {
//   position: fixed;
//   // margin: 0 auto;
//   top: 150px;
//   left: 50%;
//   transform: translate(-50%, -50%);
// .toaster {
//   width: 300px;
//   font-weight: 800;
//   margin: 0 auto;
//   color: rgb(3, 0, 0);
//   text-align: center;
//   border-radius: 8px;
//   padding: 16px;
//   z-index: 1;
//   font-size: 17px;
// }
// }
.snack {
  justify-content: center;
}

.success {
  -webkit-animation: fadein 1.8s, fadeout 1s 4s;
  animation: fadein 1.8s, fadeout 1s 4s;
  // background-color: rgb(64, 224, 64);
}
.failed {
  -webkit-animation: fadein 1.8s, fadeout 1s 4s;
  animation: fadein 1.8s, fadeout 1s 4s;
  background-color: rgb(143, 63, 63);
}
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}
@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
</style>
