/* eslint-disable no-console */

import { register } from "register-service-worker";

// if (process.env.NODE_ENV === "production") {
register(`${process.env.BASE_URL}service-worker.js`, {
  scope: "/",
  ready() {
    // console.log("ServiceWorker is ready!");
  },
  registered() {
    // console.log("ServiceWorker has been registered.");
  },
  cached() {
    // console.log("ServiceWorker has been cached for offline use.");
  },
  updatefound() {
    // console.log("ServiceWorker - New content is downloading.");
  },
  updated() {
    // console.log("ServiceWorker - New content is available; please refresh.");
  },
  offline() {
    // console.log(
    //   // "ServiceWorker - No internet connection found. App is running in offline mode."
    // );
  },
  error() {
    // console.error(
    //   "ServiceWorker - Error during service worker registration:",
    //   error
    // );
  },
});

if ("serviceWorker" in navigator) {
  // navigator.serviceWorker.register(`${process.env.BASE_URL}push-sw.js`)
  navigator.serviceWorker
    .register(`${process.env.BASE_URL}push-sw.js`, {
      scope: "/push-notifications",
    })
    .then(
      () => {
        // console.log("Service worker registration succeeded:", registration);
      },
      /*catch*/ () => {
        // console.error(`Service worker registration failed: ${error}`);
      }
    );
}

// }
