<style lang="css" scoped>
.settingsBtn {
  width: 300px;
  /* max-width: 100%; */
  max-width: 100%;
}

@media screen and (max-width: 960px) {
  .fullHeight {
    min-height: 100vh;
  }
}
</style>

<template>
  <v-dialog
    v-model="dialog"
    max-width="550"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <template v-slot:activator="{ on }">
      <v-btn dark color="grey darken-2" v-on="on" class="mx-3 settingsBtn">
        <Icon :iconClass="'mx-2'" icon="settingsIcon" :dir="'right'" />
        {{ content.settings }}
      </v-btn>
    </template>
    <v-card>
      <v-form @submit.prevent="save()">
        <v-layout column wrap class="fullHeight">
          <v-card-title class="headline text-xs-center">
            <v-layout row wrap>
              <v-flex grow>
                <Icon :iconClass="'mx-2'" icon="settingsIcon" :dir="'right'" />
                {{ content.settings }}
              </v-flex>
              <v-flex shrink>
                <Icon
                  :iconClass="'mx-2 pointer'"
                  icon="mdiClose"
                  @click.native="dialog = false"
                />
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-card-text grow>
            <v-layout row wrap>
              <v-text-field
                counter
                maxlength="25"
                :label="content.name"
                :hint="content.name_max_length"
                v-model="edit.name"
                :rules="rules.name"
              ></v-text-field>
            </v-layout>
            <v-spacer></v-spacer>
            <v-layout row wrap justify-center>
              {{ content.once }} {{ formatTime(cycle) }}
            </v-layout>
            <v-layout
              v-if="device.data.sensor_type == 14"
              row
              wrap
              justify-center
            >
              <v-flex xs12>
                <h4>{{ content.role }}:</h4>
              </v-flex>
              <v-flex xs8>
                <v-radio-group v-model="edit.settings.sensor_type" row>
                  <v-radio
                    class="radio"
                    color="primary"
                    :label="content.water"
                    :value="10"
                  ></v-radio>
                  <v-radio
                    class="radio"
                    color="primary"
                    :label="content.dry"
                    :value="14"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-layout v-if="device.data.sensor_type == 11" row>
              <v-flex>
                <h4>{{ content.role }}:</h4>
                <v-flex>
                  <v-radio-group v-model="edit.settings.sensor_type" row>
                    <v-radio
                      class="radio"
                      color="primary"
                      :label="content.magnet"
                      :value="11"
                    ></v-radio>
                    <v-radio
                      class="radio"
                      color="primary"
                      :label="content.door"
                      :value="12"
                    ></v-radio>
                    <v-radio
                      class="radio"
                      color="primary"
                      :label="content.window"
                      :value="13"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-flex>
            </v-layout>
            <v-layout
              v-if="(type == 1 && !!sensors) || (!sensors && type > 1)"
              row
              wrap
            >
              <v-flex d-flex grow align-center align-content-center>
                <h4>{{ content.role }}:</h4>
              </v-flex>
              <v-flex shrink>
                <v-radio-group v-model="edit.role" row>
                  <v-radio
                    v-if="!type || type === 1"
                    class="radio"
                    color="primary"
                    :label="content.controller"
                    value="1"
                  ></v-radio>
                  <v-radio
                    class="radio"
                    color="primary"
                    :label="content.sensors"
                    value="0"
                  ></v-radio>
                  <v-radio
                    v-if="!sensors"
                    class="radio"
                    color="primary"
                    :label="content.location"
                    value="2"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <template v-if="temperatures && device.settings.sensor_type == 1">
              <v-divider></v-divider>
              <v-layout row wrap justify-center>
                <v-flex shrink>
                  <v-switch v-model="edit.settings.single_temp" class="pt-2">
                    <template v-slot:label>
                      <strong>{{ content.single_temp }}</strong>
                    </template>
                  </v-switch>
                </v-flex>
              </v-layout>
            </template></v-card-text
          >
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-layout row wrap justify-space-between>
              <v-flex shrink>
                <v-btn
                  type="submit"
                  color="primary"
                  :loading="loading"
                  shrink
                  @click="save"
                  >{{ content.save }}</v-btn
                >
              </v-flex>
              <v-flex shrink>
                <Delete :slug="slug" @toggleSettings="toggle" shrink />
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-layout>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Delete from "./itemdelete";
import Lang from "@/helpers/lang";
// import moment from "moment";

export default {
  props: ["name", "role", "slug", "type"],
  components: { Delete },
  data() {
    return {
      locale: null,
      textDir: "text-xs-right",
      dialog: false,
      // dialog: true,
      loading: null,

      edit: { settings: {} },
      rules: {
        name: [
          (v) => !!v || this.content.rules.name[0],
          (v) => (v && v.length <= 25) || this.content.rules.name[1],
        ],
        ttl: [
          (v) => v >= 1 || this.content.rules.ttl[0],
          (v) => (v && v <= 2880) || this.content.rules.ttl[1],
        ],
        exceeds: [
          (v) => v >= 1 || this.content.rules.exceeds[0],
          (v) => (v && v <= 1000) || this.content.rules.exceeds[1],
        ],
      },
    };
  },
  created() {
    this.locale = Lang.get();
    this.setLang();
    this.textDir = this.locale == "he" ? "text-xs-left" : this.textDir;
  },
  mounted() {
    this.edit = {
      name: this.name,
      role: this.role + "",
      settings: {
        ttl: this.settings.ttl,
        exceeds: this.settings.exceeds,
        push: this.settings.push,
        emails: this.settings.emails,
        sms: this.settings.sms,
        single_temp: this.settings.single_temp,
        sensor_type: this.settings.sensor_type,
      },
    };
  },
  computed: {
    user: function () {
      return this.$store.getters["user/user"];
    },
    pushable: function () {
      return this.$store.getters["user/pushable"];
    },
    settings: function () {
      return this.device.settings;
    },
    devmode: function () {
      return this.device.data.devmode;
    },
    sensors: function () {
      return this.device.settings.sensor_type;
    },
    device: function () {
      return this.$store.getters["devices/device"](this.slug);
    },
    temperatures: function () {
      const deviceData = this.$store.getters["devices/device"](this.slug).data;
      if (deviceData && deviceData.sensors && deviceData.sensors.temperatures) {
        const temps = deviceData.sensors.temperatures;
        if (this.settings.single_temp) {
          return temps && temps.length > 0;
        } else {
          return temps && temps.length > 1;
        }
      }
      return false;
    },
    exceedtime: function () {
      return this.formatTime(this.edit.settings.exceeds * this.cycle);
    },
    cycle: function () {
      if (this.settings && this.settings.cycle) {
        return this.$store.getters["devices/device"](this.slug).settings.cycle;
      } else {
        return null;
      }
    },
  },
  methods: {
    async save() {
      this.loading = true;
      let data = {
        slug: this.slug,
        action: "edit",
        modify: {
          name: this.edit.name,
          role: this.edit.role,
          settings: this.edit.settings,
        },
      };
      this.$store.dispatch("devices/modifyDevice", data).then(() => {
        this.dialog = false;
        this.loading = false;
        // window.location.reload();
      });
    },
    toggle(status) {
      this.dialog = status;
    },
    formatTime(toFormat) {
      if (toFormat) {
        let hours = toFormat > 3600 ? Math.floor(toFormat / 3600) : 0;
        let minutes;
        let seconds;
        if (hours < 24) {
          minutes =
            toFormat % 3600 >= 60 ? Math.floor((toFormat % 3600) / 60) : 0;
          if (hours > 1) {
            if (Math.floor((toFormat % 3600) / 60) > 30) {
              minutes++;
            }
          } else {
            seconds = toFormat % 60 ? toFormat % 60 : 0;
          }
        } else {
          if (Math.floor((toFormat % 3600) / 60) > 30) {
            hours++;
          }
        }
        let time = "";
        if (hours) time = hours + " " + this.content.hours;
        if (minutes)
          time +=
            " " +
            (hours ? this.content.and : "") +
            minutes +
            " " +
            this.content.minutes;
        if ((minutes >= 2 && seconds > 5) || (minutes < 2 && seconds)) {
          time +=
            " " +
            (minutes ? this.content.and : "") +
            seconds +
            " " +
            this.content.seconds;
        }
        return time;
      } else {
        return "";
      }
    },
    setLang() {
      if (this.locale == "en") {
        this.content = {
          once: "Transmition cycle ≈ ",
          save: "Save",
          cancel: "Cancel",
          close: "Close",
          settings: "Settings",
          name: "Unit's Name",
          name_max_length: "Max length 25 characters",
          role: "Unit's Role",
          controller: "Controller",
          sensors: "Sensors",
          location: "Location",
          magnet: "Magnet",
          water: "Water",
          dry: "Dry contact",
          door: "Door",
          window: "Window",
          single_temp: "Main temperature only",
          notifications: "Notifications",
          push_nots: "Push Notifications",
          email_nots: "Emails",
          sms_nots: "SMS",
          ttl: "Time to notify of disconnection (minutes)",
          thresholdexceed:
            "A number of broadcasts that exceed the threshold for issuing an alert",
          notrecommended: "It is not recommended to change",
          broadcasts: "broadcasts",
          broadcast: "Broadcasts cycle",
          seconds: "seconds",
          minutes: "minutes",
          hours: "hours",
          and: "and ",
          rules: {
            name: [
              "Name is required",
              "The name cannot be longer than 25 characters",
            ],
            ttl: ["Can't be smaller than 1", "Can't be bigger than 2,880"],
            exceeds: ["Can't be smaller than 1", "Can't be bigger than 20"],
          },
        };
      } else if (this.locale == "he") {
        this.content = {
          once: "מחזור שידור ≈",
          save: "שמירה",
          cancel: "ביטול",
          close: "סגירה",
          settings: "הגדרות",
          name: "שם יחידה",
          name_max_length: "מקסימום של 25 תווים",
          role: "תפקיד היחידה",
          controller: "בקר",
          sensors: "חיישנים",
          location: "מיקום",
          magnet: "מגנט",
          water: "הצפה",
          dry: "מגע יבש",
          door: "דלת",
          window: "חלון",
          single_temp: "טמפרטורה ראשית בלבד",
          notifications: "התראות",
          push_nots: "התראות דחיפה",
          email_nots: "אימייל",
          sms_nots: "SMS",
          ttl: "זמן להתראה לניתוק תקשורת (דקות)",
          thresholdexceed: "מספר שידורים שחורגים בסף להוצאת התראה",
          notrecommended: "לא מומלץ לשנות",
          broadcasts: "שידורים",
          broadcast: "מחזור שידור",
          seconds: "שניות",
          minutes: "דקות",
          hours: "שעות",
          and: "ו-",
          rules: {
            name: ["חובה למלא שם", "השם לא יכול להיות ארוך מ-25 תווים"],
            ttl: ["לא יכול להיות קטן מ-1", "לא יכול להיות גדול מ-2,880"],
            exceeds: ["לא יכול להיות קטן מ-1", "לא יכול להיות גדול מ-1000"],
          },
        };
      }
    },
  },
};
</script>
