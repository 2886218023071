<style lang="scss" scoped>
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #eeeeee;
  margin: 1em 0;
  padding: 0;
}
strong {
  font-size: 120%;
}
.rssi small,
.rssi strong {
  line-height: 200%;
}
</style>

<template>
  <v-flex v-if="role !== 2" grow>
    <v-divider />
    <h4 class="pa-0 ma-0 text-center grey--text text--darken-2">RSSI:</h4>
    <v-layout row wrap>
      <v-flex grow class="mt-2">
        <v-layout row wrap justify-space-around class="px-2">
          <v-flex
            v-for="(rssi, mac) in latestRssis"
            :key="mac"
            shrink
            align-content-center
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="rssi mx-2" dir="ltr">
                  <small
                    class="ms-2 pointer"
                    @click="$router.push(`/gateways/${mac}`)"
                    >{{
                      /* gatewaysNames.length
                      ? gatewaysNames.filter((g) => g.gateway == mac).name
                      : mac */
                      gatewayName ? gatewayName[mac] : mac
                    }}:</small
                  >
                  <span :class="getDbmColor(rssi.rssi)">
                    <strong dir="ltr">{{ rssi.rssi }}</strong>
                    <small>dBm</small>
                  </span>
                </div>
              </template>
              <span>{{ formatTime(rssi.received_at) }} </span> &nbsp;
              <span>{{ gatewayName ? gatewayName[mac] : mac }}</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-flex>

  <v-flex v-else-if="Object.keys(gateways).length" class="mt-5 mb-3">
    <v-layout v-if="!active" column justify-center class="mb-2">
      <h2 class="text-xs-center red--text">{{ this.undetected }}</h2>
      <h3 class="text-xs-center">{{ this.lastSeen }}</h3>
    </v-layout>
    <v-layout column justify-content-start>
      <v-flex v-for="(rssi, mac) in latestRssis" :key="mac">
        <v-alert v-if="!rssi.status" :value="true" type="error">
          <v-layout row wrap justify-center class="black--text">
            <v-flex shrink class="mx-2">
              <strong v-if="gateways && gatewayName">
                {{ gatewayName[mac] }}
              </strong>
              <strong v-else>
                {{ mac }}
              </strong>
              <small v-if="gateways && gatewayName.length != 0">
                &nbsp;({{ mac }})&nbsp;
              </small>
              <strong>:</strong>
            </v-flex>
            <v-flex shrink class="mx-2" align-self-end>
              <small class="black--text">
                <strong dir="ltr">{{ rssi.rssi }}</strong>
                <small>dBm</small>
              </small>
            </v-flex>
            <v-flex shrink class="mx-2">
              <strong> נמצא באזור אסור! </strong>
            </v-flex>
          </v-layout>
        </v-alert>
        <v-layout
          v-else
          row
          wrap
          justify-center
          :class="rssi.inPlace ? 'green--text text--darken-2 mb-3' : 'mb-3'"
        >
          <v-flex shrink class="mx-2">
            <strong v-if="gatewayName">
              {{ gatewayName[mac] }}
            </strong>
            <strong v-else>
              {{ mac }}
            </strong>
            <small v-if="gateways[mac]"> &nbsp;({{ mac }})&nbsp; </small>
            <strong>:</strong>
          </v-flex>
          <v-flex shrink class="mx-2" align-self-end>
            <small :class="getDbmColor(rssi.rssi)">
              <strong dir="ltr">{{ rssi.rssi }}</strong>
              <small>dBm</small>
            </small>
          </v-flex>
          <v-flex shrink class="mx-2">
            <strong>
              {{ getDbmTitle(rssi) }}
            </strong>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import dayjs from "@/plugins/dayjs";
export default {
  name: "rssi",
  props: ["slug"],
  data() {
    return {
      expand: false,
      dbmTitles: [],
    };
  },
  computed: {
    defaultExpanded() {
      const email = this.$store.getters["user/email"];
      if (email) {
        return email.includes("@switchq.co.il");
      } else {
        return null;
      }
    },
    ttl() {
      return this.$store.getters["devices/device"](this.slug).settings.ttl;
    },
    cycle() {
      return this.$store.getters["devices/device"](this.slug).settings.cycle;
    },
    rssis() {
      return this.$store.getters["devices/device"](this.slug).data.signal;
      //  let signals = this.$store.getters["devices/device"](this.slug).data
      //   .signal;
      // return Object.values(signals).filter((s) => {
      //   return this.isOlderThan40Minutes(s.received_at);
      // });
    },
    latestRssis() {
      let rssis = this.rssis;
      const filterByReceivedAt = (obj) => {
        const now = new Date();
        const fortyMinutesAgo = new Date(now.getTime() - 40 * 60 * 1000);

        const result = {};

        for (const key in obj) {
          if (obj[key].received_at) {
            const receivedAtDate = new Date(obj[key].received_at);
            if (receivedAtDate > fortyMinutesAgo) {
              result[key] = obj[key];
            }
          }
        }

        return result;
      };

      const filteredData = filterByReceivedAt(rssis);
      // console.log(filteredData);
      return filteredData;
      // return this.$store.getters["devices/device"](this.slug).data.signal;
      //  let signals = this.$store.getters["devices/device"](this.slug).data
      //   .signal;
      // return Object.values(signals).filter((s) => {
      //   return this.isOlderThan40Minutes(s.received_at);
      // });
    },
    active() {
      return this.$store.getters["devices/device"](this.slug).data.active;
    },
    role() {
      return this.$store.getters["devices/device"](this.slug).role;
    },
    gateways() {
      this.$store.dispatch("devices/getGatewaysNames");
      let gateways = this.latestRssis;
      // console.log("gateways: ", gateways);
      return gateways;
    },
    gatewayName() {
      let names = this.$store.getters["devices/gNames"];
      let gateNames = [];
      if (names.length && this.latestRssis) {
        for (const key in this.latestRssis) {
          gateNames[key] = names.filter((n) => {
            return n.gateway == key;
          })[0];
          if (gateNames[key]) {
            gateNames[key] = gateNames[key].name;
          } else {
            gateNames[key] = key;
          }
        }
      } else {
        gateNames = null;
      }
      // console.log("gateNames: ", gateNames);
      return gateNames;
    },
    allowed() {
      return this.$store.getters["devices/device"](this.slug).allowed_gateways;
    },
    gatewaysNames() {
      let names = this.$store.getters["devices/gNames"];
      let gateNames = [];
      // let gateways = this.gateways;
      if (names.length) {
        names.forEach((n) => {
          gateNames.push(this.convertObservedToObject(n));
        });
      }
      return gateNames;
    },
  },
  // watch: {
  //   "this.gateways": {
  //     handler() {
  //       // console.log("this.gatewayName", this.gatewayName);
  //       console.log("watch this.gatewayName", this.gatewayName);
  //     },
  //     deep: true,
  //   },
  // },
  created() {
    this.setLang();
  },
  mounted() {
    this.expand = this.defaultExpanded;
    this.latestRssis;
    // this.gatewayName;
    // console.log("this.gatewayName", this.gatewayName);
  },
  methods: {
    isOlderThan40Minutes(receivedTime) {
      const currentTime = new Date();
      const fortyMinutesAgo = new Date(currentTime.getTime() - 40 * 60000); // 40 minutes in milliseconds
      return new Date(receivedTime) > fortyMinutesAgo;
    },
    convertObservedToObject(observedObj) {
      const regularObj = {};

      for (const key in observedObj) {
        if (observedObj[key]) {
          regularObj[key] = observedObj[key];
        }
      }

      return regularObj;
    },
    toggle() {
      this.expand = !this.expand;
    },
    formatTime(time) {
      return dayjs(time).format("HH:mm:ss DD/MM/YY");
    },
    // getGatewayColor(received) {
    // 	switch (true) {
    // 		case received > 40:
    // 			return 'red--text';
    // 		case received > 20:
    // 			return 'deep-orange--text';
    // 		case received > 12:
    // 			return 'orange--text text--darken-2';
    // 		default:
    // 			return 'green--text';
    // 	}
    // },
    getDbmColor(dBm) {
      dBm = parseInt(dBm);
      switch (true) {
        case dBm < -98:
          return "red--text";
        case dBm < -96:
          return "deep-orange--text text--darken-3";
        case dBm < -94:
          return "deep-orange--text text--darken-2";
        case dBm < -92:
          return "orange--text text--darken-2";
        case dBm < -90:
          return "amber--text text--darken-2";
        case dBm < -85:
          return "light-green--text accent-1";
        case dBm < -80:
          return "light-green--text";
        case dBm < -70:
          return "green--text";
        default:
          return "green--text text--darken-2";
      }
    },
    getDbmTitle(rssi) {
      if (rssi.inPlace) return this.dbmTitles[7];
      switch (true) {
        case rssi.rssi < rssi.area - 40:
          return this.dbmTitles[0];
        case rssi.rssi < rssi.area - 35:
          return this.dbmTitles[1];
        case rssi.rssi < rssi.area - 30:
          return this.dbmTitles[2];
        case rssi.rssi < rssi.area - 25:
          return this.dbmTitles[3];
        case rssi.rssi < rssi.area - 20:
          return this.dbmTitles[4];
        case rssi.rssi < rssi.area - 15:
          return this.dbmTitles[5];
        case rssi.rssi < rssi.area - 10:
          return this.dbmTitles[6];
        default:
          return this.dbmTitles[6];
      }
    },
    setLang() {
      if (this.$parent.locale === "he") {
        this.undetected = "המכשיר אינו מאותר";
        this.lastSeen = "המכשיר נצפה בפעם האחרונה ב:";
        this.forbiddenArea = "נמצא באזור אסור!";
        this.dbmTitles = [
          "רחוק במיוחד",
          "רחוק מאוד",
          "רחוק",
          "קצת רחוק",
          "לא רחוק",
          "קצת קרוב",
          "קרוב",
          "נמצא במקום",
        ];
      } else if (this.$parent.locale === "en") {
        this.undetected = "The device is not detected";
        this.lastSeen = "The device was last viewed at:";
        this.forbiddenArea = "In A Forbidden Area!";
        this.dbmTitles = [
          "Extremely Far",
          "Very Far",
          "Far",
          "A Bit Far",
          "Not Far",
          "A Little Close",
          "Close",
          "Is In Place",
        ];
      }
    },
    // getDbmColor(dBm) {
    //   dBm = parseInt(dBm)
    // 	switch (true) {
    // 		case dBm < -80:
    // 			return 'red--text';
    // 		case dBm < -75:
    // 			return 'deep-orange--text text--darken-2';
    // 		case dBm < -70:
    // 			return 'orange--text text--darken-2';
    // 		case dBm < -65:
    // 			return 'amber--text text--darken-2';
    // 		case dBm < -60:
    // 			return 'lime--text text--darken-4';
    // 		case dBm < -55:
    // 			return 'light-green--text';
    // 		case dBm < -50:
    // 			return 'green--text';
    // 		default:
    // 			return 'green--text text--darken-2';
    // 	}
    // }
  },
};
</script>
