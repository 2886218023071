<template>
  <v-icon
    v-if="!sIconName"
    :size="size ? size : ''"
    :x-large="xlarge ? xlarge : false"
    :small="small ? small : false"
    :large="large ? large : false"
    :medium="md ? md : false"
    :class="iconClass ? iconClass : ''"
    :color="color ? color : ''"
    :right="dir == 'right' ? dir : false"
    :left="dir == 'left' ? dir : false"
    >{{ iconName }}</v-icon
  >

  <s-icon
    v-else
    :x-large="xlarge"
    :small="small"
    :large="large"
    :medium="md"
    :color="color"
    >sensors</s-icon
  >
  <!-- 
    :color="color ? color : ''" -->
</template>
<script>
import {
  mdiAlertOctagon,
  mdiClose,
  mdiCog,
  mdiElectricSwitch,
  mdiSnowflakeVariant,
  mdiArrowExpandVertical,
  mdiChevronDown,
  mdiChevronUp,
  mdiDoorClosed,
  mdiDoorOpen,
  mdiEyeOutline,
  mdiFire,
  mdiFlashOutline,
  mdiGauge,
  mdiHomeFlood,
  mdiMagnet,
  mdiMagnetOn,
  mdiOpacity,
  mdiPencil,
  mdiSmokeDetectorAlert,
  mdiTimer,
  mdiWalk,
  mdiWater,
  mdiWaterOff,
  mdiWaterOutline,
  mdiWhiteBalanceIncandescent,
  mdiWhiteBalanceSunny,
  mdiWindowClosedVariant,
  mdiWindowOpenVariant,
  mdiArrowRight,
  mdiArrowLeft,
  mdiPlus,
  mdiElectricSwitchClosed,
  mdiReload,
  mdiBrightness5,
  mdiCheckboxBlankCircle,
  mdiEye,
  mdiEyeOff,
  mdiCursorMove,
  mdiFlash,
  mdiFlashOff,
  mdiFlashAuto,
  mdiCalendarRange,
  mdiArrowSplitHorizontal,
  mdiBellCogOutline,
  mdiBell,
  mdiCheck,
  mdiDelete,
  mdiMinus,
  mdiClockOutline,
  mdiQrcodeScan,
  mdiTranslate,
  mdiBrightness2,
  mdiMenuDown,
  mdiMenuLeft,
  mdiMenuRight,
  mdiDotsVertical,
  mdiPlaylistEdit,
  mdiGroup,
  mdiBattery,
  mdiBatteryAlert,
  mdiBattery20,
  mdiBattery50,
  mdiBattery60,
  mdiBattery70,
  mdiBattery80,
  mdiBattery90,
  mdiLock,
  mdiEmailFast,
  mdiCheckBold,
  mdiAccountCircle,
  mdiPower,
  mdiServer,
  mdiHome,
  mdiMenu,
  mdiKeyboardReturn,
  mdiRouterWireless,
  mdiVibrateOff,
  mdiVibrate,
  mdiRefreshAuto,
} from "@mdi/js";
export default {
  props: [
    "icon",
    "size",
    "iconClass",
    "color",
    "dir",
    "md",
    "large",
    "xlarge",
    "small",
  ],
  data() {
    return {
      icons: {
        menuIcon: mdiMenu,
        returnIcon: mdiKeyboardReturn,
        mdiHome: mdiHome,
        mdiAccountCircle: mdiAccountCircle,
        mdiPower: mdiPower,
        mdiServer: mdiServer,
        checkBold: mdiCheckBold,
        lock: mdiLock,
        emailFast: mdiEmailFast,
        scanIcon: mdiQrcodeScan,
        alertOctagon: mdiAlertOctagon,
        bellCogOutline: mdiBellCogOutline,
        notificationsBell: mdiBell,
        translate: mdiTranslate,
        brightness_2: mdiBrightness2,
        Minus: mdiMinus,
        deleteIcon: mdiDelete,
        checkIcon: mdiCheck,
        access_time: mdiClockOutline,
        dateRange: mdiCalendarRange,
        flashOff: mdiFlashOff,
        flash: mdiFlash,
        flashAuto: mdiFlashAuto,
        checkboxBlankCircle: mdiCheckboxBlankCircle,
        eye: mdiEye,
        eyeOff: mdiEyeOff,
        cursorMove: mdiCursorMove,
        mdiSnowflakeVariant: mdiSnowflakeVariant,
        mdiOpacity: mdiOpacity,
        mdiBrightness5: mdiBrightness5,
        mdiArrowExpandVertical: mdiArrowExpandVertical,
        settingsIcon: mdiCog,
        mdiClose: mdiClose,
        edit: mdiPencil,
        loadingIcon: mdiReload,
        flashOutline: mdiFlashOutline,
        ArrowExpandVertical: mdiArrowExpandVertical,
        chevronUp: mdiChevronUp,
        chevronDown: mdiChevronDown,
        windowClosedVariant: mdiWindowClosedVariant,
        windowOpenVariant: mdiWindowOpenVariant,
        mdiVibrateOff,
        mdiVibrate,
        smokeDetector: mdiSmokeDetectorAlert,
        waterIcon: mdiWater,
        drycontact: mdiElectricSwitch,
        drycontactflood: mdiElectricSwitchClosed,
        waterOff: mdiWaterOff,
        wb_incandescent: mdiWhiteBalanceIncandescent,
        wb_sunny: mdiWhiteBalanceSunny,
        timerIcon: mdiTimer,
        mdiArrowSplitHorizontal: mdiArrowSplitHorizontal,
        ac_unit: mdiSnowflakeVariant,
        opacityIcon: mdiOpacity,
        waterOutline: mdiWaterOutline,
        homeFlood: mdiHomeFlood,
        walkIcon: mdiWalk,
        fireIcon: mdiFire,
        eyeOutline: mdiEyeOutline,
        magnetOn: mdiMagnetOn,
        magnetIcon: mdiMagnet,
        doorOpen: mdiDoorOpen,
        doorClosed: mdiDoorClosed,
        gauge: mdiGauge,
        mdiArrowRight: mdiArrowRight,
        mdiArrowLeft: mdiArrowLeft,
        Plus: mdiPlus,
        keyboardArrowDown: mdiMenuDown,
        arrowRight: mdiMenuRight,
        arrowLeft: mdiMenuLeft,
        more: mdiDotsVertical,
        editNote: mdiPlaylistEdit,
        groupIcon: mdiGroup,
        mdiBattery: mdiBattery,
        mdiBatteryAlert: mdiBatteryAlert,
        mdiBattery20: mdiBattery20,
        mdiBattery50: mdiBattery50,
        mdiBattery60: mdiBattery60,
        mdiBattery70: mdiBattery70,
        mdiBattery80: mdiBattery80,
        mdiBattery90: mdiBattery90,
        Router: mdiRouterWireless,
        Auto: mdiRefreshAuto,
      },
    };
  },
  computed: {
    iconName() {
      return this.icon ? this.icons[this.icon] : null;
    },
    sIconName() {
      return this.icon == "sensors" ? true : false;
    },
  },
  mounted() {
    // console.log(this.name, this.size, this.iconClass, this.color, this.s);
  },
};
</script>
<style lang="scss" scoped>
//
</style>
