<template>
  <v-container grid-list-xs fluid>
    <div class="pageHeader">
      <h1>{{ $t("links.automations") }}</h1>
      <v-btn
        v-show="!newCreate"
        color="info"
        d-flex
        align-center
        :block="$vuetify.breakpoint.xs"
        @click="newCreate == false ? (newCreate = true) : ''"
      >
        <v-icon class="me-2">add</v-icon>
        {{ $t("newAuto") }}
      </v-btn>
    </div>
    <v-layout row wrap class="mt-5">
      <v-flex xs12>
        <v-stepper v-model="e1" v-show="newCreate">
          <v-stepper-header>
            <v-stepper-step
              :class="e1 > 1 ? 'pointer' : ''"
              @click="e1 > 1 ? (e1 = 1) : null"
              :complete="e1 > 1"
              step="1"
            >
              <div>
                {{ $t("auto-name") }}
              </div>
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :class="e1 > 2 ? 'pointer' : ''"
              @click="e1 > 2 ? (e1 = 2) : null"
              :complete="e1 > 2"
              step="2"
            >
              {{ $t("when") }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :class="e1 > 3 ? 'pointer' : ''"
              @click="e1 > 3 ? (e1 = 3) : null"
              :complete="e1 > 3"
              step="3"
            >
              {{ $t("then") }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4">
              {{ $t("end") }}
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12" color="grey lighten-3" height="200px">
                <v-text-field
                  class="autoNameField"
                  type="String"
                  :label="$t('auto-name')"
                  v-model="autoName"
                ></v-text-field>
              </v-card>
              <v-flex row justify-space-around>
                <v-btn
                  color="primary"
                  @click="autoName ? (e1 = 2) : alertError('mustName')"
                >
                  {{ $t("next") }}
                </v-btn>

                <v-btn @click="cancelAction">
                  {{ $t("cancel") }}
                </v-btn>
              </v-flex>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-layout v-if="$vuetify.breakpoint.xs" row wrap justify-start>
                <h3>{{ $t("when") }}</h3>
              </v-layout>
              <v-layout row wrap justify-center>
                <v-btn
                  @click="addIf = true"
                  block
                  v-show="!addIf && !configIfs"
                  class="my-3"
                >
                  {{ $t("addIf") }}
                </v-btn>
              </v-layout>
              <automations-ifs
                v-if="addIf && !configIfs"
                @createNewIf="(data) => createIf(data)"
                @closeForm="addIf = false"
              />
              <v-layout
                v-show="!addIf && !configIfs"
                justify-center
                row
                wrap
                v-for="(item, index) in ifs"
                :key="index"
              >
                <v-card elevation="2" class="my-2 d-flex flex-row">
                  <v-card-text>
                    <span v-if="item.device">
                      {{ $t("if") }}:
                      <span
                        v-if="
                          (device(item.device.if_sensor).type <= 10 ||
                            device(item.device.if_sensor).type > 14) &&
                          device(item.device.if_sensor).type != 21 &&
                          device(item.device.if_sensor).type != 7
                        "
                      >
                        {{
                          device(item.device.if_sensor).name
                            ? device(item.device.if_sensor).name +
                              " " +
                              $t(item.device.if_sensorDevice) +
                              " " +
                              actions.filter(
                                (action) =>
                                  action.value == item.device.if_action
                              )[0].text +
                              " "
                            : device(item.device.if_sensor).identifier +
                              " " +
                              $t(item.device.if_sensorDevice) +
                              " " +
                              actions.filter(
                                (action) =>
                                  action.value == item.device.if_action
                              )[0].text +
                              " "
                        }}
                        {{
                          device(item.device.to_state)
                            ? device(item.device.to_state).name
                              ? device(item.device.to_state).name
                              : device(item.device.to_state).identifier
                            : item.device.to_state
                        }}
                      </span>
                      <span v-else>
                        {{
                          device(item.device.if_sensor).name
                            ? device(item.device.if_sensor).name + " - "
                            : device(item.device.if_sensor).identifier + " - "
                        }}
                        {{
                          device(item.device.if_sensor).type == 7
                            ? item.device.if_action == 1
                              ? $t("lightOn")
                              : $t("lightOff")
                            : null
                        }}
                        {{
                          device(item.device.if_sensor).type != 7 &&
                          device(item.device.if_sensor).type != 21
                            ? item.device.if_action == 1
                              ? $t("close")
                              : $t("open")
                            : null
                        }}
                        {{
                          device(item.device.if_sensor).type == 21
                            ? item.device.if_action == 1
                              ? $t("vibrates")
                              : $t("steady")
                            : null
                        }}
                      </span>
                    </span>
                    <span v-if="item.scheduale">
                      {{ $t("inTime") }}:
                      <span v-if="item.scheduale.time">
                        {{ item.scheduale.time }}
                      </span>
                      <span v-else
                        >{{
                          $t("startTime") +
                          " " +
                          item.scheduale.startTime +
                          " " +
                          $t("endTime") +
                          " " +
                          item.scheduale.endTime +
                          " "
                        }}
                        {{ $t("days") }}:
                        <span
                          v-for="(item, index) in item.scheduale.days"
                          :key="index"
                        >
                          {{ $t(`weekDays.${item}`) }}
                        </span>
                      </span>
                    </span>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text icon color="info" @click="editIf(item, index)">
                      <Icon icon="edit" />
                    </v-btn>
                    <v-btn text icon color="red" @click="removeIf(index)">
                      <Icon icon="deleteIcon" />
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-layout>
              <automations-ifs
                :config="configIfs"
                :configData="configIfData"
                :index="configIfIndex"
                v-if="configIfs && !addIf"
                @updateNewIf="(data) => updateIf(data)"
                @closeForm="
                  (configIfs = false),
                    (configIfData = null),
                    (configIfIndex = null)
                "
              />
              <v-flex v-show="!addIf && !configIfs" row justify-space-around>
                <v-btn
                  :disabled="ifs.length == 0"
                  color="primary"
                  @click="e1 = 3"
                >
                  {{ $t("next") }}
                </v-btn>
                <v-btn @click="cancelAction">
                  {{ $t("cancel") }}
                </v-btn>
              </v-flex>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-layout v-if="$vuetify.breakpoint.xs" row wrap justify-start>
                <h3>{{ $t("then") }}</h3>
              </v-layout>
              <v-layout row wrap justify-center>
                <v-btn
                  @click="addThen = true"
                  block
                  v-show="!addThen && !configThens"
                  class="my-3"
                >
                  {{ $t("addAction") }}
                </v-btn>
              </v-layout>
              <automations-thens
                v-if="addThen && !configThens"
                @createNewThen="(data) => createThen(data)"
                @closeForm="addThen = false"
              />
              <v-layout
                v-show="!addThen && !configThens"
                justify-center
                row
                wrap
                v-for="(item, index) in thens"
                :key="index"
              >
                <v-card elevation="2" class="my-2 d-flex flex-row">
                  <v-card-text>
                    {{ item.then_action ? $t("deviceAction") : $t("sendMsg") }}:
                    {{
                      item.then_controller
                        ? device(item.then_controller).name
                          ? device(item.then_controller).name +
                            " " +
                            $t("status") +
                            " " +
                            $t(item.then_action)
                          : device(item.then_controller).identifier +
                            " " +
                            $t("status") +
                            " " +
                            $t(item.then_action)
                        : $t("via") + " " + $t(item.then_notify.not)
                    }}
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      icon
                      color="info"
                      @click="editThen(item, index)"
                    >
                      <Icon icon="edit" />
                    </v-btn>
                    <v-btn text icon color="red" @click="removeThen(index)">
                      <Icon icon="deleteIcon" />
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-layout>
              <automations-thens
                :config="configThens"
                :configData="configThenData"
                :index="configThenIndex"
                v-if="configThens && !addThen"
                @updateNewThen="(data) => updateThen(data)"
                @closeForm="
                  (configThens = false),
                    (configThenData = null),
                    (configThenIndex = null)
                "
              />
              <v-flex
                row
                v-show="!addThen && !configThens"
                justify-space-around
              >
                <v-btn
                  :disabled="thens.length == 0"
                  color="primary"
                  @click="e1 = 4"
                >
                  {{ $t("next") }}
                </v-btn>

                <v-btn @click="cancelAction">
                  {{ $t("cancel") }}
                </v-btn>
              </v-flex>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-layout v-if="$vuetify.breakpoint.xs" row wrap justify-start>
                <h3>{{ $t("end") }}</h3>
              </v-layout>
              <v-layout row wrap justify-center>
                <v-btn
                  @click="addEnd = true"
                  block
                  v-show="!addEnd && !configEnds"
                  class="my-3"
                >
                  {{ $t("addAction") }}
                </v-btn>
              </v-layout>
              <automations-ends
                v-if="addEnd && !configEnds"
                @createNewEnd="(data) => createEnd(data)"
                @closeForm="addEnd = false"
              />
              <v-layout
                v-show="!addEnd && !configEnds"
                justify-center
                row
                wrap
                v-for="(item, index) in ends"
                :key="index"
              >
                <v-card elevation="2" class="my-2 d-flex flex-row">
                  <v-card-text>
                    {{ item.end_action ? $t("deviceAction") : $t("sendMsg") }}:
                    {{
                      item.end_controller
                        ? device(item.end_controller).name
                          ? device(item.end_controller).name +
                            " " +
                            $t("status") +
                            " " +
                            $t(item.end_action)
                          : device(item.end_controller).identifier +
                            " " +
                            $t("status") +
                            " " +
                            $t(item.end_action)
                        : $t("via") + " " + $t(item.end_notify.not)
                    }}
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text icon color="info" @click="editEnd(item, index)">
                      <Icon icon="edit" />
                    </v-btn>
                    <v-btn text icon color="red" @click="removeEnd(index)">
                      <Icon icon="deleteIcon" />
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-layout>
              <automations-ends
                :config="configEnds"
                :configData="configEndData"
                :index="configEndIndex"
                v-if="configEnds && !addEnd"
                @updateNewEnd="(data) => updateEnd(data)"
                @closeForm="
                  (configEnds = false),
                    (configEndData = null),
                    (configEndIndex = null)
                "
              />

              <v-flex v-show="!addEnd && !configEnds" row justify-space-around>
                <v-btn color="primary" @click="confirm()">
                  {{ $t("confirm") }}
                </v-btn>

                <v-btn @click="cancelAction">
                  {{ $t("cancel") }}
                </v-btn>
              </v-flex>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </v-layout>
    <v-layout
      v-show="!newCreate"
      column
      nowrap
      align-center
      justify-center
      class="black--text px-2 py-4"
      v-for="(auto, index) in Object.values(automations)"
      :key="index"
    >
      <v-card
        class="autoCard"
        v-if="automations && Object.values(automations).length"
      >
        <v-flex xs12 md12 class="pa-6">
          <v-layout column>
            <v-card-title primary-title>
              <span class="text-xs-subtitle-1 text-md-h4">{{ auto.name }}</span>
              <div class="delIcon">
                <v-switch
                  v-model="auto.status"
                  :prepend-icon="auto.status ? 'mdi-flash' : 'mdi-flash-off'"
                  color="success"
                  :false-value="auto.status == 0"
                  inset
                  hide-details
                  @click="toggleAutomation(auto)"
                >
                </v-switch>
                <v-icon @click="deleteAutomation = auto.uuid">delete</v-icon>
                <!-- <Icon
                  icon="editNote"
                  class="ms-1"
                  color="info"
                  size="30"
                  @click="editAuto(auto)"
                /> -->
                <v-icon class="ms-2" color="info" @click="editAuto(auto)"
                  >edit</v-icon
                >
              </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-flex>
              <span class="me-4 text-subtitle-0"> {{ $t("when") }}: </span>
              <span
                v-for="(i, index) in auto.automation_ifs"
                :key="index"
                class="text-xs-subtitle-1 text-md-h6"
              >
                <span v-if="i.device">
                  <!-- {{ $t("if") }} -->
                  <span
                    v-if="
                      (device(i.device.if_sensor).type <= 10 ||
                        device(i.device.if_sensor).type > 14) &&
                      device(i.device.if_sensor).type != 21 &&
                      device(i.device.if_sensor).type != 7
                    "
                  >
                    {{
                      device(i.device.if_sensor).name
                        ? device(i.device.if_sensor).name + " " + ": "
                        : device(i.device.if_sensor).identifier + " " + ": "
                    }}
                    {{ $t(i.device.if_sensorDevice) }}
                    {{ action(i.device.if_action).text }} -
                    {{
                      !device(i.device.to_state)
                        ? i.device.to_state +
                          " " +
                          (i.device.if_sensorDevice == "humidity" ? "%" : "") +
                          (i.device.if_sensorDevice == "range" ? "cm" : "") +
                          (i.device.if_sensorDevice == "temperatures"
                            ? "°"
                            : "")
                        : device(i.device.to_state).name
                        ? $t(i.device.if_sensorDevice) +
                          " " +
                          device(i.device.to_state).name
                        : $t(i.device.if_sensorDevice) +
                          " " +
                          device(i.device.to_state).identifier +
                          (i.device.if_sensorDevice == "humidity" ? "%" : "") +
                          (i.device.if_sensorDevice == "range" ? "cm" : "") +
                          (i.device.if_sensorDevice == "temperatures"
                            ? "°"
                            : "")
                    }}
                  </span>
                  <span v-else>
                    {{
                      device(i.device.if_sensor).name
                        ? device(i.device.if_sensor).name + " - "
                        : device(i.device.if_sensor).identifier + " - "
                    }}
                    {{
                      device(i.device.if_sensor).type == 7
                        ? i.device.if_action == 1
                          ? $t("lightOn")
                          : $t("lightOff")
                        : null
                    }}
                    {{
                      device(i.device.if_sensor).type != 7 &&
                      device(i.device.if_sensor).type != 21 &&
                      device(i.device.if_sensor).type >= 6
                        ? i.device.if_action == 1
                          ? $t("close")
                          : $t("open")
                        : null
                    }}
                    {{
                      device(i.device.if_sensor).type == 21
                        ? i.device.if_action == 1
                          ? $t("vibrates")
                          : $t("steady")
                        : null
                    }}
                  </span>
                </span>
                <span v-else-if="i.scheduale">
                  {{ $t("inTime") + ": " }}
                  {{
                    i.scheduale.time
                      ? i.scheduale.time
                      : $t("startTime") +
                        " " +
                        i.scheduale.startTime +
                        " " +
                        $t("endTime") +
                        " " +
                        i.scheduale.endTime
                  }}
                  <span v-if="i.scheduale && !i.scheduale.time">
                    {{ $t("days") + ": " }}
                    <span
                      v-for="(item, index) in i.scheduale.days"
                      :key="index"
                    >
                      {{ $t(`weekDays.${item}`) }}
                    </span>
                  </span>
                </span>
                {{ index < auto.automation_ifs.length - 1 ? ", " : "" }}
              </span>
            </v-flex>
            <v-divider></v-divider>
            <v-flex>
              <span class="me-8 text-subtitle-0"> {{ $t("then") }}: </span>
              <span
                v-for="(i, index) in auto.automation_thens"
                :key="index"
                class="text-xs-subtitle-1 text-md-h6"
              >
                <span v-if="i.then_controller">
                  {{
                    device(i.then_controller).name
                      ? device(i.then_controller).name
                      : device(i.then_controller).identifier
                  }}
                  -
                  {{ $t("workStatus") + " " + $t(i.then_action) }}
                </span>
                <span v-if="i.then_notify">
                  {{ $t("sendMsg") + " " + $t("via") + " " }}
                  <span v-for="(n, index) in i.then_notify.not" :key="index">
                    {{ $t(n == "email" ? "emailNot" : n) }}
                    {{ index < i.then_notify.not.length - 1 ? ", " : "" }}
                  </span>
                </span>
                {{ index < auto.automation_thens.length - 1 ? ", " : "" }}
              </span>
            </v-flex>
            <v-divider
              v-if="auto.automation_ends && auto.automation_ends.length > 0"
            ></v-divider>
            <v-flex
              v-if="auto.automation_ends && auto.automation_ends.length > 0"
            >
              <span class="me-4 text-subtitle-0"> {{ $t("end") }}: </span>
              <span
                v-for="(i, index) in auto.automation_ends"
                :key="index"
                class="text-xs-subtitle-1 text-md-h6"
              >
                <span v-if="i.end_controller">
                  {{
                    device(i.end_controller).name
                      ? device(i.end_controller).name
                      : device(i.end_controller).identifier
                  }}
                  -
                  {{ $t("workStatus") + " " + $t(i.end_action) }}
                </span>
                <span v-if="i.end_notify">
                  {{ $t("sendMsg") + " " + $t("via") + " " }}
                  <span v-for="(n, index) in i.end_notify.not" :key="index">
                    {{ $t(n == "email" ? "emailNot" : n) }}
                    {{ index < i.end_notify.not.length - 1 ? ", " : "" }}
                  </span>
                </span>
                {{ index < auto.automation_ends.length - 1 ? ", " : "" }}
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-card>
    </v-layout>
    <Modal
      :activator="!!deleteAutomation"
      @close="deleteAutomation = null"
      @delete="deleteAuto"
    />
  </v-container>
</template>
<script>
import Modal from "@/components/general/DeleteModal.vue";
import AutomationsIfs from "./automationsIfs.vue";
import automationsThens from "./automationsThens.vue";
import automationsEnds from "./automationsEnds.vue";
export default {
  name: "automations",
  components: {
    Modal,
    AutomationsIfs,
    automationsThens,
    automationsEnds,
  },
  data() {
    return {
      configThenData: null,
      configThenIndex: null,
      configThens: false,
      configEndData: null,
      configEndIndex: null,
      configEnds: false,
      configIfData: null,
      configIfIndex: null,
      configIfs: false,
      addIf: false,
      addThen: false,
      addEnd: false,
      ifs: [],
      thens: [],
      ends: [],
      newCreate: false,
      autoName: null,
      deviceSelector: [],
      e1: 1,
      deleteAutomation: null,
    };
  },
  mounted() {
    console.log("this.automations", this.automations);
  },
  computed: {
    automations() {
      this.$store.dispatch("automations/getAutomations");
      // this.$store.getters["automations/automations"] ??
      // localStorage.getItem("automations");
      let automations = this.$store.getters["automations/automations"];

      // console.log("automations", automations);
      return automations;
    },

    devices() {
      let devices = this.$store.getters["devices/devices"];
      Object.values(devices).filter((d) => {
        return d.role == 0 &&
          ("temperatures" in d.data.sensors || "humidity" in d.data.sensors)
          ? this.deviceSelector.push({
              name: d.name,
              slug: d.slug,
              identifier: d.identifier,
              sensors: Object.keys(d.data.sensors),
              type: d.settings.sensor_type,
            })
          : "";
      });
      return Object.values(devices).map((d) => {
        return {
          name: d.name,
          slug: d.slug,
          identifier: d.identifier,
          sensors: Object.keys(d.data.sensors),
          type: d.settings.sensor_type,
        };
      });
    },
    // controllerDevices() {
    //   let devices = this.$store.getters["devices/devices"];
    //   return Object.values(devices).map((d) => {
    //     if (d.role == 1) {
    //       return {
    //         name: d.name,
    //         slug: d.slug,
    //         identifier: d.identifier,
    //         sensors: Object.keys(d.data.sensors),
    //         type: d.settings.sensor_type,
    //       };
    //     }
    //   });
    // },
    sensorDevices() {
      let sensors = [
        {
          value: "temperatures",
          text: this.$t("temperature"),
        },
        {
          value: "humidity",
          text: this.$t("humidity"),
        },
        {
          value: "range",
          text: this.$t("range"),
        },
      ];
      if (this.sensorVal) {
        const deviceSensors = this.devices.filter(
          (d) => d.identifier == this.sensorVal
        )[0].sensors;
        return sensors.filter((s) => deviceSensors.includes(s.value));
      } else {
        return [];
      }
    },
    actions() {
      return [
        {
          value: ">",
          text: this.$t("bigger"),
        },
        {
          value: "<",
          text: this.$t("smaller"),
        },
        {
          value: "==",
          text: this.$t("equals"),
        },
        {
          value: "!=",
          text: this.$t("not-equals"),
        },
      ];
    },
  },
  watch: {
    // automations: {
    //   handler() {
    //     // console.log(this.automations);
    //     // console.log("helooooo");
    //   },
    //   deep: true,
    // },
    automations() {
      console.log("automations", this.automations);
    },
  },
  methods: {
    editEnd(item, index) {
      this.configEnds = true;
      this.configEndData = item;
      this.configEndIndex = index;
    },
    editThen(item, index) {
      this.configThens = true;
      this.configThenData = item;
      this.configThenIndex = index;
    },
    editIf(item, index) {
      this.configIfs = true;
      this.configIfData = item;
      this.configIfIndex = index;
    },
    editAuto(auto) {
      this.autoName = auto.name;
      this.ifs = [...auto.automation_ifs];
      this.thens = [...auto.automation_thens];
      this.ends = [...(auto.automation_ends ?? [])];
      this.newCreate = true;
    },
    createIf(data) {
      this.ifs.push(data);
    },
    updateIf(data) {
      this.ifs[this.configIfIndex] = data;
      this.configIfData = null;
      this.configIfIndex = null;
      this.configIfs = false;
    },
    createThen(data) {
      this.thens.push(data);
    },
    updateThen(data) {
      this.thens[this.configThenIndex] = data;
      this.configThenData = null;
      this.configThenIndex = null;
      this.configThens = false;
    },
    createEnd(data) {
      this.ends.push(data);
    },
    updateEnd(data) {
      this.ends[this.configEndIndex] = data;
      this.configEndData = null;
      this.configEndIndex = null;
      this.configEnds = false;
    },
    removeIf(i) {
      this.ifs.splice(i, 1);
    },
    removeThen(i) {
      this.thens.splice(i, 1);
    },
    removeEnd(i) {
      this.ends.splice(i, 1);
    },
    device(identifier) {
      return this.devices.filter((d) => d.identifier == identifier)[0];
    },
    action(val) {
      return this.actions.filter((a) => a.value == val)[0];
    },
    alertError(msg) {
      this.$store.dispatch("general/setAlert", {
        type: "oops",
        msg: msg,
      });
    },
    async deleteAuto(uuid) {
      if (uuid) {
        this.deleteAutomation = uuid;
      } else if (this.deleteAutomation) {
        await this.$store.dispatch(
          "automations/deleteAutomation",
          this.deleteAutomation
        );
        this.deleteAutomation = null;
      }
    },
    cancelAction() {
      this.newCreate = false;
      this.autoName = null;
      this.e1 = 1;
      this.ifs = [];
      this.thens = [];
      this.ends = [];
      this.addIf = false;
      this.addThen = false;
      this.addEnd = false;
      this.configIfData = null;
      this.configIfIndex = null;
      this.configIfs = false;
      this.configThenData = null;
      this.configThenIndex = null;
      this.configThens = false;
      this.configEndData = null;
      this.configEndIndex = null;
      this.configEnds = false;
    },
    async confirm() {
      let data = {};
      if (
        this.ifs.length != 0 &&
        this.thens.length != 0 &&
        this.autoName != null
      ) {
        data = {
          autoName: this.autoName,
          ifs: this.ifs,
          thens: this.thens,
          ends: this.ends.length > 0 ? this.ends : null,
        };
        await this.$store.dispatch("automations/setAutomation", data);
        this.cancelAction();
      } else {
        this.alertError(this.$t("Check-required-fields"));
      }
    },
    async toggleAutomation(auto) {
      // console.log("status", auto);
      await this.$store.dispatch("automations/toggleAutomation", auto);
    },
  },
};
</script>
<style lang="scss" scoped>
.pageHeader {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  margin: 0 auto;
}
.vertDivider {
  color: red;
}

.autoNameField {
  margin: 0 auto;
  padding-top: 50px;
  width: 85%;
}

.selectors {
  padding-top: 20px;
  margin: 0 auto;
  width: 85%;
}

.autoCard {
  min-height: 60px;
  width: 85%;
  background-color: lightblue;
  // background-color: rgb(54, 186, 247);
  text-align: start;
  .v-card__title {
    margin-inline-start: -14px;
    margin-inline-end: -14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
  }
  .delIcon {
    display: flex;
    align-items: center;
    .v-icon {
      cursor: pointer;
      color: red;
    }
  }
}
// .autoCard {
//   width: 85%;
//   text-align: start;
.v-input--selection-controls {
  margin-top: unset;
  padding-top: unset;
}
</style>
