import { render, staticRenderFns } from "./notifications.vue?vue&type=template&id=cdbcf3d8&scoped=true&"
import script from "./notifications.vue?vue&type=script&lang=js&"
export * from "./notifications.vue?vue&type=script&lang=js&"
import style0 from "./notifications.vue?vue&type=style&index=0&id=cdbcf3d8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdbcf3d8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VBadge,VCard,VFlex,VLayout,VMenu,VProgressLinear})
