<template>
  <v-navigation-drawer
    v-model="drawer"
    :right="$vuetify.rtl"
    fixed
    temporary
    width="280"
  >
    <v-container class="sidemenu-container pa-0">
      <v-layout row wrap class="sidemenu-header pa-4">
        <v-flex xs10 align-self-center>
          <h3>{{ userName }}</h3>
        </v-flex>
        <v-flex xs2 align-self-center>
          <v-btn text icon @click="drawer = false">
            <Icon icon="mdiClose" color="gray" />
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="sidemenu-body">
        <v-flex xs12>
          <v-list nav dense>
            <v-list-item-group
              :value="path"
              active-class="switchqSub  switchq--text text--accent-1"
            >
              <v-list-item
                v-for="item in items.filter((i) => i.valid)"
                :key="item.to"
                :value="item.to"
                @click="item.to ? goTo(item.to) : item.method()"
              >
                <v-list-item-icon>
                  <Icon v-if="!item.sIcon" :icon="item.icon" />
                  <Icon v-if="item.sIcon" :icon="item.icon" />
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="sidemenu-footer">
        <v-flex xs12>
          <v-container grid-list-xs>
            <locale />
          </v-container>
        </v-flex>
      </v-layout>
    </v-container>
    <Modal :activator="u_logout" @close="u_logout = false" />
  </v-navigation-drawer>
</template>
<script>
import Locale from "@/components/general/locale.vue";
import lang from "@/helpers/lang";
import Modal from "../general/LogOutModal.vue";
export default {
  components: {
    Locale,
    Modal,
  },
  data() {
    return {
      u_logout: false,
    };
  },
  computed: {
    drawer: {
      get() {
        return this.$store.getters["general/sideMenu"];
      },
      set(status) {
        this.$store.dispatch("general/setSideMenu", status);
      },
    },
    userName() {
      return this.$store.getters["user/name"];
    },
    path() {
      if (this.hasGroups && this.$route.path === "/groups") {
        return "/";
      } else if (!this.hasGroups && this.$route.path === "/devices") {
        return "/";
      } else return this.$route.path;
    },
    hasGroups() {
      return this.$store.getters["groups/hasGroups"];
    },
    items() {
      return [
        {
          icon: "mdiHome",
          title: this.$t("links.home"),
          to: "/",
          valid: true,
        },
        {
          icon: "mdiServer",
          title: this.$t("links.logs"),
          to: "/logs",
          valid: true,
        },
        {
          icon: "notificationsBell",
          title: this.$t("links.notifications"),
          to: "/profile/notifications",
          valid: true,
        },
        {
          icon: "groupIcon",
          title: this.$t("links.edit-groups"),
          to: "/groups/edit",
          valid: true,
        },
        {
          icon: "Plus",
          title: this.$t("links.new"),
          to: "/new-device/new",
          valid: true,
        },
        {
          icon: "Router",
          title: this.$t("links.gateways"),
          to: "/gateways",
          valid: true,
        },
        // {
        //   icon: "Auto",
        //   title: this.$t("links.automations"),
        //   to: "/automations",
        //   valid: true,
        // },
        // {
        //   icon: "sensors",
        //   title: this.$t("links.all-devices"),
        //   to: "/devices",
        //   valid: this.hasGroups,
        //   sIcon: true,
        // },
        // {
        //   icon: mdiShape,
        //   title: this.$t("links.groups"),
        //   to: "/groups",
        //   valid: this.hasGroups,
        // },
        {
          icon: "mdiAccountCircle",
          title: this.$t("links.profile"),
          to: "/profile",
          valid: true,
        },
        // {
        //   icon: "badge",
        //   title: this.$t("links.client"),
        //   to: "/client",
        //   valid: true,
        // },
        {
          icon: "settingsIcon",
          title: this.$t("links.settings"),
          to: "/profile/settings",
          valid: true,
        },
        {
          icon: "mdiPower",
          title: this.$t("links.signout"),
          method: this.logout,
          valid: true,
        },
      ];
    },
  },
  // watch: {
  //   path(path) {
  //     console.log("path", path);
  //   },
  // },
  created() {
    this.locale = lang.get();
  },
  methods: {
    logout() {
      this.u_logout = true;
    },
    goTo(path) {
      this.$router.push(path).catch(() => {
        // console.log("errrrrroorrr");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.sidemenu-container {
  // height: 100vh;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  .sidemenu-header {
    padding: 24px 10px;
    max-height: 90px;
  }
  .sidemenu-footer {
    max-height: 84px;
  }
}
</style>
