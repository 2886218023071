<template>
  <div class="notifications">
    <v-layout column wrap justify-end align-center class="notifications-layout">
      <div v-for="(alert, i) in notifications" class="notification" :key="i">
        <v-scale-transition>
          <v-alert
            :type="types[alert.type]"
            :icon="false"
            :dense="$vuetify.breakpoint.xs"
            elevation="10"
          >
            <v-layout column wrap justify-center>
              <!-- <h4>
                {{ alert.data.message.title }}
              </h4> -->
              <strong>
                {{ alert.data.message.body }}
              </strong>
            </v-layout>
          </v-alert>
        </v-scale-transition>
      </div>
    </v-layout>
  </div>
</template>
<script>
export default {
  name: "NotificationAlerts",
  data() {
    return {
      snackbar: true,
      timeout: 3000000,
      types: {
        MagnetNotificationWarning: "warning",
        MagnetNotification: "info",
        DetectsFlood: "warning",
        FloodFixed: "info",
        ExceedsThreshold: "warning",
        ReturnedToNormal: "info",
        OfflineDevice: "red",
        OnlineDevice: "success",
      },
    };
  },
  computed: {
    notifications() {
      let nots = this.$store.getters["notifications/alerts"];
      nots = JSON.parse(JSON.stringify(nots));
      return nots;
    },
  },
};
</script>
<style scoped lang="scss">
.notifications {
  z-index: 1000;
  position: fixed;
  width: 100%;
  bottom: 0;
  .notifications-layout {
    .notification {
      width: 100%;
      max-width: 600px;
      padding: 0px 16px;
      text-align: center;
    }
  }
}
</style>
