<template>
  <v-dialog v-model="status" persistent max-width="400px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ content.create }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  counter
                  maxlength="20"
                  v-model="form.name"
                  :label="content.name"
                  required
                  :rules="rules.name"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 v-if="parent">
                <!-- <v-text-field
                  readonly
                  :value="groups[parent].name"
                  :label="content.parent_group"
                  required
                ></v-text-field> -->
                <span>
                  {{ content.parent_group + ": " + groups[parent].name }}
                </span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="switchq darken-1" text @click="save">
          {{ content.save }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="close">
          {{ content.cancel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Lang from "@/helpers/lang";
export default {
  props: ["dialog", "parent"],
  data() {
    return {
      status: false,
      form: {
        name: "",
        parent_slug: null,
      },
      rules: {},
    };
  },
  computed: {
    groups() {
      let groups = this.$store.getters["groups/groups"];
      groups = JSON.parse(JSON.stringify(groups));
      Object.values(groups).unshift({
        name: this.content.none,
        slug: null,
      });
      return groups;
    },
    groupsSlugs() {
      return Object.values(this.groups).map((c) => c.slug);
    },
  },
  watch: {
    dialog(dialog) {
      this.status = dialog;
    },
    status() {
      if (this.status == true) {
        if (this.parent) {
          this.form.parent_slug = this.parent;
        }
        // console.log("parent", this.parent);
      }
    },
  },
  created() {
    this.locale = Lang.get();
    this.setLang();
    this.setRules();
  },
  mounted() {},
  methods: {
    close() {
      this.form.name = "";
      this.form.parent_slug = null;
      this.$emit("close");
    },
    save() {
      if (this.$refs.form.validate()) {
        // console.log("form", this.form);
        this.$store.dispatch("groups/createGroup", this.form);
        this.$emit("close");
        this.form.name = "";
      }
      setTimeout(() => {
        this.$store.dispatch("groups/getGroups");
      }, 5000);
    },
    setRules() {
      this.rules.name = [
        (v) => v.length >= 3 || this.content.rules.name_short,
        (v) => v.length <= 20 || this.content.rules.name_long,
      ];
      this.rules.parent = [
        (v) =>
          !v || this.groupsSlugs.includes(v) || this.content.rules.name_short,
      ];
    },
    setLang() {
      if (this.locale == "en") {
        this.content = {
          create: "Create Group",
          save: "Save",
          cancel: "Cancel",
          name: "Group Name",
          parent_group: "Parent Group",
          none: "None",
          rules: {
            must: "Must choose group",
            name_short: "The name must be at least 3 characters long",
            name_long: "The name must be shorter than 20 characters",
            parent: "The parent group is invalid",
          },
        };
      } else if (this.locale == "he") {
        this.content = {
          create: "יצירת קבוצה",
          save: "שמירה",
          cancel: "ביטול",
          name: "שם הקבוצה",
          parent_group: "קבוצת מקור",
          none: "ללא",
          rules: {
            must: "חובה לבחור קבוצה",
            name_short: "השם חייב להכיל לפחות 3 תווים",
            name_long: "השם חייב להיות קצר מ-20 תווים",
            parent: "קבוצת המקור אינה תקינה",
          },
        };
      }
    },
  },
};
</script>
