import auth from "@/helpers/auth";
import axios from "@/plugins/axios";

/**
 * Initial state
 */
export const state = {
  user: {
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    locale: "",
  },
  notifications: {},
  token: auth.getToken(),
  valid: false,
  checked: false,
  pushable: localStorage.getItem("pushnot_subscription"),
};

/**
 * Mutations
 */
export const mutations = {
  ["SET_USER"](state, data) {
    state.valid = true;
    state.notifications = data.notifications;
    delete data.notifications;
    state.user = data;
    if (data.token) {
      auth.save(data.token);
      state.token = data.token;
    } else {
      state.token = auth.getToken();
    }
  },
  ["EDIT_WHATSAPP"](state, data) {
    state.valid = true;
    state.notifications.whatsapp = data;
    // delete data.notifications;
    // state.user = data;
    // if (data.token) {
    //   auth.save(data.token);
    //   state.token = data.token;
    // } else {
    //   state.token = auth.getToken();
    // }
  },
  ["OUT_USER"](state) {
    state.user.email = "";
    state.user.first_name = "";
    state.user.last_name = "";
    state.user.phone = "";
    state.user.locale = "";
    state.token = "";
    state.valid = false;
    this.dispatch("devices/logOut");
    this.dispatch("groups/logOut");
    this.dispatch("general/logOut");
    this.dispatch("business/logOut");
    window.location.reload();
  },
  ["EDIT_USER"](state, data) {
    // console.log(data);
    if ("firs_name" in data) {
      state.user.first_name = data.first_name;
      state.user.last_name = data.last_name;
    } else if ("password" in data) {
      state.user.password = data.password;
    } else if ("email" in data) {
      state.user.email = data.email;
    } else if ("phone" in data) {
      state.user.phone = data.phone;
    }
  },
  ["SET_PUSHNOTS"](state, value) {
    state.pushable = value;
  },
  ["SET_CHECKED"](state) {
    // setTimeout(() => {
    state.checked = true;
    //   if (state.checked) state.checked = true;
    // }, 1000);
  },
  ["SET_LOCALE"](state, value) {
    state.user.locale = value;
  },
};

/**
 * Actions
 */
export const actions = {
  setPushnots() {
    localStorage.removeItem("deniedPush");
  },
  async get({ commit }) {
    await axios
      .get("user")
      .then((res) => {
        // console.log("user/signin", res.data);
        if (res.data.data) {
          commit("SET_USER", res.data.data);
        }
      })
      .catch((err) => {
        console.log("user/get", err);
      })
      .finally(() => {
        commit("SET_CHECKED");
      });
  },
  async signIn({ commit }, user) {
    await axios
      .post("user/signin", user)
      .then((res) => {
        // console.log("user/signin", res.data);
        if (res.data) {
          axios.defaults.headers.Authorization =
            "Bearer " + res.data.data.token;

          commit("SET_USER", res.data.data);
          this.dispatch("general/setAlert", {
            type: "success",
            msg: "signIn",
          });
          commit("SET_CHECKED");
        }
      })
      .catch(() => {
        this.dispatch("general/setAlert", {
          type: "failed",
          msg: "oopsUser",
        });
      });
  },
  async signOut({ commit }) {
    auth.logout();
    localStorage.removeItem("mydata");
    localStorage.removeItem("mydataview");
    localStorage.removeItem("has-groups");
    localStorage.removeItem("inGroup");

    // this.reset();
    commit("OUT_USER");
  },
  async editUser({ commit }, data) {
    let valid = false;
    // console.log("data to edit ", data);
    await axios
      .post("/user/edit", data)
      .then(() => {
        commit("EDIT_USER", data);
        if ("first_name" in data) {
          this.dispatch("general/setAlert", {
            type: "success",
            msg: "nameChange",
          });
        } else if ("password" in data) {
          this.dispatch("general/setAlert", {
            type: "success",
            msg: "passChange",
          });
        } else if ("email" in data) {
          this.dispatch("general/setAlert", {
            type: "success",
            msg: "emailChange",
          });
        } else if ("phone" in data) {
          this.dispatch("general/setAlert", {
            type: "success",
            msg: "phoneChange",
          });
        }
        valid = true;
      })
      .catch((err) => {
        if (err.response.data.errors.email) {
          this.dispatch("general/setAlert", {
            type: "failed",
            msg: "emailTaken",
          });
        } else {
          this.dispatch("general/setAlert", {
            type: "failed",
            msg: "oops",
          });
        }
      });
    return valid;
  },
  async editNotifications({ commit }, request) {
    await axios
      .post("user/notifications/edit", request)
      .then((res) => {
        commit("SET_USER", res.data.data);
      })
      .catch(() => {
        this.dispatch("general/setAlert", {
          type: "failed",
          msg: "oops",
        });
      });
  },
  async cancelWhatsAppNotifications({ commit }, request) {
    await axios
      .get("user/notifications/whatsapp/unsubscribe", request)
      .then(() => {
        commit("EDIT_WHATSAPP", 0);
      })
      .catch(() => {
        this.dispatch("general/setAlert", {
          type: "failed",
          msg: "oops",
        });
      });
  },
  async toggleWhatsApp({ commit }) {
    commit("EDIT_WHATSAPP", 1);
  },
  async editWhatsAppNotifications({ commit }, request) {
    let valid = {
      valid: true,
      subscribe: false,
    };
    await axios
      .post("user/notifications/whatsapp/subscribe", request)
      .then(async (res) => {
        if (res.status == 200) {
          valid.valid = true;
          valid.subscribe = false;
        }
        if (res.status == 201) {
          valid.valid = true;
          valid.subscribe = true;
          commit("EDIT_WHATSAPP", 1);
        }
      })
      .catch(() => {
        valid.valid = false;
        valid.subscribe = false;
        this.dispatch("general/setAlert", {
          type: "failed",
          msg: "oops",
        });
      });
    return valid;
  },
  async setLocale({ commit }, value) {
    await axios
      .get("/user/locale?locale=" + value)
      .then(async () => {
        await commit("SET_LOCALE", value);
      })
      .catch((err) => console.log(err));
  },
};

/**
 * Getters
 */
export const getters = {
  token: (state) => state.token,
  valid: (state) => state.valid,
  user: (state) => state.user,
  checked: (state) => state.checked,
  name: (state) => state.user.first_name + " " + state.user.last_name,
  notifications: (state) => state.notifications,
};
