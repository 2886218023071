var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pull-to',{ref:"pullToContainer",attrs:{"top-config":_vm.topConfig,"is-bottom-bounce":false,"is-top-bounce":_vm.allowPullToRefresh,"top-load-method":_vm.loadMethod,"top-block-height":60},on:{"top-state-change":_vm.stateChange}},[_c('template',{slot:"top-block"},[(_vm.refresh)?_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-progress-circular',{attrs:{"width":2,"size":24,"color":"switchq","value":"70","indeterminate":_vm.loadingIcon == 'trigger' ? true : false}})],1):_c('v-layout')],1),_c('v-container',{attrs:{"grid-list-xs":"","fluid":"","xs12":""}},[(_vm.devices != null && Object.keys(_vm.groups).length)?_c('v-layout',{staticClass:"groups-layout",attrs:{"row":"","nowrap":""}},_vm._l((_vm.groups),function(group){return _c('v-flex',{key:group.slug,staticClass:"pa-3",attrs:{"xs12":"","md4":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-space-between":"","align-baseline":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('div',{staticClass:"px-2 pointer"},[_vm._v(" "+_vm._s(group.name)+" ")])]),_c('v-flex',{attrs:{"shrink":""}},[_c('router-link',{attrs:{"to":group.hasSubGroups
                  ? 'groups/' + group.slug + '/sub-groups'
                  : 'groups/' + group.slug + '/devices'}},[_vm._v(" "+_vm._s(_vm.$t("enter-group"))+" ")])],1)],1),_c('v-card',{staticClass:"groupCard",attrs:{"elevation":"3","outlined":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-layout',{staticClass:"cardCont",attrs:{"row":_vm.$vuetify.breakpoint.xsAndUp,"column":_vm.$vuetify.breakpoint.smAndDown}},[(
                  (group.hasLogs && Object.keys(group.sensors).length > 0) ||
                  (!group.hasLogs && Object.keys(group.sensors).length === 0)
                )?_c('v-flex',{staticClass:"sensors pointer",attrs:{"xs3":"","pa-3":""}},[(
                    group.hasLogs && Object.keys(group.sensors).length > 0
                  )?_c('v-layout',{staticClass:"groupAvgs",attrs:{"row":"","wrap":"","align-center":""}},[(group.sensors.temperatures)?_c('v-flex',{staticClass:"group-sensor-avg cardTemp"},[_c('h3',[_vm._v(_vm._s(_vm.$t("temp-avg")))]),_c('div',{staticClass:"sensData sensTemp mt-1"},[_c('span',{attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(group.sensors.temperatures)+" °C ")])])]):_vm._e(),(group.sensors.humidity)?_c('v-flex',{staticClass:"group-sensor-avg cardHum"},[_c('h3',[_vm._v(_vm._s(_vm.$t("hum-avg")))]),_c('div',{staticClass:"sensData sensHum mt-1"},[_vm._v(" "+_vm._s(group.sensors.humidity)+"% ")])]):_vm._e(),(group.sensors.pressure)?_c('v-flex',{staticClass:"group-sensor-avg cardPres"},[_c('h3',[_vm._v(_vm._s(_vm.$t("pres-avg")))]),_c('div',{staticClass:"sensData sensPres mt-1"},[_vm._v(" "+_vm._s(group.sensors.pressure)),_c('small',[_vm._v("hPa")])])]):_vm._e(),(group.sensors.co2)?_c('v-flex',{staticClass:"group-sensor-avg cardCo2"},[_c('h3',[_vm._v(_vm._s(_vm.$t("co2-avg")))]),_c('div',{staticClass:"sensData sensCo2 mt-1"},[_vm._v(" "+_vm._s(group.sensors.co2)),_c('small',[_vm._v("ppm")])])]):_vm._e()],1):_vm._e()],1):_vm._e(),(
                  group.devices.length &&
                  Object.keys(group.sensors).length > 0
                )?_c('v-divider',{attrs:{"light":"","vertical":_vm.$vuetify.breakpoint.mdAndUp}}):_vm._e(),(group.devices.length)?_c('v-flex',{attrs:{"xs12":"","xs9":(!group.hasLogs &&
                    Object.keys(group.sensors).length === 0) ||
                  (group.hasLogs && Object.keys(group.sensors).length > 0),"d-flex":"","flex-grow-1":!group.hasLogs && Object.keys(group.sensors).length === 0,"justify-center":"","align-center":(!group.hasLogs && group.devices.length === 0) ||
                  (group.hasLogs && Object.keys(group.sensors).length > 0)}},[(
                    group.hasLogs && Object.keys(group.sensors).length > 0
                  )?_c('GroupChart',{attrs:{"group-slug":group.slug}}):(
                    !group.hasLogs && Object.keys(group.sensors).length === 0
                  )?_c('v-progress-circular',{staticClass:"loader",attrs:{"size":120,"width":4,"color":"switchq","indeterminate":""}},[_vm._v(" "+_vm._s(_vm.$t("loading"))+"... ")]):_vm._e(),(
                    group.hasLogs && Object.keys(group.sensors).length === 0
                  )?_c('v-layout',{staticClass:"pointer noLogsCont",attrs:{"row":"","wrap":""},on:{"click":function($event){return _vm.$router.push('/groups/' + group.slug + '/devices/')}}},[_c('v-flex',{staticClass:"text-center",attrs:{"xs12":""}},[_c('div',{style:({
                        textDecoration: 'underline',
                        margin: '35px 0 20px 0',
                      })},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("devices"))+" ")])])]),_c('v-flex',{attrs:{"xs12":""}},_vm._l((group.displayDevices),function(device,index){return _c('div',{key:index,staticClass:"ma-2"},[_c('DeviceBar',{attrs:{"item":device,"index":index,"inGroup":true,"group":group}})],1)}),0)],1):_vm._e()],1):_vm._e(),(group.devices.length == 0 && !group.hasSubGroups)?_c('v-layout',{attrs:{"py-16":"","mb-16":"","row":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs8":"","justify-center":""}},[_c('div',{staticClass:"noSensorCont"},[_c('h3',{staticClass:"noSensors"},[_vm._v(" "+_vm._s(_vm.$t("empty-group"))+" ")]),_c('v-btn',{attrs:{"color":"switchq switchqSub--text"},on:{"click":function($event){return _vm.$router.push('/groups/edit')}}},[_vm._v(" "+_vm._s(_vm.$t("edit-group"))+" "),_c('Icon',{attrs:{"icon":"Plus"}})],1)],1)])],1):_vm._e(),(group.hasSubGroups)?_c('v-layout',{staticClass:"subGroupsContainer",attrs:{"row":"","wrap":"","pa-4":""}},[_c('v-flex',{staticClass:"text-center",attrs:{"xs12":""}},[_c('div',{style:({ textDecoration: 'underline' })},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("links.groups"))+" ")])])]),_vm._l((group.subs),function(sub,index){return _c('v-flex',{key:index,staticClass:"subGroupContainer",attrs:{"xs6":"","md4":"","lg3":"","pa-2":"","d-flex":"","column":""}},[_c('v-card',{attrs:{"height":"100"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(sub.name)+" ")])],1)],1)})],2):_vm._e()],1)],1)],1)],1)}),1):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }