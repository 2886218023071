<template>
  <v-layout row wrap justify-center>
    <v-flex xs12>
      <div class="drawing" id="loading">
        <div
          class="loading-dot switchq"
          :style="{
            color: $vuetify.theme.themes.light.switchq,
          }"
        ></div>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "loading",
};
</script>
<style lang="scss" scoped>
// .has-topbar {
//   margin-top: 195px;
//   .drawing {
//     min-height: calc(100vh - 195px);
//   }
//   @media only screen and (max-width: 600px) {
//     margin-top: 60px;
//     .drawing {
//       min-height: calc(100vh - 60px);
//     }
//   }
// }

/* -- Loading thingy --*/
.loading-dot {
  border-radius: 100%;
  height: 10px;
  width: 10px;
  // background-color: currentColor;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  box-shadow: 0px 40px 0px 0px currentColor, 0px -40px 0px 0px currentColor,
    40px 0px 0px 0px currentColor, -40px 0px 0px 0px currentColor,
    40px 40px 0px 0px currentColor, -40px -40px 0px 0px currentColor,
    40px -40px 0px 0px currentColor, -40px 40px 0px 0px currentColor;
  -webkit-animation: load 3s infinite linear;
  -moz-animation: load 3s infinite linear;
  -o-animation: load 3s infinite linear;
  animation: load 3s infinite linear;
}

@-webkit-keyframes load {
  0% {
    -webkit-transform: translate(0px, 0px), rotate(0deg);
    box-shadow: 0px 40px 0px 0px currentColor, 0px -40px 0px 0px currentColor,
      40px 0px 0px 0px currentColor, -40px 0px 0px 0px currentColor,
      40px 40px 0px 0px currentColor, -40px -40px 0px 0px currentColor,
      40px -40px 0px 0px currentColor, -40px 40px 0px 0px currentColor;
  }

  25% {
    box-shadow: 0px 40px 0px 0px currentColor, 0px -40px 0px 0px currentColor,
      40px 0px 0px 0px currentColor, -40px 0px 0px 0px currentColor,
      -40px -40px 0px 0px currentColor, 40px 40px 0px 0px currentColor,
      -40px 40px 0px 0px currentColor, 40px -40px 0px 0px currentColor;
  }

  50% {
    box-shadow: 0px -40px 0px 0px currentColor, 0px 40px 0px 0px currentColor,
      -40px 0px 0px 0px currentColor, 40px 0px 0px 0px currentColor,
      -40px -40px 0px 0px currentColor, 40px 40px 0px 0px currentColor,
      -40px 40px 0px 0px currentColor, 40px -40px 0px 0px currentColor;
  }

  70% {
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
  }

  75% {
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
    height: 10px;
    width: 10px;
  }

  80% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
    background-color: currentColor;
    height: 40px;
    width: 40px;
  }

  85% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
    background-color: currentColor;
    height: 40px;
    width: 40px;
  }

  90% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
    background-color: currentColor;
    height: 10px;
    width: 10px;
  }

  95% {
    box-shadow: 0px 40px 0px 0px currentColor, 0px -40px 0px 0px currentColor,
      40px 0px 0px 0px currentColor, -40px 0px 0px 0px currentColor,
      40px 40px 0px 0px currentColor, -40px -40px 0px 0px currentColor,
      40px -40px 0px 0px currentColor, -40px 40px 0px 0px currentColor;
  }

  100% {
    -webkit-transform: rotate(360deg);
    box-shadow: 0px 40px 0px 0px currentColor, 0px -40px 0px 0px currentColor,
      40px 0px 0px 0px currentColor, -40px 0px 0px 0px currentColor,
      40px 40px 0px 0px currentColor, -40px -40px 0px 0px currentColor,
      40px -40px 0px 0px currentColor, -40px 40px 0px 0px currentColor;
  }
}

@-moz-keyframes load {
  0% {
    -moz-transform: translate(0px, 0px), rotate(0deg);
    box-shadow: 0px 40px 0px 0px currentColor, 0px -40px 0px 0px currentColor,
      40px 0px 0px 0px currentColor, -40px 0px 0px 0px currentColor,
      40px 40px 0px 0px currentColor, -40px -40px 0px 0px currentColor,
      40px -40px 0px 0px currentColor, -40px 40px 0px 0px currentColor;
  }

  25% {
    box-shadow: 0px 40px 0px 0px currentColor, 0px -40px 0px 0px currentColor,
      40px 0px 0px 0px currentColor, -40px 0px 0px 0px currentColor,
      -40px -40px 0px 0px currentColor, 40px 40px 0px 0px currentColor,
      -40px 40px 0px 0px currentColor, 40px -40px 0px 0px currentColor;
  }

  50% {
    box-shadow: 0px -40px 0px 0px currentColor, 0px 40px 0px 0px currentColor,
      -40px 0px 0px 0px currentColor, 40px 0px 0px 0px currentColor,
      -40px -40px 0px 0px currentColor, 40px 40px 0px 0px currentColor,
      -40px 40px 0px 0px currentColor, 40px -40px 0px 0px currentColor;
  }

  70% {
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
  }

  75% {
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
    height: 10px;
    width: 10px;
  }

  80% {
    -moz-transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
    background-color: currentColor;
    height: 40px;
    width: 40px;
  }

  85% {
    -moz-transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
    background-color: currentColor;
    height: 40px;
    width: 40px;
  }

  90% {
    -moz-transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
    background-color: currentColor;
    height: 10px;
    width: 10px;
  }

  95% {
    box-shadow: 0px 40px 0px 0px currentColor, 0px -40px 0px 0px currentColor,
      40px 0px 0px 0px currentColor, -40px 0px 0px 0px currentColor,
      40px 40px 0px 0px currentColor, -40px -40px 0px 0px currentColor,
      40px -40px 0px 0px currentColor, -40px 40px 0px 0px currentColor;
  }

  100% {
    -moz-transform: rotate(360deg);
    box-shadow: 0px 40px 0px 0px currentColor, 0px -40px 0px 0px currentColor,
      40px 0px 0px 0px currentColor, -40px 0px 0px 0px currentColor,
      40px 40px 0px 0px currentColor, -40px -40px 0px 0px currentColor,
      40px -40px 0px 0px currentColor, -40px 40px 0px 0px currentColor;
  }
}

@keyframes load {
  0% {
    transform: translate(0px, 0px), rotate(0deg);
    box-shadow: 0px 40px 0px 0px currentColor, 0px -40px 0px 0px currentColor,
      40px 0px 0px 0px currentColor, -40px 0px 0px 0px currentColor,
      40px 40px 0px 0px currentColor, -40px -40px 0px 0px currentColor,
      40px -40px 0px 0px currentColor, -40px 40px 0px 0px currentColor;
  }

  25% {
    box-shadow: 0px 40px 0px 0px currentColor, 0px -40px 0px 0px currentColor,
      40px 0px 0px 0px currentColor, -40px 0px 0px 0px currentColor,
      -40px -40px 0px 0px currentColor, 40px 40px 0px 0px currentColor,
      -40px 40px 0px 0px currentColor, 40px -40px 0px 0px currentColor;
  }

  50% {
    box-shadow: 0px -40px 0px 0px currentColor, 0px 40px 0px 0px currentColor,
      -40px 0px 0px 0px currentColor, 40px 0px 0px 0px currentColor,
      -40px -40px 0px 0px currentColor, 40px 40px 0px 0px currentColor,
      -40px 40px 0px 0px currentColor, 40px -40px 0px 0px currentColor;
  }

  70% {
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
  }

  75% {
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
    height: 10px;
    width: 10px;
  }

  80% {
    transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
    background-color: currentColor;
    height: 40px;
    width: 40px;
  }

  85% {
    transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
    background-color: currentColor;
    height: 40px;
    width: 40px;
  }

  90% {
    transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
    background-color: currentColor;
    height: 10px;
    width: 10px;
  }

  95% {
    box-shadow: 0px 40px 0px 0px currentColor, 0px -40px 0px 0px currentColor,
      40px 0px 0px 0px currentColor, -40px 0px 0px 0px currentColor,
      40px 40px 0px 0px currentColor, -40px -40px 0px 0px currentColor,
      40px -40px 0px 0px currentColor, -40px 40px 0px 0px currentColor;
  }

  100% {
    transform: rotate(360deg);
    box-shadow: 0px 40px 0px 0px currentColor, 0px -40px 0px 0px currentColor,
      40px 0px 0px 0px currentColor, -40px 0px 0px 0px currentColor,
      40px 40px 0px 0px currentColor, -40px -40px 0px 0px currentColor,
      40px -40px 0px 0px currentColor, -40px 40px 0px 0px currentColor;
  }
}
@-o-keyframes load {
  0% {
    -o-transforms: translate(0px, 0px), rotate(0deg);
    box-shadow: 0px 40px 0px 0px currentColor, 0px -40px 0px 0px currentColor,
      40px 0px 0px 0px currentColor, -40px 0px 0px 0px currentColor,
      40px 40px 0px 0px currentColor, -40px -40px 0px 0px currentColor,
      40px -40px 0px 0px currentColor, -40px 40px 0px 0px currentColor;
  }

  25% {
    box-shadow: 0px 40px 0px 0px currentColor, 0px -40px 0px 0px currentColor,
      40px 0px 0px 0px currentColor, -40px 0px 0px 0px currentColor,
      -40px -40px 0px 0px currentColor, 40px 40px 0px 0px currentColor,
      -40px 40px 0px 0px currentColor, 40px -40px 0px 0px currentColor;
  }

  50% {
    box-shadow: 0px -40px 0px 0px currentColor, 0px 40px 0px 0px currentColor,
      -40px 0px 0px 0px currentColor, 40px 0px 0px 0px currentColor,
      -40px -40px 0px 0px currentColor, 40px 40px 0px 0px currentColor,
      -40px 40px 0px 0px currentColor, 40px -40px 0px 0px currentColor;
  }

  70% {
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
  }

  75% {
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
    height: 10px;
    width: 10px;
  }

  80% {
    -o-transforms: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
    background-color: currentColor;
    height: 40px;
    width: 40px;
  }

  85% {
    -o-transforms: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
    background-color: currentColor;
    height: 40px;
    width: 40px;
  }

  90% {
    -o-transforms: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor,
      0px 0px 0px 0px currentColor, 0px 0px 0px 0px currentColor;
    background-color: currentColor;
    height: 10px;
    width: 10px;
  }

  95% {
    box-shadow: 0px 40px 0px 0px currentColor, 0px -40px 0px 0px currentColor,
      40px 0px 0px 0px currentColor, -40px 0px 0px 0px currentColor,
      40px 40px 0px 0px currentColor, -40px -40px 0px 0px currentColor,
      40px -40px 0px 0px currentColor, -40px 40px 0px 0px currentColor;
  }

  100% {
    -o-transforms: rotate(360deg);
    box-shadow: 0px 40px 0px 0px currentColor, 0px -40px 0px 0px currentColor,
      40px 0px 0px 0px currentColor, -40px 0px 0px 0px currentColor,
      40px 40px 0px 0px currentColor, -40px -40px 0px 0px currentColor,
      40px -40px 0px 0px currentColor, -40px 40px 0px 0px currentColor;
  }
}
</style>
