<style lang="css" scoped></style>

<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on }">
      <v-btn color="error" block @click="dialogDelete()" v-on="on">{{
        content.delete_switch
      }}</v-btn>
    </template>
    <v-card>
      <v-card-title
        class="headline deep-orange lighten-4 red--text text--darken-4"
        primary-title
        >{{ content.delete_switch }}</v-card-title
      >
      <v-card-text>
        {{ content.delete_val }}
        <br />
        {{ content.delete_code }} : {{ deleteNumber }}
        <v-text-field
          type="number"
          v-model="deletecode"
          :label="content.deleting_code"
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          :disabled="deletecode != deleteNumber ? true : false"
          color="red darken-4"
          dark
          :text="deletecode != deleteNumber ? true : false"
          @click="deleteSwitch()"
          >{{ content.agree }}</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn text @click="cancel">{{ content.cancel }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Lang from "@/helpers/lang";
export default {
  props: ["slug"],
  data() {
    return {
      locale: null,
      dialog: false,
      deleteNumber: null,
      deletecode: null,
    };
  },
  created() {
    this.locale = Lang.get();
    this.setLang();
  },
  methods: {
    async deleteSwitch() {
      // this.$emit('detachDevice');
      let data = {
        slug: this.slug,
        action: "delete",
      };
      await this.$store
        .dispatch("devices/modifyDevice", data)
        .then(() => {
          this.$store.dispatch("general/setAlert", {
            type: "success",
            msg: "deleted",
          });
          this.$store.dispatch("devices/fetchDevices");
          this.dialog = false;
          this.$router.push("/devices");
          // window.location.reload();
        })
        .catch(() => {
          this.$store.dispatch("general/setAlert", {
            type: "failed",
            msg: "oops",
          });
        });

      // detach(){
      //   // this.$emit('modifyItemEvent', data);
      // },
    },
    dialogDelete() {
      this.$emit("toggleSettings", false);
      this.dialog = true;
      this.deletecode = null;
      this.deleteNumber =
        Math.floor(Math.random() * 9) +
        "" +
        Math.floor(Math.random() * 9) +
        "" +
        Math.floor(Math.random() * 9) +
        "" +
        Math.floor(Math.random() * 9);
    },
    cancel() {
      this.dialog = false;
      this.$emit("toggleSettings", true);
    },
    setLang() {
      if (this.locale == "en") {
        this.content = {
          cancel: "Cancel",
          agree: "Agree",
          delete_switch: "Delete Unit",
          delete_val: "Are you sure you want to delete the unit?",
          delete_code: "The Code for deleing is",
          deleting_code: "Type the code",
        };
      } else if (this.locale == "he") {
        this.content = {
          cancel: "ביטול",
          agree: "מסכים",
          delete_switch: "מחק יחידה",
          delete_val: "אתה בטוח שברצונך למחוק את היחידה?",
          delete_code: "הקוד למחיקה הוא",
          deleting_code: "הקלד את הקוד",
        };
      }
    },
  },
};
</script>
