// import Auth from "./auth";
import axios from "axios";
import dayjs from "dayjs";

let VIEW = {};

class Devices {
  constructor() {
    this.setViewObj();
  }

  setViewObj() {
    VIEW = Object.keys(VIEW).length > 0 ? VIEW : {};
    if (!VIEW && localStorage.getItem("mydataview")) {
      VIEW = JSON.parse(
        decodeURIComponent(
          escape(window.atob(localStorage.getItem("mydataview")))
        )
      );
    }
  }

  pull() {
    axios
      .get(`/api/switch/`)
      .then((res) => {
        if (res.data) {
          this.store(res.data);
        }
        return res.data;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  store(items) {
    let view = {};
    for (let device in items) {
      if (device in items) {
        if (items[device] && items[device].view) {
          view[device] = items[device].view;
        }
      }
    }
    VIEW = view;
    const base64view = btoa(unescape(encodeURIComponent(JSON.stringify(view))));
    localStorage.setItem("mydataview", base64view);
    items = btoa(unescape(encodeURIComponent(JSON.stringify(items))));
    localStorage.setItem("mydata", items);
  }

  get(item = false) {
    let items = localStorage.getItem("mydata");
    if (items) {
      items = JSON.parse(decodeURIComponent(escape(window.atob(items))));
      if (item) {
        return items[item];
      }
      return items;
    } else {
      return null;
    }
  }

  unstore() {
    localStorage.removeItem("mydata");
  }

  analyze(items) {
    for (let item in items) {
      items[item] = this.analyzeItem(items[item]);
    }
    return items;
  }

  analyzeItem(device) {
    // console.log(device);
    // try {
    const hasData = device.data && Object.keys(device.data) != 0;
    // if (device.data && Object.keys(device.data) != 0) {
    // }
    if (!hasData) device.data = {};
    this.fixName(device);
    if (!device.view) {
      const OLD = this.get(device.slug);
      if (OLD && OLD.view) device.view = OLD.view;
    }
    this.checkConnection(device);
    if (hasData && device.role == 1 && !isNaN(device.data.output)) {
      device.status = device.data.output ? "On" : "Off";
    } else {
      this.exceeds(device);
    }
    if (device.active_status == 0) {
      device.status = "Unpaid";
    }
    if (!device.connection) device.status = "Offline";
    this.getDevmodes(device);
    device.received = dayjs(device.received_at).format("HH:mm:ss DD/MM/YYYY");
    return device;
    // } catch (error) {
    //   console.error(error);
    //   console.log(device);
    // }
  }

  fixName(device) {
    const NAME = device.name;
    if (!NAME) {
      const OLD = this.get(device.slug);
      device.name = OLD && OLD.name ? OLD.name : NAME;
      if (name && NAME) device.name = NAME;
      if (device.name) {
        localStorage.setItem(device.slug + "-name", device.name);
      } else {
        let OLDNAME = localStorage.getItem(device.slug + "-name");
        if (OLDNAME) device.name = OLDNAME;
      }
    }
  }

  checkConnection(device) {
    // device.connection = device.online;
    const received = +new Date(device.received_at);
    const ttl = device.offline_notification.ttl * 60;
    const diff = Math.floor((+new Date() - received) / 1000);
    const isOnline = diff <= ttl;
    device.connection = isOnline;
  }

  exceeds(device) {
    let exceeds = null;
    let rules = device.rules;
    let status = "On";
    let exceedsVal = null;
    let exceedsRule = null;
    for (let i = 0; i < rules.length; i++) {
      const ruleValue = parseFloat(rules[i].value);
      const sensors = device.data.sensors;
      if ("temperatures" in sensors) {
        sensors["temperature"] = sensors["temperatures"][0];
      }
      const sensorValue = parseFloat(sensors[rules[i].sensor]);
      if (rules[i].sensor in device.data.sensors) {
        if (ruleValue > sensorValue && rules[i].action == "<") {
          exceeds = true;
          status = "Off";
          exceedsVal = rules[i].value;
          exceedsRule = rules[i].sensor;
        }
        if (ruleValue < sensorValue && rules[i].action == ">") {
          exceeds = true;
          exceedsVal = ruleValue;
          status = "Off";
          exceedsRule = rules[i].sensor;
        }

        device.exceeds = exceeds;
        device.status = status;
        device.exceedsVal = exceedsVal;
        device.exceedsRule = exceedsRule;
      }
    }
    device.status = status;
  }

  getDevmodes(device) {
    let devmodes = 0;
    switch (device.settings.sensor_type) {
      case 1:
        devmodes = 3;
        break;
      case 2:
      case 3:
      case 4:
      case 5:
        devmodes = 5;
        break;
      case device.data.battery &&
        device.data.battery.value !== null &&
        device.data.sensor_type > 0 &&
        device.data.sensor_type < 7:
        devmodes = 7;
        break;
    }
    device.devmodes = devmodes;
  }
}

export default new Devices();
