<template>
  <v-layout row wrap>
    <!-- !!!!!!!!!   - - -  Mode Buttons  - - -   !!!!!!!!! -->
    <ModeBtns
      v-if="item.active && item.connection && item.role == 1"
      :slug="item.slug"
      :mode="item.data.mode"
      class="minWidth160"
    />

    <!-- !!!!!!!!!   - - -  Tamper  - - -   !!!!!!!!! -->
    <v-flex
      v-if="item.data.sensors && item.data.sensors.tamper"
      d-flex
      class="text-right"
      :me-4="item.status == 'Off' || !item.connection"
    >
      <span class="orange--text text--darken-4">{{ content.tamper }}</span>
    </v-flex>

    <!-- !!!!!!!!!   - - -  Not Connected  - - -   !!!!!!!!! -->
    <v-flex v-if="!item.connection" d-flex class="text-xs-right">
      <span class="black--text">{{ content.not_connected }}</span>
    </v-flex>

    <!-- !!!!!!!!!   - - -  Not Connected  - - -   !!!!!!!!! -->
    <v-flex v-if="!item.active" d-flex class="text-xs-right">
      <span class="black--text"> {{ content.unpaid }}! </span>
    </v-flex>

    <!-- !!!!!!!!!   - - - Forbidden area  - - -   !!!!!!!!! -->
    <v-flex
      v-if="item.role === 2 && item.status === 'Off'"
      d-flex
      class="text-xs-right"
    >
      <span class="black--text">{{ content.forbidden_area }}</span>
    </v-flex>

    <!-- !!!!!!!!!   - - -  Sensor Exceeds  - - -   !!!!!!!!! -->
    <v-flex
      v-if="item.role == 0 && item.status == 'Off' && !item.data.sensors.tamper"
      d-flex
      class="text-right"
    >
      <span v-if="item.data.sensors.dry_contact" class="black--text">{{
        content.sensor_close
      }}</span>
      <span
        v-if="
          item.exceeds &&
          item.exceedsRule != 'water' &&
          item.exceedsRule != 'hall'
        "
        class="black--text"
      >
        {{ $t(item.exceedsRule) }} {{ content.exceeds }}
      </span>
      <span v-if="item.data.sensors.water" class="black--text">{{
        content.flooding
      }}</span>
      <span
        class="black--text"
        v-if="item.settings.sensor_type === 11 && item.data.sensors.hall"
        >{{ content.magnet_true }}</span
      >
      <span
        class="black--text"
        v-if="item.settings.sensor_type === 11 && !item.data.sensors.hall"
        >{{ content.magnet_false }}</span
      >
      <span
        class="black--text"
        v-if="item.settings.sensor_type === 12 && item.data.sensors.hall"
        >{{ content.door_true }}</span
      >
      <span
        class="black--text"
        v-if="item.settings.sensor_type === 12 && !item.data.sensors.hall"
        >{{ content.door_false }}</span
      >

      <span
        class="black--text"
        v-if="item.settings.sensor_type === 13 && item.data.sensors.hall"
        >{{ content.window_true }}</span
      >
      <span
        class="black--text"
        v-if="item.settings.sensor_type === 13 && !item.data.sensors.hall"
        >{{ content.window_false }}</span
      >
      <span
        v-if="item.data.sensors.detection && item.status == 'Off'"
        class="black--text"
        >{{ content.motion }}</span
      >
      <span v-if="item.data.sensors.smoke" class="black--text">{{
        content.smoke
      }}</span>
    </v-flex>
  </v-layout>
</template>
<script>
import ModeBtns from "@/components/devices/modebtns.vue";
import Lang from "@/helpers/lang";
export default {
  name: "DeviceBarBtns",
  props: ["item"],
  components: {
    ModeBtns,
  },
  data() {
    return {
      locale: Lang.get(),
      content: {},
    };
  },
  computed: {
    dir() {
      return this.$vuetify.rtl ? "rtl" : "ltr";
    },
  },
  created() {
    this.setLang();
  },
  methods: {
    setLang() {
      if (this.locale == "en") {
        this.content = {
          countdevices: "Number of devices",
          editview: "edit view",
          addDevices: "add switches",
          unpaid: "Not Authorized",
          not_connected: "Not Connected",
          no_sensor: "Sensor is not connected",
          forbidden_area: "Forbidden area!",
          exceeds: "Exceeds the threshold",
          tamper: "Tamper warning!",
          flooding: "Warning of flooding!",
          motion: "Detected motion!",
          smoke: "Warning of smoke!",
          sensor_close: "Warning of flooding!",
          sensor_open: "Normal state",
          magnet_true: "Magnet detected",
          magnet_false: "Magnet is NOT detected",
          door_true: "The door is closed",
          door_false: "The door is open",
          window_true: "The window is closed",
          window_false: "The window is open",
          empty: {
            group: "The group don't have registered switches yet!",
            title: "You don't have any switches registered yet!",
            subtitle: "Click here to register a new switch",
            btn: "Register A New Switch",
            sectitle: "All of our devices are hidden",
            clickto: "Click here to ",
          },
        };
      } else if (this.locale == "he") {
        this.content = {
          countdevices: "מספר מכשירים",
          editview: "עריכת תצוגה",
          addDevices: "הוספת מכשירים",
          unpaid: "לא מורשה",
          not_connected: "לא מחובר",
          no_sensor: "חיישן אינו מחובר",
          forbidden_area: "באזור אסור!",
          exceeds: "חורג מהסף",
          tamper: "אזהרת התעסקות!",
          flooding: "אזהרת הצפה!",
          motion: "זיהה תנועה!",
          smoke: "אזהרת עשן!",
          sensor_close: "אזהרת הצפה!",
          sensor_open: "מצב תקין",
          magnet_true: "מזהה מגנט",
          magnet_false: "לא מזהה מגנט",
          door_true: "הדלת סגורה",
          door_false: "הדלת פתוחה",
          window_true: "החלון סגור",
          window_false: "החלון פתוח",
          empty: {
            group: "עדיין לא נוספו מכשירים לקבוצה!",
            title: "עדיין לא רשומים אצלך מכשירים!",
            subtitle: "לחץ כאן כדי לרשום מכשיר חדש",
            btn: "הוספת מכשיר חדש",
            sectitle: "כל המכשירים הרשומים אצלך מוסתרים.",
            clickto: "לחץ כאן ל",
          },
        };
      }
    },
  },
};
</script>
<style lang="css" scoped>
.dryContact {
  max-height: 20px;
  /* max-width: 30px; */
  width: auto;
}

.maxWidthWithBtns {
  max-width: calc(100vw - 165px);
}
.itemBtns .minWidth160 {
  min-width: 120px;
}
</style>
