<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="350">
      <v-card>
        <Icon icon="mdiClose" @click.native="$emit('close')" iconClass="pa-3" />
        <v-card-title class="text-h5">
          <v-layout row wrap justify-center align-center>
            <v-flex shrink>
              {{ $t("changePass") }}
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-card-text class="text-center">
          {{ $t("first-login") }}<br />
          {{ $t("reason") }}<br />
          <v-form @submit.prevent="savePass" ref="passwordForm">
            <v-flex xs12>
              <v-layout row nowrap align-center>
                <v-flex grow>
                  <v-text-field
                    autocomplete="new-password"
                    :type="passwords.reveal.new ? 'text' : 'password'"
                    v-model="form.password"
                    :label="content.new_password"
                    :rules="rules.new_password"
                    validate-on-blur
                  ></v-text-field>
                </v-flex>
                <v-flex v-if="form.password" shrink>
                  <v-btn
                    text
                    icon
                    color="primary"
                    @click="passwords.reveal.new = !passwords.reveal.new"
                  >
                    <Icon :icon="passwords.reveal.new ? 'eyeOff' : 'eye'" />
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12>
              <v-layout row nowrap align-center>
                <v-flex grow>
                  <v-text-field
                    autocomplete="new-password"
                    :type="passwords.reveal.confirm ? 'text' : 'password'"
                    v-model="form.password_confirmation"
                    :label="content.confirm + ' ' + content.password"
                    :rules="rules.confirm_password"
                    validate-on-blur
                  ></v-text-field>
                </v-flex>
                <v-flex v-if="form.password_confirmation" shrink>
                  <v-btn
                    text
                    icon
                    color="primary"
                    @click="
                      passwords.reveal.confirm = !passwords.reveal.confirm
                    "
                  >
                    <Icon :icon="passwords.reveal.confirm ? 'eyeOff' : 'eye'" />
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :loading="loading"
            color="blue darken-1"
            text
            @click="savePass"
            >{{ content.save }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
// import passwordStr from "../tools/passwordStr";
import Lang from "@/helpers/lang";
export default {
  name: "ChangePassModal",
  // components: { passwordStr },
  props: ["activator"],
  data() {
    return {
      dialog: false,
      form: {
        password: null,
        password_confirmation: null,
      },
      passwords: {
        reveal: {
          new: false,
          confirm: false,
        },
        strength: 0,
        error: null,
      },
      rules: [],
      locale: null,
      loading: false,
    };
  },

  created() {
    this.dialog = this.activator;
    this.locale = Lang.get();
    this.setLang();
    this.setRules();
  },
  watch: {
    activator() {
      this.dialog = this.activator;
    },
  },
  methods: {
    async savePass() {
      if (this.$refs.passwordForm.validate()) {
        this.loading = true;
        // let form = {
        //   current_password: "12345678",
        //   password: this.form.password,
        //   password_confirmation: this.form.password_confirmation,
        // };
        // console.log("form to send", form);
        let changed = await this.$store.dispatch("user/editUser", {
          current_password: "12345678",
          password: this.form.password,
          password_confirmation: this.form.password_confirmation,
        });
        // console.log("changed from res", changed);
        if (changed) {
          // console.log("changed in if", changed);
          this.loading = false;
          this.$emit("close");
        } else if (!changed) {
          this.loading = false;
        }
      }
    },
    setRules() {
      this.rules = {
        email: [
          (v) => !!v || this.content.rules.email[0],
          (v) => /.+@.+/.test(v) || this.content.rules.email[1],
          (v) =>
            !this.emailTaken.includes(v) || this.$t("validation.email_taken"),
        ],
        email_confirm: [
          (v) => !!v || this.content.rules.email_confirm[0],
          (v) => v == this.form.email || this.content.rules.email_confirm[1],
        ],
        fname: [
          (v) => !!v || this.content.rules.fname[0],
          (v) => (v && v.length > 1) || this.content.rules.fname[1],
          (v) => (v && v.length < 21) || this.content.rules.fname[2],
        ],
        lname: [
          (v) => !!v || this.content.rules.lname[0],
          (v) => (v && v.length > 1) || this.content.rules.lname[1],
          (v) => (v && v.length < 21) || this.content.rules.lname[2],
        ],
        current_password: [
          (v) => !!v || this.content.rules.passwords.current[0],
        ],
        new_password: [
          (v) => !!v || this.content.rules.passwords.new[0],
          (v) => v != "12345678" || this.content.rules.passwords.new[3],
          // () =>
          //   this.passwords.strength > 25 || this.content.rules.passwords.new[1],
          (v) => (v && v.length < 19) || this.content.rules.passwords.new[2],
        ],
        confirm_password: [
          (v) => !!v || this.content.rules.passwords.confirm[0],
          (v) =>
            v == this.form.password || this.content.rules.passwords.confirm[1],
        ],
      };
    },
    setLang() {
      if (this.locale == "en") {
        this.content = {
          title: "Profile",
          hey: "Hey",
          email: "Email Address",
          name: "Full Name",
          fname: "First Name",
          lname: "Last Name",
          password: "Password",
          edit_password: "Edit Password",
          new_password: "New Password",
          current_password: "Current Password",

          rules: {
            email: ["Email is required", "Email must be valid"],
            email_confirm: [
              "Email confirmation is required",
              "The Email addresses doe's not match",
            ],
            fname: [
              "First Name is required",
              "First Name must be more than 1 characters",
              "First Name can't be more than 20 characters",
            ],
            lname: [
              "Last Name is required",
              "Last Name must be more than 1 characters",
              "Last Name can't be more than 20 characters",
            ],
            passwords: {
              current: ["Current Password is required"],
              new: [
                "New Password is required",
                "The new Password is too weak",
                "The new Password is too long",
                "You can't use the same Password",
              ],
              confirm: [
                "Password Confirmation is required",
                "The Passwords does not match",
              ],
            },
          },

          changed: {
            email: "the Email address was changed",
            password: "the Password was changed",
            name: "the Name was changed",
          },
          notChanged: {
            email: "the Email address was not changed",
            password: "the Password was not changed",
            name: "the Name was not changed",
          },
          errors: {
            wrong_password: "The Current Password is Wrong!",
            same_password: "You used the same Password",
          },

          edit: "Edit",
          save: "Save",
          cancel: "Cancel",
          confirm: "Confirm",
        };
      } else if (this.locale == "he") {
        this.content = {
          title: "פרופיל",
          hey: "היי",
          email: "כתובת אימייל",
          name: "שם מלא",
          fname: "שם פרטי",
          lname: "שם משפחה",
          password: "סיסמה",
          edit_password: "שינוי סיסמה",
          new_password: "סיסמה חדשה",
          current_password: "סיסמה נוכחית",

          rules: {
            email: ["נדרש למלא כתובת אימייל", "כתובת האימייל אינה תקינה"],
            email_confirm: [
              "נדרש למלא אישור כתובת אימייל",
              "כתובות האימייל אינן תואמות",
            ],
            fname: [
              "נדרש למלא שם פרטי",
              "שם פרטי חייב להיות ארוך מתו 1",
              "שם פרטי לא יכול להיות ארוך מ-20 תווים",
            ],
            lname: [
              "נדרש למלא שם משפחה",
              "שם משפחה חייב להיות ארוך מתו 1",
              "שם משפחה לא יכול להיות ארוך מ-20 תווים",
            ],
            passwords: {
              current: ["נדרש למלא את הסיסמה הנוכחית"],
              new: [
                "נדרש למלא סיסמה חדשה",
                "הסיסמה החדשה חלשה מידי",
                "הסיסמה החדשה ארוכה מידי",
                "אי אפשר להשתמש באותה הסיסמה",
              ],
              confirm: ["נדרש למלא אישור סיסמה", "הסיסמהות אינן תואמות"],
            },
          },

          changed: {
            email: "כתובת האימייל השתנתה",
            password: "הסיסמה השתנתה",
            name: "השם השתנה",
          },
          notChanged: {
            email: "כתובת האימייל לא השתנתה",
            password: "הסיסמה לא השתנתה",
            name: "השם לא השתנה",
          },
          errors: {
            wrong_password: "הסיסמה הנוכחית שגויה!",
            same_password: "השתמשת באותה הסיסמה",
          },

          edit: "עריכה",
          save: "שמירה",
          cancel: "ביטול",
          confirm: "אישור",
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-icon {
  margin: 0 auto;
}
.v-dialog > .v-card > .v-card__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
