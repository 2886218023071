<template>
  <v-card
    color="transparent"
    class="d-flex switchBar"
    :status="
      dir +
      (item.role == 0 ? '-Sensor-' : item.role === 2 ? '-Location-' : '-') +
      item.status
    "
  >
    <v-layout row nowrap justify-space-between class="py-0 px-2">
      <v-layout
        @click="openItem(item.slug)"
        column
        align-start
        justify-center
        grow
        :class="
          item.connection && item.role == 1
            ? 'maxWidthWithBtns pointer'
            : 'pointer'
        "
      >
        <div class="itemTitle">
          <h3 primary-title class="black--text ma-0 pa-0">
            {{ item.name }}
          </h3>
        </div>

        <v-flex shrink v-if="item.active">
          <DeviceBarData :item="item" />
        </v-flex>
      </v-layout>

      <v-layout row justify-end align-center shrink class="itemBtns">
        <DeviceBarBtns :item="item" />
      </v-layout>
    </v-layout>
  </v-card>
</template>
<script>
import DeviceBarData from "@/components/devices/DeviceBarData.vue";
import DeviceBarBtns from "@/components/devices/DeviceBarBtns.vue";
export default {
  name: "DeviceBar",
  props: ["item", "index", "inGroup", "group"],
  components: {
    DeviceBarData,
    DeviceBarBtns,
  },
  data() {
    return {};
  },
  computed: {
    dir() {
      return this.$vuetify.rtl ? "rtl" : "ltr";
    },
  },
  created() {},
  methods: {
    openItem(slug) {
      this.selectedItem = slug;
      let path = this.inGroup ? "/groups/" + this.group.slug : "";
      path += "/devices/" + slug;
      if (this.$route.path !== path) {
        this.$router.push({ path: path });
      }
    },
  },
};
</script>
<style lang="css" scoped>
.switchBar {
  min-height: 50px;
  align-items: center;
}

.itemTitle {
  font-size: 11pt;
}

.pt-0 {
  margin-top: -5px;
}

.maxWidthWithBtns {
  max-width: calc(100vw - 165px);
}
.itemBtns .minWidth160 {
  min-width: 120px;
}

.layout.itemBtns.row.wrap.justify-end.align-center.shrink {
  font-size: 13pt;
}

@media screen and (max-width: 600px) {
  .itemTitle,
  .limitWidth {
    width: 100%;
  }
}

.container.grid-list-xs .layout:not(:only-child) {
  margin: unset;
}
</style>
