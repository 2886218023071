<template>
  <v-layout row wrap v-if="item" class="item-layout px-1">
    <v-flex xs12 class="device-header white" elevation-2>
      <template flat>
        <div
          class="switchBar pa-2"
          :status="
            dir +
            (item.role == 0
              ? '-Sensor-'
              : item.role === 2
              ? '-Location-'
              : '-') +
            item.status
          "
        >
          <v-layout row wrap justify-space-between align-center>
            <v-flex class="itemTitle">
              <v-layout column wrap>
                <h2 class="grey--text text--darken-4">
                  {{ item.name }}
                </h2>
                <small class="grey--text text--darken-3" id="identifier">{{
                  item.identifier
                }}</small>
              </v-layout>
            </v-flex>
            <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />

            <v-flex v-if="item.connection == false" class="mr-2" shrink>
              <span class="title grey--text text--darken-3">
                {{ content.not_connected }}
              </span>
            </v-flex>
            <v-flex
              v-if="
                item.exceeds &&
                item.connection == true &&
                (item.settings.sensor_type < 10 ||
                  item.settings.sensor_type > 13)
              "
              d-flex
              nowrap
              row
              shrink
            >
              <span class="title grey--text text--darken-3">{{
                content.exceeds
              }}</span>
            </v-flex>
            <v-flex
              v-if="
                item.exceeds &&
                item.connection == true &&
                item.settings.sensor_type == 11
              "
              d-flex
              nowrap
              row
              shrink
            >
              <span
                class="title grey--text text--darken-3"
                v-if="
                  item.settings.sensor_type === 11 && item.data.sensors.hall
                "
                >{{ content.magnet_true }}</span
              >
              <span class="title grey--text text--darken-3" v-else>{{
                content.magnet_false
              }}</span>
            </v-flex>
            <v-flex
              v-if="
                item.exceeds &&
                item.connection == true &&
                item.settings.sensor_type == 12
              "
              d-flex
              nowrap
              row
              shrink
            >
              <span
                class="title grey--text text--darken-3"
                v-if="
                  item.settings.sensor_type === 12 && item.data.sensors.hall
                "
                >{{ content.door_true }}</span
              >
              <span class="title grey--text text--darken-3" v-else>{{
                content.door_false
              }}</span>
            </v-flex>
            <v-flex
              v-if="
                item.exceeds &&
                item.connection == true &&
                item.settings.sensor_type == 13
              "
              d-flex
              nowrap
              row
              shrink
            >
              <span
                class="title grey--text text--darken-3"
                v-if="
                  item.settings.sensor_type === 13 && item.data.sensors.hall
                "
                >{{ content.window_true }}</span
              >
              <span class="title grey--text text--darken-3" v-else>{{
                content.window_false
              }}</span>
            </v-flex>
            <v-flex
              v-if="
                item.exceeds &&
                item.connection == true &&
                item.rules[0].sensor == 'water'
              "
              d-flex
              nowrap
              row
              shrink
            >
              <span class="title grey--text text--darken-3">{{
                content.flooding
              }}</span>
            </v-flex>
            <v-flex v-if="!item.active" d-flex nowrap row shrink>
              <span class="title grey--text text--darken-3">
                {{ content.unpaid }}!
              </span>
            </v-flex>
            <v-flex
              v-if="item.active && item.connection && item.data.battery"
              shrink
            >
              <battery
                class="grey--text text--darken-2"
                :data="item.data.battery"
                :key="item.data.battery.text"
              />
            </v-flex>
          </v-layout>
        </div>
      </template>
    </v-flex>

    <v-flex xs12 v-if="inGroup" class="pa-3">
      <v-layout row wrap align-center>
        <v-flex shrink>
          <h3 class="pe-2">{{ content.device_exists + ":" }}</h3>
        </v-flex>
        <v-flex row>
          <h4>{{ devicesGroup.join(", ") }}</h4>
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex
      v-if="item.active && item.connection && item.role == 1"
      xs12
      class="pa-3"
    >
      <v-layout row wrap justify-center>
        <ModeBtns :slug="item.slug" :big="true" />
      </v-layout>
    </v-flex>

    <v-flex xs12>
      <section v-if="item.active" class="pt-2">
        <v-layout v-if="item.connection == false" row wrap justify-center>
          <p class="display-1 text-center py-1 my-1 px-2">
            {{ content.device_off }}
          </p>
        </v-layout>
        <v-layout v-if="item.data.sensors" row wrap justify-space-around>
          <template
            v-if="
              item.settings.sensor_type >= 1 &&
              item.settings.sensor_type <= 6 &&
              item.data.sensors.temperatures
            "
          >
            <v-flex
              v-for="(temp, index) in item.data.sensors.temperatures"
              :key="index"
              align-center
              class="text-xs-center align-items-center"
              text-center
            >
              <small class="title blue--text text--darken-2" dir="ltr">
                <Icon :md="true" icon="ac_unit" color="blue" />
                {{ temp }}℃
              </small>
            </v-flex>
          </template>
          <v-flex
            v-if="
              item.settings.sensor_type >= 2 &&
              item.settings.sensor_type <= 6 &&
              item.data.sensors.humidity
            "
            class="text-center"
            align-center
          >
            <small class="title cyan--text" dir="ltr">
              <Icon :md="true" :color="'cyan lighten-3'" icon="opacityIcon" />
              {{ item.data.sensors.humidity }}%
            </small>
          </v-flex>
          <v-flex
            v-if="'pressure' in item.data.sensors"
            align-center
            class="text-xs-center"
          >
            <small class="title teal--text" dir="ltr">
              <Icon :md="true" :color="'teal lighten-2'" icon="gauge" />
              {{ item.data.sensors.pressure }}<small>hPa</small>
            </small>
          </v-flex>
          <!-- <v-flex
            v-if="
              item.settings.sensor_type === 6 || item.settings.sensor_type === 7
            "
            align-center
            class="text-center"
          >
            <small class="title yellow--text text--darken-2" dir="ltr">
              <Icon
                :md="true"
                :color="'yellow darken-1'"
                icon="wb_incandescent"
              />
              {{ item.data.sensors.light + " " }}<small> lx </small>
            </small>
          </v-flex> -->
          <v-flex
            v-if="[6, 7].includes(item.settings.sensor_type)"
            align-center
            class="text-center"
          >
            <small class="title yellow--text text--darken-2" dir="ltr">
              <Icon
                :md="true"
                :color="'yellow darken-1'"
                icon="wb_incandescent"
              />
              <span v-if="item.settings.sensor_type === 7">
                {{
                  item.data.sensors.light || item.data.sensors.light != 0
                    ? "האור דולק"
                    : "האור כבוי"
                }}
              </span>
              <span v-else>
                {{ item.data.sensors.light }}<small> lx</small>
              </span>
            </small>
          </v-flex>
          <v-flex
            v-if="'co2' in item.data.sensors"
            align-center
            class="text-center justify-content-center"
          >
            <small class="title light-green--text text--darken-2" dir="ltr">
              <em class="co2-text-icon light-green--text text--darken-3">
                <span>CO²</span>
              </em>
              {{ item.data.sensors.co2 }}<small>ppm</small>
            </small>
          </v-flex>
          <v-flex
            v-if="'range' in item.data.sensors"
            align-center
            justify-center
            class="text-center d-flex"
          >
            <small class="title orange--text text--darken-3" dir="ltr">
              <em class="range-text-icon orange--text text--darken-3"> </em>
              {{ item.data.sensors.range.toFixed(2) }}<small>cm</small>
            </small>
            <span>
              <Icon
                :md="true"
                :color="'orange darken-3'"
                icon="ArrowExpandVertical"
              />
            </span>
          </v-flex>
          <v-flex
            v-if="
              item.settings.sensor_type >= 16 && item.settings.sensor_type <= 18
            "
          >
            <v-flex
              v-for="(wh, index) in item.data.sensors.electricity_usage"
              :key="index"
              align-center
              class="text-center"
            >
              <small class="title light-green--text text--darken-2" dir="ltr">
                <Icon :md="true" color="light-green" icon="flashOutline" />
                {{ wh }}<small>Wh</small>
              </small>
            </v-flex>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="item.data.sensors && item.data.sensors.tamper"
          row
          wrap
          justify-center
        >
          <v-flex align-center class="text-center pt-3">
            <span class="title orange--text text--darken-4">{{
              content.tamper
            }}</span>
          </v-flex>
        </v-layout>

        <v-layout
          v-if="'sensors' in item.data && item.settings.sensor_type >= 8"
          row
          wrap
          justify-center
        >
          <v-flex
            v-if="
              item.settings.sensor_type === 14 &&
              item.data.sensors.drycontact == 0
            "
            align-center
            class="text-center pt-3"
          >
            <span class="title teal--text text--darken-2">{{
              content.sensor_open
            }}</span>
          </v-flex>
          <v-flex
            v-if="
              item.settings.sensor_type === 14 &&
              item.data.sensors.drycontact == 1
            "
            align-center
            class="text-center pt-3 bg-red"
          >
            <span class="title red--text text--darken-4">{{
              content.sensor_close
            }}</span>
          </v-flex>
          <v-flex
            v-if="item.settings.sensor_type === 10 && item.data.sensors.water"
            align-center
            class="text-center pt-3 bg-red"
          >
            <span class="title red--text text--darken-4">{{
              content.water_true
            }}</span>
          </v-flex>
          <v-flex
            v-if="item.data.sensors.detection && item.status == 'Off'"
            align-center
            class="text-center pt-3 bg-red"
          >
            <span class="title red--text text--darken-4">{{
              content.motion
            }}</span>
          </v-flex>
          <v-flex
            v-if="item.data.sensors.smoke"
            align-center
            class="text-center pt-3 bg-red"
          >
            <span class="title red--text text--darken-4">{{
              content.smoke
            }}</span>
          </v-flex>
          <v-flex
            v-if="item.settings.sensor_type === 11 && item.data.sensors.hall"
            align-center
            class="text-center pt-3"
          >
            <span class="title teal--text text--darken-2">{{
              content.magnet_true
            }}</span>
          </v-flex>
          <v-flex
            v-if="item.settings.sensor_type === 11 && !item.data.sensors.hall"
            align-center
            class="text-center pt-3"
          >
            <span class="title teal--text text--darken-2">{{
              content.magnet_false
            }}</span>
          </v-flex>
          <v-flex
            v-if="item.settings.sensor_type === 12 && item.data.sensors.hall"
            align-center
            justify-center
            class="text-center pt-3"
          >
            <span class="title teal--text text--darken-2">{{
              content.door_true
            }}</span>
          </v-flex>
          <v-flex
            v-if="item.settings.sensor_type === 12 && !item.data.sensors.hall"
            align-center
            justify-center
            class="text-center pt-3"
          >
            <span class="title teal--text text--darken-2">{{
              content.door_false
            }}</span>
          </v-flex>
          <v-flex
            v-if="item.settings.sensor_type === 13 && item.data.sensors.hall"
            align-center
            class="text-center pt-3"
          >
            <span class="title teal--text text--darken-2">{{
              content.window_true
            }}</span>
          </v-flex>
          <v-flex
            v-if="item.settings.sensor_type === 13 && !item.data.sensors.hall"
            align-center
            class="text-center pt-3 bg-red"
          >
            <span class="title teal--text text--darken-2">{{
              content.window_false
            }}</span>
          </v-flex>
          <v-flex
            v-if="item.settings.sensor_type == 21"
            align-center
            class="text-center pt-3 bg-red"
          >
            <span
              v-if="item.data.sensors.isVibrating == 0"
              class="title teal--text text--darken-2"
              >{{ content.vibrate_false }}</span
            >
            <span v-else class="title teal--text text--darken-2">{{
              content.vibrate_true
            }}</span>
          </v-flex>
        </v-layout>

        <v-layout
          v-if="'sensors' in item.data && item.settings.sensor_type >= 8"
          row
          wrap
          justify-center
        >
          <v-flex
            v-if="item.settings.sensor_type === 14"
            class="ma-2 text-xs-center"
          >
            <v-layout row nowrap justify-center>
              <Icon
                iconClass="dryContact"
                color="switchq"
                icon="drycontact"
                :xlarge="true"
              />
            </v-layout>
          </v-flex>
          <v-flex
            v-if="item.settings.sensor_type === 11"
            class="ma-2 text-center"
          >
            <Icon
              v-if="item.data.sensors.hall"
              color="switchq"
              icon="magnetOn"
              :xlarge="true"
            />
            <Icon v-else color="red" icon="magnetIcon" :xlarge="true" />
          </v-flex>
          <v-flex
            v-if="item.settings.sensor_type === 12"
            class="ma-2 text-center"
          >
            <Icon
              v-if="item.data.sensors.hall"
              color="switchq"
              icon="doorClosed"
              :xlarge="true"
            />
            <Icon v-else color="red" icon="doorOpen" :xlarge="true" />
          </v-flex>
          <v-flex
            v-if="item.settings.sensor_type === 13"
            class="ma-2 text-center"
          >
            <Icon
              v-if="!item.data.sensors.hall"
              color="red"
              icon="windowOpenVariant"
              :xlarge="true"
            />
            <Icon
              v-else
              color="switchq"
              icon="windowClosedVariant"
              :xlarge="true"
            />
          </v-flex>
          <v-flex
            v-if="item.settings.sensor_type === 21"
            class="ma-2 text-center"
          >
            <Icon
              v-if="item.data.sensors.isVibrating != 0"
              color="switchq"
              icon="mdiVibrate"
              :xlarge="true"
            />
            <Icon v-else color="switchq" icon="mdiVibrateOff" :xlarge="true" />
          </v-flex>
          <v-flex
            v-if="'detection' in item.data.sensors"
            class="ma-2 text-xs-center"
          >
            <Icon
              v-if="item.status == 'Off'"
              color="red"
              icon="walkIcon"
              :xlarge="true"
            />
            <Icon v-else color="switchq" icon="eyeOutline" :xlarge="true" />
          </v-flex>
          <v-flex
            v-if="'smoke' in item.data.sensors"
            class="ma-2 text-xs-center"
          >
            <Icon
              v-if="item.status == 'Off'"
              color="red"
              icon="fireIcon"
              :xlarge="true"
            />
            <Icon v-else color="switchq" icon="smokeDetector" :xlarge="true" />
          </v-flex>
          <v-flex
            v-if="item.settings.sensor_type === 10"
            class="ma-2 text-xs-center"
          >
            <v-layout v-if="item.data.sensors.water" row nowrap justify-center>
              <Icon color="info" icon="homeFlood" :xlarge="true" />
            </v-layout>
            <v-layout v-else row nowrap justify-center>
              <Icon color="info" icon="waterIcon" :xlarge="true" />
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout
          v-if="item.devmodes > 1 && item.role == 1"
          class="my-3"
          row
          wrap
        >
          <v-flex xs12>
            <!--  !!!!!!  -  Devmode Form  -  !!!!!!   -->
            <devmode
              v-if="item.connection"
              :locale="locale"
              :devmodes="item.devmodes"
              :key="item.slug + '-devmode'"
            ></devmode>
            <!--  !!!!!!  -  Threshold Form  -  !!!!!!   -->
            <threshold
              v-if="item.devmodes > 0 && item.connection"
              :locale="locale"
              :key="item.slug + '-threshold'"
            ></threshold>
          </v-flex>
        </v-layout>

        <v-layout class="my-3" row wrap>
          <v-flex xs12>
            <notificationRules :key="item.slug" />
          </v-flex>
        </v-layout>

        <!--  !!!!!!  -  Schedule  -  !!!!!!   -->
        <schedule
          v-if="item.role == 1"
          :locale="locale"
          :action="reqScheduleAction"
          :key="item.slug"
          class="mt-2"
        />

        <!--  !!!!!!  -  Map Iframe  -  !!!!!!   -->
        <v-layout
          v-if="item.type == 3 && item.connection && item.data.gps"
          row
          wrap
          justify-center
        >
          <v-flex grow :key="item.data.gps.latitude + item.data.gps.longitude">
            <iframe
              :src="
                'https://maps.google.com/maps?q=' +
                item.data.gps.latitude +
                ',' +
                item.data.gps.longitude +
                '&hl=' +
                this.locale +
                '&z=14&amp;output=embed'
              "
              width="100%"
              height="300"
              frameborder="0"
              style="border: 0"
              allowfullscreen
            ></iframe>
          </v-flex>
        </v-layout>
      </section>
      <section v-else-if="item.status == 'Unpaid'">
        <p class="display-1 text-xs-center py-5 my-4 px-2">
          {{ content.unpaid }}!
        </p>
      </section>

      <!--  !!!!!!  -  Graphs  -  !!!!!!   -->
      <section
        id="graph"
        v-if="
          item.active &&
          item.log_settings.required &&
          item.settings.sensor_type != 9
        "
      >
        <!-- &&
      item.settings.sensor_type != 13 &&
      item.settings.sensor_type != 21 -->
        <!-- v-if="item.connection" -->
        <Graph
          :slug="item.slug"
          :type="item.data.type"
          :sensors="item.settings.sensor_type"
          :role="item.role"
          :devmode="item.data.type == 4 ? 0 : item.data.devmode"
          :key="item.slug"
        />
      </section>
      <!-- !!!!!! - Device Notification table - !!!-->

      <v-layout row justify-center class="mt-4 mb-2" v-if="item.active">
        <v-flex text-center xs12 class="grey--text text--lighten-1">
          <v-btn
            width="300px"
            color="switchq lighten-1 text--accent-1"
            type="submit"
            dark
            class="mb-3"
            outlined
            @click="deviceNotifications = !deviceNotifications"
            >{{ content.lastNots }}
            <Icon
              v-if="!deviceNotifications"
              iconClass="ms-4"
              icon="chevronDown"
            />
            <Icon
              v-if="deviceNotifications"
              iconClass="ms-4"
              icon="chevronUp"
            />
          </v-btn>
        </v-flex>
        <v-expand-transition>
          <DeviceNots v-if="deviceNotifications" />
        </v-expand-transition>
      </v-layout>

      <!--  !!!!!!  -  Settings  -  !!!!!!   -->
      <v-layout row wrap justify-center class="mt-4 mb-2">
        <Settings
          :name="item.name"
          :role="item.role"
          :slug="item.slug"
          :type="item.type"
          :key="item.slug + '-settings'"
        />
      </v-layout>

      <!--  !!!!!!  -  Last Update  -  !!!!!!   -->
      <v-layout v-if="item.active" row wrap justify-center class="mt-4 mb-2">
        <v-flex text-center class="grey--text text--lighten-1">
          {{ content.last_update }}:
          <span dir="ltr">{{ item.received }}</span>
        </v-flex>
      </v-layout>

      <v-layout v-if="item.data.signal" class="mt-8 mb-4" row wrap>
        <rssi :slug="item.slug" :key="item.slug" />
      </v-layout>
    </v-flex>

    <Reqschedule
      v-if="item.data.schedule"
      :slug="item.slug"
      :status="
        Object.keys(item.data.schedule).length < 1 && item.data.mode == 0
          ? true
          : false
      "
      @reqScheduleEvent="reqScheduleAnswer"
    />
  </v-layout>
  <v-layout v-else>
    <v-flex xs12>
      <loading />
    </v-flex>
  </v-layout>
</template>

<script>
import ModeBtns from "@/components/devices/modebtns.vue";
import Battery from "@/components/tools/battery.vue";
import Rssi from "@/components/tools/rssi";
import Devmode from "@/components/devices/edit/devmode.vue";
import Threshold from "@/components/devices/edit/threshold";
import notificationRules from "@/components/devices/edit/notificationRules";
import Schedule from "@/components/devices/schedule";
import Reqschedule from "@/components/devices/schedule-required";
import DeviceNots from "@/components/devices/DeviceNots.vue";
import Settings from "./itemsettings";
import loading from "@/components/tools/loading.vue";
import Graph from "@/components/tools/itemgraph.vue";
export default {
  components: {
    ModeBtns,
    Battery,
    Rssi,
    Devmode,
    Threshold,
    notificationRules,
    Schedule,
    Reqschedule,
    Graph,
    Settings,
    loading,
    DeviceNots,
  },
  props: ["locale", "dir"],
  data() {
    return {
      deviceNotifications: false,
      loadingBtn: null,
      loadingDevmode: null,
      loadingThreshold: null,
      threshold: {
        btn: {
          color: "blue",
          loading: false,
        },
      },
      devmode: {
        status: null,
        color: null,
      },

      reqScheduleAction: null,
    };
  },
  computed: {
    item() {
      return this.$store.getters["devices/device"](this.$route.params.slug);
    },
    devicesGroup() {
      let groups = this.$store.getters["groups/groups"];
      return Object.values(groups)
        .filter((g) => g.devices.includes(this.item.slug))
        .map((g) => g.name);
    },
    inGroup() {
      return this.devicesGroup.length > 0;
    },
  },
  created() {
    this.setLang();
  },
  mounted() {
    // console.log("device", this.item);
    if (this.$vuetify.breakpoint.smAndDown) {
      this.$vuetify.goTo(0, {
        duration: 0,
      });
    }
  },
  methods: {
    reqScheduleAnswer(answer) {
      this.reqScheduleAction = answer;
    },

    setLang() {
      if (this.locale == "en") {
        this.content = {
          device_exists: "Groups",
          device_off: "The device has no power or internet connection",
          unpaid: "Not Authorized",
          not_connected: "Not Connected",
          no_sensor: "Sensor is not connected",
          tamper: "Tamper warning!",
          water_true: "Detects water",
          water_false: "Does not detect water",
          sensor_open: "The buoy is in a normal state",
          sensor_close: "Warning of flooding!",
          flooding: "Warning of flooding!",
          motion: "Detected motion!",
          smoke: "Warning of smoke!",
          magnet_true: "Magnet detected",
          magnet_false: "Magnet is NOT detected",
          door_true: "The door is closed",
          door_false: "The door is open",
          window_true: "The window is closed",
          window_false: "The window is open",
          vibrate_true: "Vibrates!",
          vibrate_false: "Steady no vibrations",
          exceeds: "Exceeds the threshold",
          agree: "Agree",
          cancel: "Cancel",
          last_update: "Last Update",
          lastNots: "Last device's notifications",
        };
      } else if (this.locale == "he") {
        this.content = {
          device_exists: "קבוצות",
          device_off: "למכשיר אין חיבור לחשמל או לאינטרנט",
          unpaid: "לא מורשה",
          not_connected: "לא מחובר",
          no_sensor: "חיישן אינו מחובר",
          tamper: "אזהרת התעסקות!",
          water_true: "מזהה רטיבות",
          water_false: "אינו מזהה רטיבות",
          sensor_open: "המצוף במצב תקין",
          sensor_close: "אזהרת הצפה!",
          flooding: "אזהרת הצפה!",
          motion: "זיהה תנועה!",
          smoke: "אזהרת עשן!",
          magnet_true: "מזהה מגנט",
          magnet_false: "לא מזהה מגנט",
          door_true: "הדלת סגורה",
          door_false: "הדלת פתוחה",
          window_true: "החלון סגור",
          window_false: "החלון פתוח",
          vibrate_true: "רועד!",
          vibrate_false: "יציב ללא רעידות",
          exceeds: "חורג מהסף",
          agree: "מסכים",
          cancel: "ביטול",
          last_update: "עדכון אחרון",
          lastNots: "התראות מכשיר אחרונות",
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.device-header {
  border-radius: 10px;
  .switchBar {
    border-radius: 10px;
  }
}

.itemTitle {
  h2 {
    padding: 0;
    white-space: wrap;
    font-weight: 500;
  }
}

.v-btn.outlined {
  border: 1px solid #f00;
  border-radius: 50%;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #eeeeee;
  margin: 1em 0;
  padding: 0;
}

.sensorData {
  display: flex;
}

.item-layout {
  margin-top: 12px;
}

.dryContact {
  max-height: 40px;
  width: auto;
}

.magnet {
  max-height: 50px;
  width: auto;
}

.center-input {
  text-align: center;
}
.center-input::-webkit-input-placeholder {
  text-align: center;
}
.center-input:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}
.center-input::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}
.center-input:-ms-input-placeholder {
  text-align: center;
}
</style>
