<template>
  <div class="chartcontainer w-100 elevation-4" dir="ltr">
    <!-- <LineChartGenerator
      dir="rtl"
      ref="line"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :width="chartWidth"
      :height="300"
    /> -->
    <apexchart
      type="rangeBar"
      :options="chartOptions"
      :series="series"
      :height="300"
      :width="chartWidth"
    ></apexchart>
  </div>
</template>
<script>
import dayjs from "@/plugins/dayjs";
import VueApexCharts from "vue-apexcharts"; // import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import lang from "@/helpers/lang";
import "chartjs-adapter-luxon";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: ["logs", "sensors", "temperaturescount"],
  name: "stackedBarChart",
  data() {
    return {
      LOCALE: null,

      series: [],
      chartOptions: {},
    };
  },
  computed: {
    chartWidth() {
      if (this.width) return this.width;
      if (this.$vuetify.breakpoint.xs) {
        return "120%";
      } else {
        const contWidth = document.getElementById("mainWrap").offsetWidth;
        return contWidth > 600 ? 550 : contWidth - 16;
      }
    },
    device() {
      return this.$store.getters["devices/device"](this.$route.params.slug);
    },
    deviceSensors() {
      const sensorsNames = {
        hall: "מגנט",
        water: "רטיבות",
        drycontact: "מגע יבש",
        light: "אור",
        isVibrating: "רעידות",
      };
      if (
        this.device &&
        this.device.data &&
        Object.keys(this.device.data.sensors).length
      ) {
        // delete this.device.data.sensors.temperatures;
        return Object.keys(this.device.data.sensors)
          .map((s) => {
            // if (s === "temperatures") s = "temperature";
            return {
              value: s,
              text: sensorsNames[s],
            };
          })
          .filter((s) => s.text);
      } else {
        return {};
      }
    },
  },
  mounted() {
    this.LOCALE = lang.get();
    this.sensors;
    // console.log(this.deviceSensors);
    this.setDataNew();
    this.initiate();
  },
  methods: {
    async initiate() {
      this.$emit("ready");
    },
    setDataNew() {
      let category = null;
      let state0 = null;
      let state1 = null;

      let sensorName = {
        7: "light",
        8: "detection",
        10: "water",
        11: "hall",
        12: "door",
        13: "window",
        14: "drycontact",
        21: "isVibrating",
      };

      switch (this.device.settings.sensor_type) {
        case 7:
          category = sensorName[this.device.settings.sensor_type];
          state1 = this.$t("lightOn");
          state0 = this.$t("lightOff");
          break;
        case 8:
          category = sensorName[this.device.settings.sensor_type];
          state1 = this.$t("detectionOn");
          state0 = this.$t("detectionOff");
          break;
        case 10:
          category = sensorName[this.device.settings.sensor_type];
          state1 = this.$t("wet");
          state0 = this.$t("dry");
          break;
        case 11:
          category = sensorName[this.device.settings.sensor_type];
          state1 = this.$t("open");
          state0 = this.$t("close");
          break;
        case 12:
          category = sensorName[this.device.settings.sensor_type];
          state1 = this.$t("open");
          state0 = this.$t("close");
          break;
        case 13:
          category = sensorName[this.device.settings.sensor_type];
          state1 = this.$t("open");
          state0 = this.$t("close");
          break;
        case 14:
          category = sensorName[this.device.settings.sensor_type];
          state1 = this.$t("open");
          state0 = this.$t("close");
          break;
        case 21:
          category = sensorName[this.device.settings.sensor_type];
          state1 = this.$t("vibrate");
          state0 = this.$t("steady");
          break;

        default:
          break;
      }

      let createIntervals = (data) => {
        // console.log("category", category, state1, state0);
        const intervals = { state1: [], state0: [] };
        let currentState = null;
        let intervalStart = null;

        data.forEach((item, index) => {
          // console.log("item", item);
          category == "window" || category == "door"
            ? (category = "hall")
            : null;
          if (!item[category]) return; // Skip entries without drycontact

          if (currentState === null) {
            currentState = item[category];
            intervalStart = item.time;
          } else if (currentState !== item[category]) {
            // State change detected, close the previous interval
            const intervalEnd = data[index - 1].time;
            if (currentState === "1") {
              intervals.state1.push({
                // x: this.$t(category),
                x:
                  this.device.settings.sensor_type != 12 &&
                  this.device.settings.sensor_type != 13
                    ? this.$t(category)
                    : this.device.settings.sensor_type != 12
                    ? this.$t("window")
                    : this.$t("door"),
                y: [intervalStart, intervalEnd],
              });
            } else {
              intervals.state0.push({
                x:
                  this.device.settings.sensor_type != 12 &&
                  this.device.settings.sensor_type != 13
                    ? this.$t(category)
                    : this.device.settings.sensor_type != 12
                    ? this.$t("window")
                    : this.$t("door"),
                y: [intervalStart, intervalEnd],
              });
            }
            // Start new interval
            currentState = item[category];
            intervalStart = item.time;
          }
        });

        // Close the last interval
        if (intervalStart !== null) {
          const intervalEnd = data[data.length - 1].time;
          if (currentState === "1") {
            intervals.state1.push({
              x:
                this.device.settings.sensor_type != 12 &&
                this.device.settings.sensor_type != 13
                  ? this.$t(category)
                  : this.device.settings.sensor_type != 12
                  ? this.$t("window")
                  : this.$t("door"),
              y: [intervalStart, intervalEnd],
            });
          } else {
            intervals.state0.push({
              x:
                this.device.settings.sensor_type != 12 &&
                this.device.settings.sensor_type != 13
                  ? this.$t(category)
                  : this.device.settings.sensor_type != 12
                  ? this.$t("window")
                  : this.$t("door"),
              y: [intervalStart, intervalEnd],
            });
          }
        }

        return intervals;
      };

      const intervals = createIntervals(this.logs);
      // console.log("intervals: ", intervals);

      this.series = [
        {
          name: this.$t(state1),
          data: intervals.state1,
        },
        {
          name: this.$t(state0),
          data: intervals.state0,
        },
      ];
      // console.log("series: ", this.series);
      this.chartOptions = {
        chart: {
          height: 300,
          type: "rangeBar",
          offsetX: -10,
          toolbar: {
            show: true,
            offsetX: -20,
            offsetY: 0,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              // customIcons: [],
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "100%",
            rangeBarGroupRows: true,
          },
        },
        colors: ["#ff6178", "#26e7a6"], // Blue for Flooded, Green for Dry
        fill: {
          type: "solid",
        },
        xaxis: {
          type: "datetime",
          min: this.logs[0].time,
          max: this.logs[this.logs.length - 1].time,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 10,
        },

        tooltip: {
          followCursor: false,
          fixed: {
            enabled: true,
            position: "center",
            offsetX: 35,
            offsetY: 40,
          },
          custom: function (opts) {
            const fromYear = dayjs(opts.y1).format("HH:mm:ss DD/MM/YYYY");
            const toYear = dayjs(opts.y2).format("HH:mm:ss DD/MM/YYYY");

            const w = opts.ctx.w;
            // let ylabel = w.globals.labels[opts.dataPointIndex];
            let seriesName = w.config.series[opts.seriesIndex].name
              ? w.config.series[opts.seriesIndex].name
              : "";
            const color = w.globals.colors[opts.seriesIndex];
            return (
              '<div class="apexcharts-tooltip-rangebar" dir="ltr">' +
              '<div> <span class="series-name" style="color: ' +
              color +
              '">' +
              (seriesName ? seriesName : "") +
              "</span></div>" +
              '  <span class="value start-value">' +
              fromYear +
              '</span> <span class="separator">-</span> <span class="value end-value">' +
              toYear +
              "</span></div>"
            );
          },
        },
      };
    },

    formatTime(time) {
      return dayjs(time).isValid()
        ? dayjs(time).format("HH:mm:ss DD/MM/YYYY")
        : time;
    },
  }, // End of Methods
};
</script>
<style lang="scss" scoped>
.chartcontainer {
  background-color: #fff;
  border-radius: 5px;
  padding: 8px;
}

.apexcharts-tooltip-rangebar {
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  z-index: 1000;
}
</style>
