import axios from "@/plugins/axios";
import Devices from "@/helpers/devices";
/**
 * Initial state
 */
export const state = {
  devices: null,
  loading: true,
  fetching: false,
  fetchedLogs: false,
  dailyLogs: {},
  hasDailyLogs: [],
};

/**
 * Mutations
 */
export const mutations = {
  ["SET_DEVICES"](state, devices) {
    // setTimeout(() => {
    devices = Devices.analyze(devices);
    Devices.store(devices);
    state.devices = devices;
    state.loading = false;
    // }, 5000);
  },
  ["REANALAYZE_DEVICES"](state, devices) {
    devices = Devices.analyze(state.devices);
    Devices.store(devices);
    state.devices = devices;
  },
  ["SET_DAILY_LOGS"](state, data) {
    state.dailyLogs[data.slug] = data.logs;
    state.hasDailyLogs.push(data.slug);
  },
  ["SET_ANALYZED_DEVICES"](state, devices) {
    Devices.store(devices);
    state.devices = devices;
  },
  ["SET_DEVICES_FAILURE"](state) {
    console.error("SET_DEVICES_FAILURE");
    state.devices = null;
  },
  ["START_LOADING"](state) {
    // Vue.set(state, "loading", true);
    state.loading = true;
  },
  ["SET_DEVICE"](state, device) {
    // console.log("device is", device);
    let devices = state.devices;
    if (device[1] == true) {
      const newDevice = Devices.analyzeItem(device[0]);
      devices[device[0].slug] = newDevice;
      // Vue.set(state.devices, data[0].slug, device);
      Devices.store(devices);
      // state.devices = devices;
    } else {
      const newDevice = Devices.analyzeItem(device[0]);
      devices[device[0].slug] = newDevice;
      Devices.store(devices);
    }
    state.devices = devices;
  },
  ["UNSET_DEVICE"](state, slug) {
    // Vue.delete(state.devices, slug);
    let devices = state.devices;
    delete devices[slug];
    state.devices = devices;
    Devices.store(state.devices);
  },
  ["START_DEVICES_FETCHING"](state) {
    state.fetching = true;
  },
  ["END_DEVICES_FETCHING"](state) {
    state.fetching = false;
  },
  ["START_FETCHING_LOGS"](state) {
    state.fetchedLogs = true;
  },
  ["LOGOUT"](state) {
    state.devices = null;
    state.loading = true;
    state.fetching = false;
    state.fetchedLogs = false;
    state.dailyLogs = {};
    state.hasDailyLogs = [];
  },
};

/**
 * Actions
 */
export const actions = {
  logOut({ commit }) {
    // this.reset();
    commit("LOGOUT");
  },
  async fetchDevices({ commit }) {
    commit("START_DEVICES_FETCHING");
    // localStorage.removeItem('mydata');
    await axios
      .get(`/devices`)
      .then(async (res) => {
        if (res.data) {
          await commit("SET_DEVICES", res.data.data);
          commit("END_DEVICES_FETCHING");
        }
      })
      .catch((err) => {
        commit("SET_DEVICES_FAILURE");
        commit("END_DEVICES_FETCHING");
        console.error(err);
      });
  },
  setAnalyzedDevices({ commit }, devices) {
    commit("SET_ANALYZED_DEVICES", devices);
  },
  async fetchDevice({ commit }, device) {
    commit("START_DEVICES_FETCHING");
    localStorage.removeItem("mydata");
    await axios
      .get(`/switch/get?slug=${device}`)
      .then(async (res) => {
        if (res.data) {
          await commit("SET_DEVICE", [res.data.device, true]);
          commit("END_DEVICES_FETCHING");
        }
      })
      .catch((err) => {
        console.error(err);
        commit("END_DEVICES_FETCHING");
      });
  },
  reanalyzeDevices({ commit }) {
    commit("REANALAYZE_DEVICES");
  },
  setDevice({ commit }, device) {
    commit("SET_DEVICE", [device, false]);
  },
  startLoading({ commit }) {
    commit("START_LOADING");
  },
  async modifyDevice({ commit }, data) {
    let action = data.action ? data.action : "modify";
    await axios
      .post(`/devices/${data.slug}/${action}`, data.modify)
      .then((res) => {
        // console.log("the res is", res.data);
        if (res.data.success) {
          if (action === "delete") {
            commit("UNSET_DEVICE", data.slug);
          } else {
            commit("SET_DEVICE", [res.data.success, true]);
          }
        }
        return true;
      })
      .catch((err) => {
        console.error("err is:", err);
        if (!err.message.includes("500")) {
          this.dispatch("general/setAlert", {
            type: "failed",
            msg: "oops",
          });
        }
        return false;
      });
  },
  async deviceDailyLogs({ commit }, device) {
    await axios
      .post(`/devices/${device}/logs`, { time: "6hours" })
      .then(async (res) => {
        commit("SET_DAILY_LOGS", { logs: res.data.data, slug: device });
      })
      .catch((err) => {
        console.error(err);
      });
  },
  startFetchingLogs({ commit }) {
    commit("START_FETCHING_LOGS");
  },
};

/**
 * Getters
 */
export const getters = {
  // devices: (state) => state.devices,
  loading: (state) => state.loading,
  logs: (state) => state.dailyLogs,
  fetchedLogs: (state) => state.fetchedLogs,
  deviceLogs: (state) => (slug) => state.dailyLogs[slug],
  hasDailyLogs: (state) => (slug) => state.hasDailyLogs.includes(slug),
  devices: (state) => {
    const devices = state.devices ? state.devices : Devices.get();
    if (devices) {
      const sortable = Object.values(devices).sort((a, b) => {
        const o1 = a.view && a.view.order ? a.view.order : 2048;
        const o2 = b.view && b.view.order ? b.view.order : 2049;
        return o1 - o2;
      });
      let mapped = {};
      for (let device in sortable) {
        if (
          // sortable.hasOwnProperty(device) &&
          sortable[device] &&
          sortable[device].slug
        ) {
          if (sortable[device].view && sortable[device].view.visibility) {
            mapped[sortable[device].slug] = sortable[device];
          }
        }
      }
      return mapped;
    } else {
      return {};
    }
  },
  alldevices: (state) => {
    const devices = state.devices ? state.devices : Devices.get();
    if (devices) {
      const sortable = Object.values(devices).sort((a, b) => {
        const o1 = a.view && a.view.order ? a.view.order : 2048;
        const o2 = b.view && b.view.order ? b.view.order : 2049;
        return o1 - o2;
      });
      // const sortable = Object.values(devices).sort((a,b) => a.view.order - b.view.order);
      let mapped = {};
      for (let device in sortable) {
        if (device in sortable && sortable[device] && sortable[device].slug) {
          mapped[sortable[device].slug] = sortable[device];
        }
      }
      return mapped;
    } else {
      return {};
    }
  },
  devicesCount: (state, getters) =>
    getters.alldevices ? Object.keys(getters.alldevices).length : null,
  device: (state) => (slug) => state.devices ? state.devices[slug] : null,
  // loading: (state, getters) =>
  //   Object.keys(getters.devices).length ? false : state.loading,
  fetching: (state) => state.fetching,
};
